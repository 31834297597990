//aboutUs.js
import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './Home.css';
import './AboutUs.css';
import Sidebar from './sidebar';
import './Profile.css';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { useNavigate,useLocation } from 'react-router-dom';
import firestore from './Firebase/Firestore';
import storage from './Firebase/Storage';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IoSearchOutline, IoPencil, IoTrash, IoPerson, IoBarChart, IoNotifications } from "react-icons/io5";
import { IoLogoAndroid  } from "react-icons/io5";
import { Alert, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Layout from './Layout';
import { UserContext } from './UserContext';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import LoadingBackdrop from './LoadingBackdrop';
import Swal from 'sweetalert2';
import 'animate.css';
import emailjs from 'emailjs-com';


function AboutUs() {
  const navigate = useNavigate();
  const location = useLocation();

  let DEFAULT_LOGO_URL = "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/PAM_HR_logo.png?alt=media&token=00d6bf28-0af6-49f9-bdd4-2a999fd19382";
  const [logoUrl, setLogoUrl] = useState(''); // State for logo URL
  const [companyName,setCompanyName] = useState('');
  const [detail, setDetail] = useState('');
  const [help_1,setHelp_1] = useState('');
  const [help_2,setHelp_2] = useState('');
  const [help_3,setHelp_3] = useState('');
  //const [companyData, setCompanyData] = useState(null);
  const [timeLeft, setTimeLeft] = useState('');
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCompanyName, setEditCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const [deleteEmail, setDeleteEmail] = useState('');
  const { account, companyId,userPlan,company_name,setCompany_Name,companyData,userData } = useContext(UserContext);

  const EMAILJS_SERVICE_ID = 'service_5egw6b8';
  const EMAILJS_TEMPLATE_ID = 'template_wrkesla';
  const EMAILJS_USER_ID = 'sT8ZIWDhLD1_zjzvb';

  const planData = [
    { title: 'demo', features: ['Support 2 employees.', 'Basic payroll processing.'] },
    { title: '10', features: ['Support 10 employees.', 'Basic payroll processing.'] },
    { title: '20', features: ['Support 20 employees.', 'Basic payroll processing.'] },
    { title: '30', features: ['Support 30 employees.', 'Basic payroll processing.'] },
    { title: '50', features: ['Support 50 employees.', 'Basic payroll processing.'] },
    { title: '80', features: ['Support 80 employees.', 'Basic payroll processing.'] },
    { title: '100', features: ['Support 100 employees.', 'Basic payroll processing.'] },
    { title: '125', features: ['Support 125 employees.', 'Basic payroll processing.'] },
    { title: '150', features: ['Support 150 employees.', 'Basic payroll processing.'] },
  ];

   // ฟังก์ชันเปิด-ปิด Modal
   const handleOpenEditModal = () => {
    setEditCompanyName(companyName); // เตรียมค่าเดิมมาใส่ในฟอร์ม
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  // ฟังก์ชันบันทึกชื่อบริษัทใหม่
  const handleSaveCompanyName = async () => {
    setIsLoading(true)
    try {
      // เรียกใช้งานฟังก์ชัน update จาก firestore
      // สมมุติว่ามี updateCompanyData ภายใน Firestore.js
      await firestore.updateCompanyData(companyId, {
        companyName: editCompanyName
      });

      // อัปเดต state local
      setCompany_Name(editCompanyName);

      //alert('Company name updated successfully!');
      setShowEditModal(false);
      setIsLoading(false)
      Swal.fire({
        title: 'Success',
        text: 'Company name updated successfully!',
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
      })
    } catch (error) {
      console.error('Error updating company name:', error);
      //alert('Error updating company name. Please try again.');
      setIsLoading(false)
      Swal.fire({
        title: 'Error',
        text:  `Error updating company name: ${error}`,
        icon: 'error',
        customClass: {
          popup: 'my-swal-size'
        },
      })
    }
  };

  const onSaveAbout=()=>{
    firestore.addAboutUs(companyId,{
      aboutUs:detail || '',
      help_1:help_1 || '',
      help_2:help_2 || '',
      help_3:help_3 || '',
    },()=>{
      Swal.fire({
        title: 'Success',
        text: 'Save successfully!',
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
      })
    },
  (e)=>{
    Swal.fire({
      title: 'Error',
      text: `Save error: ${e}`,
      icon: 'error',
      customClass: {
        popup: 'my-swal-size'
      },
    })
  })
  }

  const fetchLogo = async () => {
    //localStorage.removeItem('logo');
    if (!companyId) {
      setLogoUrl(DEFAULT_LOGO_URL); // Set default logo if companyId is not available
      return;
    }
  
    const cachedLogo = localStorage.getItem('logo');
    if (cachedLogo && cachedLogo !== 'undefined' && cachedLogo !== 'null' && cachedLogo.trim() !== '') {
      console.log("logo: ",cachedLogo)
      setLogoUrl(cachedLogo); // Use cached logo if available
      return;
    }
  
    try {
      const logoData = await firestore.getCompanyData(companyId); // Fetch company data from Firestore
      //console.log("logo: ",logoData)
      if (logoData?.logo) {
        setLogoUrl(logoData.logo);
        DEFAULT_LOGO_URL=logoData.logo
        localStorage.setItem('logo', logoData.logo); // Cache the logo in localStorage
      } else {
        setLogoUrl(DEFAULT_LOGO_URL); // Set default logo if Firestore does not have a logo
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
      setLogoUrl(DEFAULT_LOGO_URL); // Set default logo in case of an error
    }
    //setLogoUrl(DEFAULT_LOGO_URL)
  };

  useEffect(() => {
    fetchLogo()
    firestore.getAboutUs(companyId,(data)=>{
      setDetail(data.aboutUs)
      setHelp_1(data.help_1)
      setHelp_2(data.help_2)
      setHelp_3(data.help_3)
      setCompanyName(data.companyName)
      //setLogoUrl(data.logo)
    },(e)=>console.log(e))
    
  }, [location.state,companyId]);

  const handleLogoChange = async (event) => {
    console.log('handleLogoChange triggered');
    const file = event.target.files[0];
    if (!file) return;
  
    const previousLogo = logoUrl; // Save current logo URL before changing
  
    try {
      localStorage.removeItem('logo'); 
      // Upload the new logo
      setIsLoading(true)
      const newLogoUrl = await storage.uploadImage(companyId, file);
      console.log('newURL:',newLogoUrl)
  
      // Update Firestore with the new logo
      await firestore.updateLogo(companyId, { logo: newLogoUrl });
  
      // Set the new logo in the UI
     // const forcedUrl = newLogoUrl + '?t=' + Date.now();
      setLogoUrl(newLogoUrl);
  
      // Cache the new logo in localStorage
      localStorage.setItem('logo', newLogoUrl);
  
      // Delete the old logo if it's not the default one
      if (previousLogo && previousLogo !== DEFAULT_LOGO_URL) {
        await storage.deleteFile(previousLogo);
      }
  
      // Show success alert
      setIsLoading(false)
      //alert('Logo updated successfully!')
      Swal.fire({
        title: 'Success',
        text: 'Logo updated successfully!',
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
      })
      window.dispatchEvent(new Event('logoUpdated'));
    } catch (error) {
      setIsLoading(false)
      console.error('Error updating logo:', error);
      //alert('Error updating logo. Please try again.');
      setLogoUrl(DEFAULT_LOGO_URL); // Reset to default logo in case of an error
      Swal.fire({
        title: 'Error',
        text: `Error updating logo: ${error}`,
        icon: 'error',
        customClass: {
          popup: 'my-swal-size'
        },
      })
    }
  };

  // ฟังก์ชันคำนวณเวลาที่เหลือ (14 วัน)
  const calculateCountdown = (trialStart) => {
    // กำหนดระยะเวลาทดลอง 14 วัน (14 วัน = 14 * 24 * 3600 * 1000 มิลลิวินาที)
    const trialDuration = 14 * 24 * 3600 * 1000;
    const trialEnd = new Date(trialStart.getTime() + trialDuration);
    const now = new Date();
    const diff = trialEnd - now;
    //console.log(diff)
    if (diff <= 0) {
      return "Trial expired";
    }

    const days = Math.floor(diff / (24 * 3600 * 1000));
    const hours = Math.floor((diff % (24 * 3600 * 1000)) / (3600 * 1000));
    const minutes = Math.floor((diff % (3600 * 1000)) / (60 * 1000));
    const seconds = Math.floor((diff % (60 * 1000)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  // useEffect(() => {
  //   // เรียก checkPlan เพื่อดึงข้อมูลของบริษัท
  //   if (!companyId) return;
  //   firestore.checkPlan(
  //     companyId,
  //     (data) => {
  //       setCompanyData(data);
  //     },
  //     (error) => {
  //       console.error("Error in checkPlan:", error);
  //     }
  //   );
  // }, [companyId]);

  // useEffect สำหรับ countdown timer
  useEffect(() => {
    let timer;
    if (companyData && companyData.trialStart) {
      // แปลง trialStart เป็น Date object (ถ้าเป็น Timestamp ให้ใช้ .toDate())
      const trialStartDate = companyData.trialStart.toDate ? companyData.trialStart.toDate() : new Date(companyData.trialStart);
      // อัปเดต countdown ทุกวินาที
      timer = setInterval(() => {
        const countdownStr = calculateCountdown(trialStartDate);
        setTimeLeft(countdownStr);

      }, 1000);
    }
    return () => clearInterval(timer);
  }, [companyData]);

  // ฟังก์ชันเปิด Modal เปลี่ยน plan
  const handleOpenPlanModal = () => {
    setSelectedPlan(null);
    setShowPlanModal(true);
  };

  // ฟังก์ชันเลือกแผน
  const handleSelectPlan = (plan) => {
    // ถ้าแผนที่เลือกเป็นแผนปัจจุบัน ไม่ให้เลือก (disabled)
    if (plan.title === userPlan) return;
    setSelectedPlan(plan);
  };

  // ฟังก์ชันสำหรับส่งอีเมลหา Admin ว่ามีการร้องขอเปลี่ยนแผน
  const sendEmailToAdminPlanChange = async (requestItem) => {
    // adminEmail จะเป็นอีเมลของ admin ที่คุณต้องการให้รับแจ้ง
    const adminEmail = 'miscible.dev@gmail.com';
    // ข้อความในอีเมล
    const planChangeMsg = `
      A user requested to change plan:\n
      - Company: ${requestItem.companyName} (ID: ${requestItem.companyId})
      - Old Plan: ${requestItem.oldPlan}
      - New Plan: ${requestItem.newPlan}
      - Requested by: ${requestItem.name}, Email: ${requestItem.email}
      - Requested on: ${requestItem.date.toLocaleString()}
      \nPlease confirm at: https://www.pam-hrmsoftware.com/admin_confirm
    `;

    const templateParams = {
      to_email: adminEmail,
      subject: 'Plan Change Request',
      message: planChangeMsg,
    };

    // เรียกใช้ emailjs.send(...)
    const result = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      templateParams,
      EMAILJS_USER_ID
    );
    console.log('Email sent to Admin:', result.text);
  };


  // ฟังก์ชันบันทึกการเปลี่ยนแผน (simulate backend request)
  const handleConfirmPlanChange = async () => {
    setIsLoading(true)
    if(!name || !email){
      setShowPlanModal(false);
      setIsLoading(false)
      Swal.fire({
        title: 'แจ้งเตือน',
        text: `กรอกชื่อและอีเมลให้ครบ`,
        icon: 'warning',
        customClass: {
          popup: 'my-swal-size'
        },
      });
      return
    }
    let item ={
      oldPlan:userPlan,
      newPlan:selectedPlan.title,
      date:new Date(),
      companyName:companyData.companyName,
      companyId:companyId,
      status:'waiting',
      name:name,
      email:email,
    }
    try {
      // 3) บันทึกลง Firestore
      await firestore.changePlanRq(item);
      // 4) ส่งเมลแจ้ง Admin
      await sendEmailToAdminPlanChange(item);
  
      // 5) แจ้งผู้ใช้
      setIsLoading(false)
      Swal.fire({
        title: 'Request Sent',
        text: `คำขอเปลี่ยนแผนไปยังแผน: PAM ${selectedPlan.title} ถูกส่งแล้ว!\nกรุณารอ admin ยืนยัน`,
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
      });
    } catch (error) {
      console.error('Error sending plan change request:', error);
      setIsLoading(false)
      Swal.fire({
        title: 'Error',
        text: `ไม่สามารถส่งคำขอได้: ${error}`,
        icon: 'error',
        customClass: {
          popup: 'my-swal-size'
        },
      });
    } finally {
      // ปิด modal หลังบันทึก
      setIsLoading(false)
      setShowPlanModal(false);
      setName('')
      setEmail('')
    }
  };

  // เรียกเมื่อต้องการให้ popup ยืนยันการลบแสดง
  const handleOpenDeleteModal = () => {
    setDeleteName('');   // เคลียร์ค่าเดิม
    setDeleteEmail('');
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const sendEmailToAdminDeleteCompany = async (requestItem) => {
    const adminEmail = 'miscible.dev@gmail.com';
  
    // สร้างข้อความเป็น HTML หรือ Plain text
    const deleteMsg = `
      A user requested to delete the company:\n
      - Company: ${requestItem.companyName} (ID: ${requestItem.companyId})\n
      - Requested by: ${requestItem.name}, Email: ${requestItem.email}\n
      - Requested on: ${requestItem.date.toLocaleString()}\n
      \nPlease see details at: https://www.pam-hrmsoftware.com/admin_confirm
    `;
  
    const templateParams = {
      to_email: adminEmail,
      subject: 'Delete Company Request',
      message: deleteMsg, // หรือหากเป็น HTML ให้ใช้ triple mustache ใน emailjs template
    };
  
    const result = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      templateParams,
      EMAILJS_USER_ID
    );
    console.log('Email sent to Admin (delete company):', result.text);
  };

  const handleDelete =async()=>{
    setIsLoading(true);

    // ตรวจสอบว่ากรอกชื่อและอีเมลหรือยัง
    if (!deleteName || !deleteEmail) {
      setIsLoading(false);
      Swal.fire({
        title: 'แจ้งเตือน',
        text: 'กรอกชื่อและอีเมลให้ครบก่อนขอลบบัญชี',
        icon: 'warning',
        customClass: {
          popup: 'my-swal-size'
        },
      });
      return;
    }

    let item = {
      plan: userPlan,
      date: new Date(),
      companyName: companyData.companyName,
      companyId: companyId,
      status: 'waiting',
      name: deleteName,
      email: deleteEmail,
    };

    try {
      // บันทึกคำขอลบไปยัง Firestore
      await firestore.deleteCompanyRq(item);  
      // ส่งอีเมลแจ้ง Admin
      await sendEmailToAdminDeleteCompany(item);

      setIsLoading(false);
      // แจ้งผู้ใช้
      Swal.fire({
        title: 'Request Sent',
        text: 'คำขอลบบัญชีถูกส่งแล้ว กรุณารอ admin ดำเนินการ',
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
      });
    } catch (error) {
      console.error('Error sending delete company request:', error);
      setIsLoading(false);
      Swal.fire({
        title: 'Error',
        text: `ไม่สามารถส่งคำขอได้: ${error}`,
        icon: 'error',
        customClass: {
          popup: 'my-swal-size'
        },
      });
    } finally {
      // ปิด modal
      setIsLoading(false);
      setShowDeleteModal(false);
      setDeleteName('')
      setDeleteEmail('')
    }
  }


  return (
    
      <div className="dashboard">
        
        <Layout />
        
        <main className="main-content">
          <div style={{display:'flex',height:50,width:'100%',zIndex:0,backgroundColor:'#f8f8f8',alignItems:'center',justifyContent:'flex-end',borderRadius:5}}>
          
            <p style={{margin:10}}>Hello, {userData.name}</p>
                  
          </div>
          <div className="main">
            <div className="content-container">
              <div className="left-panel">
                <div className="logo-container" style={{width:150,height:150,alignSelf:'center',paddingTop:20,}}>
                {console.log("Render AboutUs with logoUrl:", logoUrl)}
                  <img src={logoUrl} width={150} height={150} alt="Logo"  
                    style={{ border: '3px solid rgb(65, 65, 65, 0.544)', borderRadius: '75px',
                      boxShadow: '0 0 10px 5px rgb(65, 65, 65, 0.544)',
                      objectFit: 'cover',         // ให้รูปครอบเต็มกรอบ (คงอัตราส่วน)
                      // objectFit: 'contain',    // ถ้าต้องการให้รูปทั้งหมดอยู่ในกรอบ โดยอาจมีขอบขาว
                      display: 'block'           // ป้องกันการเผื่อ space ใต้รูป 
                    }} 
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                    style={{ display: 'none',width:150,height:150, }}
                    id="logoUpload-aboutUs"
                  />
                  <label htmlFor="logoUpload-aboutUs" className="change-logo-icon">
                    <i className="fas fa-camera"></i> 
                  </label>
                </div> 
                <div style={{ padding: '15px' }}>
                  <p style={{marginTop:20}}>Company Name: {company_name}{' '}
                  <Button variant="outline-primary" style={{borderRadius:20}} size="sm" onClick={handleOpenEditModal}>
                    <IoPencil />
                  </Button>
                  </p>
                  <p>Company Id: {companyId}</p>
                  <div className="form-group" style={{marginLeft:0}}>
                    <label htmlFor="detail">รายละเอียด</label>
                    <textarea
                      id="detail"
                      name="detail"
                      value={detail}
                      style={{height:150}}
                      onChange={(e) => setDetail(e.target.value)}
                      required
                    />
                  </div>

                  <div style={{ marginTop: '16px',marginLeft:-5,fontSize:24 }}>
                    <button className="Next-button" style={{width:'20%'}} onClick={onSaveAbout}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            
              <div className="right-panel">
                <div className='showPlan-panel'>
                  <div style={{ padding: '15px' }}>
                  {
                    // หาค่าแผนที่ตรงกับ userPlan
                    (() => {
                      const matchedPlan = planData.find(item => item.title === userPlan);
                      
                      if (matchedPlan) {
                        // ถ้าเจอ แสดงรายละเอียด
                        return (
                          <>
                            <p style={{textAlign:'center'}}>Plan: PAM {matchedPlan.title}</p>
                            <ul style={{marginTop:-15}}>
                              {matchedPlan.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                              ))}
                            </ul>
                            <p style={{marginTop:-20}}><b>Status:</b> {companyData?.status|| ''}&nbsp;&nbsp; 
                            {userPlan=='demo' && <span><b>Trial expires in:</b> {timeLeft}</span>}</p>
                            {/* {userPlan=='demo' && <p style={{marginTop:-20}}>Trial expires in: {timeLeft}</p>} */}
                            {/* ปุ่มเปลี่ยน Plan */}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',  // ปุ่มอยู่ทางซ้าย
                                gap: '10px',
                                marginTop: '10px',
                                fontSize: 18,
                                width: '100%'
                              }}
                            >
                              <button
                                className="Next-button"
                                onClick={handleOpenPlanModal}
                                style={{ width: '20%', textAlign: 'center' }}
                              >
                                Change Plan
                              </button>
                              <button
                                className="Next-button"
                                onClick={handleOpenDeleteModal}
                                style={{ width: '20%', textAlign: 'center', backgroundColor: '#ffcccc', borderColor: '#ff6666',color:'black' }}
                              >
                                Delete Account
                              </button>
                            </div>
                          </>
                        );
                      } else {
                        // ถ้าไม่เจอ
                        return <p>Plan: PAM {userPlan} (No details found!)</p>;
                      }
                    })()
                  }
                  </div>
                </div> 
                <div className='help-panel'>
                  {/* replace with textarea boxes */}
                  <div style={{ padding: '10px 15px 10px 15px' }}>
                    <div className="form-group" style={{marginLeft:0}}>
                      <label htmlFor="help_1">Help - 1</label>
                      <textarea
                        id="help_1"
                        name="help_1"
                        value={help_1}
                        onChange={(e) => setHelp_1(e.target.value)}
                        required
                      />

                      <label htmlFor="help_2">Help - 2</label>
                      <textarea
                        id="help_2"
                        name="help_2"
                        value={help_2}
                        onChange={(e) => setHelp_2(e.target.value)}
                        required
                      />

                      <label htmlFor="help_3">Help - 3</label>
                      <textarea
                        id="help_3"
                        name="help_3"
                        value={help_3}
                        onChange={(e) => setHelp_3(e.target.value)}
                        required
                      />
                    </div>

                    {/* <div style={{ marginTop: '16px' }}>
                      <button className="Next-button" onClick={onSaveAbout}>
                        Save
                      </button>
                    </div> */}
                  </div>
                </div> 
              </div>
            </div>
            {/* <div className='header-page'>
              <header>
                <h1 ></h1>
                <div className="logo-container" style={{width:150,height:150}}>
                  <img src={logoUrl} width={150} height={150} alt="Logo" />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                    style={{ display: 'none' }}
                    id="logoUpload"
                  />
                  <label htmlFor="logoUpload" className="change-logo-icon">
                    <i className="fas fa-camera"></i> 
                  </label>
                </div> 
              </header>
            </div>
            <div className="main-contain">
              <div style={{width:'95%'}}>
                <div className="form-group">
                    <label htmlFor="detail">รายละเอียด</label>
                    <textarea 
                    id="detail" 
                    name="detail" 
                    value={detail} 
                    onChange={(e) => setDetail(e.target.value)} 
                    required 
                    />
                    <label htmlFor="detail">Help - 1</label>
                    <textarea 
                    id="detail" 
                    name="help_1" 
                    value={help_1} 
                    onChange={(e) => setHelp_1(e.target.value)} 
                    required 
                    />
                    <label htmlFor="detail">Help - 2</label>
                    <textarea 
                    id="detail" 
                    name="help_2" 
                    value={help_2} 
                    onChange={(e) => setHelp_2(e.target.value)} 
                    required 
                    />
                    <label htmlFor="detail">Help - 3</label>
                    <textarea 
                    id="detail" 
                    name="help_3" 
                    value={help_3} 
                    onChange={(e) => setHelp_3(e.target.value)} 
                    required 
                    />
                </div>
                
              </div>
              <div style={{width:'95%',alignSelf:'center'}}>
                <button className='Next-button' style={{marginLeft:0}} onClick={onSaveAbout}>Save</button>
              </div>
            </div> */}
          </div>
          <footer className="footer-container" style={{marginTop:45}}>
            <div className="footer-left">
              {/* ปุ่มไปหน้า Administrator */}
              {/* <button
                className="footer-link"
                onClick={() => navigate('/login_admin')}
              >
                Administrator
              </button> */}
              <span style={{ marginLeft: '8px' }}>
                © 2024 Miscible Technology Co.,Ltd. - All rights reserved.
              </span>
              <button
                className="footer-link"
                onClick={() => navigate('/')}
              >
                Website
              </button>
              
            </div>
            <div className="footer-right">
              {/* ปุ่มไป Terms of Service */}
              <button
                className="footer-link"
                onClick={() => navigate('/get-apk')}
              >
                <IoLogoAndroid /> App
              </button>
              <button
                className="footer-link"
                onClick={() => navigate('/terms-of-service')}
              >
                Terms of Service
              </button>
              {/* ปุ่มไป Privacy */}
              <button
                className="footer-link"
                onClick={() => navigate('/privacy-policy')}
              >
                Privacy
              </button>
            </div>
          </footer>
        </main>

        {/* ================= Modal แก้ไขชื่อบริษัท ================= */}
        <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Company Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group" style={{marginLeft:0}}>
              <label htmlFor="edit-company-name">Company Name</label>
              <input
                id="edit-company-name"
                type="text"
                className="form-control"
                value={editCompanyName}
                onChange={(e) => setEditCompanyName(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveCompanyName}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal สำหรับเลือก Plan */}
        <Modal show={showPlanModal} onHide={() => setShowPlanModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Select a Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Grid ปุ่มเลือก Plan */}
            <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
                  gap: '15px',
                  justifyItems: 'center'
                }}
              >
                {planData
                  // ถ้าแผนปัจจุบันไม่ใช่ demo ให้ไม่แสดง demo
                  .filter(plan => (userPlan !== 'demo' ? plan.title !== 'demo' : true))
                  .map(plan => {
                    // เพิ่ม class active เมื่อ selectedPlan ตรงกับแผนที่เลือก
                    const isActivePlan = selectedPlan && selectedPlan.title === plan.title;
                    return (
                      <button
                        key={plan.title}
                        onClick={() => handleSelectPlan(plan)}
                        disabled={plan.title === userPlan}
                        className={`changePlan-button ${plan.title === userPlan ? 'disabled' : ''} ${isActivePlan ? 'active' : ''}`}
                        title={`PAM ${plan.title}`}
                      >
                        PAM {plan.title}
                      </button>
                    );
                  })}
              </div>

            {/* แสดงรายละเอียด Features ของแผนที่เลือก (ถ้ามี) */}
            {selectedPlan && (
              <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #4d058b', borderRadius: 8 }}>
                <h5 style={{ textAlign: 'center' }}>Plan: PAM {selectedPlan.title}</h5>
                <ul>
                  {selectedPlan.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
              </div>
            )}
            {selectedPlan &&
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label style={{fontSize:18,marginTop:5}}>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    autoFocus
                    style={{fontSize:18}}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label style={{fontSize:18}}>ชื่อ-นามสกุล ผู้ส่งคำขอ</Form.Label>
                  <Form.Control
                    type="name"
                    autoFocus
                    style={{fontSize:18}}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Form>
            }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowPlanModal(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleConfirmPlanChange}
              disabled={!selectedPlan}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="deleteEmail">
                <Form.Label style={{ fontSize: 18 }}>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={deleteEmail}
                  onChange={(e) => setDeleteEmail(e.target.value)}
                  style={{ fontSize: 18 }}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="deleteName">
                <Form.Label style={{ fontSize: 18 }}>ชื่อ-นามสกุล ผู้ส่งคำขอ</Form.Label>
                <Form.Control
                  type="text"
                  value={deleteName}
                  onChange={(e) => setDeleteName(e.target.value)}
                  style={{ fontSize: 18 }}
                  required
                />
              </Form.Group>
            </Form>
            <p style={{ marginTop: 10, fontSize: 16, color: 'red' }}>
              การลบบัญชี/ปิดใช้งานนี้อาจทำให้ข้อมูลทั้งหมดถูกลบ กรุณายืนยันอีกครั้ง
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Confirm Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {isLoading && (
          <LoadingBackdrop />
        )}
      </div>
      
    
  );
}

export default AboutUs;

  