import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, MenuItem } from '@mui/material';
import firestore from '../Firebase/Firestore';
import storage from '../Firebase/Storage';
import Layout from '../Layout';
import '../Home.css';
import '../addProfile.css';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import { sha256 } from 'crypto-hash';
import { UserContext } from '../UserContext';
import Modal from 'react-bootstrap/Modal'; // Import Bootstrap modal
import Button from 'react-bootstrap/Button'; // Import Bootstrap button
import { AiFillWarning,AiOutlineMan,AiOutlineWoman } from "react-icons/ai";
import {IoTrash,} from "react-icons/io5";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th'
import { hashPassword } from '../hashPassword';
import LoadingBackdrop from '../LoadingBackdrop';
import Swal from 'sweetalert2';
import 'animate.css';


function ProfileEdit() {
  const navigate = useNavigate();
  const location = useLocation();

  const [prefixth,setPrefixTh] = useState('');
  const [prefixEn,setPrefixEn] = useState('');
  const [emID,setEmID] = useState('');
  const [uid, setUid] = useState('');
  const [name, setName] = useState('');
  const [nameEng, setNameEng] = useState('');
  const [position, setPosition] = useState('');
  const [national,setNational] = useState('');
  const [employment,setEmployment] = useState('');
  const [firstDay, setFirstDay] = useState(null);
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [sex, setSex] = useState('');
  const [username,setUsername] = useState('');
  const [originalUsername, setOriginalUsername] = useState('');
  const [password,setPassword] = useState('');
  const [level, setLevel] = useState('');
  const [image_off, setImage_Off] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [nat_id, setNat_ID] = useState('');
  const [personal_status, setPersonal_Status] = useState('');
  const [child, setChild] = useState('');
  const [bank, setBank] = useState('');
  const [bank_type, setBank_type] = useState('');
  const [bank_id, setBank_ID] = useState('');
  const [emer_name, setEmer_Name] = useState('');
  const [emer_relate, setEmer_Relate] = useState('');
  const [emer_phone, setEmer_Phone] = useState('');
  const [address_off, setAddress_Off] = useState(''); // ที่อยู่ตามบัตรประชาชน
  const [disease, setDisease] = useState('');
  const [blood_type, setBlood_type] = useState('');
  const [Ldrug, setLdrug] = useState(''); //แพ้ยา
  const [wealthHos, setWealthHos] = useState('');
  const [jobDesc,setJobDesc] = useState('');
  const [duty,setDuty] = useState('');
  const [nickNameTh,setNickNameTH] = useState('');
  const [nickNameEn,setNickNameEN] = useState('');
  const [birthDay,setBirthDay] = useState(null);

  const [costL, setCostL] = useState(0); // ค่าครองชีพ
  const [ot, setOT] = useState(0); // ค่าล่วงเวลา
  const [allowance, setAllowance] = useState(0); // เบี้ยเลี้ยง
  const [salary, setSalary] = useState(0); // ค่าเงินเดือน
  const [venhicle, setVenhicle] = useState(0); // ค่ายานพาหนะ
  const [sub, setSub] = useState(0); // เงินอุดหนุน
  const [welth, setWelth] = useState(0); // ค่าสวัสดิการ
  const [bonus, setBonus] = useState(0); // เงินโบนัส
  const [tax, setTax] = useState(0); // หักภาษี
  const [insurance, setInsurance] = useState(0); // ประกันสังคม
  const [late, setLate] = useState(0); // เข้างานสาย
  const [missing, setMissing] = useState(0); // ขาดงาน
  const [borrow, setBorrow] = useState(0); // เงินกู้ยืม
  const [withdraw, setWithdraw] = useState(0); // เงินเบิกล่วงหน้า
  const [allDeposit,setAllDeposit] = useState(0); //รายได้สะสม
  const [allInsurance,setAllInsurance] = useState(0); //ประกันสังคมสะสม
  const [department,setDepartment] =useState('');
  const [generation,setGeneration] = useState('');
  const [accident,setAccident] = useState('');
  const [providentFund,setProvidentFund] = useState('');
  const [healthCheck,setHealthCheck] = useState('');
  const [extraBenefitTitle1,setExtraBenefitTitle1] = useState('');
  const [extraBenefitDetail1,setExtraBenefit1Detail1] = useState('');
  const [extraBenefitTitle2,setExtraBenefitTitle2] = useState('');
  const [extraBenefitDetail2,setExtraBenefit1Detail2] = useState('');
  const [extraBenefitTitle3,setExtraBenefitTitle3] = useState('');
  const [extraBenefitDetail3,setExtraBenefit1Detail3] = useState('');

  const [prefixThOptions, setPrefixThOptions] = useState([]);
  const [prefixEnOptions, setPrefixEnOptions] = useState([]);
  const [sexOptions, setSexOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [generationOptions, setGenerationOptions] = useState([]);
  // const [levelOptions, setLevelOptions] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [nationalOptions, setNationalOptions] = useState([]);
  const [employmentOptions, setEmploymentOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);
  const [default_username,setDefault_Username] = useState(null)
  const [default_pass,setDefault_Pass] = useState(null)
  const { setCurrentUser, companyId,userData } = useContext(UserContext);

  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [filterPosition,setFilterPosition] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  // บัตรประชาชน

  const [idCardFile, setIdCardFile] = useState(null);    // File ใหม่ (ถ้ามี)
  const [idCardName, setIdCardName] = useState('');      // ชื่อไฟล์ (ไว้โชว์บน UI)
  const [idCardUrl, setIdCardUrl] = useState('');        // URL ใน Storage เดิม (ถ้ามี)
  const [idCardRemoved, setIdCardRemoved] = useState(false); // true ถ้าผู้ใช้กด X เพื่อลบไฟล์ออกจาก state

  // ทะเบียนบ้าน
  const [houseFile, setHouseFile] = useState(null);
  const [houseName, setHouseName] = useState('');
  const [houseUrl, setHouseUrl] = useState('');
  const [houseRemoved, setHouseRemoved] = useState(false);

  // สมุดบัญชี
  const [bankFile, setBankFile] = useState(null);
  const [bankDocName, setBankDocName] = useState('');
  const [bankDocUrl, setBankDocUrl] = useState('');
  const [bankRemoved, setBankRemoved] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const warningImg = "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/%E0%B8%9B%E0%B9%89%E0%B8%B2%E0%B8%A2%E0%B9%80%E0%B8%95%E0%B8%B7%E0%B8%AD%E0%B8%99%E0%B9%81%E0%B8%95%E0%B9%88%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A2%E0%B8%AA%E0%B8%B8%E0%B8%A0%E0%B8%B2%E0%B8%9E.jpg?alt=media&token=e704f682-cf3c-4e6f-97c8-4ad7e6fdbefe"
  const defaultMen = "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/333.png?alt=media&token=f5b9e3a6-8644-417e-a366-c4cddac12007"
  const defaultWm = "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/222.png?alt=media&token=97664b5e-3970-4805-a7b4-9fbd43baf2c4"

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const levelOptions = [
    {label:'Employee',value:'employee'},
    {label:'Leader',value:'leader'},
    {label:'Master',value:'master'}
  ]

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const getUsernameSuc=(data)=>{
    setUsername(data.username)
    setOriginalUsername(data.username); 
    setLevel(data.level)
    setDefault_Username(data.default_username)
    setDefault_Pass(data.default_password)
  }

  const getUsernameUnsuc =(err) => console.log(err);

  const getUserSuccess = (data) => {
    setPrefixTh(data.prefixth);
    setPrefixEn(data.prefixEn);
    setEmID(data.emID);
    setName(data.name + " " + data.lastname);
    setNameEng(data.FName + " " + data.LName);
    setSex(data.sex);
    setPosition(data.position);
    setNational(data.national);
    setEmployment(data.employment);
    setFirstDay(dayjs(data.workstart, 'DD/MM/YYYY'));
    setAddress(data.address);
    setEmail(data.email);
    setPhone(data.phone);
    setLevel(data.level);
    setImage_Off(data.image_off);
    setNat_ID(data.nat_id);
    setPersonal_Status(data.personal_status);
    setChild(data.child);
    setBank(data.bank);
    setBank_type(data.bank_type);
    setBank_ID(data.bank_id);
    setEmer_Name(data.emer_name);
    setEmer_Relate(data.emer_relate);
    setEmer_Phone(data.emer_phone);
    setAddress_Off(data.address_off);
    setDisease(data.disease);
    setBlood_type(data.blood_type);
    setLdrug(data.Ldrug);
    setWealthHos(data.wealthHos);
    setSalary(data.salary);
    setSub(data.sub);
    setOT(data.ot);
    setAllowance(data.allowance);
    setVenhicle(data.venhicle);
    setWelth(data.welth);
    setBonus(data.bonus);
    setCostL(data.costL);
    setInsurance(data.insurance);
    setLate(data.late);
    setWithdraw(data.withdraw);
    setBorrow(data.borrow);
    setMissing(data.missing);
    setTax(data.tax);
    setJobDesc(data.jobDesc);
    setDuty(data.duty);
    setAllDeposit(data.allDeposit)
    setAllInsurance(data.allInsurance)
    setDepartment(data.department)
    setNickNameTH(data.nickNameTh);
    setNickNameEN(data.nickNameEn);
    setBirthDay(dayjs(data.birthDay, 'DD-MM-YYYY'));
    setGeneration(data.generation)
    setAccident(data.accident);
    setProvidentFund(data.providentFund);
    setHealthCheck(data.healthCheck);
    setExtraBenefitTitle1(data.extraBenefitTitle1)
    setExtraBenefit1Detail1(data.extraBenefitDetail1)
    setExtraBenefitTitle2(data.extraBenefitTitle2)
    setExtraBenefit1Detail2(data.extraBenefitDetail2)
    setExtraBenefitTitle3(data.extraBenefitTitle3)
    setExtraBenefit1Detail3(data.extraBenefitDetail3)
    if (data.importantDocs) {
      // idCard
      if (data.importantDocs.idCard) {
        setIdCardName(data.importantDocs.idCard.name || '');
        setIdCardUrl(data.importantDocs.idCard.url || '');
      }
      // houseReg
      if (data.importantDocs.houseReg) {
        setHouseName(data.importantDocs.houseReg.name || '');
        setHouseUrl(data.importantDocs.houseReg.url || '');
      }
      // bankBook
      if (data.importantDocs.bankBook) {
        setBankDocName(data.importantDocs.bankBook.name || '');
        setBankDocUrl(data.importantDocs.bankBook.url || '');
      }
    }
  };

  const getUserUnsuccess = (e) => {
    console.log('f edit' + e);
  };

  const updateSuccess = () => {
    setIsLoading(false)
    //alert('Update data success!!')
    Swal.fire({
      title: 'เรียบร้อย',
      text: `อัปเดตข้อมูลสำเร็จ`,
      icon: 'success',
      customClass: {
        popup: 'my-swal-size'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then(()=>{
      navigate('/profile', { state: { startIndex, endIndex, search, searchQuery, position:filterPosition } });
    })
  };

  const updateUnsuccess = (error) => {
    console.log(error);
  };

  const updateUsernameS =()=>{}
  const updateUsernameUn =(e)=> console.log(e)

  const handleImageChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // 1) ตรวจสอบขนาดไฟล์ <= 5MB
    if (file.size > 5 * 1024 * 1024) {
      Swal.fire({
        title: 'ไฟล์ใหญ่เกินกำหนด',
        text: 'รูปต้องมีขนาดไม่เกิน 5MB',
        icon: 'warning',
      });
      // Reset input เพื่อไม่ให้ค้างไฟล์
      e.target.value = null;
      return;
    }

    // 2) ตรวจสอบอัตราส่วนรูป (ratio 4:3)
    const tempImg = new Image();
    tempImg.src = URL.createObjectURL(file);
    tempImg.onload = () => {
      // const width = tempImg.width;
      // const height = tempImg.height;
      // const ratio = width / height; // คำนวณ ratio

      // 4:3 = 1.333... => อาจตั้ง tolerance ได้ เช่น ±0.01
      // const targetRatio = 4 / 3;
      // if (Math.abs(ratio - targetRatio) > 0.01) {
      //   Swal.fire({
      //     title: 'อัตราส่วนไม่ถูกต้อง',
      //     text: 'โปรดใช้รูปที่เป็นอัตราส่วน 4:3',
      //     icon: 'warning',
      //   });
      //   e.target.value = null;
      //   return;
      // }

      // ถ้าผ่านทั้ง 2 เงื่อนไข => จึงค่อย setImage
      setImage_Off(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    };
  };

  const hashPass=async(password)=>{
    try {
      const hashedPassword = await sha256(password);
      console.log("Encrypt pass: " + hashedPassword)
      return hashedPassword;
    } catch (error) {
      console.error('Error hashing password:', error);
      throw new Error('Hashing failed');
    }
  }

  const validateNumberInput = (input) => {
    const number = Number(input);
    if (isNaN(number)) {
      alert("Invalid input, resetting to 0.");
      return 0;
    }
    return number;
  };

  const ResetUsername =()=>{
    firestore.updateUsername(companyId, uid, {username:default_username,password:default_pass}, ()=> {
      //alert('Reset username success!!')
      Swal.fire({
        title: 'เรียบร้อย',
        text: `รีเซต username สำเร็จ`,
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }, updateUsernameUn);
  }

  const onSave = async () => {
    setIsLoading(true)
    if (username !== originalUsername) {
      const isTaken = await firestore.verifyUsername(companyId, username);
      if (!isTaken) {
        setIsLoading(false)
        //alert("This username is already taken.");
        Swal.fire({
          title: 'แจ้งเตือน',
          text: 'username นี้ถูกใช้แล้ว โปรดใช้ชื่ออื่น',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
        return;
      }
    }
  
    let imageUrl = '';
    if (image_off instanceof File) {
      imageUrl = await storage.uploadImage(companyId, image_off);
      firestore.resetLikeStatus(companyId,uid)
    } else {
      imageUrl = image_off; // Keep the existing URL if no new image is uploaded
    }
     // 1) จัดการ idCard
     let finalIdCardName = idCardName;
     let finalIdCardUrl  = idCardUrl;
 
     // ถ้าผู้ใช้ removeIdCard = true และยังมี url เดิม => ลบ
     if (idCardRemoved && idCardUrl) {
       await storage.deleteFile(idCardUrl);
       finalIdCardName = '';
       finalIdCardUrl  = '';
     } else if (idCardFile) {
       // ผู้ใช้เลือกไฟล์ใหม่ -> ถ้ามี url เก่าก็ลบ
       if (idCardUrl) {
         await storage.deleteFile(idCardUrl);
       }
       // อัปโหลดไฟล์ใหม่
       const uploadedUrl = await new Promise((resolve, reject) => {
         storage.uploadFile(
           companyId,
           idCardFile,
           (progress) => {},
           (downloadURL) => resolve(downloadURL),
           (error) => reject(error)
         );
       });
       finalIdCardUrl = uploadedUrl;
       finalIdCardName = idCardFile.name;
     }
 
     // houseReg
     let finalHouseName = houseName;
     let finalHouseUrl  = houseUrl;
     if (houseRemoved && houseUrl) {
       await storage.deleteFile(houseUrl);
       finalHouseName = '';
       finalHouseUrl  = '';
     } else if (houseFile) {
       if (houseUrl) {
         await storage.deleteFile(houseUrl);
       }
       const upUrl = await new Promise((resolve, reject) => {
         storage.uploadFile(
           companyId,
           houseFile,
           null,
           (downloadURL) => resolve(downloadURL),
           (error) => reject(error)
         );
       });
       finalHouseUrl = upUrl;
       finalHouseName = houseFile.name;
     }
 
     // bankBook
     let finalBankName = bankDocName;
     let finalBankUrl  = bankDocUrl;
     if (bankRemoved && bankDocUrl) {
       await storage.deleteFile(bankDocUrl);
       finalBankName = '';
       finalBankUrl  = '';
     } else if (bankFile) {
       if (bankDocUrl) {
         await storage.deleteFile(bankDocUrl);
       }
       const upUrl = await new Promise((resolve, reject) => {
         storage.uploadFile(
           companyId,
           bankFile,
           null,
           (downloadURL) => resolve(downloadURL),
           (error) => reject(error)
         );
       });
       finalBankUrl = upUrl;
       finalBankName = bankFile.name;
     }
 
     // แล้วค่อย update Firestore
     const importantDocs = {
       idCard: {
         name: finalIdCardName || '',
         url: finalIdCardUrl || '',
       },
       houseReg: {
         name: finalHouseName || '',
         url: finalHouseUrl || '',
       },
       bankBook: {
         name: finalBankName || '',
         url: finalBankUrl || '',
       }
     };
    const formattedFirstDay = firstDay ? firstDay.format('DD/MM/YYYY') : null;
    const formattedBirthDay = birthDay ? birthDay.format('DD/MM/YYYY') : null;
  
    var nameth = name.split(" ");
    var nameEn = nameEng.split(" ");
    let item = {
      prefixth: prefixth || '',
      prefixEn: prefixEn || '',
      emID: emID || '',
      name: nameth[0] || '',
      lastname: nameth[1] || '',
      FName: nameEn[0] || '',
      LName: nameEn[1] || '',
      position: position || '',
      national:national || '',
      employment:employment || '',
      workstart: formattedFirstDay || '',
      address: address || '',
      phone: phone || '',
      email: email || '',
      sex: sex || '',
      level: level || '',
      image_off: imageUrl || '',
      nat_id: nat_id || '',
      personal_status: personal_status || '',
      child: child || '',
      bank: bank || '',
      bank_type: bank_type || '',
      bank_id: bank_id || '',
      emer_name: emer_name || '',
      emer_relate: emer_relate || '',
      emer_phone: emer_phone || '',
      address_off: address_off || '',
      disease: disease || '',
      blood_type: blood_type || '',
      Ldrug: Ldrug || '',
      wealthHos: wealthHos || '',
      department:department || '',
      nickNameTh:nickNameTh || '',
      nickNameEn:nickNameEn || '',
      birthDay:formattedBirthDay || '',
      generation:generation || '',
      importantDocs,
      accident:accident || '',
      providentFund:providentFund || '',
      healthCheck:healthCheck || '',
      extraBenefitTitle1:extraBenefitTitle1 || '',
      extraBenefitDetail1:extraBenefitDetail1 || '',
      extraBenefitTitle2:extraBenefitTitle2 || '',
      extraBenefitDetail2:extraBenefitDetail2 || '',
      extraBenefitTitle3:extraBenefitTitle3 || '',
      extraBenefitDetail3:extraBenefitDetail3 || '',
    };
  
    firestore.updateUser(companyId, uid, item, updateSuccess, updateUnsuccess);
  
    if (password !== '') {
      let pass = await hashPass(password);
      let item1 = {
        username: username || '',
        password: pass,
        level: level || '',
        // default_username:username,
        // default_password:pass,
      };
      firestore.updateUsername(companyId, uid, item1, updateUsernameS, updateUsernameUn);
    } else {
      let item1 = {
        username: username || '',
        level: level || '',
      };
      firestore.updateUsername(companyId, uid, item1, updateUsernameS, updateUsernameUn);
    }
  };

  const addOldValueToOptions = (oldValue, options) => {
    
    const trimmedOldValue = oldValue ? oldValue.trim() : '';
    if (trimmedOldValue && !options.includes(trimmedOldValue)) {
      console.log("Adding old value to options:", trimmedOldValue); // Debug log
      return [trimmedOldValue, ...options];
    }
    return options;
  };

  const fetchDropdownOptions = async () => {
    try {
      console.log(prefixth)
      const prefixThOptionsData = await firestore.getDropdownOptions(companyId, 'prefixTh');
      const loadedPrefixThOptions = prefixThOptionsData.map(option => option.name.trim()); // Trim options to match
      const updatedPrefixThOptions = addOldValueToOptions(prefixth, loadedPrefixThOptions);
      setPrefixThOptions(updatedPrefixThOptions);
      console.log("Updated prefixThOptions:", updatedPrefixThOptions); // Debug log
  
      const prefixEnOptionsData = await firestore.getDropdownOptions(companyId, 'prefixEn');
      const loadedPrefixEnOptions = prefixEnOptionsData.map(option => option.name.trim());
      setPrefixEnOptions(addOldValueToOptions(prefixEn, loadedPrefixEnOptions));
  
      const sexOptionsData = await firestore.getDropdownOptions(companyId, 'sex');
      const loadedSexOptions = sexOptionsData.map(option => option.name.trim());
      setSexOptions(addOldValueToOptions(sex, loadedSexOptions));
  
      const positionOptionsData = await firestore.getDropdownOptions(companyId, 'position');
      const loadedPositionOptions = positionOptionsData.map(option => option.name.trim());
      setPositionOptions(addOldValueToOptions(position, loadedPositionOptions));
  
      const departmentOptionsData = await firestore.getDropdownOptions(companyId, 'department');
      const loadedDepartmentOptions = departmentOptionsData.map(option => option.name.trim());
      setDepartmentOptions(addOldValueToOptions(department, loadedDepartmentOptions));
  
      const bankOptionsData = await firestore.getDropdownOptions(companyId, 'bank');
      const loadedBankOptions = bankOptionsData.map(option => option.name.trim());
      setBankOptions(addOldValueToOptions(bank, loadedBankOptions));
  
      const statusOptionsData = await firestore.getDropdownOptions(companyId, 'status_per');
      const loadedStatusOptions = statusOptionsData.map(option => option.name.trim());
      setStatusOptions(addOldValueToOptions(personal_status, loadedStatusOptions));
      
      const nationalOptions = await firestore.getDropdownOptions(companyId,'nationality')
      const loadedNationalOptions = nationalOptions.map(option => option.name.trim());
      setNationalOptions(addOldValueToOptions(national, loadedNationalOptions));

      const employmentOptions = await firestore.getDropdownOptions(companyId,'employment')
      const loadedEmploymentOptions = employmentOptions.map(option => option.name.trim());
      setEmploymentOptions(addOldValueToOptions(employment, loadedEmploymentOptions));

      const generationOptions = await firestore.getDropdownOptions(companyId,'generation')
      const loadedGenerationOptions = generationOptions.map(option => option.name.trim());
      setGenerationOptions(addOldValueToOptions(generation, loadedGenerationOptions));
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  useEffect(() => {
    if (prefixth) {
      fetchDropdownOptions();
    }else{
      fetchDropdownOptions();
    }
  }, [prefixth]); // รัน fetchDropdownOptions เมื่อ prefixTh มีค่า

  useEffect(() => {
    const loadData = async () => {
      if (location.state && location.state.uid) {
        console.log(location.state.uid);
        setUid(location.state.uid);
  
        // เรียกข้อมูลผู้ใช้ก่อน
        await new Promise((resolve) => {
          firestore.getUser(
            companyId,
            location.state.uid,
            (data) => {
              getUserSuccess(data);
              resolve(); // รอให้ข้อมูลถูกโหลด
            },
            (error) => {
              getUserUnsuccess(error);
              resolve(); // แม้เกิดข้อผิดพลาด ให้ resolve ต่อ
            }
          );
        });
  
        // เรียกข้อมูล username
        firestore.getUsername(companyId, location.state.uid, getUsernameSuc, getUsernameUnsuc);
      } else {
        console.warn('No ID found in location state');
      }
  
      if (location.state) {
        setStartIndex(location.state.startIndex || 0);
        setEndIndex(location.state.endIndex || 10);
        setSearch(location.state.search || '');
        setSearchQuery(location.state.searchQuery || '');
        setFilterPosition(location.state.position || '');
      }
  
      // โหลดข้อมูล Dropdown หลังจากข้อมูลผู้ใช้ถูกตั้งค่าแล้ว
      //fetchDropdownOptions();
    };
  
    loadData();
  }, [location.state]);

  return (
    <div className="dashboard">
      <Layout />
      <main className="main-content">
        <div className="main">
          <div className='header-page'>
            <header>
              <h1>แก้ไขประวัติพนักงาน</h1>
            </header>
          </div>
          <div className="main-contain" style={{overflowX:'hidden'}}>
            <div className='block_img'>
              <img src={imagePreview || image_off} width={150} height={150} alt="Profile"  
                style={{cursor: 'pointer', border: '3px solid rgb(65, 65, 65, 0.544)', 
                  borderRadius: '75px', objectFit: 'cover',         // ให้รูปครอบเต็มกรอบ (คงอัตราส่วน)
                  // objectFit: 'contain',    // ถ้าต้องการให้รูปทั้งหมดอยู่ในกรอบ โดยอาจมีขอบขาว
                  display: 'block'           // ป้องกันการเผื่อ space ใต้รูป 
                }} 
                onClick={() => document.getElementById('imagePicker').click()} 
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="imagePicker"
              />
              <label htmlFor="imagePicker" style={{ cursor: 'pointer', color: '#007bff',textAlign:'center',alignSelf:'center' }}>
                <p>Click to upload image</p>
              </label>
              <div style={{ display: 'flex', justifyContent: 'center',alignSelf:'center', flexDirection:'row', }}>
                <button style={{ borderRadius: 10,marginRight:5 }} onClick={() => setImage_Off(warningImg)}>
                  <AiFillWarning />
                </button>
                <button style={{ borderRadius: 10,marginRight:5,marginLeft:5 }} onClick={() => setImage_Off(defaultMen)}>
                  <AiOutlineMan />
                </button>
                <button style={{ borderRadius: 10,marginLeft:5 }} onClick={() => setImage_Off(defaultWm)}>
                  <AiOutlineWoman />
                </button>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%'}}>
            <div className="form-row" style={{ display: 'flex',}}>
                <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>ข้อมูลส่วนบุคคล :</p>
            </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
            <TextField
                  className="form-field"
                  select
                  label="คำนำหน้าชื่อ"
                  variant="filled"
                  style={{ width: '35%',marginRight:'1%'}}
                  value={prefixth}
                  onChange={(e) => setPrefixTh(e.target.value)}
                >
                  {prefixThOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
                </TextField>
                <TextField
                  className="form-field"
                  select
                  label="คำนำหน้าชื่อภาษาอังกฤษ"
                  variant="filled"
                  style={{ width: '35%',marginRight:'1%'}}
                  value={prefixEn}
                  onChange={(e) => setPrefixEn(e.target.value)}
                >{prefixEnOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
                </TextField>
                <TextField
                  className="form-field"
                  label="รหัสพนักงาน"
                  variant="filled"
                  style={{ width: '28%',}}
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  value={emID}
                  onChange={(e) => setEmID(e.target.value)}
                />
              </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
              <TextField
                className="form-field"
                label="ชื่อ-นามสกุล"
                variant="filled"
                style={{ width: '35%',marginRight:'1%'}}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                className="form-field"
                label="ชื่อ-นามสกุล ภาษาอังกฤษ"
                variant="filled"
                style={{ width: '35%',marginRight:'1%'}}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={nameEng}
                onChange={(e) => setNameEng(e.target.value)}
              />
              <TextField
                className="form-field"
                id="filled-select"
                select
                label="เพศ"
                variant="filled"
                style={{ width: '28%' }}
                value={sex}
                onChange={(e) => setSex(e.target.value)}
              >
                {sexOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                <TextField
                  className="form-field"
                  label="ชื่อเล่น"
                  variant="filled"
                  style={{ width: '35%',marginRight:'1%'}}
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  value={nickNameTh}
                  onChange={(e) => setNickNameTH(e.target.value)}
                />
                <TextField
                  className="form-field"
                  label="ชื่อเล่น(ภาษาอังกฤษ)"
                  variant="filled"
                  style={{ width: '35%',marginRight:'1%'}}
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  value={nickNameEn}
                  onChange={(e) => setNickNameEN(e.target.value)}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                      <DatePicker
                      label="วันเกิด"
                      value={birthDay}
                      onChange={(newValue) => setBirthDay(newValue)}
                      //disabled={!editable}
                      sx={{
                        width: '28%', // Set the width
                      }}
                      />
                </LocalizationProvider>
              </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px' }}>
              <TextField
                className="form-field"
                label="ที่อยู่ปัจจุบัน"
                variant="filled"
                style={{ width: '71%',marginRight:'1%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <TextField
                className="form-field"
                select
                label="ตำแหน่ง"
                variant="filled"
                style={{ width: '28%'}}
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              >
                {positionOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              
            </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}}>
                <TextField
                  className="form-field"
                  select
                  label="แผนก"
                  variant="filled"
                  //InputLabelProps={{ style: { color: '#000' } }}
                  //InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  style={{ width: '35%', marginRight:'1%' }}
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                >
                  {departmentOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                    className="form-field"
                    select
                    label="สัญชาติ"
                    variant="filled"
                    //InputLabelProps={{ style: { color: '#000' } }}
                    //InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    style={{ width: '35%', marginRight:'1%' }}
                    value={national}
                    onChange={(e) => setNational(e.target.value)}
                  >
                    {nationalOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className="form-field"
                    select
                    label="ประเภทการจ้าง"
                    variant="filled"
                    //InputLabelProps={{ style: { color: '#000' } }}
                    //InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    style={{ width: '28%' }}
                    value={employment}
                    onChange={(e) => setEmployment(e.target.value)}
                  >
                    {employmentOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
            </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}}>
                  <TextField
                    className="form-field"
                    select
                    label="Generation"
                    variant="filled"
                    //InputLabelProps={{ style: { color: '#000' } }}
                    //InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    style={{ width: '35%', marginRight:'1%' }}
                    value={generation}
                    onChange={(e) => setGeneration(e.target.value)}
                  >
                    {generationOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
              </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px' }}>
            <TextField
                className="form-field"
                label="ที่อยู่ตามบัตรประชาชน"
                variant="filled"
                style={{ width: '71%',marginRight:'1%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={address_off}
                onChange={(e) => setAddress_Off(e.target.value)}
              />
              <TextField
                className="form-field"
                label="เลขบัตรประจำตัวประชาชน"
                variant="filled"
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                style={{ width: '28%'}}
                value={nat_id}
                onChange={(e) => setNat_ID(e.target.value)}
              />
            </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}}>
              <TextField
                className="form-field"
                label="E-mail"
                variant="filled"
                style={{ width: '35%', marginRight:'1%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                className="form-field"
                label="เบอร์โทร"
                variant="filled"
                style={{ width: '35%', marginRight: '1%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <LocalizationProvider className="form-field" dateAdapter={AdapterDayjs} adapterLocale="th">
                        <DatePicker
                        label="วันเข้าทำงาน"
                        value={firstDay}
                        onChange={(newValue) => setFirstDay(newValue)}
                        //disabled={!editable}
                        sx={{
                          width: '28%', // Set the width
                        }}
                        />
                  </LocalizationProvider>
              
            </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}}>
            <TextField
                className="form-field"
                select
                label="สถานภาพ"
                variant="filled"
                style={{ width: '35%', marginRight:'1%' }}
                value={personal_status}
                onChange={(e) => setPersonal_Status(e.target.value)}
              >
                {statusOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className="form-field"
                label="จำนวนบุตร"
                variant="filled"
                style={{ width: '35%', marginRight: '1%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={child}
                onChange={(e) => setChild(e.target.value)}
              />
              <TextField
                className="form-field"
                label="กรุ๊ปเลือด"
                variant="filled"
                style={{ width: '28%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={blood_type}
                onChange={(e) => setBlood_type(e.target.value)}
              />
            </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}}>
              <TextField
                  className="form-field"
                  label="โรงพยาบาล ที่มีสิทธิประกันสังคม"
                  variant="filled"
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  style={{ width: '35%', marginRight:'1%' }}
                  value={wealthHos}
                  onChange={(e) => setWealthHos(e.target.value)}
                />
              <TextField
                  className="form-field"
                  label="โรคประจำตัว"
                  variant="filled"
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  style={{ width: '35%', marginRight:'1%' }}
                  value={disease}
                  onChange={(e) => setDisease(e.target.value)}
                />
                <TextField
                  className="form-field"
                  label="แพ้ยา"
                  variant="filled"
                  style={{ width: '28%' }}
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  value={Ldrug}
                  onChange={(e) => setLdrug(e.target.value)}
                />
            </div>
            <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}}>
              <TextField
                  className="form-field"
                  label="ประกันอุบัติเหตุ"
                  variant="filled"
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  style={{ width: '35%', marginRight:'1%' }}
                  value={accident}
                  onChange={(e) => setAccident(e.target.value)}
                />
              <TextField
                  className="form-field"
                  label="กองทุนสำรองเลี้ยงชีพ"
                  variant="filled"
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  style={{ width: '35%', marginRight:'1%' }}
                  value={providentFund}
                  onChange={(e) => setProvidentFund(e.target.value)}
                />
                <TextField
                  className="form-field"
                  label="ตรวจสุขภาพประจำปี"
                  variant="filled"
                  style={{ width: '28%'}}
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  value={healthCheck}
                  onChange={(e) => setHealthCheck(e.target.value)}
                />
            </div>
            <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'101%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>สิทธิประโยชน์อื่นๆ :</p>
            <div className="form-row">
                <TextField
                    className="form-field"
                    label="สิทธิประโยชน์ - 1"
                    variant="filled"
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff',width:'100%' } }}
                    style={{ width: '100%',marginBottom:20}}
                    value={extraBenefitTitle1}
                    onChange={(e) => setExtraBenefitTitle1(e.target.value)}
                  />
                <TextField
                    className="form-field"
                    label="รายละเอียด"
                    variant="filled"
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    style={{ width: '100%',marginBottom:20}}
                    value={extraBenefitDetail1}
                    onChange={(e) => setExtraBenefit1Detail1(e.target.value)}
                  />
              </div>
              <div className="form-row">
                <TextField
                    className="form-field"
                    label="สิทธิประโยชน์ - 2"
                    variant="filled"
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    style={{ width: '100%',marginBottom:20}}
                    value={extraBenefitTitle2}
                    onChange={(e) => setExtraBenefitTitle2(e.target.value)}
                  />
                <TextField
                    className="form-field"
                    label="รายละเอียด"
                    variant="filled"
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    style={{ width: '100%',marginBottom:20 }}
                    value={extraBenefitDetail2}
                    onChange={(e) => setExtraBenefit1Detail2(e.target.value)}
                  />
              </div>
              <div className="form-row" >
                <TextField
                    className="form-field"
                    label="สิทธิประโยชน์ - 3"
                    variant="filled"
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    style={{ width: '100%',marginBottom:20}}
                    value={extraBenefitTitle3}
                    onChange={(e) => setExtraBenefitTitle3(e.target.value)}
                  />
                <TextField
                    className="form-field"
                    label="รายละเอียด"
                    variant="filled"
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    style={{ width: '100%',marginBottom:20 }}
                    value={extraBenefitDetail3}
                    onChange={(e) => setExtraBenefit1Detail3(e.target.value)}
                  />
              </div>
            <div className="form-row" style={{ display: 'flex',}}>
              <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>บัญชีธนาคาร :</p>
            </div>
            <div className="form-row" style={{ display: 'flex',  marginBottom: '20px' }}>
            <TextField
                className="form-field"
                select
                label="ชื่อธนาคาร"
                variant="filled"
                style={{ width: '35%', marginRight: '1%' }}
                value={bank}
                onChange={(e) => setBank(e.target.value)}
              >
                {bankOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className="form-field"
                label="ประเภทบัญชี"
                variant="filled"
                style={{ width: '35%', marginRight: '1%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={bank_type}
                onChange={(e) => setBank_type(e.target.value)}
              />
              <TextField
                className="form-field"
                label="เลขที่บัญชี"
                variant="filled"
                style={{ width: '28%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={bank_id}
                onChange={(e) => setBank_ID(e.target.value)}
              />
            </div>
            <div className="form-row" style={{ display: 'flex'}}>
              <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>บุคคลติดต่อฉุกเฉิน :</p>
            </div>
            <div className="form-row" style={{ display: 'flex',  marginBottom: '20px' }}>
            <TextField
                className="form-field"
                label="ชื่อ - นามสกุล"
                variant="filled"
                style={{ width: '35%', marginRight: '1%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={emer_name}
                onChange={(e) => setEmer_Name(e.target.value)}
              />
              <TextField
                className="form-field"
                label="ความสัมพันธ์"
                variant="filled"
                style={{ width: '35%', marginRight: '1%' }}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={emer_relate}
                onChange={(e) => setEmer_Relate(e.target.value)}
              />
              <TextField
                className="form-field"
                label="เบอร์โทร"
                variant="filled"
                style={{ width: '28%',}}
                InputLabelProps={{ style: { color: '#000' } }}
                InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                value={emer_phone}
                onChange={(e) => setEmer_Phone(e.target.value)}
              />
            </div>
            <div className="form-row" style={{ display: 'flex' }}>
                <p style={{
                  fontSize: 28, backgroundColor: '#D3D3D3', width: '100%',
                  alignSelf: 'center', borderLeft: '5px solid black',
                  borderRadius: 5, paddingLeft: 5
                }}>
                  เอกสารสำคัญ :
                </p>
              </div>

              <div className="form-row" style={{ display: 'flex', marginBottom: '20px' }}>
                {/* 1) บัตรประชาชน */}
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20 }}>
                  <label>บัตรประชาชน</label>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setIdCardFile(e.target.files[0]);
                        setIdCardName(e.target.files[0].name);
                        //setIdCardUrl(''); // ยังไม่มี URL จนกว่าจะอัปโหลดเสร็จ
                        setIdCardRemoved(false);
                      }
                    }}
                  />
                  {/* ถ้ามี idCardFile หรือ idCardUrl จะแสดงชื่อไฟล์ และปุ่ม View / Remove */}
                  {(idCardFile || idCardUrl) && !idCardRemoved && (
                    <div style={{ marginTop: 5 }}>
                      <span>{idCardFile ? idCardFile.name : idCardName}</span>
                      {idCardFile && (
                        <a
                          href={URL.createObjectURL(idCardFile)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: 10, color: 'blue' }}
                        >
                          View
                        </a>
                      )}
                      {(!idCardFile && idCardUrl) && (
                        <a
                          href={idCardUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: 10, color: 'blue' }}
                        >
                          View
                        </a>
                      )}
                      <button
                        onClick={() => {
                          setIdCardFile(null);
                          setIdCardName('');
                          //setIdCardUrl('');
                          setIdCardRemoved(true);
                        }}
                        style={{ marginLeft: 10, color: 'red',borderRadius:5 }}
                      >
                        <IoTrash size={20} />
                      </button>
                    </div>
                  )}
                  {idCardRemoved && (
                    <p style={{ fontStyle: 'italic', color: 'gray' }}>
                      (ไม่มีเอกสาร / ผู้ใช้ลบไฟล์ออกจากฟอร์ม)
                    </p>
                  )}
                </div>

                {/* 2) สำเนาทะเบียนบ้าน */}
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20 }}>
                  <label>สำเนาทะเบียนบ้าน</label>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setHouseFile(e.target.files[0]);
                        setHouseName(e.target.files[0].name);
                        setHouseUrl('');
                        setHouseRemoved(false);
                      }
                    }}
                  />
                  {(houseFile || houseUrl) && !houseRemoved && (
                    <div style={{ marginTop: 5 }}>
                      <span>{houseFile ? houseFile.name : houseName}</span>
                      {houseFile && (
                        <a
                          href={URL.createObjectURL(houseFile)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: 10, color: 'blue' }}
                        >
                          View
                        </a>
                      )}
                      {(!houseFile && houseUrl) && (
                        <a
                          href={houseUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: 10, color: 'blue' }}
                        >
                          View
                        </a>
                      )}
                      <button
                        onClick={() => {
                          setHouseFile(null);
                          setHouseName('');
                          setHouseUrl('');
                          setHouseRemoved(true);
                        }}
                        style={{ marginLeft: 10, color: 'red',borderRadius:5 }}
                      >
                        <IoTrash size={20} />
                      </button>
                    </div>
                  )}
                  {houseRemoved && (
                    <p style={{ fontStyle: 'italic', color: 'gray' }}>
                      (ไม่มีเอกสาร / ผู้ใช้ลบไฟล์ออกจากฟอร์ม)
                    </p>
                  )}
                </div>

                {/* 3) สมุดบัญชีธนาคาร */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>สมุดบัญชีธนาคาร</label>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setBankFile(e.target.files[0]);
                        setBankDocName(e.target.files[0].name);
                        setBankDocUrl('');
                        setBankRemoved(false);
                      }
                    }}
                  />
                  {(bankFile || bankDocUrl) && !bankRemoved && (
                    <div style={{ marginTop: 5 }}>
                      <span>{bankFile ? bankFile.name : bankDocName}</span>
                      {bankFile && (
                        <a
                          href={URL.createObjectURL(bankFile)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: 10, color: 'blue' }}
                        >
                          View
                        </a>
                      )}
                      {(!bankFile && bankDocUrl) && (
                        <a
                          href={bankDocUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: 10, color: 'blue' }}
                        >
                          View
                        </a>
                      )}
                      <button
                        onClick={() => {
                          setBankFile(null);
                          setBankDocName('');
                          setBankDocUrl('');
                          setBankRemoved(true);
                        }}
                        style={{ marginLeft: 10, color: 'red',borderRadius:5 }}
                      >
                        <IoTrash size={20} />
                      </button>
                    </div>
                  )}
                  {bankRemoved && (
                    <p style={{ fontStyle: 'italic', color: 'gray' }}>
                      (ไม่มีเอกสาร / ผู้ใช้ลบไฟล์ออกจากฟอร์ม)
                    </p>
                  )}
                </div>
              </div>
 
            <div className="form-row" style={{ display: 'flex'}}>
              <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>สิทธิ์การใช้งานแอปฯ :</p>
            </div>
            <div className="form-row" style={{ display: 'flex',  marginBottom: '20px' }}>
            <TextField
                  className="form-field"
                  label="Username"
                  variant="filled"
                  style={{ width: '35%', marginRight: '1%' }}
                  InputLabelProps={{ style: { color: '#000' } }}
                  InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <FormControl className="form-field" sx={{ width: '35%', backgroundColor: '#fff',marginRight:'1%' }} variant="filled" autoComplete="new-password">
                  <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <TextField
                  className="form-field"
                  id="filled-select"
                  select
                  label="ระดับ"
                  variant="filled"
                  style={{ width: '28%'}}
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                >
                  {levelOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
            </div>
            <div style={{ display:'flex',flexDirection:'row',width:'100%',marginBottom: '20px' }}>
              <button style={{minWidth:150,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF',marginRight:10}} onClick={ResetUsername}>Reset Username</button> 
            </div>
          
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
              <button style={{ width: 100, maxWidth: 300,height:50,borderRadius:5,backgroundColor:'#D3D3D3',marginRight:10}} onClick={onSave}>บันทึกข้อมูล</button>
              <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF',marginRight:10}} onClick={()=>navigate('/profile', { state: { startIndex, endIndex, search, searchQuery, position:filterPosition } })}>ย้อนกลับ</button>
              
            </div>

          </div>
        </div>
      </main>
      {/* Loading Overlay */}
      {isLoading && (
            <LoadingBackdrop />
          )}
    </div>
  );
}

export default ProfileEdit;
