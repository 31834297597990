// Service.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import logo from './icon/PAM_HR_logo.png';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBars, FaTimes, FaChevronDown, FaChevronUp, FaUsers, FaCalendarCheck, FaDollarSign } from 'react-icons/fa';

const Service = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0); // State to track the current slide
  const [activeButton, setActiveButton] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const keyFeaturesData = [
    {
      icon: <FaUsers />,
      title: 'Employee Management',
      description: 'Organize and update employee information efficiently.'
    },
    {
      icon: <FaCalendarCheck />,
      title: 'Leave & Attendance',
      description: 'Track leaves and attendance in real-time.'
    },
    {
      icon: <FaDollarSign />,
      title: 'Payroll Processing',
      description: 'Automate salary calculations and disbursements.'
    },
    // เพิ่มได้ตามต้องการ
  ];

  // สำหรับแสดงแผน
  const planData = [
    { title: 'PAM 10', features: ['Support 10 employees.', 'Basic payroll processing.'] },
    { title: 'PAM 20', features: ['Support 20 employees.', 'Basic payroll processing.'] },
    { title: 'PAM 30', features: ['Support 30 employees.', 'Basic payroll processing.'] },
    { title: 'PAM 50', features: ['Support 50 employees.', 'Basic payroll processing.'] },
    { title: 'PAM 80', features: ['Support 80 employees.', 'Basic payroll processing.'] },
    { title: 'PAM 100', features: ['Support 100 employees.', 'Basic payroll processing.'] },
    { title: 'PAM 125', features: ['Support 125 employees.', 'Basic payroll processing.'] },
    { title: 'PAM 150', features: ['Support 150 employees.', 'Basic payroll processing.'] },
  ];

  const [showAllPlans, setShowAllPlans] = useState(false);

  const highlightImages = [
    require('./icon/highlight_1.png'),
    require('./icon/highlight_2.png'),
    require('./icon/highlight_3.png'),
    require('./icon/highlight_4.png'),
    require('./icon/highlight_5.png'),
    require('./icon/highlight_6.png')
  ];

  const slides = [
    <div className="custom-slide first-slide" >
      <div className="content-center-wrapper">
      {/* Left Content */}
      <div className="content-center left-content">
        <img src={logo} alt="PAM Logo" className="circle-logo" />
        <p className="quote">"Unlock HR with PAM"</p>
        <p className="desc-quote">
          PAM (Personnel Assistance Management) is software to help HR  <br />manage
          human resources with web and mobile applications.
        </p>
        {/* <p className="quote">"Streamline Your HR"</p>
        <p style={{ textAlign: 'center', color: '#333', fontSize: 24, fontWeight: 'bold', lineHeight: '1.5' }}>
          Boost efficiency and simplify HR management with our intuitive
          solutions.
        </p> */}
      </div>
      {/* Right Content */}
      <div className="content-center right-content">
        <div className="message-box">
          <h3 style={{ margin: '0 0 10px 0', fontWeight: 'bold' }}>Key Features:</h3>
          <ul style={{ paddingLeft: '20px', margin: 0 }}>
            <li>Employee Management</li>
            <li>Leave and Attendance Tracking</li>
            <li>Payroll Processing</li>
            <li>Performance Analysis</li>
            <li>Mobile and Web Compatibility</li>
          </ul>
        </div>
      </div>
    </div>
    </div>,
    <div className="custom-slide">
      
      <p>Coming Soon...</p>
    </div>,
    <div className="custom-slide">
      
      <p>Coming Soon...</p>
    </div>,
  ];

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  // Keyboard event listener for left and right arrow keys
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') nextSlide();
      if (e.key === 'ArrowLeft') prevSlide();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const scrollToKeyFeature = () => {
    document.getElementById('feature-section').scrollIntoView({ behavior: 'smooth' });
    setActiveButton('feature'); 
  };

  const scrollToPlan = () => {
    document.getElementById('plan-section').scrollIntoView({ behavior: 'smooth' });
    setActiveButton('plan'); // Set the active button to "Plan"
  };

  const scrollToHighlight = () => {
    document.getElementById('highlight-section').scrollIntoView({ behavior: 'smooth' });
    setActiveButton('highlight'); 
  };

  const toGetApp = () => {
    setActiveButton('app'); 
    navigate('/get-apk')
  };

  const toAdmin = () => {
    setActiveButton('app'); 
    navigate('/login_admin')
  };

  // ถ้า showAllPlans = false => slice(0,3) (หรือ 4) เฉพาะแผนแรก ๆ
  const displayedPlans = showAllPlans ? planData : planData.slice(0, 3);

  return (
    <div className="service-container">
       <nav className="navbar">
        <div className="navbar-left">
          <div className="navbarlogo-container">
            <img src={logo} alt="PAM Logo" className="navbarlogo" />
          </div>
          <button
            className={`nav-button plan-button ${activeButton === 'plan' ? '' : ''}`}
            onClick={scrollToPlan}
          >
            Plans
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
            onClick={toGetApp}
          >
            Applications
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
            onClick={toAdmin}
          >
            For Admin
          </button>
        </div>
        <div className="button-container">
          <button
            className="nav-button"
            style={{ backgroundColor: '#D9D9D9' }}
            onClick={() => navigate('/login_company')}
          >
            Login
          </button>
          <button
            className="nav-button"
            style={{ backgroundColor: '#343434', color: '#FFF' }}
            onClick={()=> navigate('/register')}
          >
            Register
          </button>
        </div>
        <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className="navbarlogo-container" style={{width:'100%',justifyContent:'space-between'}}>
            <img src={logo} alt="PAM Logo" className="navbarlogo" />
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>

        {isMenuOpen && (
          <div className="mobile-menu">
            <button
              className="nav-button"
              onClick={scrollToPlan}
            >
              Plans
            </button>
            <button
              className="nav-button"
              onClick={toGetApp}
            >
              Applications
            </button>
            <button
              className="nav-button"
              onClick={toAdmin}
            >
              For Admin
            </button>
            <button
              className="nav-button"
              style={{ backgroundColor: '#D9D9D9',marginBottom:5 }}
              onClick={() => navigate('/login_company')}
            >
              Login
            </button>
            <button
              className="nav-button"
              style={{ backgroundColor: '#343434', color: '#FFF' }}
              onClick={()=> navigate('/register')}
            >
              Register
            </button>
          </div>
        )}
      </nav>

      <div className="slideshow-container">
        <div className="arrow left-arrow" onClick={prevSlide}>
          &#8249;
        </div>
        <div className="slide-item">{slides[currentSlide]}</div>
        <div className="arrow right-arrow" onClick={nextSlide}>
          &#8250;
        </div>
        <div className="dots-container">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentSlide === index ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
            ></span>
          ))}
        </div>
      </div>

      {/* Phrase section */}
      <div id="phrase-section" className="phrase-section">
        <div className="phrase-header">
          <span className="phrase-line"></span>
          <h2 className="phrase-title">Simplicity At It's Best.</h2>
          <span className="phrase-line"></span>
        </div>
        <div>
          <p style={{ fontSize: 28,color:'#f1f1f1' }}>Manage essential HR tasks effortlessly, bridging web and mobile for streamlined communication.</p>
        </div>
      </div>

      {/* Key feature section */}
      <div id="feature-section" className="feature-section">
        <div className="plan-header">
          <span className="line"></span>
          <h2 className="plan-title">Key Features</h2>
          <span className="line"></span>
        </div>
        <div className="plan-cards">
          {keyFeaturesData.map((feature, idx) => (
            <div className="card" key={idx}>
              <div className="icon-wrapper">{feature.icon}</div>
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-desc">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Plan section */}
      <div id="plan-section" className="plan-section">
        <div className="plan-header">
          <span className="line"></span>
          <h2 className="plan-title">Plans</h2>
          <span className="line"></span>
        </div>

        {/*
          แทนที่จะ .plan-cards ตรง ๆ เราห่อด้วย .plan-cards-wrapper
          เพื่อทำ Transition และ Gradient
        */}
        <div className={`plan-cards-wrapper ${showAllPlans ? 'expanded' : 'collapsed'}`}>
          <div className="plan-cards" >
            {planData.map((plan, idx) => (
              <div className="card" key={idx}>
                <h3>{plan.title}</h3>
                <ul>
                  {plan.features.map((feature, fIdx) => (
                    <li key={fIdx}>
                      <FaCheckCircle className="icon" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* ปุ่ม Toggle */}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            className="show-more-button"
            onClick={() => setShowAllPlans(!showAllPlans)}
          >
            {showAllPlans ? (
              <>
                <FaChevronUp style={{ marginLeft: 0 }} />
              </>
            ) : (
              <>
                <FaChevronDown style={{ marginLeft: 0 }} />
              </>
            )}
          </button>
        </div>
      </div>

      {/* Highlight section */}
      <div id="highlight-section" className="highlight-section" style={{marginTop:50}}>
        <div className="plan-header">
          <span className="line"></span>
          <h2 className="plan-title">Highlights</h2>
          <span className="line"></span>
        </div>
        <div>
          <p style={{fontSize:28,color:'#f1f1f1'}}>CMS Web App</p>
          <div className="highlight-grid">
            {/* สมมติใส่ 6 รูป */}
            {highlightImages.map((imgPath, index) => (
              <div className="highlight-image" key={index}>
                {/* alt ใส่ชื่อรูปหรือคำอธิบายสั้น ๆ */}
                <img src={imgPath} alt={`highlight_${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Video Section */}
      <div id="video-section" className="video-section">
        <div className="plan-header">
          <span className="line"></span>
          <h2 className="plan-title">Video Tutorials</h2>
          <span className="line"></span>
        </div>

        <div className="video-container">
          {/* ตัวอย่าง iframe YouTube */}
          <iframe
            width="640"
            height="480"
            src="https://www.youtube.com/embed/kNUqB_j1New"
            title="YouTube video tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          {/* เพิ่มวิดีโออื่นได้ตามต้องการ */}
          {/* <iframe ... /> */}
        </div>
      </div>

      <footer className="footer-container">
        <div className="footer-left">
          {/* ปุ่มไปหน้า Administrator */}
          <button
            className="footer-link"
            onClick={() => navigate('/login_admin')}
          >
            Administrator
          </button>
          <span style={{ marginLeft: '8px' }}>
            © 2024 Miscible Technology Co.,Ltd. - All rights reserved.
          </span>
        </div>
        <div className="footer-right">
          {/* ปุ่มไป Terms of Service */}
          <button
            className="footer-link"
            onClick={() => navigate('/terms-of-service')}
          >
            Terms of Service
          </button>
          {/* ปุ่มไป Privacy */}
          <button
            className="footer-link"
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy
          </button>
        </div>
      </footer>
    </div>
  );
};

export default Service;
