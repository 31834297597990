//AssetAdd.js
import React, { useState, useCallback,useContext,useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Navigate,useLocation } from 'react-router-dom';
import Sidebar from './sidebar';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import './addProfile.css'
import { TextField } from '@mui/material';
import firestore from './Firebase/Firestore';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import './FilePicker.css';
import { useDropzone } from 'react-dropzone';
import storage from './Firebase/Storage';
import Layout from './Layout';
import { Label } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import { UserContext } from './UserContext';



function AssetAdd() {
  const navigate = useNavigate();
  const location = useLocation();
  const [name,setName] = useState('');
  const [serial,setSerial] = useState('');
  const [date,setDate] = useState(dayjs());
  const [detail,setDetail] = useState('');
  const [allower,setAllower] = useState('');
  const [undertaker,setUndertaker] = useState('');
  const [allowerId,setAllowerId] = useState('');
  const [undertakerId,setUndertakerId] = useState('');
  const [users,setUsers] = useState([]);
  const [type,setType] = useState('');
  const [id,setID] = useState('');
  const [action,setAction] = useState('');
  const [feedback,setFeedback] = useState('');
  const [asset_Status,setAsset_Status] =  useState({ label: '', value: '' })

  const [files, setFiles] = useState([]);
  const [fileName,setFileName] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [uploadProgress, setUploadProgress] = useState({});
  const { setCurrentUser, companyId } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [filterPosition,setFilterPosition] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);

  const assetStatus =[
    {label:'ใช้งาน',value:0},
    {label:'รอซ่อม',value:1},
    {label:'เสื่อมสภาพ',value:2}
  ]

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      alert("You can only upload one file.");
      return;
    }
    
    const isImage = acceptedFiles.every((file) => file.type.startsWith("image/"));
    if (!isImage) {
      alert("Only image files are allowed.");
      return;
    }
  
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Accept only image files
    maxFiles: 1,      // Limit to one file
  });

  const assetSuc=()=>{
   if(action=='add'){
    alert('Add asset success.')
    navigate('/asset_sync',{state:{startIndex, endIndex, search,searchQuery}})
   }
   if(action=='edit'){
    alert('Update asset success.')
    navigate('/asset_sync',{state:{startIndex, endIndex, search,searchQuery}})
   }

  }

  const assetUnsuc=(e)=>{
    console.log(e)
  }

  const handleSave = async () => {
    
  };

  const onSave = async () => {
    let date_str = date.format('DD/MM/YYYY');

    let file = fileURL;
    let file_name = fileName;
  
    if (files.length > 0) {
      const uploadFiles = async (files) => {
        const uploadPromises = files.map((file) => {
          return new Promise((resolve, reject) => {
            console.log("File to upload:", file);
            console.log("File size:", file.size);
            if (file.size === 0) {
              throw new Error("File size is 0. Cannot upload empty file.");
            }
            storage.uploadFile(
              companyId,
              file,
              (progress) => {
                setUploadProgress((prevProgress) => ({
                  ...prevProgress,
                  [file.name]: progress,
                }));
              },
              async (downloadURL) => {
                resolve({ name: file.name, url: downloadURL });
              },
              (error) => {
                console.error("Upload failed:", error);
                reject(error);
              }
            );
            console.log("Files array:", files);
          });
        });
  
        try {
          const uploadedFiles = await Promise.all(uploadPromises);
          return uploadedFiles;
        } catch (error) {
          console.error("Error uploading files: ", error);
          alert("Error uploading files");
          throw error;
        }
      };
  
      const uploadedFiles = await uploadFiles(files);
      // อัปเดตค่า file และ file_name เมื่อมีการอัปโหลดไฟล์ใหม่
      file = uploadedFiles[0]?.url || "";
      file_name = uploadedFiles[0]?.name || "";
    }
  
    try {
  
      let item = {
        name: name,
        serial: serial,
        detail: detail,
        date: date_str,
        file: file,
        file_name:file_name,
        allower: allower,
        allowerId:allowerId,
        undertaker:undertaker,
        undertakerId:undertakerId,
        type: type,
        asset_Status:asset_Status
      };

      if(action=='add'){
        setID(null)
      }
  
      await firestore.addAndUpdateAsset(companyId,action,id,item, assetSuc, assetUnsuc);
    } catch (error) {
      console.error('Error saving announcement: ', error);
    }
  }

  const removeFile = (file) => () => {
    setFiles(files.filter((f) => f !== file));
    setUploadProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[file.name];
      return newProgress;
    });
  };

  const removeExistingFile = async () => {
    await storage.deleteFile(fileURL);
    setFileName('');
    setFileURL('');
  };

  const addOldValueToOptions = (oldValue, options) => {
    
    const trimmedOldValue = oldValue ? oldValue.trim() : '';
    if (trimmedOldValue && !options.includes(trimmedOldValue)) {
      console.log("Adding old value to options:", trimmedOldValue); // Debug log
      return [trimmedOldValue, ...options];
    }
    return options;
  };

  const fetchDropdownOptions = async () => {
    try {
      const assetTypeOptions = await firestore.getDropdownOptions(companyId,'asset_type')
      const loadedAssetTypeOptions = assetTypeOptions.map(option => option.name.trim());
      setAssetTypeOptions(addOldValueToOptions(type, loadedAssetTypeOptions));
    
      // const loadedAollwerOptions = users.map(option => option.name.trim());
      // setAllower(addOldValueToOptions(allower, loadedAollwerOptions));
      // const loadedUndertakerOptions = users.map(option => option.name.trim());
      // setUndertaker(addOldValueToOptions(undertaker, loadedUndertakerOptions));
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  useEffect(() => {
    if (type) {
      fetchDropdownOptions();
    }else{
      fetchDropdownOptions();
    }
  }, [type]);

  useEffect(() => {
    const loadData = async () => {
      if (location.state && location.state.uid) {
        setID(location.state.uid);
      } 
      if (location.state) {
        setStartIndex(location.state.startIndex || 0);
        setEndIndex(location.state.endIndex || 10);
        setSearch(location.state.search || '');
        setSearchQuery(location.state.searchQuery || '');
        setFilterPosition(location.state.position || '');
        setAction(location.state.action)
      }
      if(location.state.action=='edit'){
        try {
          const assetData = await firestore.getAssetById(companyId, location.state.uid);
          setName(assetData.name || '');
          setSerial(assetData.serial || '');
          setDate(dayjs(assetData.date, 'DD/MM/YYYY'));
          setDetail(assetData.detail || '');
          setAllower(assetData.allower || '');
          setUndertaker(assetData.undertaker || '');
          setAllowerId(assetData.allowerId)
          setUndertakerId(assetData.undertakerId)
          setType(assetData.type);
          setFileName(assetData.file_name)
          setFileURL(assetData.file)
          setAsset_Status(assetData.asset_Status)
          setFeedback(assetData.feedback)
          // Optionally, handle existing files or images
        } catch (error) {
          console.error('Error fetching asset data:', error);
          // Optionally, navigate back or handle the error
        }
      }
    };
    loadData();
    
    firestore.getAllUser(companyId,(data)=>setUsers(data),(e)=>console.log(e))
  }, [location.state]);

  return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        <main className="main-content">
          
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>เพิ่มรายการ</h1>
                {/* Add user profile and logout here */}
              </header>
            </div>
            <div className="main-contain" style={{overflowX:'hidden'}}>
              <div className='block_img'>
                {/* <img src='https://i.postimg.cc/YChjY7Pc/image-10.png' width={150} height={150} alt="Logo" /> */}
              </div>
              <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%'}}>
                <div  className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <TextField
                    label="ชื่อทรัพย์สิน"
                    className="form-field"
                    variant="filled"
                    style={{width:'100%'}}
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    
                  />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                    <TextField
                        label="ประเภททรัพย์สิน"
                        className="form-field"
                        select
                        variant="filled"
                        style={{width:'49%',marginRight:'1%'}}
                        InputLabelProps={{ style: { color: '#000' } }}
                        value={type}
                        onChange={(e) => {setType(e.target.value)
                          // console.log(e.target.value)
                        }}
                    >{assetTypeOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                      </TextField>
                      <TextField
                        label="สถานะ"
                        className="form-field"
                        select
                        variant="filled"
                        // InputLabelProps={{ style: { color: '#000' } }}
                        // InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        style={{width:'50%'}}
                        value={asset_Status?.value ?? ''}
                        onChange={(e) => {
                          const selected = assetStatus.find((item) => item.value === e.target.value);
                          if (selected) {
                            // เป็น 0,1,2
                            setAsset_Status(selected);
                          } else {
                            // เคส value = ''
                            // อาจเซตเป็น { label: '', value: '' } หรือเก็บ label เดิม
                            setAsset_Status({ label: '', value: '' });
                          }
                        }}
  
                    >
                      {assetStatus.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                    {asset_Status.value === '' && asset_Status.label && (
                      <MenuItem value="">
                        {asset_Status.label}
                      </MenuItem>
                    )}
                    </TextField>
                  
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                        
                        <DatePicker
                        label="วันที่"
                        className="form-field"
                        sx={{
                          width: '49%',
                          '& .MuiInputBase-root': {
                            backgroundColor: '#fff',
                          },
                          '& .MuiInputLabel-root': {
                            color: '#000',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#000',
                          },marginRight:'1%'
                        }}
                        value={dayjs(date, 'dd/mm/yy')}
                        onChange={(newValue) => setDate(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="dd/MM/YYYY"
                        />
                
                    </LocalizationProvider>

                    <TextField
                        label="เลขทะเบียนทรัพย์สิน"
                        className="form-field"
                        variant="filled"
                        style={{width:'50%'}}
                        InputLabelProps={{ style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        value={serial}
                        onChange={(e) => setSerial(e.target.value)}
                        
                    />                     
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                    <TextField
                        label="ชื่อผู้อนุมัติ"
                        className="form-field"
                        select
                        variant="filled"
                        // InputLabelProps={{ style: { color: '#000' } }}
                        // InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        style={{width:'49%',marginRight:'1%'}}
                        value={allowerId || allower}
                        onChange={(e) => {
                          const selectedUser = users.find((user) => user.id === e.target.value);
                          if (selectedUser) {
                            // เจอ user จริงในระบบ
                            setAllower(selectedUser.name);
                            setAllowerId(selectedUser.id);
                          } else {
                            // ถ้าเลือกค่าอื่น (เช่นเป็นสตริงทั่วไป หรือ none)
                            setAllower(e.target.value);
                            setAllowerId('');
                          }
                        }}
  
                    >
                      <MenuItem value="">
                        None / Clear Selection
                      </MenuItem>
                      {users.map((user, index) => (
                      <MenuItem key={index} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                    {(allowerId === '' && allower) && (
                      <MenuItem value={allower}>
                        {allower} {/* แสดงชื่อที่มาจาก Excel */}
                      </MenuItem>
                    )}
                    </TextField>
                    <TextField
                        label="ชื่อผู้รับผิดชอบ/ดูแล"
                        className="form-field"
                        select
                        variant="filled"
                        // InputLabelProps={{ style: { color: '#000' } }}
                        // InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        style={{width:'50%'}}
                        value={undertakerId || undertaker}
                        onChange={(e) => {
                          const selectedUser = users.find((user) => user.id === e.target.value);
                          if (selectedUser) {
                            // เจอ user จริงในระบบ
                            setUndertaker(selectedUser?.name || ''); // Set the leader's name
                            setUndertakerId(selectedUser?.id || ''); // Set the leader's id
                          } else {
                            // ถ้าเลือกค่าอื่น (เช่นเป็นสตริงทั่วไป หรือ none)
                            setAllower(e.target.value);
                            setAllowerId('');
                          }
                        }}
                        
                    >
                      <MenuItem value="">
                        None / Clear Selection
                      </MenuItem>
                      {users.map((user, index) => (
                      <MenuItem key={index} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                    {(undertakerId === '' && undertaker) && (
                      <MenuItem value={undertaker}>
                        {undertaker} {/* แสดงชื่อที่มาจาก Excel */}
                      </MenuItem>
                    )}
                    </TextField>
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                    <TextField
                        label="รายละเอียด"
                        className="form-field"
                        variant="filled"
                        multiline
                        rows={4}
                        InputLabelProps={{ style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        style={{width:'100%'}}
                        value={detail}
                        onChange={(e) => setDetail(e.target.value)}
                        
                    >
                    </TextField>
                </div>
                <div  className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <TextField
                    disabled
                    label="รายงานจากผู้ดูแล"
                    className="form-field"
                    variant="filled"
                    style={{width:'100%'}}
                    InputLabelProps={{ style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={feedback}
                  />
                </div>
                <div className="file-picker" style={{ marginTop: 20 }}>
                {fileURL && (
                      <div 
                        className="old-file" 
                        style={{ 
                          marginBottom: 10, 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'space-between', 
                          border: '1px solid #BEBEBE', 
                          padding: '10px', 
                          borderRadius: '4px',
                          backgroundColor: '#fff'
                        }}
                      >
                        <span style={{flex:1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                          Current File: {fileName}
                        </span>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                          {fileURL && (
                            <a
                              href={fileURL}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'underline', color: '#007BFF' }}
                            >
                              View
                            </a>
                          )}
                          <button
                            onClick={() => {
                              removeExistingFile();
                            }}
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                              cursor: 'pointer',
                              color: '#FF0000',
                              fontWeight: 'bold',
                              fontSize: '16px'
                            }}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    )}
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p style={{ fontSize: 22 }}>Drag and drop an preview image file here, or click to select one.</p>
                      </div>
                      <div className="file-list">
                        {files.map((file, index) => (
                          <div key={index} className="file-item">
                            <span>{file.name}</span>
                            <span>{Math.round(uploadProgress[file.name] || 0)}%</span>
                            <button onClick={removeFile(file)}>Remove</button>
                          </div>
                        ))}
                      </div>
                    </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
              <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#D3D3D3',marginRight:10}} onClick={onSave}>บันทึกข้อมูล</button>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF'}} onClick={()=>navigate('/asset_sync',{state:{startIndex, endIndex, search,searchQuery}})}>กลับ</button>
              </div>

            </div>
          </div>
        </main>
      </div>
      
    
  );
}

export default AssetAdd;

  