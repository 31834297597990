import React, { useContext, useEffect, useState } from 'react';
import './Home.css'; // Your custom CSS file
import Layout from './Layout';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { UserContext } from './UserContext';
import { Chart, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { useNavigate,useLocation } from 'react-router-dom';
import firestore from './Firebase/Firestore';

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

function Home() {
  const navigate = useNavigate();
  const { setCurrentUser, companyId, userData } = useContext(UserContext);
  const [workplaceUserData, setWorkplaceUserData] = useState([]);
  const [departmentData, setDepartmentData] = useState({});
  const [nationalData, setNationalData] = useState({});
  const [employmentData, setEmploymentData] = useState({});
  const [generationData, setGenerationData] = useState({});
  const [leaveData, setLeaveData] = useState({ working: 0, leave: { 'ลากิจ': 0, 'ป่วย': 0, 'พักร้อน': 0 } });
  const [employeeLeaveData, setEmployeeLeaveData] = useState([]);
  const [barData1, setBarData1] = useState({});
  const [barData2, setBarData2] = useState({}); 
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const generateSummary = (labels, data, total) => {
    return labels
      // .map((label, index) => `${label} [${data[index]} / ${(data[index] / total * 100).toFixed(2)}%],`)
      .map((label, index) => `${label} [${(data[index] / total * 100).toFixed(2)}%],`)
      .join(' ');
  };
  
  // Summarize pieData1 (Department Data)
  const pieData1Labels = Object.keys(departmentData);
  const pieData1Values = Object.values(departmentData);
  const pieData1Total = pieData1Values.reduce((acc, val) => acc + val, 0);
  const pieData1Summary = generateSummary(pieData1Labels, pieData1Values, pieData1Total);

  const absentCount = Math.max(
    0,
    totalUsers
      - leaveData.working
      - leaveData.leave['ลากิจ']
      - leaveData.leave['ลาป่วย']
      - leaveData.leave['ลาพักร้อน']
  );
  // Summarize pieData2 (Work and Leave Summary)
  const pieData2Labels = ['เข้าทำงาน', 'ลากิจ', 'ลาป่วย', 'ลาพักร้อน',];
  const pieData2Values = [
    leaveData.working,
    leaveData.leave['ลากิจ'],
    leaveData.leave['ลาป่วย'],
    leaveData.leave['ลาพักร้อน'],
    absentCount,
  ];
  const pieData2Total = pieData2Values.reduce((acc, val) => acc + val, 0);
  const pieData2Summary = generateSummary(pieData2Labels, pieData2Values, pieData2Total);

  // Summarize otherPieChartData (Workplace Data)
  // const otherPieChartLabels = workplaceUserData.map((workplace) => workplace.name);
  // const otherPieChartValues = workplaceUserData.map((workplace) => workplace.count);
  const otherPieChartLabels = workplaceUserData.map((wp) => wp.workplace);
  const otherPieChartValues = workplaceUserData.map((wp) => wp.count);
  const otherPieChartTotal = otherPieChartValues.reduce((acc, val) => acc + val, 0);
  const otherPieChartSummary = generateSummary(otherPieChartLabels, otherPieChartValues, otherPieChartTotal);

  const slides = [
    { title: "สรุปรายละเอียดพนักงานทั้งหมด", content: pieData1Summary },
    { title: "สรุปรายละเอียดการเข้าทำงาน", content: pieData2Summary },
    { title: "สรุปการจัดการกำลังพล", content: otherPieChartSummary },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const nationalDataLabels = Object.keys(nationalData);
  const nationalDataValues = Object.values(nationalData);
  const nationalDataTotal = nationalDataValues.reduce((acc, val) => acc + val, 0);
  const nationalDataSummary = generateSummary(nationalDataLabels, nationalDataValues, nationalDataTotal);

  const employmentDataLabels = Object.keys(employmentData);
  const employmentDataValues = Object.values(employmentData);
  const employmentDataTotal = employmentDataValues.reduce((acc, val) => acc + val, 0);
  const employmentDataSummary = generateSummary(employmentDataLabels, employmentDataValues, employmentDataTotal);

  const generationDataLabels = Object.keys(generationData);
  const generationDataValues = Object.values(generationData);
  const generationDataTotal = generationDataValues.reduce((acc, val) => acc + val, 0);
  const generationDataSummary = generateSummary(generationDataLabels, generationDataValues, generationDataTotal);


  function hexToHSL(hex) {
    hex = hex.replace(/^#/, '');
    if (hex.length === 3) {
      hex = hex.split('').map(h => h + h).join('');
    }
    const r = parseInt(hex.substr(0, 2), 16) / 255;
    const g = parseInt(hex.substr(2, 2), 16) / 255;
    const b = parseInt(hex.substr(4, 2), 16) / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l;
    l = (max + min) / 2;
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return { h: h * 360, s: s * 100, l: l * 100 };
  }
  
  // แปลงสี HSL กลับเป็น Hex
  function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    let r = 0, g = 0, b = 0;
    if (0 <= h && h < 60) { r = c; g = x; b = 0; }
    else if (60 <= h && h < 120) { r = x; g = c; b = 0; }
    else if (120 <= h && h < 180) { r = 0; g = c; b = x; }
    else if (180 <= h && h < 240) { r = 0; g = x; b = c; }
    else if (240 <= h && h < 300) { r = x; g = 0; b = c; }
    else if (300 <= h && h < 360) { r = c; g = 0; b = x; }
    const toHex = (x) => {
      const hex = Math.round((x + m) * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }
  
  // ฟังก์ชัน generateColors ที่รับจำนวนสีและสีฐาน
  const generateColors = (n, baseColor = '#5d8bfe') => {
    const hsl = hexToHSL(baseColor);
    // เพิ่มช่วงการไล่เฉดให้กว้างขึ้น เช่น ±30 องศา
    const startHue = hsl.h - 50;
    const endHue = hsl.h + 50;
    const step = (endHue - startHue) / (n - 1);
    const colors = [];
    for (let i = 0; i < n; i++) {
      const hue = startHue + i * step;
      colors.push(hslToHex(hue, hsl.s, hsl.l));
    }
    return colors;
  };
  
  function lightenOrDarkenColor(hex, amt) {
    // ตัวอย่าง: amt เป็น +20 = สว่างขึ้น , -20 = เข้มขึ้น
    // ฟังก์ชันนี้พื้นฐานมากๆ อาจยังไม่รองรับทุกกรณี
    let usePound = false;
    let color = hex;
    
    if (color[0] === "#") {
      usePound = true;
      color = color.slice(1);
    }
  
    // แยกเป็นค่า r,g,b
    let num = parseInt(color, 16);
    let r = (num >> 16) + amt;
    let g = ((num >> 8) & 0x00ff) + amt;
    let b = (num & 0x0000ff) + amt;
  
    // บังคับไม่ให้ต่ำกว่า 0 หรือต่ำกว่า 255
    if (r < 0) r = 0;   if (r > 255) r = 255;
    if (g < 0) g = 0;   if (g > 255) g = 255;
    if (b < 0) b = 0;   if (b > 255) b = 255;
  
    // ประกอบกลับเป็น hex
    let newColor = (r << 16) | (g << 8) | b;
    let str = newColor.toString(16);
    while (str.length < 6) {
      str = "0" + str;
    }
    
    return (usePound ? "#" : "") + str;
  }

  const countEmployeesByDepartment = (employees) => {
    const departmentCounts = {};
    employees.forEach((user) => {
      const department = user.department?.trim() || "อื่นๆ"; // Handle undefined, null, or empty department
      departmentCounts[department] = (departmentCounts[department] || 0) + 1;
    });
    // Check if "อื่นๆ" exists and move it to the end
    if (departmentCounts['อื่นๆ']) {
      const othersCount = departmentCounts['อื่นๆ'];
      delete departmentCounts['อื่นๆ']; // Remove it from its original position
      departmentCounts['อื่นๆ'] = othersCount; // Add it to the end
    }
    return departmentCounts;
  };

  const countNational = (employees) => {
    const nationalCounts = {};
    employees.forEach((user) => {
      const national = user.national?.trim() || "อื่นๆ"; // Handle undefined, null, or empty department
      nationalCounts[national] = (nationalCounts[national] || 0) + 1;
    });
    // Check if "อื่นๆ" exists and move it to the end
    if (nationalCounts['อื่นๆ']) {
      const othersCount = nationalCounts['อื่นๆ'];
      delete nationalCounts['อื่นๆ']; // Remove it from its original position
      nationalCounts['อื่นๆ'] = othersCount; // Add it to the end
    }
    return nationalCounts;
  };

  const countEmployment = (employees) => {
    const employmentCounts = {};
    employees.forEach((user) => {
      const employment = user.employment?.trim() || "อื่นๆ"; // Handle undefined, null, or empty department
      employmentCounts[employment] = (employmentCounts[employment] || 0) + 1;
    });
    // Check if "อื่นๆ" exists and move it to the end
    if (employmentCounts['อื่นๆ']) {
      const othersCount = employmentCounts['อื่นๆ'];
      delete employmentCounts['อื่นๆ']; // Remove it from its original position
      employmentCounts['อื่นๆ'] = othersCount; // Add it to the end
    }
    return employmentCounts;
  };

  const countGeneration = (employees) => {
    const generationCounts = {};
    employees.forEach((user) => {
      const generation = user.generation?.trim() || "อื่นๆ"; // Handle undefined, null, or empty department
      generationCounts[generation] = (generationCounts[generation] || 0) + 1;
    });
    // Check if "อื่นๆ" exists and move it to the end
    if (generationCounts['อื่นๆ']) {
      const othersCount = generationCounts['อื่นๆ'];
      delete generationCounts['อื่นๆ']; // Remove it from its original position
      generationCounts['อื่นๆ'] = othersCount; // Add it to the end
    }
    return generationCounts;
  };

  const parseBirthDay = (birthDayString) => {
    if (!birthDayString) return null;
    const [day, month, year] = birthDayString.split('/');
    if (!day || !month || !year) return null;
    return new Date(+year, +month - 1, +day);
  };

  const getAge = (birthDayString) => {
    // if (!dob) return null;
    // const diffMs = Date.now() - dob.getTime();
    // if (diffMs < 0) return null; // หากเป็นอนาคตหรือไม่สมเหตุสมผล
    // const ageDate = new Date(diffMs);
    // return Math.abs(ageDate.getUTCFullYear() - 1970);
    if (!birthDayString) return null;
    const [d, m, y] = birthDayString.split('/').map(str => parseInt(str, 10));
    if (!y) return null;
    const currentYear = new Date().getFullYear();
    // บวกหรือลบตามอยากได้ เช่น
    // อาจไม่ต้องสนวัน/เดือน:
    return currentYear - y; 
  };

  const countAgeDistribution = (users) => {
    const distribution = {
      "<20": 0,
      "20-29": 0,
      "30-39": 0,
      "40-49": 0,
      "50-59": 0,
      "60+": 0,
      "ไม่ระบุ": 0,
    };

    users.forEach((user) => {
      const dob = parseBirthDay(user.birthDay); // birthDay เป็น "dd/mm/yyyy"
      const age = getAge(user.birthDay);

      if (age === null) {
        distribution["ไม่ระบุ"]++;
      } else if (age < 20) {
        distribution["<20"]++;
      } else if (age >= 20 && age <= 29) {
        distribution["20-29"]++;
      } else if (age >= 30 && age <= 39) {
        distribution["30-39"]++;
      } else if (age >= 40 && age <= 49) {
        distribution["40-49"]++;
      } else if (age >= 50 && age <= 59) {
        distribution["50-59"]++;
      } else {
        // 60+
        distribution["60+"]++;
      }
    });

    return distribution;
  };

  const [ageDistribution, setAgeDistribution] = useState({
    "<20": 0,
    "20-29": 0,
    "30-39": 0,
    "40-49": 0,
    "50-59": 0,
    "60+": 0,
    "ไม่ระบุ": 0,
  });

  const getTodayString=()=>{
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return `${dd}/${mm}/${yyyy}`;  // ได้รูปแบบ dd/mm/yyyy
  }

  const extractPercentage = (text) => {
    const match = text.match(/\[(\d+(?:\.\d+)?)%\]/);
    return match ? match[1] + '%' : '0%';
  };

  useEffect(() => {
    if (!companyId) {
      console.error('Invalid companyId:', companyId);
      return;
    }
    
    // firestore.getWorkplaceUserCounts(
    //   companyId,
    //   (data) => {setWorkplaceUserData(data); console.log(data)},  // On success, set the state
    //   (error) => console.error('Error fetching workplace data:', error) // On error
    // );
    const dateString = getTodayString(); // หรือกำหนดวันที่ที่ต้องการ
    const unsubscribe = firestore.getDailyCheckinSummary(
      companyId,
      dateString,
      (result) => {
        // result จะมีรูปแบบ [{ workplace: 'xxx', count: 10, user: [...]}]
        // เราต้องการให้ "นอกพื้นที่" ไปอยู่ตัวสุดท้าย
        // เขียนฟังก์ชันช่วย เช่น moveNoAreaToEnd

        function moveNoAreaToEnd(arr) {
          const noAreaIndex = arr.findIndex(item => item.workplace === 'นอกพื้นที่');
          if (noAreaIndex === -1) return arr; // ไม่มี 'นอกพื้นที่' ก็ส่งคืนปกติ

          // ดึงออกมา 1 ชิ้น
          const [noAreaItem] = arr.splice(noAreaIndex, 1);
          // ต่อท้าย
          arr.push(noAreaItem);
          return arr;
        }

        const sortedData = moveNoAreaToEnd([...result]);
        // เก็บลง State ไว้ไป plot กราฟ
        setWorkplaceUserData(sortedData);
      },
      (error) => {
        console.error('Error fetching checkin summary:', error);
      }
    );
    firestore.getAllUser(
      companyId,
      (users) => {
        setTotalUsers(users.length);
        const counts = countEmployeesByDepartment(users);
        setDepartmentData(counts); // Set the department counts in state
        const counts_national = countNational(users);
        setNationalData(counts_national); 
        const counts_employment = countEmployment(users);
        setEmploymentData(counts_employment); 
        const counts_generation = countGeneration(users);
        setGenerationData(counts_generation); 
      },
      (error) => {
        console.error('Error fetching employee data:', error);
      }
    );
    firestore.getWorkAndLeaveDataForCurrentDate(
      companyId,
      (data) => {
        //console.log("Fetched leave data: ", data); 
        setLeaveData(data); // On success, set the data
      },
      (error) => console.error('Error fetching work and leave data:', error) // Handle error
    );

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [companyId]);

  useEffect(() => {
    if (!companyId) {
      console.error('Invalid companyId:', companyId);
      return;
    }
    // Fetch all users and then fetch their leave data from wealthfare collection
    firestore.getAllUser(
      companyId,
      (users) => {
        console.log("Fetched users:", users);
  
        const leaveDataPromises = users.map((user) =>
          new Promise((resolve) => {
            firestore.getAllLeaveDataMtoL(
              companyId,
              user.id,
              (leaveData) => {
                // ---------------------------
                // 1) เตรียมคำนวณภายในสิทธิ / เกินสิทธิ
                // ---------------------------
                const absenceAllowed  = leaveData.absence   || 0;
                const absenceRemainder = leaveData.absenceR || 0;
                const absenceUsed   = parseFloat((absenceAllowed - absenceRemainder).toFixed(2));
                const absenceWithin = (absenceUsed <= absenceAllowed)
                  ? absenceUsed
                  : absenceAllowed;
                const absenceOver   = (absenceUsed > absenceAllowed)
                  ? parseFloat((absenceUsed - absenceAllowed).toFixed(2))
                  : 0;
  
                const sickAllowed  = leaveData.sick   || 0;
                const sickRemainder = leaveData.sickR || 0;
                const sickUsed   = parseFloat((sickAllowed - sickRemainder).toFixed(2));
                const sickWithin = (sickUsed <= sickAllowed)
                  ? sickUsed
                  : sickAllowed;
                const sickOver   = (sickUsed > sickAllowed)
                  ? parseFloat((sickUsed - sickAllowed).toFixed(2))
                  : 0;
  
                const holidayAllowed  = leaveData.holiday   || 0;
                const holidayRemainder = leaveData.holidayR || 0;
                const holidayUsed   = parseFloat((holidayAllowed - holidayRemainder).toFixed(2));
                const holidayWithin = (holidayUsed <= holidayAllowed)
                  ? holidayUsed
                  : holidayAllowed;
                const holidayOver   = (holidayUsed > holidayAllowed)
                  ? parseFloat((holidayUsed - holidayAllowed).toFixed(2))
                  : 0;
  
                const attendenceCount = parseFloat(leaveData.attendence || 0);
  
                // รวม “วันลาที่ใช้” (ภายใน + เกิน) ของ 3 ประเภทลาทั้งหมด
                // + attendenceCount (ถ้ายังนับเป็น “ขาดงาน”)
                const totalLeaveUsed = absenceUsed + sickUsed + holidayUsed + attendenceCount;
  
                resolve({
                  name: user.name,
                  // ภายในสิทธิ / เกินสิทธิ (ลากิจ, ลาป่วย, ลาพักร้อน)
                  absenceWithin, absenceOver,
                  sickWithin,    sickOver,
                  holidayWithin, holidayOver,
                  attendenceCount,
                  totalLeaveUsed,
                });
              },
              (error) => {
                console.warn(`Skipping user ${user.id} due to missing data: ${error}`);
                resolve(null); // กรณี wealthfare ไม่มีข้อมูล
              }
            );
          })
        );
  
        Promise.all(leaveDataPromises).then((leaveDataResults) => {
          const filteredData = leaveDataResults.filter(data => data !== null);
  
          // ---------------------------
          // 2) จัดเรียงหา Top 10 (มาก -> น้อย)
          // ---------------------------
          const top10LeaveData = filteredData
            .sort((a, b) => b.totalLeaveUsed - a.totalLeaveUsed)
            .slice(0, 10);
  
          setEmployeeLeaveData(top10LeaveData);
  
          // สร้าง BarData1 -> 7 datasets (ในสิทธิ / เกิน / ขาดงาน)
          if (top10LeaveData.length > 0) {
            setBarData1({
              labels: top10LeaveData.map(employee => employee.name),
  
              datasets: [
                // ---- ลาพักร้อน ----
                {
                  label: 'ลาพักร้อน (ในสิทธิ)',
                  backgroundColor: '#211C84',
                  data: top10LeaveData.map(e => e.holidayWithin),
                  stack: 'holiday',
                },
                {
                  label: 'ลาพักร้อน (เกิน)',
                  backgroundColor: '#f44336', // สีแดง
                  data: top10LeaveData.map(e => e.holidayOver),
                  stack: 'holiday',
                },
  
                // ---- ลาป่วย ----
                {
                  label: 'ลาป่วย (ในสิทธิ)',
                  backgroundColor: '#4D55CC',
                  data: top10LeaveData.map(e => e.sickWithin),
                  stack: 'sick',
                },
                {
                  label: 'ลาป่วย (เกิน)',
                  backgroundColor: '#f44336', // สีแดง
                  data: top10LeaveData.map(e => e.sickOver),
                  stack: 'sick',
                },
  
                // ---- ลากิจ ----
                {
                  label: 'ลากิจ (ในสิทธิ)',
                  backgroundColor: '#7A73D1',
                  data: top10LeaveData.map(e => e.absenceWithin),
                  stack: 'absence',
                },
                {
                  label: 'ลากิจ (เกิน)',
                  backgroundColor: '#f44336', // สีแดง
                  data: top10LeaveData.map(e => e.absenceOver),
                  stack: 'absence',
                },
  
                // ---- ขาดงาน ----
                {
                  label: 'ขาดงาน',
                  backgroundColor: '#B5A8D5',
                  data: top10LeaveData.map(e => e.attendenceCount),
                  stack: 'attendance',
                },
              ],
  
              // ใส่ข้อมูลผู้ใช้ (ถ้าจะใช้ plugin custom) - optional
              users: top10LeaveData,
            });
          }
  
          // ---------------------------
          // 3) จัดเรียงหา Bottom 10 (น้อย -> มาก)
          // ---------------------------
          const bottom10LeaveData = filteredData
            .sort((a, b) => a.totalLeaveUsed - b.totalLeaveUsed)
            .slice(0, 10);
  
          if (bottom10LeaveData.length > 0) {
            setBarData2({
              labels: bottom10LeaveData.map(employee => employee.name),
  
              datasets: [
                // ---- ลาพักร้อน ----
                {
                  label: 'ลาพักร้อน (ในสิทธิ)',
                  backgroundColor: '#DDEB9D',
                  data: bottom10LeaveData.map(e => e.holidayWithin),
                  stack: 'holiday',
                },
                {
                  label: 'ลาพักร้อน (เกิน)',
                  backgroundColor: '#f44336', 
                  data: bottom10LeaveData.map(e => e.holidayOver),
                  stack: 'holiday',
                },
  
                // ---- ลาป่วย ----
                {
                  label: 'ลาป่วย (ในสิทธิ)',
                  backgroundColor: '#A0C878',
                  data: bottom10LeaveData.map(e => e.sickWithin),
                  stack: 'sick',
                },
                {
                  label: 'ลาป่วย (เกิน)',
                  backgroundColor: '#f44336',
                  data: bottom10LeaveData.map(e => e.sickOver),
                  stack: 'sick',
                },
  
                // ---- ลากิจ ----
                {
                  label: 'ลากิจ (ในสิทธิ)',
                  backgroundColor: '#27667B',
                  data: bottom10LeaveData.map(e => e.absenceWithin),
                  stack: 'absence',
                },
                {
                  label: 'ลากิจ (เกิน)',
                  backgroundColor: '#f44336',
                  data: bottom10LeaveData.map(e => e.absenceOver),
                  stack: 'absence',
                },
  
                // ---- ขาดงาน ----
                {
                  label: 'ขาดงาน',
                  backgroundColor: '#143D60',
                  data: bottom10LeaveData.map(e => e.attendenceCount),
                  stack: 'attendance',
                },
              ],
              users: bottom10LeaveData,
            });
          }
        });
      },
      (error) => console.error('Error fetching users:', error)
    );

    firestore.getAllUser(
      companyId,
      (users) => {
        const distribution = countAgeDistribution(users);
        setAgeDistribution(distribution);
      },
      (error) => {
        console.error('Error fetching employee data:', error);
      }
    );
  }, [companyId]);

  // const customTooltipPlugin = {
  //   id: 'customTooltipPlugin',
  //   beforeDraw: (chart) => {
  //     const ctx = chart.ctx;
  //     const users = chart.config.data.users || [];
  //     const meta = chart.getDatasetMeta(0);

  //     meta.data.forEach((bar, index) => {
  //       const user = users[index];
  //       if (user) {
  //         const image = new Image();
  //         image.src = user.image;
  //         const x = bar.x - 15;
  //         const y = chart.chartArea.bottom + 10;

  //         image.onload = () => {
  //           ctx.drawImage(image, x, y, 30, 30);
  //         };
  //       }
  //     });
  //   },
  // };

  // Chart.register(customTooltipPlugin);
  
  // const valueLabelPlugin = {
  //   id: 'valueLabelPlugin',
  //   afterDatasetsDraw: (chart) => {
  //     const { ctx } = chart;
  //     chart.data.datasets.forEach((dataset, i) => {
  //       const meta = chart.getDatasetMeta(i);
  //       meta.data.forEach((bar, index) => {
  //         const value = dataset.data[index];
  //         ctx.save();
  //         ctx.font = '12px Arial';
  //         ctx.fillStyle = '#000'; // Text color
  //         ctx.textAlign = 'center';
  //         ctx.textBaseline = 'bottom';
  //         ctx.fillText(value, bar.x, bar.y - 5); // Position text above the bar
  //         ctx.restore();
  //       });
  //     });
  //   },
  // };
  
  // Chart.register(valueLabelPlugin);

  function formatValue(val) {
    // ถ้า value ต่างจากค่าปัดเศษไม่เกิน 1e-9 ถือว่าเป็น integer
    if (Math.abs(val - Math.round(val)) < 1e-9) {
      return Math.round(val).toString(); // แสดงเป็น integer เช่น "3"
    } else {
      return val.toFixed(2);            // แสดงทศนิยม 2 ตำแหน่ง เช่น "3.25"
    }
  }
  
  // ปลั๊กอินสำหรับ “แสดงค่าบนแท่ง” ของแต่ละ segment ใน Stacked Bar
  const stackedTotalPlugin = {
    id: 'stackedValuePlugin',
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
      data.datasets.forEach((dataset, dsIndex) => {
        const meta = chart.getDatasetMeta(dsIndex);
  
        // วนทุกแท่งใน dataset
        meta.data.forEach((bar, idx) => {
          const rawValue = dataset.data[idx] || 0;
          // ถ้าไม่อยากโชว์ค่า 0 ก็เช็คก่อน
          if (rawValue === 0) return;
  
          // เรียกใช้ formatValue
          const displayValue = formatValue(rawValue);
  
          ctx.save();
          ctx.font = '12px Arial';
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          // bar.x, bar.y คือจุดบนสุดของ segment นั้น
          ctx.fillText(displayValue, bar.x, bar.y - 5);
          ctx.restore();
        });
      });
    },
  };

  Chart.register(stackedTotalPlugin);
  

  const pieData1 = {
    labels: pieData1Labels,
    datasets: [
      {
        label: 'จำนวนพนักงาน',
        data: Object.values(departmentData),
        backgroundColor: generateColors(Object.keys(departmentData).length,'#143D80'),
        hoverBackgroundColor: generateColors(Object.keys(departmentData).length,'#143D80').map(c => lightenOrDarkenColor(c, -20)),
      },
    ],
  };

  //const pieData2Labels = ['เข้าทำงาน', 'ลากิจ', 'ลาป่วย', 'ลาพักร้อน'];
  const pieData2 = {
    labels: pieData2Labels,
    datasets: [
      {
        label: 'สรุปประจำวัน',
        data: [
          leaveData.working,
          leaveData.leave['ลากิจ'],
          leaveData.leave['ลาป่วย'],
          leaveData.leave['ลาพักร้อน'],
          absentCount,
        ],
        backgroundColor: generateColors(pieData2Labels.length,'#A0C878'),
        hoverBackgroundColor: generateColors(pieData2Labels.length,'#A0C878').map(c => lightenOrDarkenColor(c, -20)),
      },
    ],
  };

  // Prepare the data for "จำนวนคนอื่นๆ" pie chart
  const otherPieChartData = {
    labels: otherPieChartLabels, // Workplace names
    datasets: [
      {
        label: 'จำนวนคนตามพื้นที่ทำงาน',
        data: otherPieChartValues, // Number of users in each workplace
        // backgroundColor: generateColors(workplaceUserData.length),
        // hoverBackgroundColor: generateColors(workplaceUserData.length),
        backgroundColor: generateColors(otherPieChartLabels.length,'#E493B3'),
        hoverBackgroundColor: generateColors(otherPieChartLabels.length,'#E493B3').map(c => lightenOrDarkenColor(c, -20)),
      },
    ],
  };

  const nationalChart = {
    labels: nationalDataLabels,
    datasets: [
      {
        label: 'สัญชาติพนักงาน',
        data: Object.values(nationalData),
        backgroundColor: generateColors(Object.keys(nationalData).length,'#EEA5A6'),
        hoverBackgroundColor: generateColors(Object.keys(nationalData).length,'#EEA5A6').map(c => lightenOrDarkenColor(c, -20)),
      },
    ],
  };

  const employmentChart = {
    labels: employmentDataLabels,
    datasets: [
      {
        label: 'ประเภทการจ้าง',
        data: Object.values(employmentData),
        backgroundColor: generateColors(Object.keys(employmentData).length,'#A1D6CB'),
        hoverBackgroundColor: generateColors(Object.keys(employmentData).length,'#A1D6CB').map(c => lightenOrDarkenColor(c, -20)),
      },
    ],
  };

  const generationChart = {
    labels: generationDataLabels,
    datasets: [
      {
        label: 'สัดส่วน Generation',
        data: Object.values(generationData),
        backgroundColor: generateColors(Object.keys(generationData).length,'#80C4E9'),
        hoverBackgroundColor: generateColors(Object.keys(generationData).length,'#80C4E9').map(c => lightenOrDarkenColor(c, -20)),
      },
    ],
  };

  const ageLabels = Object.keys(ageDistribution);   // ["<20", "20-29", ...]
  const ageValues = Object.values(ageDistribution); // [จำนวน, จำนวน, ...]

  const ageChartData = {
    labels: ageLabels,
    datasets: [
      {
        label: 'จำนวนพนักงานตามช่วงอายุ',
        data: ageValues,
        backgroundColor: generateColors(ageLabels.length,'#9550f5'),
        hoverBackgroundColor: generateColors(ageLabels.length,'#9550f5').map(c => lightenOrDarkenColor(c, -20)),
      },
    ],
  };

  return (
    <div className="dashboard">
      <Layout />
      
      <main className="dashboard-contain">
        {/* First Row: Two Pie Charts */}
        <div className="chart-row">
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>จำนวนพนักงาน</p>
            <Bar
              data={pieData1}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
                elements: {
                  bar: {
                    borderRadius: { topLeft: 10, topRight: 10, bottomLeft: 0, bottomRight: 0 },
                    borderSkipped: false,
                  },
                },
              }}
              plugins={[stackedTotalPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
          
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>สรุปประจำวัน</p>
            {/* <Pie
              data={pieData2}
              options={{ maintainAspectRatio: false }}
              style={{ width: '300px', height: '300px' }} // Direct control of width and height
            /> */}
            <Bar
              data={pieData2}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
                elements: {
                  bar: {
                    borderRadius: { topLeft: 10, topRight: 10, bottomLeft: 0, bottomRight: 0 },
                    borderSkipped: false,
                  },
                },
              }}
              plugins={[stackedTotalPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
        </div>

        {/* Second Row: One Pie Chart and One Container */}
        <div className="chart-row">
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>จัดการกำลังคน</p>
            {/* <Pie
              data={otherPieChartData}
              options={{ maintainAspectRatio: false }}
              style={{ width: '300px', height: '300px' }} // Direct control of width and height
            /> */}
            <Bar
              data={otherPieChartData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
                elements: {
                  bar: {
                    borderRadius: { topLeft: 10, topRight: 10, bottomLeft: 0, bottomRight: 0 },
                    borderSkipped: false,
                  },
                },
              }}
              plugins={[stackedTotalPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
          
         
          <div className="chart-container">
            <p className="data-title">Data Visualization</p>

            <div className="slideDash-container">
              <button onClick={prevSlide} className="slide-nav-btn">◀</button>

              <div className="slideDash-scroll">
                <p className="slideDash-title">{slides[currentSlide].title}</p>
                <div className="slideDash-content-grid">
                  {slides[currentSlide].content
                    .split(',')
                    .filter(item => item.trim() !== '')
                    .map((item, index) => (
                      <div key={index} className="slideDash-item" style={{ '--perc': extractPercentage(item) }}>{item.trim()}</div>
                  ))}
                </div>
              </div>

              <button onClick={nextSlide} className="slide-nav-btn">▶</button>
            </div>
          </div>
        
        </div>

        <div className="chart-row">
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>ช่วงอายุ</p>
            <Bar
              data={ageChartData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
                elements: {
                  bar: {
                    borderRadius: { topLeft: 10, topRight: 10, bottomLeft: 0, bottomRight: 0 },
                    borderSkipped: false,
                  },
                },
              }}
              plugins={[stackedTotalPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
          
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>สัญชาติพนักงาน</p>
            <Bar
              data={nationalChart}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
                elements: {
                  bar: {
                    borderRadius: { topLeft: 10, topRight: 10, bottomLeft: 0, bottomRight: 0 },
                    borderSkipped: false,
                  },
                },
              }}
              plugins={[stackedTotalPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
        </div>

        <div className="chart-row">
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>ประเภทการจ้าง</p>
            <Bar
              data={employmentChart}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
                elements: {
                  bar: {
                    borderRadius: { topLeft: 10, topRight: 10, bottomLeft: 0, bottomRight: 0 },
                    borderSkipped: false,
                  },
                },
              }}
              plugins={[stackedTotalPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
          
          <div className="pie-chart-container">
            <p style={{marginBottom:-10}}>สัดส่วน Generation</p>
            {/* <Pie
              data={pieData2}
              options={{ maintainAspectRatio: false }}
              style={{ width: '300px', height: '300px' }} // Direct control of width and height
            /> */}
            <Bar
              data={generationChart}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false }, // Optional: Hide the legend if not needed
                },
                layout: {
                  padding: {
                    top: 20, // Add extra padding to the top
                  },
                },
                elements: {
                  bar: {
                    borderRadius: { topLeft: 10, topRight: 10, bottomLeft: 0, bottomRight: 0 },
                    borderSkipped: false,
                  },
                },
              }}
              plugins={[stackedTotalPlugin]} // Use the value label plugin
              style={{ width: '500px', height: '400px' }} // Adjust dimensions
            />
          </div>
        </div>


        {/* Third Row: First Bar Chart Most Leave ลามากสุด*/}
        <div className="chart-row">
          <div className="chart-container bar-chart-fullwidth">
            {barData1 && barData1.labels ? (
              <Bar
                data={barData1}
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    padding: {
                      top: 20,
                    },
                  },
                  // ---------- (1) ตั้งให้เป็น Stacked Bar ----------
                  scales: {
                    x: { stacked: true },
                    y: {
                      stacked: true,
                      // ---------- (2) กำหนดทศนิยม 2 ตำแหน่งบนแกน Y ----------
                      ticks: {
                        callback: function (value) {
                          return Number(value).toFixed(2);
                        },
                      },
                    },
                  },
                  // ---------- (3) tooltip ให้แสดง 2 ทศนิยม ----------
                  plugins: {
                    legend: { display: true },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          const val = context.parsed.y || 0;
                          return `${context.dataset.label}: ${val.toFixed(2)}`;
                        },
                      },
                    },
                  },
                  elements: {
                    bar: {
                      borderRadius: { topLeft: 3, topRight: 3, bottomLeft: 0, bottomRight: 0 },
                      borderSkipped: false,
                    },
                  },
                }}
                // ---------- (4) ใช้ปลั๊กอิน custom เพื่อ “แสดงเฉพาะเลขรวมบนสุด” ----------
                plugins={[stackedTotalPlugin]}
                style={{ width: '800px', height: '400px' }}
              />
            ) : (
              <p>Loading bar chart...</p>
            )}
          </div>
        </div>

        {/* Fourth Row: Second Bar Chart Least Leave ลาน้อยสุด*/}
        <div className="chart-row">
          <div className="chart-container bar-chart-fullwidth">
            {barData2 && barData2.labels ? (
              <Bar
                data={barData2}
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    padding: {
                      top: 20,
                    },
                  },
                  scales: {
                    x: { stacked: true },
                    y: {
                      stacked: true,
                      ticks: {
                        callback: function (value) {
                          return formatValue(value);
                        },
                      },
                    },
                  },
                  plugins: {
                    legend: { display: true },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          const val = context.parsed.y || 0;
                          return `${context.dataset.label}: ${formatValue(val)}`;
                        },
                      },
                    },
                  },
                  elements: {
                    bar: {
                      borderRadius: { topLeft: 3, topRight: 3, bottomLeft: 0, bottomRight: 0 },
                      borderSkipped: false,
                    },
                  },
                }}
                plugins={[stackedTotalPlugin]}
                style={{ width: '800px', height: '400px' }}
              />
            ) : (
              <p>Loading bar chart...</p>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
