import './App.css';
import React, { useState, useContext,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import firestore from './Firebase/Firestore';
import auth from './Firebase/Auth';
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';

const LoginCompany = () => {
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setCompanyId: setContextCompanyId } = useContext(UserContext);

  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const trimmedCompanyId = companyId.replace(/\s+/g, '');
    const success = (found) => {
      if (found) {
        setIsLoading(false)
        setContextCompanyId(trimmedCompanyId);
        localStorage.setItem('companyId', trimmedCompanyId);  // Save companyId to localStorage
        navigate('/login');
      } else {
        setIsLoading(false)
        //alert('Company not found!');
        Swal.fire({
          // title: 'Warning',
          // text: 'Cannot delete an Administrator account.',
          title: 'แจ้งเตือน',
          text: `ไม่พบ Company ID`,
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });
      }
    };

    const unsuccess = () => {
      setIsLoading(false)
      //alert('Company not found!');
      const trimmedCompanyId = companyId.replace(/\s+/g, '');
      Swal.fire({
        // title: 'Warning',
        // text: 'Cannot delete an Administrator account.',
        title: 'แจ้งเตือน',
        text: `ไม่พบ Company ID`,
        icon: 'warning',
        customClass: {
          popup: 'my-swal-size'
        },
        // showClass: {
        //   popup: 'animate__animated animate__fadeInDown'
        // },
        // hideClass: {
        //   popup: 'animate__animated animate__fadeOutUp'
        // }
      });
    };

    firestore.checkCompany(trimmedCompanyId, success, unsuccess);
  };

  const register = () => {
    navigate('/register')
  };

  useEffect(() => {
    auth.checksignin((user) => {
      if (user) {
        //firestore.getAccount(companyId,user.id,(data)=>console.log(data),(e)=>console.log(e))
        navigate('/home');
      }
    });
  }, [navigate]);

  return (
    <div className="App">
      <main className="App-header">
        <div className='Main'>
          <img src={require('./icon/PAM_HR_logo.png')} className='App-logo' width={200} height={200} alt="Logo" />
          <form onSubmit={onLogin}>
            <input
              style={{ marginTop: 50, textAlign: companyId ? 'left' : 'center'}}
              className="input-field"
              placeholder="Company ID"
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
              required
              autoComplete="off"
            />
            <div style={{ textAlign: 'right',marginRight:8}}>
            <p className="register">
              ถ้าคุณยังไม่มีบัญชี{' '}
              <span className="register-link" onClick={register}>ลงทะเบียน</span>
            </p>
            </div>
            <button type="submit" className="login-button" style={{marginTop:30}}>Next</button>
          </form>
        </div>
        <div style={{fontSize:20,lineHeight: 1,marginTop:20}}>
            <p>© 2024 Miscible Technology Co.,Ltd. - all rights reserved</p>
        </div>
          
      </main>
      <footer className="footer-container" style={{marginTop:0}}>
        <div className="footer-left">
          {/* ปุ่มไปหน้า Administrator */}
          {/* <button
            className="footer-link"
            onClick={() => navigate('/login_admin')}
          >
            Administrator
          </button> */}
          <span style={{ marginLeft: '8px' }}>
            © 2024 Miscible Technology Co.,Ltd. - All rights reserved.
          </span>
          <button
            className="footer-link"
            onClick={() => navigate('/')}
          >
            Website
          </button>
        </div>
        <div className="footer-right">
          {/* ปุ่มไป Terms of Service */}
          <button
            className="footer-link"
            onClick={() => navigate('/terms-of-service')}
          >
            Terms of Service
          </button>
          {/* ปุ่มไป Privacy */}
          <button
            className="footer-link"
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy
          </button>
        </div>
      </footer>
      {isLoading && (
        <LoadingBackdrop />
      )}
    </div>
  );
};

export default LoginCompany;
