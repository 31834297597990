import app from "./Config";
import { getFirestore, collection, addDoc, setDoc, doc, getDoc, onSnapshot, 
        deleteDoc, updateDoc, orderBy, query, where, getDocs,writeBatch, limit, collectionGroup,serverTimestamp  } from "firebase/firestore";
import storage from "./Storage";
import { SUCCESS } from "dropzone";

class FireStore{
  constructor(){
    this.db = getFirestore(app);
  }

  getAllRequestDemo=(success,unsuccess)=>{
      try {
          const demoRef = collection(this.db, "companies", "adminPAM", "requestDemo");
          const q = query(demoRef);
      
          // เรียก onSnapshot เพื่อฟังการเปลี่ยนแปลงแบบเรียลไทม์
          const unsubscribe = onSnapshot(q,
            (snapshot) => {
              const allRequestDemos = [];
              snapshot.forEach((doc) => {
                allRequestDemos.push({ id: doc.id, ...doc.data() });
              });
              success(allRequestDemos);
            },
            (error) => {
              unsuccess(error);
            }
          );
      
          // รีเทิร์นฟังก์ชัน unsubscribe ออกไป 
          return unsubscribe;
      } catch (error) {
          unsuccess(error);
      }
  }

  getRequestDemoById=async(docId)=> {
    try {
      const docRef = doc(this.db, "companies", "adminPAM", "requestDemo", docId);
      const snapshot = await getDoc(docRef);
      if (!snapshot.exists()) {
        return null;
      }
      return { id: snapshot.id, ...snapshot.data() };
    } catch (error) {
      console.error("Error in getRequestDemoById =>", error);
      throw error;
    }
  }

    // 1) ฟังก์ชันสร้าง/อัปเดตเอกสารที่ companies/{companyId}
  createCompanyDoc = async (companyId, companyObj) => {
    try {
      const docRef = doc(this.db, "companies", companyId);
      // หากต้องการ merge => setDoc(docRef, companyObj, { merge: true })
      await setDoc(docRef, companyObj);
      console.log("Created/Updated company doc =>", companyId);
    } catch (error) {
      console.error("Error creating company doc =>", error);
      throw error;
    }
  };

  // 2) ฟังก์ชันอัปเดตเอกสารใน companies/adminPAM/requestDemo/{itemId}
  updateRequestDemo = async (itemId, data) => {
    try {
      const docRef = doc(this.db, "companies", "adminPAM", "requestDemo", itemId);
      await updateDoc(docRef, data);
      console.log("Request demo updated =>", itemId, data);
    } catch (error) {
      console.error("Error updating request demo =>", error);
      throw error;
    }
  };


    getAdminUser=async(companyId,id,success,unsuccess)=>{
        try{
          const docRef = doc(this.db, "companies", companyId, "account_cms", id);
          const docSnap = await getDoc(docRef);
    
          if (docSnap.exists()) {
            //console.log("Document data:", docSnap.data());
            success(docSnap.data())
          } else {
            // docSnap.data() will be undefined in this case
            //console.log("No such document!");
            unsuccess()
          }
        }catch(e){
          unsuccess(e)
        }
    }
    
    addAccount= async (companyId,id,item)=>{
        try {
            const docRef = await setDoc(doc(this.db, "companies", companyId, "account_cms", id), item);
            //console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    updateAccount = async (companyId, id, data, success, unsuccess) => {
        try {
          const docRef = doc(this.db, "companies", companyId, "account_cms", id);
          await updateDoc(docRef, data);
          success();
        } catch (e) {
          unsuccess(e);
        }
      };
    
    deleteAccount= async (companyId,id)=>{
        await deleteDoc(doc(this.db, "companies", companyId, "account_cms", id));
    }
}

const adminFirestore = new FireStore();
export default adminFirestore;