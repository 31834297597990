import React,{useState,useEffect,useContext} from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useNavigate,useLocation } from 'react-router-dom';
import './Home.css';
import Sidebar from './sidebar';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import firestore from './Firebase/Firestore';
import storage from './Firebase/Storage';
import { IoSearchOutline,IoTrash,IoChevronBackOutline } from "react-icons/io5";
import Layout from './Layout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import THSarabunNew from './fonts/THSarabunNew-normal';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { UserContext } from './UserContext';
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';


function SalaryList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [uid,setUid] = useState('') // user id
  const [name,setName] = useState('');
  const [date,setDate] = useState(dayjs(new Date(),'DD-MM-YYYY'));
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [startIndexSalary, setStartIndexSalary] = useState(location.state?.startIndex || 0);
  const [endIndexSalary, setEndIndexSalary] = useState(location.state?.endIndex || 10);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [allBill,setAllBill] = useState([])
  const [allBillCF,setAllBillCF] = useState([])
  const [addAble,setAddAble] = useState(null)
  const [allDeposit,setAllDeposit] = useState(null)
  const [allInsurance,setAllInsurance] = useState(null)
  const { setCurrentUser, companyId,company_name } = useContext(UserContext);

  const [selectedBillIds, setSelectedBillIds] = useState([]); // Track selected bills for the first table
  const [selectedBillCFIds, setSelectedBillCFIds] = useState([]); // Track selected bills for the second table
  const [selectAllBills, setSelectAllBills] = useState(false);
  const [selectAllBillCFs, setSelectAllBillCFs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const getUserSuccess=(data)=>{
    setName(data.name+" "+data.lastname)
  }

  const getUserUnsuccess=(e)=>{
    console.log(e)
  }

  const getAllBillSuc = (doc) => {
    let bills = [];
    let billsCF = [];
  
    
    doc.forEach((item) => {
      // item.confirm จะเป็น true หรือ false
      if (item.confirm === false) {
        bills.push(item);
      } else {
        billsCF.push(item);
      }
    });
    
  
    // Sort the bills array by date in descending order
    bills.sort((a, b) => dayjs(b.date, 'DD/MM/YYYY') - dayjs(a.date, 'DD/MM/YYYY'));
    billsCF.sort((a, b) => dayjs(b.date, 'DD/MM/YYYY') - dayjs(a.date, 'DD/MM/YYYY'));

    if(bills.length < 1){
      setAddAble(true)
    }else{
      setAddAble(false)
    }

  
    setAllBill(bills);
    setAllBillCF(billsCF);
  };

  const getAllBillUnsuc=(error)=>{
    console.log(error)
  }

  const addSalary=(id)=>{
    navigate('/profile_salary', { state: { action: 'edit', uid: uid } })
  }

  const calSalary=(id)=>{
    if(addAble){
      let act = "cal";
      navigate('/salary_cal',{state:{uid,act:id,act}})
    }else{
      alert('You create bill already.')
    }
  }

  const toEdit=(date,id)=>{
    let act = "edit";
    navigate('/salary_cal',{state:{uid,date,act,id:uid,date,act,id}})
  }

  const generatePdfAndUpload = async (billData) => {
    try {
      // 1) สร้าง jsPDF instance
      const doc = new jsPDF();
      
      // 2) ฝังฟอนต์ TH Sarabun
      doc.addFileToVFS("THSarabunNew.ttf", THSarabunNew.base64);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");

      // 3) ปรับฟอนต์
      doc.setFontSize(24);
      // สมมติคุณมี company_name เก็บใน context หรือส่งมาใน billData
      doc.text(company_name || "บริษัท ตัวอย่าง จำกัด", 14, 22);

      doc.setFontSize(20);
      doc.text("ใบแจ้งเงินเดือน (PAY SLIP)", 14, 30);

      // แสดงข้อมูลชื่อ/ตำแหน่ง/วันที่
      doc.text(`ชื่อ: ${billData.name}`, 14, 38);
      doc.text(`ตำแหน่ง: ${billData.position || ""}`, 14, 46);
      doc.text(`วันที่จ่าย (DATE): ${billData.date}`, 14, 54);

      // 4) เตรียมตาราง
      const tableColumn = [
        [
          { content: "รวมรายได้", colSpan: 2, styles: { halign: 'center' } },
          { content: "รวมเงินหัก", colSpan: 2, styles: { halign: 'center' } },
        ],
      ];

      // ตัวอย่างการสร้าง row จากข้อมูลใน billData 
      // (ปรับแก้ให้ตรง field ของคุณเอง)
      const tableRows = [
        ["เงินเดือน", format(billData.salary),          "ประกันสังคม", format(billData.insurance)],
        ["ค่าจ้าง",   format(billData.sub),             "ภาษีหัก ณ ที่จ่าย", format(billData.late)],
        ["ค่าพาหนะ",   format(billData.venhicle),       "เงินเบิกล่วงหน้า",  format(billData.withdraw)],
        ["ค่าเบี้ยเลี้ยง", format(billData.allowance), "เงินกู้ยืมสวัสดิการ", format(billData.borrow)],
        ["ค่าล่วงเวลา", format(billData.ot),           "กองทุนสำรองเลี้ยงชีพ", format(billData.liveFund)],
        ["ค่าสวัสดิการ", format(billData.welth),       "ภาษีเงินได้", format(billData.tax)],
        ["เงินโบนัส",    format(billData.bonus),        "เงินหักอื่นๆ", format(billData.other)],
        ["เงินพิเศษ",    format(billData.costL),        "-", "-"],

        // ตัวอย่างการโชว์ "สาย -999", "ขาดงาน -999"
        ["สาย", `- ${format(billData.late)}`, "-", "-"],
        ["ขาดงาน", `- ${format(billData.missing)}`, "-", "-"],

        ["เงินได้สะสม", format(billData.allDeposit), "ประกันสังคมสะสม", format(billData.allInsurance)],
        ["รวมเงินได้", format(billData.deposit), "รวมเงินหัก", format(billData.allWithdraw)],
      ];

      // 5) autoTable
      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        startY: 60,
        styles: {
          font: 'THSarabunNew',
          fontSize: 18,
          cellPadding: 3,
          overflow: 'linebreak',
          halign: 'right',
          valign: 'middle',
          lineWidth: 0.5,
          lineColor: [0, 0, 0],
        },
        headStyles: {
          fillColor: [191, 228, 255],
          textColor: [0, 0, 0],
          halign: 'center',
        },
        columnStyles: {
          0: { halign: 'left' },
          1: { halign: 'right', cellWidth: 30 },
          2: { halign: 'left' },
          3: { halign: 'right', cellWidth: 30 },
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.75,
        didDrawCell: (data) => {
          // ลบเส้นแบ่ง
          if (data.column.index === 1) {
            doc.setDrawColor(255, 255, 255);
            doc.line(data.cell.x - 0.5, data.cell.y, data.cell.x - 0.5, data.cell.y + data.cell.height);
          }
          if (data.column.index === 3) {
            doc.setDrawColor(255, 255, 255);
            doc.line(data.cell.x - 0.5, data.cell.y, data.cell.x - 0.5, data.cell.y + data.cell.height);
          }
        },
      });

      // 6) สรุป (เงินได้สุทธิ)
      const netSalary = format(billData.amount);
      doc.setFontSize(20);
      doc.setTextColor(0, 0, 0);
      doc.setFillColor(191, 228, 255);
      doc.rect(14, doc.lastAutoTable.finalY + 10, 182, 10, 'F');
      doc.text(`เงินได้สุทธิ: ${netSalary}`, 18, doc.lastAutoTable.finalY + 17);

      // 7) แปลง PDF เป็น blob
      const pdfBlob = doc.output('blob');

      // 8) ตั้งชื่อไฟล์ (เช่น: {ชื่อ}_salary_{วันที่}.pdf)
      const pdfFileName = `${billData.name || "emp"}_salary_${(billData.date || "").replace(/\//g, "_")}.pdf`;

      // 9) อัปโหลดไปยัง Storage
      const downloadURL = await storage.uploadBill(companyId, pdfFileName, pdfBlob);

      return downloadURL;
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);
      throw error;
    }
  };

  const format=(val)=>{
    if(!val) return "0.00";
    if(typeof val === 'string') {
      // ถ้าเป็น string มี comma อยู่แล้ว อาจ parse ออก
      val = parseFloat(val.replace(/,/g,"")) || 0;
    }
    return new Intl.NumberFormat('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(val);
  }

  const saveSuc=()=>{}
  const saveUnsuc=(e)=>console.log(e)

  const onConfirm=async(id,uid,allDeposit,allInsurance,billData)=>{
    setIsLoading(true)
    try {
      // 1) generate PDF
      const pdfURL = await generatePdfAndUpload(billData);

      // 2) update Bill => confirm = true + urlPDF = pdfURL
      let item = {
        confirm: true,
        urlPDF: pdfURL,
      };
      firestore.updateBill(companyId, id, item, saveSuc, saveUnsuc);
      firestore.updateUser(companyId,uid,{allDeposit:allDeposit,allInsurance:allInsurance},
      ()=>console.log('success'),(e)=>console.log(e))
      setIsLoading(false)
      Swal.fire({
        title: 'สำเร็จ',
        text: 'บันทึกข้อมูลสำเร็จ',
        // text: `ไม่สามารถเพิ่มพนักงานได้เกิน ${maxAllowed} คน (plan: ${userPlan})`,
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
      });
    } catch (err) {
      //alert("Error creating PDF: " + err.message);
      setIsLoading(false)
      Swal.fire({
        title: 'พบข้อผิดพลาด',
        // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
        text: `เกิดปัญหา error: ${err.message}`,
        icon: 'error',
        customClass: {
          popup: 'my-swal-size'
        },
      });

    }
  }

  useEffect(() => {
    if (location.state && location.state.uid) {
      setUid(location.state.uid);
      //console.log('from eff'+uid)
      firestore.getUser(companyId,location.state.uid,getUserSuccess,getUserUnsuccess)
      firestore.getAllBill(companyId,location.state.uid,getAllBillSuc,getAllBillUnsuc)
    } else {
      console.warn('No ID found in location state');
    }
  }, [location.state]);

  const onNext = () => {
    setStartIndex(startIndex + 5); // Increment the start index by 5
    setEndIndex(endIndex + 5); // Increment the end index by 5
  };

  const onPrevious = () => {
    setStartIndex(Math.max(startIndex - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndIndex(Math.max(endIndex - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearch(event.target.value);
    setSearchQuery(query);
    // const filtered = allUser.filter(user => user.name.toLowerCase().includes(query));
    // setFilteredUsers(filtered);
  };

  const handleSelectAllBills = () => {
    if (selectAllBills) {
      setSelectedBillIds([]);
    } else {
      const allIds = allBill.map((bill) => bill.docId);
      setSelectedBillIds(allIds);
    }
    setSelectAllBills(!selectAllBills);
  };

  const handleSelectAllBillCFs = () => {
    if (selectAllBillCFs) {
      setSelectedBillCFIds([]);
    } else {
      const allIds = allBillCF.map((bill) => bill.docId);
      setSelectedBillCFIds(allIds);
    }
    setSelectAllBillCFs(!selectAllBillCFs);
  };

  const handleSelectBill = (id) => {
    if (selectedBillIds.includes(id)) {
      setSelectedBillIds(selectedBillIds.filter((billId) => billId !== id));
    } else {
      setSelectedBillIds([...selectedBillIds, id]);
    }
  };

  const handleSelectBillCF = (id) => {
    if (selectedBillCFIds.includes(id)) {
      setSelectedBillCFIds(selectedBillCFIds.filter((billId) => billId !== id));
    } else {
      setSelectedBillCFIds([...selectedBillCFIds, id]);
    }
  };

  const handleDeleteSelected = () => {
    setIsLoading(true)
    const allSelectedIds = [...selectedBillIds, ...selectedBillCFIds];
    if (allSelectedIds.length === 0) {
      //alert("No bills selected for deletion.");
      setIsLoading(false)
      Swal.fire({
        title: 'แจ้งเตือน',
        // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
        text: `ไม่ได้เลือกบิล`,
        icon: 'warning',
        customClass: {
          popup: 'my-swal-size'
        },
      });
      return;
    }
    if (window.confirm("Are you sure you want to delete the selected bills?")) {
      allSelectedIds.forEach((id) => {
        // 1) ก่อนลบใน Firestore ให้หา Bill ที่ตรงกับ ID นั้น
        //    เพื่อจะได้เข้าถึง urlPDF
        const allBills = [...allBill, ...allBillCF];
        const billToDelete = allBills.find((bill) => bill.docId === id);

        // 2) ถ้ามี urlPDF => ลบไฟล์ใน storage
        if (billToDelete && billToDelete.urlPDF) {
          storage.deleteFile(
            billToDelete.urlPDF,
            () => {
              console.log("File deleted from storage:", billToDelete.urlPDF);
            },
            (err) => {
              console.error("Error deleting file from storage:", err);
            }
          );
        }

        //3) ลบ Bill ใน Firestore
        firestore.deleteBill(
          companyId,
          id,
          () => {
            console.log("Bill deleted successfully:", id);
            // Update local state: filter the deleted ID out
            setAllBill((prevBills) => prevBills.filter((bill) => bill.id !== id));
            setAllBillCF((prevBillsCF) => prevBillsCF.filter((billCF) => billCF.id !== id));
          },
          (error) => {
            console.error("Error deleting bill:", error);
            setIsLoading(false)
            Swal.fire({
              title: 'พบข้อผิดพลาด',
              // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
              text: `เกิดปัญหา error: ${error}`,
              icon: 'error',
              customClass: {
                popup: 'my-swal-size'
              },
            });
          }
        );
      });
      setIsLoading(false)
      Swal.fire({
        title: 'สำเร็จ',
        // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
        text: `ลบบิลที่เลือกสำเร็จ`,
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
      });
  
      // Reset selection after deletion
      setSelectedBillIds([]);
      setSelectedBillCFIds([]);
      setSelectAllBills(false);
      setSelectAllBillCFs(false);
    }
  };

  const handleBack = () => {
    navigate('/salary', { state: { startIndex: startIndexSalary, endIndex: endIndexSalary, bypassPassword: true } });
  };


  return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        
        <main className="main-content">
          
          <div class="main">
            <div className='header-page'>
            <header>
              <h1>ทำเรื่องเงินเดือน</h1>
              {/* Add user profile and logout here */}
            </header>
            </div>
            <div class="main-contain">
                
              <p style={{fontSize:36,marginLeft:15,marginTop:20}} onClick={handleBack}>
                <IoChevronBackOutline
                  size={32}
                  style={{ cursor: 'pointer', marginRight: 10,alignSelf:'center',justifyContent:'center' }}
                  onClick={handleBack}
                />
                เงินเดือน: {name}
              </p>
            {/* <div className="search-field">
                <button className='Add-button' onClick={()=> calSalary(uid)}>คำนวณเงินเดือน</button>
            <p style={{marginTop:17}}>ค้นหา</p>
                <input style={{width:'40%',margin:5,height:40,borderRadius:20,paddingInlineStart:10,fontSize:18}}
                placeholder='search..' 
                value={search}
                onChange={handleSearch} />
                <button className="search-button" ><IoSearchOutline size={24} /></button>
              </div> */}
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:10,width:'95%',alignSelf:'center'}}>
                <button className='Edit-button' style={{fontSize:20,width:150,height:50}} onClick={()=> addSalary(uid)}>เพิ่มข้อมูลเงินเดือน</button>
                <button className='Add-button' style={{fontSize:20}} onClick={()=> calSalary(uid)}>คำนวณเงินเดือน</button>
              </div>
      
              <div style={{width:'95%',alignSelf:'center',marginTop:20}}>
              <p style={{fontSize:28,textAlign:'center'}}>เงินเดือนล่าสุด</p>
              <IoTrash
                size={32}
                className="trash-icon"
                onClick={handleDeleteSelected}
                style={{
                  marginBottom: '10px',
                  cursor: 'pointer',
                  color: 'red',
                  border: '2px solid red',
                  padding: '5px',
                  borderRadius: '5px',
                }}
              />
              <TableBootstrap striped bordered hover className='table'>
                <thead>
                    <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={selectAllBills}
                        onChange={handleSelectAllBills}
                      />
                    </th>
                    <th scope="col">ลำดับ</th>
                    <th scope="col">เดือน</th>
                    <th scope="col" style={{width:'35%'}}>actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/*{allUser.slice(startIndex, endIndex).map((item, index) => (*/}
                  {allBill.map((item, index) => (
                    <tr key={item.docId}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedBillIds.includes(item.docId)}
                          onChange={() => handleSelectBill(item.docId)}
                        />
                      </td>
                      {/*<th scope="row">{startIndex + index + 1}</th>*/}
                      <th scope="row">{index + 1}</th>
                      <td>
                        {item.date}
                      </td>
                      <td style={{textAlign:'center'}}>
                          <button className='Edit-button' style={{height:'30%'}} onClick={()=> toEdit(item.date,item.docId)}>ดูและแก้ไข</button>
                          <button className='Edit-button' style={{height:'30%',backgroundColor:'#343434'}} onClick={()=>onConfirm(item.docId,item.id,item.allDeposit,item.allInsurance,item)}>Submit</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableBootstrap>
              <p style={{fontSize:28,textAlign:'center',marginTop:20}}>ประวัติการจัดทำเงินเดือน</p>
              <TableBootstrap striped bordered hover className='table'>
                <thead>
                  <tr>
                  <th>
                      <input
                        type="checkbox"
                        checked={selectAllBillCFs}
                        onChange={handleSelectAllBillCFs}
                      />
                    </th>
                    <th scope="col">ลำดับ</th>
                    <th scope="col">เดือน</th>
                    <th scope="col" style={{width:'35%'}}>actions</th>
                  </tr>
                </thead>
                <tbody>
                {allBillCF.map((item, index) => (
                    <tr key={item.docId}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedBillCFIds.includes(item.docId)}
                          onChange={() => handleSelectBillCF(item.docId)}
                        />
                      </td>
                      {/*<th scope="row">{startIndex + index + 1}</th>*/}
                      <th scope="row">{index + 1}</th>
                      <td>
                        {item.date}
                      </td>
                      <td style={{textAlign:'center'}}>
                        <button className='Edit-button' style={{width:'35%',height:'30%'}} onClick={()=> toEdit(item.date,item.docId)}>ดู</button>  
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableBootstrap>
              {/*<div>
                <button onClick={onPrevious}>Previous</button>
                <button onClick={onNext}>Next</button>
                </div>*/}
              </div>
            </div>
          </div>
        </main>
        {isLoading && (
          <LoadingBackdrop />
        )}
      </div>
      
    
  );
}

export default SalaryList;

  