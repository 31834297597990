//SalarySetting.js
import React, { useState,useRef,useEffect,useContext } from 'react';
import Sidebar from './sidebar';
import './Home.css';
import { useNavigate,useLocation } from 'react-router-dom';
import './addProfile.css'
import { Alert, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import firestore from './Firebase/Firestore';
import storage from './Firebase/Storage';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Layout from './Layout';
import { UserContext } from './UserContext';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';



function SalarySetting() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const location = useLocation();

  const [action,setAction] = useState('');
  const [uid,setUid] = useState('');

  const [costPerDay,setCostPerDay] = useState("") //ค่าแรง/วัน
  const [costPerHr,setCostPerHr] = useState("") //ค่าแรง/ชั่วโมง
  const [late,setLate] = useState("") //สาย
  const [attendance,setAttendance] = useState("") //ขาดงาน
  const [sociteIns,setSociteIns] = useState("") // %ประกันสังคม
  const [sociteInsMax,setSociteInsMax] = useState("")
 
  const { setCurrentUser, companyId } = useContext(UserContext);
  const [startIndexSalary, setStartIndexSalary] = useState(location.state?.startIndex || 0);
  const [endIndexSalary, setEndIndexSalary] = useState(location.state?.endIndex || 10);
  const [isLoading, setIsLoading] = useState(false);

  const sanitizeInput = (input) => {
    // ตัดอักขระที่ไม่ใช่ตัวเลขหรือจุด
    let sanitized = input.replace(/[^0-9.]/g, '');
  
    // ตรวจสอบว่ามีกี่จุด (.)
    const parts = sanitized.split('.');
    if (parts.length > 2) {
      // กรณีมีจุดมากกว่า 1 จุด (เช่น "12.3.4"),
      // ให้เก็บเฉพาะส่วน before-dot + dot + after-dot ส่วนแรก
      // เช่น ["12", "3", "4"] -> "12.3"
      sanitized = parts[0] + '.' + parts[1];
    }
  
    return sanitized;
  };
  
  // 2) ฟังก์ชัน removeLeadingZeros
  const removeLeadingZeros = (input) => {
    if (input === '' || input === '.') {
      // กรณีพิมพ์แค่ '.' ก็ยังอนุญาตค้างไว้ได้
      return input;
    }
    // ถ้า input เป็น '0.???' ให้เก็บไว้เป็นรูปทศนิยม เช่น '0.12' => ไม่ต้องลบ
    if (input.startsWith('0.') ) {
      return input;
    }
    // กรณีเริ่มด้วย '0' แต่มีมากกว่า 1 หลัก เช่น '07', '0005'
    // parseFloat('0005') => 5 -> แปลงเป็น string '5'
    // parseFloat('000.12') => 0.12 -> '0.12'
    if (input.startsWith('0') && input.length > 1) {
      return parseFloat(input).toString();
    }
  
    return input;
  };
  
  const validateNumberInput = (input) => {
    // ขั้นตอนที่ 1 : remove non-numeric + จำกัดจุดได้แค่จุดเดียว
    let sanitized = sanitizeInput(input);
  
    // ขั้นตอนที่ 2 : remove leading zeros
    let noLeadingZeros = removeLeadingZeros(sanitized);
  
    return noLeadingZeros;
  };

  const loadData = () => {
    const onSuccess = (data) => {
      if (data) {
        setCostPerDay(
          parseFloat(data.costPerDay || 0).toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
        setCostPerHr(
          parseFloat(data.costPerHr || 0).toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
        setLate(
          parseFloat(data.late || 0).toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
        setAttendance(
          parseFloat(data.attendance || 0).toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
        // สมมุติว่า Firestore เก็บ sociteIns เป็นเลขทศนิยม (เช่น 0.05) แต่เราต้องการแสดงเป็น 5.00%
        setSociteIns(
          data?.sociteIns
            ? (parseFloat(data.sociteIns) * 100).toLocaleString('th-TH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            : "0.00"
        );
        setSociteInsMax(
          data.sociteInsMax
            ? parseFloat(data.sociteInsMax).toLocaleString('th-TH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            : "0.00"
        );
      } else {
        // ถ้าไม่มีเอกสาร (null)
        // set ค่า default = 0
        setCostPerDay(0);
        setCostPerHr(0);
        setLate(0);
        setAttendance(0);
        setSociteIns(0);
        setSociteInsMax(0);
      }
    };
    const onError = (error) => {
      console.error(error);
      alert('Error loading data: ' + error.message);
    };

    // เรียก getSalarySetting
    firestore.getSalarySetting(companyId, onSuccess, onError);
  };

  const onSave= async()=>{
    setIsLoading(true)
    let item ={
        costPerDay: parseFloat((costPerDay|| '0').replace(/,/g, '')) || 0,
        costPerHr: parseFloat((costPerHr|| '0').replace(/,/g, '')) || 0,
        late: parseFloat((late|| '0').replace(/,/g, '')) || 0,
        attendance: parseFloat((attendance|| '0').replace(/,/g, '')) || 0,
        sociteIns: (parseFloat((sociteIns|| '0').replace(/,/g, '')) / 100) || 0, 
        sociteInsMax: parseFloat((sociteInsMax|| '0').replace(/,/g, '')) || 0
    }
   
    const updateSuccess = () => {
        //alert('Save data success!!');
        setIsLoading(false)
        Swal.fire({
          title: 'สำเร็จ',
          text: 'บันทึกข้อมูลสำเร็จ',
          // text: `ไม่สามารถเพิ่มพนักงานได้เกิน ${maxAllowed} คน (plan: ${userPlan})`,
          icon: 'success',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        navigate('/salary', { state: { startIndex: startIndexSalary, endIndex: endIndexSalary, bypassPassword: true } })
    };
    const updateUnsuccess = (error) => {
      console.error(error);
      //alert('Error saving data: ' + error.message);
      setIsLoading(false)
      Swal.fire({
        title: 'พบข้อผิดพลาด',
        // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
        text: `เกิดปัญหา error: ${error.message}`,
        icon: 'error',
        customClass: {
          popup: 'my-swal-size'
        },
      });
    };

    firestore.upsertSalarySetting(companyId, item, updateSuccess, updateUnsuccess);
  }

  useEffect(() => {
    if (companyId) {
        loadData();
      }
  }, [location.state,companyId]);

  const handleBlur2Decimals=(value, setFn)=> {
    if (value === '' || value === '.') {
      setFn('0.00');
      return;
    }
    const numeric = parseFloat(value);
    if (!isNaN(numeric)) {
      // setFn(formatCurrency(numeric.toFixed(2)));
      setFn(numeric.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    } else {
      setFn('0.00');
    }
    //setManualWorkTimeCost(false);
  }

  return (
    
      <div className="dashboard">
        <Layout />
        
        <main className="main-content">
          
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>ตั้งค่าเงินเดือน</h1>
                {/* Add user profile and logout here */}
              </header>
            </div>
            <div className="main-contain">
              
              <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%',marginTop:30}}>
              <div className="form-row" style={{ display: 'flex'}}>
                <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>เวลาทำงาน :</p>
              </div>
              <div className="form-row" style={{ display: 'flex',  marginBottom: '20px',justifyItems:'center',alignItems:'center' }}>
                <div style={{width:'19%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end'
                    }}>
                    ค่าแรง/วัน :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                </p></div>
                <TextField
                    //label="เงินเดือน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={costPerDay}
                    onChange={(e) => {
                        const val = validateNumberInput(e.target.value);
                        setCostPerDay(val);
                      }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setCostPerDay((costPerDay || "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(costPerDay, setCostPerDay)}
                  />
                  <div style={{width:'19%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end'
                    }}>
                        ค่าแรง/ชั่วโมง :
                        {/* <Tooltip title="เงินเดือนของพนักงาน">
                            <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                        </Tooltip> */}
                    </p></div>
                  <TextField
                    //label="ค่าล่วงเวลา"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={costPerHr}
                    onChange={(e) => {
                        const val = validateNumberInput(e.target.value);
                        setCostPerHr(val);
                      }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setCostPerHr((costPerHr|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(costPerHr, setCostPerHr)}
                  />

              </div>

              <div className="form-row" style={{ display: 'flex',  marginBottom: '20px',justifyItems:'center',alignItems:'center' }}>
              <div style={{width:'19%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end'
                    }}>
                    สาย :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                </p></div>
                <TextField
                    //label="เงินเดือน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={late}
                    onChange={(e) => {
                        const val = validateNumberInput(e.target.value);
                        setLate(val);
                      }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setLate((late|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(late, setLate)}
                  />
                  <div style={{width:'19%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end'
                    }}>
                        ขาดงาน :
                        {/* <Tooltip title="เงินเดือนของพนักงาน">
                            <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                        </Tooltip> */}
                    </p></div>
                  <TextField
                    //label="ค่าล่วงเวลา"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={attendance}
                    onChange={(e) => {
                        const val = validateNumberInput(e.target.value);
                        setAttendance(val);
                      }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setAttendance((attendance|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(attendance, setAttendance)}
                  />         
              </div>
              <div className="form-row" style={{ display: 'flex' }}>
                <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>รายการหัก :</p>
              </div>
              <div className="form-row" style={{ display: 'flex',  marginBottom: '20px',justifyItems:'center',alignItems:'center' }}>
                    <div style={{width:'19%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end'
                    }}>
                        ประกันสังคม(%) :
                        {/* <Tooltip title="เงินเดือนของพนักงาน">
                            <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                        </Tooltip> */}
                    </p></div>
                  <TextField
                    //label="ค่าล่วงเวลา"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={sociteIns}
                    onChange={(e) => {
                        const val = validateNumberInput(e.target.value);
                        setSociteIns(val);
                      }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setSociteIns((sociteIns || "").replace(/,/g, ""));
                    }}
                    onBlur={() => handleBlur2Decimals(sociteIns, setSociteIns)}
                  /> 
                    <div style={{width:'19%',marginRight:'1.25%'}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end'
                    }}>
                       จำนวนหักสูงสุด:
                    </p></div> 
                    <TextField
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={sociteInsMax}
                    onChange={(e) => {
                        const val = validateNumberInput(e.target.value);
                        setSociteInsMax(val);
                      }}
                      onFocus={() => {
                        // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                        setSociteInsMax((sociteInsMax|| "").replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(sociteInsMax, setSociteInsMax)}
                  />       
              </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#D3D3D3',marginRight:10}} onClick={onSave}>บันทึกข้อมูล</button>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF',marginRight:10}} onClick={()=>{navigate('/salary', { state: { startIndex: startIndexSalary, endIndex: endIndexSalary, bypassPassword: true } })}}>ย้อนกลับ</button>
              </div>

            </div>
          </div>
        </main>
        {isLoading && (
          <LoadingBackdrop />
        )}
      </div>
      
    
  );
}

export default SalarySetting;

  