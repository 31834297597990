// UserContext.js
import React, { createContext, useState, useEffect, useRef } from 'react';
import auth from './Firebase/Auth';
import firestore from './Firebase/Firestore';
import Swal from 'sweetalert2';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => JSON.parse(localStorage.getItem('userData')) || null);
  const [currentAdmin, setCurrentAdmin] = useState(() => JSON.parse(localStorage.getItem('adminData')) || null);
  const [companyId, setCompanyId] = useState(localStorage.getItem('companyId') || null);
  const [userData, setUserData] = useState(null); // Store Firestore user data
  const [loading, setLoading] = useState(true);  // Set loading to true initially
  const [newLeaveRequests, setNewLeaveRequests] = useState(false);
  const [newOtRequests, setNewOtRequests] = useState(false);

  const [userPlan, setUserPlan] = useState(() => localStorage.getItem('plan') || '');
  const [company_name, setCompany_Name] = useState(() => localStorage.getItem('company_name') || '');
  const [companyData, setCompanyData] = useState(null);
  const hasAlerted = useRef(false);

  let DEFAULT_LOGO_URL = "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/PAM_HR_logo.png?alt=media&token=00d6bf28-0af6-49f9-bdd4-2a999fd19382";
  const [logoUrl, setLogoUrl] = useState(DEFAULT_LOGO_URL); // State for logo URL

  // useEffect ครั้งแรก: ดึงข้อมูลบริษัทจาก Firestore ครั้งเดียวเมื่อ companyId มีค่า
  useEffect(() => {
    localStorage.removeItem('sidebarScroll');
    if (!companyId) {
      setLoading(false);
      return;
    }
    const unsubscribe = firestore.onCompanySnapshot(companyId, (docData) => {
      setCompanyData(docData);
      // อัปเดต plan และ companyName จาก docData
      if (docData) {
        setUserPlan(docData.plan);
        setCompany_Name(docData.companyName);
        localStorage.setItem('plan', docData.plan);
        localStorage.setItem('company_name', docData.companyName);
        if (docData?.logo) {
          setLogoUrl(docData.logo);
          DEFAULT_LOGO_URL=docData.logo
          localStorage.setItem('logo', docData.logo); // Cache the logo in localStorage
        } else {
          setLogoUrl(DEFAULT_LOGO_URL); // Set default logo if Firestore does not have a logo
        }
        setLoading(false);
      }
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [companyId]);

  useEffect(() => {
    if (companyData?.status === "inactive" && !hasAlerted.current) {
      hasAlerted.current = true;
      Swal.fire({
        title: 'Trial expired',
        text: 'Your demo trial has expired. Please contact admin for further details.',
        icon: 'warning'
      }).then(() => {
        handleLogout();
        window.location.replace('/login_company')
      });
    }
  }, [companyData?.status]);

  // useEffect สำหรับตรวจสอบ trial period เมื่อข้อมูลบริษัทถูกดึงมาแล้ว
  // เฉพาะเมื่อ plan เป็น "demo" และมี trialStart
  useEffect(() => {
    if (companyData && companyData.plan === "demo" && companyData.trialStart) {
      // แปลง trialStart เป็น Date object
      const trialStartDate = companyData.trialStart.toDate
        ? companyData.trialStart.toDate()
        : new Date(companyData.trialStart);
      const trialDuration = 14 * 24 * 3600 * 1000; // 14 วันในมิลลิวินาที
      const trialEnd = new Date(trialStartDate.getTime() + trialDuration);
      
      // ใช้ interval คำนวณทุก 1 วินาที
      const intervalId = setInterval(() => {
        const now = new Date();
        if (now >= trialEnd && companyData.status !== "inactive" ) {
          // Trial หมดแล้ว: update status เป็น inactive และแจ้งเตือน
          firestore.updateCompanyData(companyId, { status: "inactive" })
            .then(() => {
              //hasAlerted.current = true;
              Swal.fire({
                title: 'Trial expired',
                text: 'Your demo trial has expired. Please contact admin for further details.',
                icon: 'warning'
              }).then(() => {
                handleLogout();
                window.location.replace('/login_company')
              });
            })
            .catch((error) => console.error("Error updating status:", error));
          clearInterval(intervalId);
        }
        
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [companyData, companyId,]);

  useEffect(() => {
    const isAdminMode = localStorage.getItem('isAdminMode') === 'true';

    // หากอยู่ใน Admin Mode ไม่โหลดข้อมูล User
    if (!isAdminMode) {
      const storedUser = JSON.parse(localStorage.getItem('userData'));
      const storedAdmin = JSON.parse(localStorage.getItem('adminData'));
      if (storedUser) {
        setCurrentUser(storedUser);
        setUserData(storedUser)
        setLoading(false);
      } else {
        setLoading(false);
      }

      const unsubscribe = auth.checksignin((user) => {
        if (user) {
          const storedCompanyId = localStorage.getItem('companyId');
          if (storedCompanyId) {
            firestore.getAccount(storedCompanyId, user.uid, (accountData) => {
              if (companyData && companyData.status === "inactive") {
                Swal.fire({
                  title: 'Trial expired',
                  text: 'Your demo trial has expired. Please contact admin for further details.',
                  icon: 'warning'
                }).then(() => {
                  handleLogout();
                  window.location.replace('/login_company')
                });
                return;
              }
              localStorage.setItem('userData', JSON.stringify(accountData));
              setCurrentUser(accountData);
              setUserData(accountData)
              //setLoading(false);

              // firestore.checkPlan(
              //   storedCompanyId,
              //   (planValue) => {
              //     // planValue เช่น "demo", "10", "50", ...
              //     setUserPlan(planValue.plan);
              //     setCompany_Name(planValue.companyName)
              //     localStorage.setItem('plan', planValue.plan);
              //     localStorage.setItem('company_name', planValue.companyName);
              //     setLoading(false);
              //   },
              //   () => {
              //     // กรณีเกิด error หรือไม่พบ plan
              //     console.log('No plan found or error occurred.');
              //     setUserPlan('');
              //     localStorage.removeItem('plan');
              //     setCompany_Name('')
              //     localStorage.removeItem('company_name');
              //     setLoading(false);
              //   }
              // );
            }, () => handleLogout(false));
          } else {
            handleLogout(false);
          }
        } else {
          handleLogout(false);
        }
      });

      return () => unsubscribe && unsubscribe();
    } else {
      setLoading(false);
    }
  }, [companyData]);


  const handleLogout = (clearAll = true) => {
    if (clearAll) {
      setCurrentUser(null);
      setCompanyId(null);
      setUserData(null)
      localStorage.removeItem('companyId');
      localStorage.removeItem('userData');
      setUserPlan('');
      localStorage.removeItem('plan');
      setCompany_Name('')
      localStorage.removeItem('company_name');
      localStorage.removeItem('logo');
      localStorage.removeItem('sidebarScroll');
      setLoading(false)
    }
  };

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser, companyId, setCompanyId,userData, setUserData,
                                   loading,setLoading, newLeaveRequests, setNewLeaveRequests, newOtRequests, setNewOtRequests, handleLogout,
                                   currentAdmin,setCurrentAdmin,userPlan,setUserPlan,company_name,setCompany_Name,companyData, }}>
      {children}
    </UserContext.Provider>
  );
};
