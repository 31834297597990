import React,{useState,useEffect,useContext} from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './Home.css';
import Sidebar from './sidebar';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { useNavigate } from 'react-router-dom';
import firestore from './Firebase/Firestore';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { IoSearchOutline,IoTrash } from "react-icons/io5";
import Layout from './Layout';
import { UserContext } from './UserContext';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { TextField} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import {FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineFilter, AiOutlineExport } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { hashPassword } from './hashPassword';
import * as XLSX from 'xlsx';

function ChatHub() {
  const navigate = useNavigate();

  const [show,setShow] = useState(false);
  const [selectID,setSelectID] = useState('')
  const [chathubList, setChathubList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const itemsPerPage = 10;
  const [selectedChatIds, setSelectedChatIds] = useState([]); // Track selected OT requests
  const [selectAll, setSelectAll] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [deny,setDeny]= useState(false)
  const { setCurrentUser, companyId, userData } = useContext(UserContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [sortOrder, setSortOrder] = useState('desc'); // State for date sorting order
  const [nameSortOrder, setNameSortOrder] = useState('asc');
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);  // จะเก็บ item ที่เรากด
  const [replyMessage, setReplyMessage] = useState('');


  useEffect(() => {
    const unsubscribe = firestore.getAllChatHubHr(
        companyId,
        (data) => {
          setChathubList(data);
        },
        (error) => {
          console.log('Error fetching chatHub:', error);
        }
      );
  
      // คืน unsubscribe function เพื่อล้างค่าเมื่อ component unmount
      return () => {
        // ถ้ามี unsubscribe อยู่จะเรียกใช้งานเพื่อหยุดฟัง real-time
        if (unsubscribe) {
          unsubscribe();
        }
      };
   
  }, [companyId]);

  const onNext = () => {
    setStartIndex(startIndex + 10); // Increment the start index by 5
    setEndIndex(endIndex + 10); // Increment the end index by 5
  };

  const onPrevious = () => {
    setStartIndex(Math.max(startIndex - 10, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndIndex(Math.max(endIndex - 10, 10)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearch(event.target.value);
    setSearchQuery(query);
    // const filtered = allOT.filter(user => user.name.toLowerCase().includes(query));
    // sortData(sortData, setFilteredUsers, filtered);
  };

   // Convert date string (dd/MM/yyyy) to Date object for sorting
   const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  // Sort data by date
  const sortData = (order, setData, data) => {
    const sortedData = [...data].sort((a, b) => {
      // Primary sorting: 'not allowed' (state1 === false) entries first
      // if (a.state !== b.state) {
      //   return a.state1 ? 1 : -1;
      // }
      
      // Secondary sorting: sort by date within each status group
      const dateA = parseDate(a.date);
      const dateB = parseDate(b.date);
      if (dateA < dateB) return order === 'asc' ? -1 : 1;
      if (dateA > dateB) return order === 'asc' ? 1 : -1;

      // If dates are the same, sort by requestTime
      const timeA = a.requestTime ? new Date(`1970-01-01T${a.requestTime}`).getTime() : 0;
      const timeB = b.requestTime ? new Date(`1970-01-01T${b.requestTime}`).getTime() : 0;

      return order === 'asc' ? timeA - timeB : timeB - timeA;
    });
  
    setData(sortedData);
  };

  // Sort data by name
  const sortByName = (order, setData, data) => {
    const sortedData = [...data].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return order === 'asc' ? (nameA < nameB ? -1 : 1) : (nameA > nameB ? -1 : 1);
    });
    setData(sortedData);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Deselect all
      setSelectedChatIds([]);
    } else {
      // Select all *visible* rows
      const allVisibleIds = chathubList
        .slice(startIndex, endIndex)
        .map((item) => item.id);
      setSelectedChatIds(allVisibleIds);
    }
    setSelectAll(!selectAll);
  };

  // Handle selection of individual OT request
  const handleSelect = (id) => {
    setSelectedChatIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        // ถ้าเคยเลือกแล้ว -> เอาออก
        return prevSelected.filter((chatId) => chatId !== id);
      } else {
        // ถ้ายังไม่เคยเลือก -> เพิ่มเข้าไป
        return [...prevSelected, id];
      }
    });
  };

  const handleDeleteSelected = () => {
    if (selectedChatIds.length === 0) {
      alert("No OT requests selected for deletion.");
      return;
    }
    if (window.confirm("Are you sure you want to delete the selected Chat?")) {
      selectedChatIds.forEach((id) => {
        firestore.deleteChat(companyId, id, () => {
          console.log("Chat deleted successfully.");
        }, (error) => {
          console.error("Error deleting leave request:", error);
        });
      });
      // Reset selection after deletion
      setSelectedChatIds([]);
      setSelectAll(false);
    }
  };

//   const handleFilter = () => {
//     const filtered = allOT.filter(item => {
//         const matchesName = selectedName ? item.name === selectedName : true;
//         const matchesMonth = selectedMonth
//             ? item.date && new Date(item.date.split('/').reverse().join('-')).getMonth() + 1 === parseInt(selectedMonth)
//             : true;
//         return matchesName && matchesMonth;
//     });
//     sortData(sortData, setFilteredUsers, filtered);
//     setShowFilterModal(false);
//   };

  // ---------------- เปิด Modal เมื่อคลิกแถว ---------------
  const handleRowClick = (chatItem) => {
    setCurrentChat(chatItem);
    setReplyMessage(chatItem.reply ? chatItem.reply : '');
    setShowReplyModal(true);
  };

  // --------------- บันทึกข้อความตอบกลับ -------------------
  const handleSaveReply = () => {
    if (!currentChat) return;

    const replyObj = {
      reply: replyMessage,
      replyId: 'cms',
      reply_date: new Date(),  // หรือ Firestore Timestamp
      reply_status: true,      // ตัวอย่าง ถ้าหมายถึงตอบแล้ว = true
    };

    firestore.replyChatHub(
      companyId,
      currentChat.id, // หรือ currentChat.id (ถ้าเก็บ doc.id ใน field นี้)
      replyObj,
      () => {
        alert("บันทึกสำเร็จ");
        setShowReplyModal(false);
        setReplyMessage('');
      },
      (error) => {
        console.error("Error saving reply:", error);
        alert("เกิดข้อผิดพลาดในการบันทึก");
      }
    );
  };

  // กรองข้อมูลตาม search (ตัวอย่างง่าย ๆ)
  const filteredList = chathubList.filter(item =>
    (item.senderName || '').toLowerCase().includes(search.toLowerCase())
    || (item.title?.label || '').toLowerCase().includes(search.toLowerCase())
  );

  const totalItems = chathubList.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentPage = startIndex / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handlePageChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedPage - 1) * itemsPerPage;
    const newEndIndex = selectedPage * itemsPerPage;

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  };


  return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        <main className="main-content">
        
          <div className="main">
          <div className='header-page'>
          <header>
            <h1>ChatHub</h1>
            {/* Add user profile and logout here */}
          </header>
          </div>
            <div className="main-contain">
              {/* <div className="search-field">
                <input style={{width:'95%',margin:5,height:40,borderRadius:5,paddingInlineStart:10,fontSize:22,alignSelf:'center',justifyContent:'center'}}
                placeholder='search..' 
                value={search}
                onChange={handleSearch} />
              </div> */}
              

                
                <div style={{width:'95%',alignSelf:'center'}}>
                <div style={{ display: 'flex',flexDirection:'row', justifyContent: 'space-between', alignItems: 'center',width:'100%' }}>
                  <IoTrash
                    size={42}
                    className="trash-icon"
                    onClick={handleDeleteSelected}
                    style={{
                      marginTop: '20px',      
                      // marginBottom: '10px',    
                      cursor: 'pointer',       
                      color: 'red',            
                      border: '2px solid red', 
                      padding: '5px',          
                      borderRadius: '5px',  
                      height:40
                    }}
                  />
                  <div style={{display: 'flex',flexDirection:'row',marginTop: '20px',justifyContent:'flex-end' }}>
                    <Button variant="outlined" onClick={() => setShowFilterModal(true)} style={{
                      borderColor: '#000000', // Set border color
                      borderWidth: '2px',     // Set border width
                      color: '#000000',        // Set text color to match the border
                      height:40
                    }}>
                      <AiOutlineFilter size={24} /> Filter
                    </Button>
                  </div>
                </div>
                <div className="table-container">
                  <TableBootstrap striped bordered hover className='table' style={{marginTop:20,}}>
                    <thead>
                      <tr>
                        <th style={{ width:'10%'}}> 
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th scope="col" style={{ width:'10%'}}>ลำดับ</th>
                        <th style={{ cursor: 'pointer',width:'20%'}}>
                          วันที่ 
                        </th>
                        <th style={{ cursor: 'pointer',width:'20%' }}>
                          ชื่อ-สกุล 
                        </th>
                        <th style={{ cursor: 'pointer',width:'30%' }}>
                          หัวข้อ 
                        </th>
                        <th style={{ cursor: 'pointer',width:'10%',textAlign:'center' }}>
                          สถานะ 
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                    {chathubList.slice(startIndex, endIndex).map((item, index) => {
                    
                    
                    return(<tr key={item.id} style={{ cursor: 'pointer' }}  onClick={() => handleRowClick(item)}> 
                        <td onClick={(e) => e.stopPropagation()}>
                              <input
                              type="checkbox"
                              checked={selectedChatIds.includes(item.id)}
                              onChange={(ev) => {
                                  ev.stopPropagation();
                                  handleSelect(item.id); 
                              }}
                              />
                          </td>
                        <td scope="row">{startIndex + index + 1}</td>
                        
                        <td>
                          {item.date && item.date.toDate
                              ? item.date.toDate().toLocaleDateString('en-GB') 
                              // item.date.toDate().toLocaleDateString('th-TH', {
                              //     year: 'numeric',
                              //     month: 'long',
                              //     day: 'numeric',
                              // })
                              : ''
                          }
                        </td>
                        <td>{item.senderName}</td>
                        <td>{item.title?.label}</td>
                        <td style={{ textAlign: 'center',width:'10%' }}>
                          <span
                              style={{
                              display: 'inline-block',
                              width: 15,
                              height: 15,
                              borderRadius: '50%',
                              backgroundColor: item.reply_status ? '#06D001' : '#E72929',
                              }}
                          />
                        </td>
                      </tr>)
                    })}
                  </tbody>
                  </TableBootstrap>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center'}}>
                <button className='Previous-button' onClick={onPrevious}>Previous</button>
                <div style={{ margin: '0 10px' }}>
                  <select
                    value={currentPage}
                    onChange={handlePageChange}
                    style={{ fontSize: '16px', padding: '2px 8px' }}
                  >
                    {Array.from({ length: totalPages }, (_, idx) => (
                      <option key={idx + 1} value={idx + 1}>
                        {idx + 1}
                      </option>
                    ))}
                  </select>
                  <span style={{ marginLeft: '5px' }}>จาก {totalPages}</span>
                </div>
                <button className='Next-button' onClick={onNext}>Next</button>
                </div>
                </div>
                
              

            </div>
          </div>
        </main>
        {/* --------------------- Modal สำหรับตอบกลับ --------------------- */}
        <Modal
            show={showReplyModal}
            onHide={() => setShowReplyModal(false)}
            size="lg"
            centered
        >
            <Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Modal.Title style={{ fontSize: 24 }}>
                ตอบกลับ {currentChat?.senderName} สำหรับคำถาม {currentChat?.title?.label}
            </Modal.Title>
            {/* ปุ่ม X ปิด Modal */}
            <Button variant="light" onClick={() => setShowReplyModal(false)} style={{ fontWeight: 'bold' }}>
                X
            </Button>
            </Modal.Header>
            <Modal.Body>
            <div style={{ fontSize: 20 }}>
                <p>รายละเอียด: {currentChat?.detail}</p>
                <p>ข้อความตอบกลับ:</p>
                <Form.Control
                as="textarea"
                rows={3}
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
                style={{ fontSize: 20 }}
                />
            </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
            <Button variant="primary" style={{ backgroundColor: '#D3D3D3', color: 'black',fontSize:20 }} onClick={handleSaveReply}>
                บันทึก
            </Button>
            <Button
                variant="secondary" style={{ backgroundColor: '#343434',fontSize:20, }}
                onClick={() => setShowReplyModal(false)}
            >
                ยกเลิก
            </Button>
            </Modal.Footer>
        </Modal>
      </div>
      
    
  );
}

export default ChatHub;

  