//Privacy.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import logo from './icon/PAM_HR_logo.png';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';

const Privacy = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0); // State to track the current slide
  const [activeButton, setActiveButton] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const customScrollBarStyles = `
  .scroll-container {
    max-height: 70vh;     /* ปรับได้ตามต้องการ */
    overflow-y: auto;
    position: relative;

    /* Firefox จะใช้ scrollbar-width + scrollbar-color */
    scrollbar-width: none;     /* ตั้งค่าเริ่มต้นให้ “none” คือซ่อน */
    scrollbar-color: #888 #f1f1f1;
    -ms-overflow-style: none;  /* IE & old Edge ซ่อน scrollbar */
  }
  
  /* Default (ไม่มี show-scrollbar) => ซ่อน scrollbar (Chrome, Safari, Opera) */
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar {
    width: 0;
  }
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar-track {
    background: transparent;
  }
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar-thumb {
    background: transparent;
  }

  /* เมื่อมี .show-scrollbar => แสดง scrollbar */
  .scroll-container.show-scrollbar {
    scrollbar-width: thin;  /* Firefox: เปลี่ยนเป็นแบบบาง */
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar {
    width: 6px;             /* ความกว้าง scrollbar สำหรับ Chrome/Safari/Opera */
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  `;

  useEffect(() => {
    let hideTimer;
    const scrollContainer = document.querySelector('.scroll-container');
    if (!scrollContainer) return;

    const handleScroll = () => {
      // เพิ่มคลาส show-scrollbar เมื่อเริ่ม scroll
      scrollContainer.classList.add('show-scrollbar');

      // ถ้ามี timer เดิมอยู่ ให้ยกเลิกก่อน
      if (hideTimer) clearTimeout(hideTimer);

      // ตั้งเวลาไว้ 2 วินาที (2000 ms) หากไม่เลื่อนต่อ => ซ่อน scrollbar
      hideTimer = setTimeout(() => {
        scrollContainer.classList.remove('show-scrollbar');
      }, 2000);
    };

    // ผูก event scroll
    scrollContainer.addEventListener('scroll', handleScroll);

    // ลบ event เมื่อ component ถูก unmount
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      if (hideTimer) clearTimeout(hideTimer);
    };
  }, []);

  return (
    <div className="service-container">
       <nav className="navbar">
        <div className="navbar-left">
          <div className="navbarlogo-container"  style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}>
            <img src={logo} alt="PAM Logo" className="navbarlogo" />
          </div>
          {/* <button
            className={`nav-button plan-button ${activeButton === 'plan' ? '' : ''}`}
            onClick={scrollToPlan}
          >
            Plans
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
            onClick={toGetApp}
          >
            Applications
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
            onClick={toAdmin}
          >
            For Admin
          </button>*/}
        </div>
        <div className="button-container">
          <button
            className="nav-button"
            style={{ backgroundColor: '#D9D9D9' }}
            onClick={() => navigate('/login_company')}
          >
            Login
          </button>
          <button
            className="nav-button"
            style={{ backgroundColor: '#343434', color: '#FFF' }}
            onClick={()=> navigate('/register')}
          >
            Register
          </button> 
        </div>
        <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className="navbarlogo-container" style={{width:'100%',justifyContent:'space-between'}} >
            <img src={logo} alt="PAM Logo" className="navbarlogo" onClick={() => navigate('/')} />
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
        {isMenuOpen && (
          <div className="mobile-menu">
            <button
              className="nav-button"
              style={{ backgroundColor: '#D9D9D9',marginBottom:5 }}
              onClick={() => navigate('/login_company')}
            >
              Login
            </button>
            <button
              className="nav-button"
              style={{ backgroundColor: '#343434', color: '#FFF' }}
              onClick={()=> navigate('/register')}
            >
              Register
            </button>
          </div>
        )}
      </nav>
      <style>{customScrollBarStyles}</style>
      <div className="scroll-container" style={{width:'95%',backgroundColor:'#FFFFFF',  maxHeight: '100vh',overflowY: 'auto',marginTop:40,alignSelf:'center',borderRadius:20,justifySelf:'center'}}>
        <div style={{margin:20}}>
            <h1 style={{textAlign:'center'}}>นโยบายความเป็นส่วนตัว (Privacy Policy)</h1>
            <ol style={{ fontSize: 20, lineHeight: 1.6 }}>
            <li>
              <h2>บทนำ (Introduction)</h2>
              <p>
                ที่ บริษัท มิสซิเบิล เทคโนโลยี จำกัด ("เรา", "ของเรา", "บริษัท") ให้ความสำคัญกับความเป็นส่วนตัวของคุณและมุ่งมั่นที่จะปกป้องข้อมูลส่วนบุคคลของคุณ 
                นโยบายความเป็นส่วนตัวนี้อธิบายวิธีการที่เราเก็บ รวบรวม ใช้ และปกป้องข้อมูลส่วนบุคคลของคุณเมื่อคุณใช้บริการของเรา
              </p>
              <p style={{ marginTop: 0 }}>
                At Miscible Technology Co., Ltd. ("we", "our", "Company"), we value your privacy
                and are committed to protecting your personal data. This Privacy Policy explains how
                we collect, use, disclose, and safeguard your personal information when you use
                our services.
              </p>
            </li>
            <li>
              <h2>ข้อมูลที่เรารวบรวม (Information We Collect)</h2>
              <p>
                เราอาจรวบรวมข้อมูลส่วนบุคคลต่างๆ เพื่อให้บริการของเรามีประสิทธิภาพและตอบสนองความต้องการของคุณ รวมถึง:
                <ul style={{ listStyleType: 'disc', paddingLeft: 24 }}>
                  <li>ข้อมูลส่วนตัว: ชื่อ, ที่อยู่, อีเมล, หมายเลขโทรศัพท์</li>
                  <li>ข้อมูลการใช้งาน: ข้อมูลที่เกี่ยวข้องกับการใช้งานซอฟต์แวร์ของเรา เช่น ประวัติการเข้าสู่ระบบ, การดำเนินการภายในซอฟต์แวร์</li>
                </ul>
              </p>
              <p style={{ marginTop: 0 }}>
                We may collect various types of personal data to provide and improve our services
                and to meet your needs, including:
                <ul style={{ listStyleType: 'disc', paddingLeft: 24 }}>
                  <li>Personal Information: Name, address, email, phone number</li>
                  <li>Usage Information: Data related to your use of our software, such as login history, actions within the software</li>
                </ul>
              </p>
            </li>
            <li>
              <h2>วิธีการที่เราใช้ข้อมูลของคุณ (How We Use Your Information)</h2>
              <p>
                เราใช้ข้อมูลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้:
                <ul style={{ listStyleType: 'disc', paddingLeft: 24 }}>
                  <li>เพื่อให้บริการและสนับสนุนลูกค้า</li>
                  <li>เพื่อปรับปรุงและพัฒนาซอฟต์แวร์ของเรา</li>
                  <li>เพื่อการสื่อสารกับคุณเกี่ยวกับการอัปเดตหรือการเปลี่ยนแปลงบริการ</li>
                </ul>
              </p>
              <p style={{ marginTop: 0 }}>
                We use your information for the following purposes:
                <ul style={{ listStyleType: 'disc', paddingLeft: 24 }}>
                  <li>To provide and support our services</li>
                  <li>To improve and develop our software</li>
                  <li>To communicate with you about updates or changes to our services</li>
                </ul>
              </p>
            </li>
            <li>
              <h2>การเปิดเผยข้อมูล (Disclosure of Your Information)</h2>
              <p>
                เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณในกรณีต่อไปนี้:
                <ul style={{ listStyleType: 'disc', paddingLeft: 24 }}>
                  <li>กับบุคคลภายนอกที่ให้บริการแก่เรา: ผู้ให้บริการที่ช่วยเราดำเนินงาน เช่น ผู้ให้บริการโฮสติ้ง, ผู้ให้บริการชำระเงิน</li>
                  <li>ตามกฎหมาย: เมื่อถูกบังคับให้เปิดเผยข้อมูลตามกฎหมาย หรือคำสั่งศาล</li>
                </ul>
              </p>
              <p style={{ marginTop: 0 }}>
                We may disclose your personal information in the following circumstances:
                <ul style={{ listStyleType: 'disc', paddingLeft: 24 }}>
                  <li>To third-party service providers: Service providers that assist us in operating our business, such as hosting providers, payment processors</li>
                  <li>As required by law: When compelled to do so by law or court order</li>
                </ul>
              </p>
            </li>
            <li>
              <h2>การเก็บรักษาข้อมูล (Data Retention)</h2>
              <p>
                เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้เฉพาะเท่าที่จำเป็นสำหรับวัตถุประสงค์ที่กล่าวถึงในนโยบายนี้ 
                และจะลบหรือทำให้ข้อมูลของคุณไม่สามารถระบุตัวตนได้เมื่อไม่จำเป็นอีกต่อไป
              </p>
              <p style={{ marginTop: 0 }}>
                We retain your personal data only as long as necessary for the purposes outlined
                in this policy and will delete or anonymize your information when it is no longer
                needed.
              </p>
            </li>
            <li>
              <h2>สิทธิ์ของคุณตาม PDPA (Your Rights Under PDPA)</h2>
              <p>
                ภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 คุณมีสิทธิ์ในการ:
                <ul style={{ listStyleType: 'disc', paddingLeft: 24 }}>
                  <li>ขอเข้าถึงข้อมูลส่วนบุคคลของคุณ</li>
                  <li>ขอให้แก้ไขหรือปรับปรุงข้อมูลที่ไม่ถูกต้อง</li>
                  <li>ขอให้ลบข้อมูลส่วนบุคคลของคุณ</li>
                  <li>ขอให้จำกัดการใช้งานข้อมูลของคุณ</li>
                  <li>ขอย้ายข้อมูลของคุณไปยังผู้ให้บริการอื่น</li>
                </ul>
              </p>
              <p style={{ marginTop: 0 }}>
                Under the Personal Data Protection Act B.E. 2562 (PDPA), you have the right to:
                <ul style={{ listStyleType: 'disc', paddingLeft: 24 }}>
                  <li>Request access to your personal data</li>
                  <li>Request corrections or update of inaccurate information</li>
                  <li>Request deletion of your personal data</li>
                  <li>Request restriction of processing your data</li>
                  <li>Request transfer of your data to another service provider</li>
                </ul>
              </p>
            </li>
            <li>
              <h2>การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว (Changes to This Privacy Policy)</h2>
              <p>
                เราขอสงวนสิทธิ์ในการปรับเปลี่ยนนโยบายความเป็นส่วนตัวนี้ได้ทุกเมื่อ 
                การเปลี่ยนแปลงจะมีผลทันทีเมื่อโพสต์บนเว็บไซต์ของเรา
              </p>
              <p style={{ marginTop: 0 }}>
                We reserve the right to modify this Privacy Policy at any time.
                Changes will take effect immediately upon posting on our website.
              </p>
            </li>
            <li>
              <h2>ติดต่อเรา (Contact Us)</h2>
              <p>
                หากคุณมีคำถามหรือข้อสงสัยเกี่ยวกับนโยบายความเป็นส่วนตัวนี้ กรุณาติดต่อเราที่:
                <br />
                อีเมล: miscible@miscible.co.th <br />
                ที่อยู่: 221/24 ถนนปัญญาอินทรา แขวงบางชัน เขตคลองสามวา กรุงเทพฯ 10510 <br />
                หมายเลขโทรศัพท์: 02-548-0414-5
              </p>
              <p style={{ marginTop: 0 }}>
                If you have any questions or concerns about this Privacy Policy, please contact us at:
                <br />
                Email: miscible@miscible.co.th <br />
                Address: 221/24 Panya-Intra Rd., Bangchan, Klongsamwa, Bangkok 10510 <br />
                Phone Number: 02-548-0414-5
              </p>
            </li>
          </ol>
        </div>

      </div>
      
      <footer className="footer-container">
        <div className="footer-left">
          {/* ปุ่มไปหน้า Administrator */}
          <button
            className="footer-link"
            //onClick={() => navigate('/login_admin')}
          >
            Administrator
          </button>
          <span style={{ marginLeft: '8px' }}>
            © 2024 Miscible Technology Co.,Ltd. - All rights reserved.
          </span>
        </div>
        <div className="footer-right">
          {/* ปุ่มไป Terms of Service */}
          <button
            className="footer-link"
            onClick={() => navigate('/terms-of-service')}
          >
            Terms of Service
          </button>
          {/* ปุ่มไป Privacy */}
          <button
            className="footer-link"
            //onClick={() => navigate('/privacy-policy')}
          >
            Privacy
          </button>
        </div>
      </footer>
    </div>
  );
};

export default Privacy;
