import React,{useState,useEffect,useContext} from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './Home.css';
import Sidebar from './sidebar';
import { useNavigate } from 'react-router-dom';
import './addProfile.css'
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { IoTrashBin,IoPencil } from "react-icons/io5";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import auth from './Firebase/Auth';
import firestore from './Firebase/Firestore';
import Layout from './Layout';
import { UserContext } from './UserContext';
import ReactSelect from 'react-select';
import CryptoJS from 'crypto-js';
import { IoEyeOff, IoEye,IoPersonAddSharp } from "react-icons/io5";
import { hashPassword } from './hashPassword';
import LoadingBackdrop from './LoadingBackdrop';
import Swal from 'sweetalert2';
import 'animate.css';

function ManageAccount() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [level, setLevel] = useState('');
    const [allaccount, setAllAccount] = useState([]);
    const [selectID, setSelectID] = useState();
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(10);
    const { setCurrentUser, companyId, userData,setCompanyId,setUserData } = useContext(UserContext);
    const [isEditing, setIsEditing] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [notifyMsg, setNotifyMsg] = useState('');
    const [showNotify, setShowNotify] = useState(false);

    const levels = [
      { label: 'Administrator', value: 0 },
      { label: 'Super Admin', value: 1 },
      { label: 'Admin', value: 2 },
      { label: 'User', value: 3 }
    ];

    let selectableLevels = levels;
    // ถ้าไม่ใช่ Administrator(0) -> filter(0) ออก
    if (userData.level !== 0) {
      selectableLevels = selectableLevels.filter(l => l.value !== 0);
    }

    // ถ้าไม่ใช่ Super Admin(1) -> filter(1) ออก
    if (userData.level !== 1 && userData.level !== 0) {
      selectableLevels = selectableLevels.filter(l => l.value !== 1);
    }

    
    
    const getallAccountSuccess=(doc)=>{
      console.log(userData)
      let accounts = [];
      if (allaccount.length === 0) {
        
        doc.forEach((item) => {
          // item.level เก็บเป็นตัวเลขใน Firestore (0,1,2,3)
          const numericValue = item.level;
          const matchingLevel = levels.find(l => l.value === item.level);
          const levelLabel = matchingLevel ? matchingLevel.label : 'Unknown';
          accounts.push({id: item.id, email: item.email, name: item.name, position: item.position,numericLevel: numericValue, level: levelLabel});
        });
        setAllAccount(accounts);
      }
      console.log(doc)
    }
    const getallAccountUnsuccess=(error)=>{
      
    }

    useEffect(() => {
      firestore.getAllAccount(companyId,getallAccountSuccess,getallAccountUnsuccess)
    }, []);
  

    const handleClose = () => {
      setSelectID('');
      setEmail('');
      setPassword('');
      setName('');
      setPosition('');
      setLevel('');
      setShow(false);
      setIsEditing(false);
    }
    const handleShow = () => setShow(true);
    const handleDelClose = () =>{
      setSelectID('');
      setEmail('');
      setPassword('');
      setName('');
      setPosition('');
      setLevel('');
      setShowDel(false);
    } 
    const handleDelShow = (id,email, numericLevel) => {
      // 1) ถ้า target เป็น Administrator => ห้ามลบ
      if (numericLevel === 0) {
        Swal.fire({
          title: 'Warning',
          text: 'Cannot delete an Administrator account.',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });
        return;
      }

      // 2) ถ้า userData.level > numericLevel => ไม่มีสิทธิ์ลบ
      if (userData.level > numericLevel) {
        Swal.fire({
          title: 'Warning',
          text: 'You do not have permission to delete a user of higher role.',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        return;
      }

      setEmail(email)
      setSelectID(id)
      setShowDel(true);
    }

    const handleEditShow = (account) => {
      // ตรวจสอบสิทธิ์ว่า "userData.level <= account.numericLevel" หรือไม่
      // ถ้า userData.level > account.numericLevel => หมายถึงเรามีระดับต่ำกว่า -> ห้ามแก้
      if (userData.level > account.numericLevel) {
        Swal.fire({
          title: 'Warning',
          text: 'You do not have permission to edit a user of higher role.',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        return;
      }

      // อีกกรณี: ถ้าเราคือ Super Admin (1) แต่ target เป็น Administrator (0) -> ห้ามแก้
      if (userData.level === 1 && account.numericLevel === 0) {
        Swal.fire({
          title: 'Warning',
          text: 'A Super Admin cannot edit an Administrator.',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        return;
      }
      setIsEditing(true); // Set editing mode to true
      setSelectID(account.id);
      setEmail(account.email);
      setName(account.name);
      setPosition(account.position);
      setLevel(account.numericLevel);
      setShow(true);
    };

    // ฟังก์ชันเปิด Notification
    const openNotify = (msg) => {
      setNotifyMsg(msg);
      setShowNotify(true);
    };

    // ฟังก์ชันปิด Notification
    const closeNotify = () => {
      setNotifyMsg('');
      setShowNotify(false);
    };

    const createSuccess=async(user)=>{
      //const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
      const encoder = new TextEncoder();
      const data = encoder.encode(password);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashedPassword = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      let item={
        email:email,
        name:name,
        position:position,
        level:level,
        password: hashedPassword
      }
      //console.log(item)
      firestore.addAccount(companyId,user.uid,item)
      .then(() => {
        alert("Account created successfully!");
        handleClose();
      })
      .catch((error) => {
        console.error("Error adding account to Firestore", error);
      });
    }

    const createUnsuccess=(error)=>{

    }

    const onSave = async (e) => {
      e.preventDefault();
      
      setIsLoading(true);
      // ถ้า userData.level > level => แปลว่าเราพยายามสร้าง/แก้ไข user ที่ role สูงกว่าเรา
      // => ไม่มีสิทธิ์
      if (userData.level > level) {
        setIsLoading(false);
        Swal.fire({
          title: 'Warning',
          text: 'You do not have permission to create/update an account with higher role.',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        return;
      }

      // + กรณีเป็น Super Admin(1) -> ห้ามสร้าง Administrator(0)
      if (userData.level === 1 && level === 0) {
        setIsLoading(false);
        Swal.fire({
          title: 'Warning',
          text: 'Super Admin cannot create Administrator account.',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        return;
      }

      if (isEditing) {
        // Edit existing account
        // 1) หา account เป้าหมาย (ที่กำลังจะแก้ไข) จาก allaccount
        const targetAcc = allaccount.find(acc => acc.id === selectID);
        if (!targetAcc) {
          setIsLoading(false);
          Swal.fire({
            title: 'Error',
            text: 'Cannot find the target user to edit.',
            icon: 'error',
            customClass: {
              popup: 'my-swal-size'
            },
          });
          return;
        }

        // 2) เช็คว่าผู้ใช้ปัจจุบัน (userData.level) มีสิทธิ์แก้ไข targetAcc.numericLevel หรือไม่

        // (ก) ถ้า target เป็น Administrator (0) แต่ผู้แก้คือ Super Admin(1) หรือต่ำกว่า => ห้ามแก้
        if (targetAcc.numericLevel === 0 && userData.level > 0) {
          setIsLoading(false);
          Swal.fire({
            title: 'Warning',
            text: 'You cannot edit an Administrator account.',
            icon: 'warning',
            customClass: {
              popup: 'my-swal-size'
            },
          });
          return;
        }

        // (ข) ถ้า userData.level > targetAcc.numericLevel => แปลว่าเรามีสิทธิ์ต่ำกว่า => ห้ามแก้
        if (userData.level > targetAcc.numericLevel) {
          setIsLoading(false);
          Swal.fire({
            title: 'Warning',
            text: 'You do not have permission to edit a user of higher role.',
            icon: 'warning',
            customClass: {
              popup: 'my-swal-size'
            },
          });
          return;
        }
        let updatedData = {
          // email,
          name,
          position,
          level
        };
        if (password.trim() !== '') {
          const hashedPassword = await hashPassword(password); // Hash password if provided
          updatedData.password = hashedPassword;
          auth.changeUserPassword(
            selectID,
            password,
            () => {
              console.log("Password in Firebase Auth updated successfully");
            },
            (error) => {
              console.error("Error updating password in Firebase Auth:", error);
              setIsLoading(false);
              Swal.fire({
                title: 'Error',
                text: 'Error updating password in Firebase Auth: ' + error.message,
                icon: 'error',
                customClass: {
                  popup: 'my-swal-size'
                },
              });
              return; // ถ้าต้องการหยุด process หากไม่สามารถอัปเดต password ใน Auth ได้
            }
          );
        }
        setShow(false)
        firestore.updateAccount(companyId, selectID, updatedData, () => {
          setIsLoading(false); 
          Swal.fire({
            title: 'Success',
            text: 'Account updated successfully!',
            icon: 'success',
            customClass: {
              popup: 'my-swal-size'
            },
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          }).then(() => {
            handleClose();
          });
          // Reload accounts if necessary
        }, (error) => {
          console.error("Error updating account:", error);
          setIsLoading(false); 
          Swal.fire({
            title: 'Error',
            text: 'Error updating account: ' + error.message,
            icon: 'error',
            customClass: {
              popup: 'my-swal-size'
            },
          });
        });
      } else {
        // Create a new account
        auth.createUserAdminMode(
          email, 
          password, 
          async (data) => {
            // data.uid = uid ที่ถูกสร้างใน Auth
            console.log("success callback data => ", data);
            const uid = data.uid;
            const hashedPassword = await hashPassword(password);
            // บันทึก Firestore เหมือนเดิม
            setShow(false)
            firestore.addAccount(companyId, uid, {
              email,
              name,
              level,
              position,
              password:hashedPassword
            })
            .then(() => {
              setIsLoading(false);
              Swal.fire({
                title: 'Success',
                text: 'Account created successfully!',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  popup: 'my-swal-size'
                },
              }).then(() => {
                setEmail('');
                setPassword('');
                handleClose();
              });
            })
            .catch((error) => {
              console.error("Error adding account to Firestore", error);
              setIsLoading(false); 
              Swal.fire({
                title: 'Error',
                text: 'Failed to add account. ' + error.message,
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                  popup: 'my-swal-size'
                },
              });
            });
        }, (error) => {
          console.error("Error creating account:", error);
          setIsLoading(false); 
          Swal.fire({
            title: 'Error',
            text: error.code === 'auth/email-already-in-use'
                   ? 'This email is already in use. Please choose another.'
                   : 'Failed to create user: ' + error.message,
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: {
              popup: 'my-swal-size'
            },
          });
        });
      }
    };

    const delSuc =()=>{

    }

    const delUnsuc =(e)=>{
      console.log(e)
    }
    
    const Delete =()=>{
      setIsLoading(true);
      // 0) ดึง user (target) จาก allaccount โดย match selectID
      const targetAcc = allaccount.find(acc => acc.id === selectID);
      if (!targetAcc) {
        setIsLoading(false);
        return;
      }

      // 1) ถ้าเป็น Administrator => ห้ามลบ
      if (targetAcc.numericLevel === 0) {
        setIsLoading(false);
        Swal.fire({
          title: 'Warning',
          text: 'Cannot delete an Administrator account.',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        handleDelClose();
        return;
      }

      // 2) ถ้าเรามี level > target => ไม่มีสิทธิ์ (กันเผื่อว่า user ไปแก้โค้ด front-end)
      if (userData.level > targetAcc.numericLevel) {
        setIsLoading(false);
        Swal.fire({
          title: 'Warning',
          text: 'You do not have permission to delete a user of higher role.',
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        handleDelClose();
        return;
      }

      // 3) ถ้าเป็น Super Admin
      if (targetAcc.numericLevel === 1) {
        // 3.1) ถ้าเราไม่ใช่ Super Admin หรือ Administrator => ห้ามลบ
        if (userData.level > 1) {
          setIsLoading(false);
          Swal.fire({
            title: 'Warning',
            text: 'You do not have permission to delete a Super Admin.',
            icon: 'warning',
            customClass: {
              popup: 'my-swal-size'
            },
          });
          handleDelClose();
          return;
        }
        // 3.2) เช็คว่าเหลือ Super Admin กี่คน
        const superAdminAccounts = allaccount.filter(acc => acc.numericLevel === 1);
        if (superAdminAccounts.length === 1 && superAdminAccounts[0].id === selectID) {
          setIsLoading(false);
          Swal.fire({
            title: 'Warning',
            text: 'Cannot delete the only Super Admin user!',
            icon: 'warning',
            customClass: {
              popup: 'my-swal-size'
            },
          });
          handleDelClose();
          return;
        }
      }

      const isDeletingMyself = (targetAcc.id === userData?.id);

      // ถ้ากำลังลบตัวเอง => บังคับ Logout ก่อน
      if (isDeletingMyself) {
        auth.signOut(
          // success callback
          () => {
            setCurrentUser(null);  // Clear user from context
            setCompanyId(null);    // Clear companyId from context
            setUserData(null);     // Clear userData from context
            localStorage.removeItem('companyId');  // Clear companyId from localStorage
            localStorage.removeItem('userData'); // Clear companyId from localStorage
            localStorage.removeItem('sidebarOpen');
            localStorage.removeItem('sidebarScroll');
            navigate('/login_company');  // Redirect to login company page
            console.log("User has been logged out before deleting themselves.");
            
            // จากนั้นค่อยสั่งลบใน Auth (ผ่าน Cloud Function หรือ Admin SDK)
            auth.deleteUser(
              selectID,
              () => {
                firestore.deleteAccount(companyId, selectID)
                  .then(() => {
                    setIsLoading(false);
                    Swal.fire({
                      title: 'Deleted!',
                      text: `User with email ${email} deleted successfully!`,
                      icon: 'success',
                      customClass: {
                        popup: 'my-swal-size'
                      },
                      showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                      },
                      hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                      }
                    });
                    handleDelClose();
                  })
                  .catch((err) => {
                    console.error("Error deleting user from Firestore:", err);
                    setIsLoading(false);
                    Swal.fire({
                      title: 'Error',
                      text: 'Failed to delete user: ' + err.message,
                      icon: 'error',
                      customClass: {
                        popup: 'my-swal-size'
                      },
                    });
                  });
              },
              (err) => {
                console.error("Error deleting user from Firebase Authentication:", err);
                setIsLoading(false); 
                Swal.fire({
                  title: 'Error',
                  text: 'Failed to delete user from Firebase Auth: ' + err.message,
                  icon: 'error',
                  customClass: {
                    popup: 'my-swal-size'
                  },
                });
              }
            );
          },
          // error callback signOut
          (err) => {
            console.error("Error logging out:", err);
            setIsLoading(false);
            Swal.fire({
              title: 'Error',
              text: 'Failed to sign out before deleting: ' + err.message,
              icon: 'error',
              customClass: {
                popup: 'my-swal-size'
              },
            });
          }
        );
      } else {
        // *** ลบคนอื่น (ไม่ใช่ตัวเอง) ***
        auth.deleteUser(
          selectID,
          () => {
            firestore.deleteAccount(companyId, selectID)
              .then(() => {
                setIsLoading(false);
                Swal.fire({
                  title: 'Deleted!',
                  text: `User with email ${email} deleted successfully!`,
                  icon: 'success',
                  customClass: {
                    popup: 'my-swal-size'
                  },
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                });
                handleDelClose();
              })
              .catch((error) => {
                console.error('Error deleting user from Firestore:', error);
                setIsLoading(false);
                Swal.fire({
                  title: 'Error',
                  text: 'Failed to delete user from Firestore: ' + error.message,
                  icon: 'error',
                  customClass: {
                    popup: 'my-swal-size'
                  },
                });
              });
          },
          (error) => {
            console.error('Error deleting user from Firebase Authentication:', error);
            setIsLoading(false);
            Swal.fire({
              title: 'Error',
              text: 'Failed to delete user from Firebase Auth: ' + error.message,
              icon: 'error',
              customClass: {
                popup: 'my-swal-size'
              },
            });
          }
        );
      }
    };

    const onNext = () => {
      setStartIndex(startIndex + 10); // Increment the start index by 5
      setEndIndex(endIndex + 10); // Increment the end index by 5
    };
  
    const onPrevious = () => {
      setStartIndex(Math.max(startIndex - 10, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
      setEndIndex(Math.max(endIndex - 10, 10)); // Decrement the end index by 5, ensuring it doesn't go below 5
    };

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        <main className="main-content">
          
          <div className="main">
            <div className='header-page'>
            <header>
              <h1>การจัดการผู้ใช้งาน</h1>
              {/* Add user profile and logout here */}
            </header>
            </div>
            <div className="main-contain">
            {/*<div class="search-field">
                <p style={{marginTop:10}}>ค้นหาพนักงาน</p>
                <input style={{width:'40%',margin:5,height:30,borderRadius:20,paddingInlineStart:10,fontSize:18}} />
                <button class="search-button"></button>
              </div>*/}
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:10,width:'95%',alignSelf:'center'}}>
                  <button className='addAccount-button' onClick={handleShow} title='เพิ่มผู้ใช้'><IoPersonAddSharp size={24} /></button>
                </div>

                <div style={{width:'95%',alignSelf:'center'}}>
                
                <div className="table-container">
                <TableBootstrap striped bordered hover className='table' style={{marginTop:20}} >
                  <thead>
                    <tr>
                      <th scope="col">Email</th>
                      <th scope="col">User</th>
                      <th scope="col">Position</th>
                      <th scope="col">Level</th>
                      <th scope='col'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allaccount.slice(startIndex, endIndex).map((item, index) => (
                      <tr key={item.id}>
                        {/* <th scope="row">{startIndex + index + 1}</th> */}
                        <td>{item.email}</td>
                        <td>{item.name}</td>
                        <td>
                          {item.position}
                        </td>
                        <td>{item.level}</td>
                        <td style={{textAlign: 'center'}}>
                          <IoPencil size={24} onClick={() => handleEditShow(item)} style={{ marginRight: 10,cursor:'pointer' }} />
                          <IoTrashBin size={24} style={{cursor:'pointer' }} onClick={()=>handleDelShow(item.id,item.email)} />
                        </td>
                      </tr>
                    ))}
                  </tbody> 
                </TableBootstrap>
                </div>
                <div>
                <button className='Previous-button' onClick={onPrevious}>Previous</button>
                <button className='Next-button' onClick={onNext}>Next</button>
                </div>
                </div>
                
              
            </div>
          </div>
        </main>
        <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'แก้ไขบัญชีผู้ใช้' : 'เพิ่มบัญชีผู้ใช้'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{fontSize:20}}>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                style={{fontSize:20}}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={isEditing}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label style={{fontSize:20}}>Password</Form.Label>
              <div style={{ position: 'relative' }}>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}  // Change input type based on visibility state
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  style={{ paddingRight: '2.5rem',fontSize:20 }}  // Adjust padding to prevent text overlaying the icon
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  {showPassword ? <IoEyeOff size={24} /> : <IoEye size={24} />}
                </button>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{fontSize:20}}>Name</Form.Label>
              <Form.Control
                type="name"
                autoFocus
                value={name}
                style={{fontSize:20}}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{fontSize:20}}>Position</Form.Label>
              <Form.Control
                type="name"
                autoFocus
                style={{fontSize:20}}
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLevel">
              <Form.Label style={{fontSize:20}}>Level</Form.Label>
              <ReactSelect
                value={levels.find(option => option.value === level)}
                onChange={(selectedOption) => setLevel(selectedOption.value)}
                options={selectableLevels}
                styles={{
                  fontSize:20,
                  control: (base) => ({
                    ...base,
                    borderRadius: '4px', // Border radius for the select field
                    border: '1px solid #ced4da',
                    padding: '4px',  // Adjust padding for the select field
                  }),
                  option: (base) => ({
                    ...base,
                    borderRadius: '4px', // Border radius for options when they are rendered
                    padding: '10px', // Add padding to options
                  }),
                  menu: (base) => ({
                    ...base,
                    borderRadius: '4px', // Border radius for the dropdown menu
                  }),
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" style={{backgroundColor:'#D3D3D3',color:'black'}} onClick={onSave}>
            {isEditing ? 'Save Changes' : 'Create Account'}
          </Button>
          <Button variant="secondary" style={{backgroundColor:'#343434',width:'20%'}} onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>

      <Modal show={showDel} onHide={handleDelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>ลบบัญชีผู้ใช้: {email}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>ยืนยันจะลบบัญชีผู้ใช้หรือไม่</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" style={{backgroundColor:'#D3D3D3',color:'black'}} onClick={Delete}>
            OK
          </Button>
          <Button variant="secondary" style={{backgroundColor:'#343434'}} onClick={handleDelClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Notification Modal */}
      <Modal show={showNotify} onHide={closeNotify} centered>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: 18 }}>{notifyMsg}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" style={{backgroundColor:'#d209be'}} onClick={closeNotify}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Loading Overlay */}
      {isLoading && (
        <LoadingBackdrop />
      )}
      </div>
      
    
  );
}

export default ManageAccount;

  