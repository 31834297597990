// ApprovePlan.js
import React,{ useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import logo from './icon/PAM_HR_logo.png';
import { AdminProvider, AdminContext } from './AdminContext';
import adminAuth from './Firebase/AdminAuth';
import adminFirestore from './Firebase/AdminFirestore';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { Modal, Button } from 'react-bootstrap';
import { hashPassword } from './hashPassword';
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';

const Navbar = () => {
  const navigate = useNavigate();
  const { currentAdmin, setCurrentAdmin ,loadingAdmin, setLoadingAdmin , } = useContext(AdminContext);

  const logOutSuccess = () => {
    setCurrentAdmin(null);
    setLoadingAdmin(false)
    localStorage.removeItem('adminData');
    navigate('/login_admin');  // Redirect to login company page
  };

  return (
    <div className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="PAM Logo" className="navbarlogo" />
      </div>
      <div className="button-container">
      <button className="nav-button" onClick={() => navigate('/register')}>Sign Up</button>
      <button className="nav-button" onClick={() => adminAuth.signout(logOutSuccess)}>Sign Out</button>
      </div>
    </div>
  );
};

const ApprovePlan = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [requestDemos, setRequestDemos] = useState([]);
  // state สำหรับควบคุมการเปิด/ปิด Modal
  const [showModal, setShowModal] = useState(false);
  // state สำหรับเก็บ item ที่ถูกเลือก (เพื่อแสดงข้อมูลใน modal)
  const [selectedItem, setSelectedItem] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);

  const EMAILJS_SERVICE_ID = 'service_5egw6b8';
  const EMAILJS_TEMPLATE_ID = 'template_wrkesla';
  const EMAILJS_USER_ID = 'sT8ZIWDhLD1_zjzvb';
  
  const [isLoading, setIsLoading] = useState(false);

  const { currentAdmin, setCurrentAdmin ,loadingAdmin, setLoadingAdmin , } = useContext(AdminContext);

  const logOutSuccess = () => {
    setCurrentAdmin(null);
    setLoadingAdmin(false)
    localStorage.removeItem('adminData');
    navigate('/login_admin');  // Redirect to login company page
  };

  const customScrollBarStyles = `
  .scroll-container {
    max-height: 70vh;     /* ปรับได้ตามต้องการ */
    overflow-y: auto;
    position: relative;

    /* Firefox จะใช้ scrollbar-width + scrollbar-color */
    scrollbar-width: none;     /* ตั้งค่าเริ่มต้นให้ “none” คือซ่อน */
    scrollbar-color: #888 #f1f1f1;
    -ms-overflow-style: none;  /* IE & old Edge ซ่อน scrollbar */
  }
  
  /* Default (ไม่มี show-scrollbar) => ซ่อน scrollbar (Chrome, Safari, Opera) */
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar {
    width: 0;
  }
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar-track {
    background: transparent;
  }
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar-thumb {
    background: transparent;
  }

  /* เมื่อมี .show-scrollbar => แสดง scrollbar */
  .scroll-container.show-scrollbar {
    scrollbar-width: thin;  /* Firefox: เปลี่ยนเป็นแบบบาง */
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar {
    width: 6px;             /* ความกว้าง scrollbar สำหรับ Chrome/Safari/Opera */
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  `;

  // ฟังก์ชันเรียกใช้การดึงข้อมูลแบบ Real-time
  const fetchRequestDemos = () => {
    // adminFirestore.getAllRequestDemo จะ return unsubscribe กลับมา
    const unsubscribe = adminFirestore.getAllRequestDemo(
      (data) => {
        console.log(data)
        // เรียงข้อมูลตามเงื่อนไขเดิม (status=false ก่อน, จากใหม่->เก่า)
        const falseStatus = data.filter(d => d.status === false);
        const trueStatus  = data.filter(d => d.status === true);

        // กรณี createdAt เป็น Firestore Timestamp
        const sortDesc = (a, b) => {
          const timeA = a.createdAt ? a.createdAt.toMillis() : 0;
          const timeB = b.createdAt ? b.createdAt.toMillis() : 0;
          return timeB - timeA; // ล่าสุดมาก่อน
        };
        falseStatus.sort(sortDesc);
        trueStatus.sort(sortDesc);

        const sortedData = [...falseStatus, ...trueStatus];
        setRequestDemos(sortedData);
      },
      (error) => {
        console.error("Failed to get demo requests:", error);
      }
    );
    return unsubscribe; 
  };

  useEffect(() => {
    console.log(currentAdmin)
    // เรียก fetchRequestDemos แล้วเก็บตัว unsubscribe ที่คืนมา
    const unsubscribe = fetchRequestDemos();

    // เมื่อ component unmount => unsubscribe
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    let hideTimer;
    const scrollContainer = document.querySelector('.scroll-container');
    if (!scrollContainer) return;

    const handleScroll = () => {
      // เพิ่มคลาส show-scrollbar เมื่อเริ่ม scroll
      scrollContainer.classList.add('show-scrollbar');

      // ถ้ามี timer เดิมอยู่ ให้ยกเลิกก่อน
      if (hideTimer) clearTimeout(hideTimer);

      // ตั้งเวลาไว้ 2 วินาที (2000 ms) หากไม่เลื่อนต่อ => ซ่อน scrollbar
      hideTimer = setTimeout(() => {
        scrollContainer.classList.remove('show-scrollbar');
      }, 2000);
    };

    // ผูก event scroll
    scrollContainer.addEventListener('scroll', handleScroll);

    // ลบ event เมื่อ component ถูก unmount
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      if (hideTimer) clearTimeout(hideTimer);
    };
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return "-";
    const date = timestamp.toDate(); // แปลง Firestore Timestamp -> Date object

    const day   = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year  = date.getFullYear();    // ค.ศ. (ถ้าต้องการ พ.ศ. => +543)
    const hours = ("0" + date.getHours()).slice(-2);
    const mins  = ("0" + date.getMinutes()).slice(-2);
    const secs  = ("0" + date.getSeconds()).slice(-2);

    return `${day}/${month}/${year} ${hours}:${mins}:${secs}`;
  };

  // เมื่อคลิกชื่อบริษัท => เปิด modal พร้อมตั้งค่าสินค้าที่เลือก
  const handleOpenModal = (item) => {
    setSelectedItem(item); // เก็บ item ไว้ใน state
    setShowModal(true);    // เปิด modal
  };

  // ปิด Modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  // const sendApproveEmailToUser = async (toEmail, companyId, password, item) => {
  const sendApproveEmailToUser = async (toEmail, itemId, pin, item) => {
    try {
      const fromEmail = "pamhr-cms@example.com"; // ปรับเป็นอีเมลผู้ส่งจริง หรือผูกจาก Template ก็ได้
      // const subject = "อนุมัติคำขอใช้งาน Demo";
      // const message = `
      //   แจ้งว่าได้รับการอนุมัติแล้ว
      //   companyId: ${companyId}
      //   username: ${item.email}
      //   password: ${password}
      //   go to web: https://www.pam-hrmsoftware.com/login_company
        
      //   from PAM-HR CMS
      // `;
      const subject = "Your demo request has been approved";
      // ข้อความที่จะส่ง
      // เปลี่ยนได้ตามต้องการ
      const message = `
        Dear ${item.name},

        Your request for the demo plan has been approved.

        Please use the following 6-digit verification code to complete your registration:
        ${pin}

        Click the link below to sign up:
        https://www.pam-hrmsoftware.com/register_demo/${itemId}

        Best regards,
        Miscible Technology
      `;
  
      // templateParams ต้องสอดคล้องกับตัวแปรใน Template ของคุณใน EmailJS
      const templateParams = {
        from_email: fromEmail,
        to_email: toEmail,
        subject: subject,
        message: message
      };
  
      // เรียกใช้ emailjs
      const result = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams,
        EMAILJS_USER_ID
      );
  
      console.log("Email sent to user (approve) =>", result.text);
    } catch (error) {
      console.error("Error sending approval email:", error);
      throw error;
    }
  };

  const generateRandomPassword = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const generateRandomPin = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const handleApproveCancel = async (item) => {
    // แค่ pop-up ถาม
    const confirmMsg = `คุณต้องการ${item.status ? 'ยกเลิก' : 'อนุมัติ'} Request Demo นี้หรือไม่?`;
    const isConfirm = window.confirm(confirmMsg);
    setIsLoading(true)
    if (!isConfirm) {
      setIsLoading(false)
      return;
    }

    // ถ้า status ปัจจุบันเป็น false => "ยังไม่ได้อนุมัติ" => เราจะทำขั้นตอนอนุมัติ
    // ถ้าเป็น true => คุณอาจจะใส่ logic ยกเลิกได้ (ตามต้องการ)
    if (!item.status) {
      try {
        // 1) สุ่ม password 6 หลัก
        // const password = generateRandomPassword();
         // 1) สุ่ม PIN 6 หลัก
         const pin = generateRandomPin();

        // 2) hash password
        //const passwordHash = await hashPassword(password);
        // 2) (ถ้าต้องการ hash pin ก็ได้)
        const pinHash = await hashPassword(pin);
        
        // 3) update ค่าใน requestDemo
        // สร้าง object update
        const now = new Date();
        const updateData = {
          status: true,
          approve_date: new Date(),
          pin: pinHash // เก็บ plain-text pin หรือเก็บ hash ถ้าต้องการ
        };
        await adminFirestore.updateRequestDemo(item.id, updateData);

        // 4) สร้าง account ใน Firebase Auth
        // const user = await adminAuth.createAccount(
        //   item.email,
        //   password,
        //   (user) => {
        //     console.log("Create account success =>", user.uid);
        //   },
        //   (error) => {
        //     console.error("Create account error =>", error);
        //   }
        // );

        // ได้ user.uid
        // const userId = user.uid;

        // 5) สร้างเอกสารใน companies/{companyId}
        // ตัวอย่าง field
        // const companyObj = {
        //   aboutUs: " ",
        //   help_1: " ",
        //   help_2: " ",
        //   help_3: " ",
        //   logo: "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/PAM_HR_logo.png?alt=media&token=00d6bf28-0af6-49f9-bdd4-2a999fd19382",
        //   plan: "demo"
        // };
        // await adminFirestore.createCompanyDoc(item.companyId, companyObj);

        // 6) สร้างเอกสารใน companies/{companyId}/account_cms/{userId}
        // ตัวอย่าง field
        // const accountObj = {
        //   email: item.email,
        //   level: 1,
        //   name: item.name,
        //   password: passwordHash,
        //   position: item.position || ""
        // };
        // await adminFirestore.addAccount(item.companyId, userId, accountObj);

        // await sendApproveEmailToUser(item.email, item.companyId, password, item);
        await sendApproveEmailToUser(item.email, item.id, pin, item);
        setIsLoading(false)
        //alert("อนุมัติสำเร็จ (Pin และลิงก์ส่งไปยังอีเมลแล้ว)");
        Swal.fire({
          // title: 'Warning',
          // text: 'Cannot delete an Administrator account.',
          title: 'อนุมัติเรียบร้อย',
          text: `อนุมัติสำเร็จ (Pin และลิงก์ส่งไปยังอีเมลแล้ว)`,
          icon: 'success',
          customClass: {
            popup: 'my-swal-size'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });
      } catch (error) {
        console.error(error);
        setIsLoading(false)
        //alert("ไม่สามารถอนุมัติได้: " + error.message);
        Swal.fire({
          // title: 'Warning',
          // text: 'Cannot delete an Administrator account.',
          title: 'ไม่อนุมัติ',
          text: `ไม่สามารถอนุมัติได้: ${error.message}`,
          icon: 'error',
          customClass: {
            popup: 'my-swal-size'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });
      }
    } else {
      // กรณี status == true => ให้ทำการยกเลิกหรือไม่ทำอะไรตามต้องการ
      console.log("TODO: ยกเลิก (ถ้าต้องการ)");
    }
  };

  // เลือกแถวเดียว
  const handleSelectRow = (id) => {
    // ถ้า id นั้นถูกเลือกอยู่ => เอาออก
    // ถ้า id นั้นยังไม่ถูกเลือก => เพิ่มเข้าไป
    setSelectedRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((rowId) => rowId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  // เลือกทั้งหมด / ยกเลิกทั้งหมด
  const handleSelectAll = () => {
    if (selectedRows.length === requestDemos.length) {
      // ถ้าถูกเลือกครบทุกแถว => ยกเลิกหมด
      setSelectedRows([]);
    } else {
      // เลือกทุกแถว
      const allIds = requestDemos.map((item) => item.id);
      setSelectedRows(allIds);
    }
  };

  // ตรวจสอบว่าเลือกครบทุกแถวหรือไม่
  const isAllSelected = requestDemos.length > 0 && selectedRows.length === requestDemos.length;

  return (
    <div className="service-container">
      <nav className="navbar">
        <div className="navbar-left">
          <div className="navbarlogo-container">
            <img src={logo} alt="PAM Logo" className="navbarlogo" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
          </div>
          <button
            className={`nav-button plan-button ${activeButton === 'plan' ? '' : ''}`}
           
          >
            Upgrade Request
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
           
          >
            Delete Request
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
           
          >
            List_3
          </button>
        </div>
        <div className="button-container">
          {/* <button
            className="nav-button"
            style={{ backgroundColor: '#D9D9D9' }}
            onClick={() => navigate('/register')}>Sign Up</button> */}
          <button
            className="nav-button"
            style={{ backgroundColor: '#343434', color: '#FFF' }}
            onClick={() => adminAuth.signout(logOutSuccess)}>Sign Out</button>
        </div>
        <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className="navbarlogo-container" style={{width:'100%',justifyContent:'space-between'}}>
            <img src={logo} alt="PAM Logo" className="navbarlogo" onClick={() => navigate('/')} />
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
        {isMenuOpen && (
          <div className="mobile-menu">
            <button
              className="nav-button"
            >
               List_1
            </button>
            <button
              className="nav-button"
            >
               List_2
            </button>
            <button
              className="nav-button"
            >
               List_3
            </button>
            <button
              className="nav-button"
              style={{ backgroundColor: '#343434', color: '#FFF' }}
              onClick={() => adminAuth.signout(logOutSuccess)}
            >
              Sign Out
            </button>
          </div>
        )}
      </nav>
      <div style={{display:'flex',alignItems:'center',width:'100%',height:'100%',flexDirection:'column'}}>
        <style>{customScrollBarStyles}</style>
        <p style={{fontSize:36,textAlign:'center',marginTop:20}}>Request Demo</p>
        <div className="scroll-container" style={{width:'96%',backgroundColor:'#FFFFFF',overflowY: 'auto',alignSelf:'center',borderRadius:20}}>
          <div style={{margin:10}}>
          <div className="table-container">
          <TableBootstrap striped bordered hover className='table'>
              <thead>
                <tr style={{fontSize:20}}>
                  <th style={{ width: "50px", textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th style={{ width: "180px" }}>วันที่ส่ง</th>
                  <th style={{ width: "200px" }}>ชื่อบริษัท</th>
                  <th style={{ width: "130px" }}>Email</th>
                  <th style={{ width: "130px" }}>ชื่อ</th>
                  <th style={{ width: "130px" }}>วันที่อนุมัติ</th>
                  <th style={{ width: "80px" }}>Plan</th>
                  <th style={{ width: "120px" }}>อนุมัติ / ยกเลิก</th>
                  <th style={{ width: "80px",textAlign:'center' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {requestDemos.map((item, index) =>{
                  const createdAtDate = item.createdAt ? item.createdAt.toDate() : null;
                  const approveDate = item.approve_date ? item.approve_date.toDate() : null;
                  // ฟังก์ชัน helper สำหรับ format วัน/เดือน/ปี (DD/MM/YYYY)
                  const formatDate = (date) => {
                    const day   = ("0" + date.getDate()).slice(-2);        // เติม 0 นำหน้าให้เต็ม 2 หลัก
                    const month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() เริ่มจาก 0 จึงต้อง +1
                    const year  = date.getFullYear();                      // เป็นปี ค.ศ. (CE) อยู่แล้ว
                    const hours  = ("0" + date.getHours()).slice(-2);
                    const mins   = ("0" + date.getMinutes()).slice(-2);
                    const secs   = ("0" + date.getSeconds()).slice(-2);
                    return `${day}/${month}/${year} ${hours}:${mins}:${secs}`;
                  };

                
                  return  (
                  <tr key={item.id} style={{fontSize:20}}>
                    {/* ลำดับ */}
                    {/* <td>{index + 1}</td> */}
                    <td style={{ textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleSelectRow(item.id)}
                      />
                    </td>

                    {/* วันที่ส่ง (format ตามต้องการ) */}
                    {/* <td>{item.createdAt ? item.createdAt.toDate().toLocaleString() : ""}</td> */}
                    <td>{createdAtDate ? formatDate(createdAtDate) : "-"}</td>
                    
                    {/* ชื่อบริษัท */}
                    <td>
                          <span
                            style={{
                              color: 'blue',
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleOpenModal(item)}
                          >
                            {item.companyName}
                          </span>
                        </td>
                    
                    {/* companyId */}
                    <td>{item.email}</td>
                    
                    {/* ชื่อ */}
                    <td>{item.name}</td>
                    
                    {/* Pin */}
                    <td>{approveDate ? formatDate(approveDate) : "-"}</td>
                    
                    {/* plan */}
                    <td>{item.plan}</td>
                    
                    {/* ปุ่มอนุมัติ/ยกเลิก (ยังไม่ทำฟังก์ชันจริง แค่ pop-up) */}
                    <td style={{ textAlign: 'center' }}>
                      <button
                        className="Edit-button"
                        onClick={() => handleApproveCancel(item)}
                        style={{
                          // เปลี่ยนสีปุ่มถ้า status===true => สีแดง
                          // ถ้า status===false => สีปกติ (เช่น สีเทาเข้ม #383838)
                          backgroundColor: item.status ? "#a50000" : "#383838",
                          color: '#FFF'
                        }}
                      >
                        {item.status ? 'ยกเลิก' : 'อนุมัติ'}
                      </button>
                    </td>
                    
                    {/* status (dot color) */}
                    <td style={{ textAlign: 'center' }}>
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          margin: '0 auto',
                          backgroundColor: item.status ? '#06D001' : '#E72929'
                        }}
                      />
                    </td>
                  </tr>
                )})}
              </tbody>
            </TableBootstrap>
            </div>
          </div>
        </div>
      </div>
      
      {/* </div> */}
      {/* ========== Modal สำหรับแสดงข้อมูลรายละเอียดของ requestDemo ========== */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>รายละเอียด Request Demo</Modal.Title>
          <FaTimes
            onClick={handleCloseModal}
            style={{ cursor: 'pointer', marginLeft: 'auto' }}
            size={20}
          />
        </Modal.Header>
        <Modal.Body>
          {/* เช็คว่ามี selectedItem ไหม */}
          {selectedItem && (
            <div style={{ fontSize: 18 }}>
              {/* ตัวอย่างการแสดงข้อมูลเพิ่มเติม สามารถปรับได้ตามต้องการ */}
              <p><strong>Company Name:</strong> {selectedItem.companyName}</p>
              <p><strong>Company ID:</strong> {selectedItem.companyId}</p>
              <p><strong>Email:</strong> {selectedItem.email}</p>
              <p><strong>Name:</strong> {selectedItem.name}</p>
              <p><strong>Plan:</strong> {selectedItem.plan}</p>
              <p><strong>Status:</strong> {selectedItem.status ? 'Approved' : 'N/A'}</p>
              <p><strong>Created At:</strong> {formatDate(selectedItem.createdAt)}</p>
              <p><strong>Approved Date:</strong> {selectedItem.approve_date ? formatDate(selectedItem.approve_date) : "-"}</p>
              {/* อยากแสดง field อื่น ๆ (เช่น level, position, companyId) ก็เพิ่มได้ */}
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            ปิด
          </Button>
        </Modal.Footer> */}
      </Modal>

      {isLoading && (
        <LoadingBackdrop />
      )}
    </div>
  );
};

export default ApprovePlan;
