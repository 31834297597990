import React, { useState,useEffect,useContext,useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useNavigate, useLocation } from 'react-router-dom';
import './Layout.css';
import auth from './Firebase/Auth';
import { UserContext } from './UserContext';
import firestore from './Firebase/Firestore';
import storage from './Firebase/Storage';
import { HiClipboardList,HiAnnotation,HiOutlineOfficeBuilding  } from "react-icons/hi";
import { BsEasel2,BsDatabaseFillGear,BsDatabaseGear,  } from "react-icons/bs";
import { LuNetwork } from "react-icons/lu";
import { FaBitcoin } from "react-icons/fa6";

const Layout = ({ children }) => {
  // const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 1024); // Default: open on large screens, closed on small screens
  // const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024); // Check if it's mobile view
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId, setCurrentUser, setCompanyId,setUserData,userData,newLeaveRequests, 
    setNewLeaveRequests,newOtRequests,setNewOtRequests,setLoading,userPlan } = useContext(UserContext);
 
  let DEFAULT_LOGO_URL = "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/PAM_HR_logo.png?alt=media&token=00d6bf28-0af6-49f9-bdd4-2a999fd19382";
  const [logoUrl, setLogoUrl] = useState(DEFAULT_LOGO_URL); // State for logo URL
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    const savedSidebarState = localStorage.getItem('sidebarOpen');
    return savedSidebarState ? JSON.parse(savedSidebarState) : window.innerWidth > 1024;
  });

  // กำหนด state sidebarMode มี 3 ค่าได้แก่ 'full', 'collapsed', 'hidden'
  const [sidebarMode, setSidebarMode] = useState('open'); 
  const sidebarContentRef = useRef(null);
  
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);

  const fetchLogo = async () => {
    //localStorage.removeItem('logo');
    if (!companyId) {
      setLogoUrl(DEFAULT_LOGO_URL); // Set default logo if companyId is not available
      return;
    }
  
    const cachedLogo = localStorage.getItem('logo');
    if (cachedLogo) {
      console.log("logo: ",cachedLogo)
      setLogoUrl(cachedLogo); // Use cached logo if available
      return;
    }
  
    try {
      const logoData = await firestore.getCompanyData(companyId); // Fetch company data from Firestore
      //console.log("logo: ",logoData)
      if (logoData?.logo) {
        setLogoUrl(logoData.logo);
        DEFAULT_LOGO_URL=logoData.logo
        localStorage.setItem('logo', logoData.logo); // Cache the logo in localStorage
      } else {
        setLogoUrl(DEFAULT_LOGO_URL); // Set default logo if Firestore does not have a logo
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
      setLogoUrl(DEFAULT_LOGO_URL); // Set default logo in case of an error
    }
  };

  useEffect(() => {
    fetchLogo(); // Fetch logo URL on component mount
    // เมื่อ component mount, เพิ่ม event listener สำหรับ logoUpdated
    const handleLogoUpdated = () => {
    fetchLogo();  // ดึงโลโก้ใหม่แล้ว setLogoUrl ใหม่ใน Layout
    };

    window.addEventListener('logoUpdated', handleLogoUpdated);

    return () => window.removeEventListener('logoUpdated', handleLogoUpdated);
  }, [companyId]);

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const previousLogo = logoUrl; // Save current logo URL before changing
  
    try {
      // Upload the new logo
      const newLogoUrl = await storage.uploadImage(companyId, file);
  
      // Update Firestore with the new logo
      await firestore.updateLogo(companyId, { logo: newLogoUrl });
  
      // Set the new logo in the UI
      setLogoUrl(newLogoUrl);
  
      // Cache the new logo in localStorage
      localStorage.setItem('logo', newLogoUrl);
  
      // Delete the old logo if it's not the default one
      if (previousLogo && previousLogo !== DEFAULT_LOGO_URL) {
        await storage.deleteFile(previousLogo);
      }
  
      // Show success alert
      alert('Logo updated successfully!');
    } catch (error) {
      console.error('Error updating logo:', error);
      alert('Error updating logo. Please try again.');
      setLogoUrl(DEFAULT_LOGO_URL); // Reset to default logo in case of an error
    }
  };

  // useEffect(() => {
  //   const savedSidebarState = localStorage.getItem('sidebarOpen');
  //   if (savedSidebarState !== null) {
  //     setSidebarOpen(JSON.parse(savedSidebarState));
  //   }
  //   const handleResize = () => {
  //     if (window.innerWidth <= 1024) {
  //       setIsMobileView(true);
  //       setSidebarOpen(false); // Collapse on mobile view
  //     } else {
  //       setIsMobileView(false);
  //       //setSidebarOpen(true); // Expand on large screens
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);

  //   // Initial check
  //   handleResize();

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  useEffect(() => {
    const updateSidebarMode = () => {
      const width = window.innerWidth;
      // <= 576 => closed
      // <= 1024 => collapsed
      // > 1024 => open
      if (width <= 576) {
        setSidebarMode('closed');
      } else if (width <= 1024) {
        setSidebarMode('collapsed');
      } else {
        setSidebarMode('open');
      }
    };
  
    updateSidebarMode(); 
    window.addEventListener('resize', updateSidebarMode);
    return () => window.removeEventListener('resize', updateSidebarMode);
  }, []);


  useEffect(() => {
    if (!companyId) {
      console.error('Invalid companyId:', companyId);
      return; // Early exit if companyId is not valid
    }
  
    // Listen for new leave requests from Firestore
    const unsubscribe = firestore.getAllLeave(companyId, (allLeave) => {
      // Check if there are any requests with state === true
      const hasNewRequests = allLeave.some(leave => leave.state1 === false && leave.deny != true);

      if (hasNewRequests) {
        setNewLeaveRequests(true); // Set notification flag to true when new requests are detected
      } else {
        setNewLeaveRequests(false); // Set it to false if there are no new requests
      }
    }, console.error);

    return () => unsubscribe(); // Unsubscribe from Firestore when the component unmounts
  }, [companyId, setNewLeaveRequests]);

  useEffect(() => {
    // Reset `newLeaveRequests` when "คำขอลางาน" page is active
    if (location.pathname === '/leave_request' && newLeaveRequests) {
      console.log('Resetting newLeaveRequests to false');
      setNewLeaveRequests(false); // Reset flag when user navigates to the leave request page
    }
  }, [location.pathname, newLeaveRequests, setNewLeaveRequests]);

  useEffect(() => {
    if (!companyId) {
      console.error('Invalid companyId:', companyId);
      return;
    }
    // Listen for new leave requests from Firestore
    const unsubscribe = firestore.getAllOT(companyId, (allOt) => {
      // Check if there are any requests with state === true
      const hasNewRequests = allOt.some(ot => ot.state1 === false && ot.deny != true);

      if (hasNewRequests) {
        setNewOtRequests(true); // Set notification flag to true when new requests are detected
      } else {
        setNewOtRequests(false); // Set it to false if there are no new requests
      }
    }, console.error);

    return () => unsubscribe(); // Unsubscribe from Firestore when the component unmounts
  }, [companyId, setNewOtRequests]);

  useEffect(() => {
    // Reset `newLeaveRequests` when "คำขอลางาน" page is active
    if (location.pathname === '/ot_request' && newOtRequests) {
      //console.log('Resetting newLeaveRequests to false');
      setNewOtRequests(false); // Reset flag when user navigates to the leave request page
    }
  }, [location.pathname, newOtRequests, setNewOtRequests]);

  // const toggleSidebar = () => {
  //   const newSidebarState = !sidebarOpen;
  //   setSidebarOpen(newSidebarState);
  //   localStorage.setItem('sidebarOpen', JSON.stringify(newSidebarState)); // Store sidebar state
  // };

  const toggleSidebar = () => {
    const width = window.innerWidth;
    
    if (width <= 576) {
      // มือถือ => closed ↔ open
      if (sidebarMode === 'closed') {
        setSidebarMode('open');
      } else {
        setSidebarMode('closed');
      }
    } else if (width <= 1024) {
      // แท็บเล็ต => collapsed ↔ open
      if (sidebarMode === 'collapsed') {
        setSidebarMode('open');
      } else {
        setSidebarMode('collapsed');
      }
    } else {
      // จอใหญ่ => open ↔ collapsed
      if (sidebarMode === 'open') {
        setSidebarMode('collapsed');
      } else {
        setSidebarMode('open');
      }
    }
  };

  const logOutSuccess = () => {
    setCurrentUser(null);  // Clear user from context
    setCompanyId(null);    // Clear companyId from context
    setUserData(null);     // Clear userData from context
    localStorage.removeItem('companyId');  // Clear companyId from localStorage
    localStorage.removeItem('userData'); // Clear companyId from localStorage
    localStorage.removeItem('sidebarOpen');
    localStorage.removeItem('sidebarScroll');
    localStorage.removeItem('logo');
    navigate('/login_company');  // Redirect to login company page
    setLoading(false)
  };

  const logout = (e) => {
    e.preventDefault();
    auth.signOut(logOutSuccess);
  };

  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const isDemo = userPlan === 'demo';

  const handleSidebarScroll = () => {
    if (sidebarContentRef.current) {
      localStorage.setItem('sidebarScroll', sidebarContentRef.current.scrollTop);
    }
  };

  useEffect(() => {
    const savedScroll = localStorage.getItem('sidebarScroll');
    if (savedScroll && sidebarContentRef.current) {
      sidebarContentRef.current.scrollTop = parseInt(savedScroll, 10);
    }
  }, []);

  return (
    <div className="layout">
      <div className={`sidebar ${sidebarMode}`}>
        <div className="sidebar-content" ref={sidebarContentRef} onScroll={handleSidebarScroll}>
        <div className='head-sidebar'>
            {/* <img src="https://i.postimg.cc/VLLwZdzX/PAM-logo.png" width={80} height={80} style={{marginRight:20}} alt="Logo" /> */}
            <div className="logo-container">
              <img src={logoUrl} width={80} height={80} alt="Logo"
                style={{
                  objectFit: 'cover',         // ให้รูปครอบเต็มกรอบ (คงอัตราส่วน)
                  // objectFit: 'contain',    // ถ้าต้องการให้รูปทั้งหมดอยู่ในกรอบ โดยอาจมีขอบขาว
                  display: 'block'           // ป้องกันการเผื่อ space ใต้รูป s
                }}
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                style={{ display: 'none' }}
                id="logoUpload-layout"
              />
              {/* <label htmlFor="logoUpload" className="change-logo-icon">
                <i className="fas fa-camera"></i> 
              </label> */}
            </div>
            {sidebarMode === 'open' && <h4>Personnel Assistance Manager</h4>}
          </div>
          <nav>
            {/* หมวดที่ 1 : หน้าหลัก */}
            <div className="sidebar-category">
              <h4 className="category-title">หน้าหลัก</h4>
              <div className="category-underline"></div>
              <ul className="category-menu">
                <li className={isActive("/home") ? "active" : ""}>
                  <Link to="/home">
                    <i className="fas fa-home"  title={sidebarMode === 'collapsed' ? "สรุปภาพรวมข้อมูล" : ""}></i>
                    <span>สรุปภาพรวมข้อมูล</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* หมวดที่ 2 : ตั้งค่าพื้นฐาน */}
            <div className="sidebar-category">
              <h4 className="category-title">ตั้งค่าพื้นฐาน</h4>
              <div className="category-underline"></div>
              <ul className="category-menu">
                <li className={isActive("/manageIndex") ? "active" : ""}>
                  <Link to="/manageIndex">
                    <BsDatabaseGear className="menu-icon" style={{ marginRight: 0 }} title={sidebarMode === 'collapsed' ? "การตั้งค่าพื้นฐาน" : ""}/>
                    <span>การตั้งค่าพื้นฐาน</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* หมวดที่ 3 : จัดการพนักงาน */}
            <div className="sidebar-category">
              <h4 className="category-title">จัดการพนักงาน</h4>
              <div className="category-underline"></div>
              <ul className="category-menu">
                <li className={isActive("/profile") ? "active" : ""}>
                  <Link to="/profile">
                    <i className="fas fa-user" title={sidebarMode === 'collapsed' ? "การจัดการข้อมูลพนักงาน" : ""}></i>
                    <span>การจัดการข้อมูลพนักงาน</span>
                  </Link>
                </li>
                <li className={isActive("/welthfare") ? "active" : ""}>
                  <Link to="/welthfare">
                    <i className="fas fa-calendar-check" title={sidebarMode === 'collapsed' ? "การจัดการสิทธิ์และวันหยุด" : ""}></i>
                    <span>การจัดการสิทธิ์และวันหยุด</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* หมวดที่ 4 : การบริหารงาน */}
            <div className="sidebar-category">
              <h4 className="category-title">การบริหารงาน</h4>
              <div className="category-underline"></div>
              <ul className="category-menu">
                <li className={isActive("/managment") ? "active" : ""}>
                  <Link to="/managment">
                    <i className="fas fa-users" title={sidebarMode === 'collapsed' ? "การบริหารพื้นที่ปฏิบัติการ" : ""}></i>
                    <span>การบริหารพื้นที่ปฏิบัติการ</span>
                  </Link>
                </li>
                <li className={isActive("/salary") ? "active" : ""}>
                  <Link to="/salary">
                    <FaBitcoin className="menu-icon" title={sidebarMode === 'collapsed' ? "การจัดการเงินเดือน" : ""} />
                    <span>การจัดการเงินเดือน</span>
                  </Link>
                </li>
                <li className={isActive("/checkin_history") ? "active" : ""}>
                  <Link to="/checkin_history">
                    <i className="fas fa-clock" title={sidebarMode === 'collapsed' ? "ประวัติการเข้า-ออกงาน" : ""}></i>
                    <span>ประวัติการเข้า-ออกงาน</span>
                  </Link>
                </li>
                <li className={isActive("/asset_sync") ? "active" : ""}>
                  <Link to="/asset_sync">
                    <HiClipboardList className="menu-icon" style={{ marginRight: 0 }} title={sidebarMode === 'collapsed' ? "Asset Sync" : ""} />
                    <span>Asset Sync</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* หมวดที่ 5 : คำขอต่าง ๆ */}
            <div className="sidebar-category">
              <h4 className="category-title">คำขอต่าง ๆ</h4>
              <div className="category-underline"></div>
              <ul className="category-menu">
                <li className={isActive("/leave_request") ? "active" : ""}>
                  <Link to="/leave_request">
                    <i className="fas fa-suitcase" title={sidebarMode === 'collapsed' ? "การขอลางาน" : ""}></i>
                    <span>การขอลางาน</span>
                    {newLeaveRequests && <span className="notification-dot"></span>}
                  </Link>
                </li>
                <li className={isActive("/ot_request") ? "active" : ""}>
                  <Link to="/ot_request">
                    <i className="fas fa-hourglass-half" title={sidebarMode === 'collapsed' ? "การทำงานล่วงเวลา" : ""}></i>
                    <span>การขอทำงานล่วงเวลา</span>
                    {newOtRequests && <span className="notification-dot"></span>}
                  </Link>
                </li>
              </ul>
            </div>

            {/* หมวดที่ 6 : การสื่อสาร */}
            <div className="sidebar-category">
              <h4 className="category-title">การสื่อสาร</h4>
              <div className="category-underline"></div>
              <ul className="category-menu">
                <li className={isActive("/chathub") ? "active" : ""}>
                  <Link to="/chathub" title={sidebarMode === 'collapsed' ? "Chat Hub" : ""}>
                    <HiAnnotation className="menu-icon" style={{ marginRight: 0 }} />
                    <span>Chat Hub</span>
                  </Link>
                </li>
                <li className={isActive("/annouce") ? "active" : ""}>
                  <Link to="/annouce">
                    <i className="fas fa-bullhorn" title={sidebarMode === 'collapsed' ? "การประกาศข่าวสาร" : ""}></i>
                    <span>การประกาศข่าวสาร</span>
                  </Link>
                </li>
                <li className={isActive("/2_annouce_extend") ? "active" : ""}>
                  <Link to="/2_annouce_extend">
                    <i className="fas fa-newspaper" title={sidebarMode === 'collapsed' ? "การประกาศหน้า 2" : ""}></i>
                    <span>ประกาศหน้า 2</span>
                  </Link>
                </li>
                <li className={isActive("/acknowledge") ? "active" : ""}>
                  <Link to="/acknowledge">
                    <BsEasel2 className="menu-icon" style={{ marginRight: 0 }} title={sidebarMode === 'collapsed' ? "Acknowledge" : ""} />
                    <span>Acknowledge</span>
                  </Link>
                </li>
                <li className={isActive("/network") ? "active" : ""}>
                  <Link to="/network">
                    <LuNetwork className="menu-icon" title={sidebarMode === 'collapsed' ? "เครือข่ายภายใน" : ""} />
                    <span>เครือข่ายภายใน</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* หมวดที่ 7 : ฟีเจอร์อื่น ๆ */}
            <div className="sidebar-category">
              <h4 className="category-title">ฟีเจอร์อื่น ๆ</h4>
              <div className="category-underline"></div>
              <ul className="category-menu">
                <li className={isActive("/calendar") ? "active" : ""}>
                  <Link to="/calendar">
                    <i className="fas fa-calendar" title={sidebarMode === 'collapsed' ? "ปฏิทินบันทึกโน้ต" : ""}></i>
                    <span>ปฏิทินบันทึกโน้ต</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* หมวดที่ 8 : การจัดการผู้ใช้ & ติดต่อ */}
            <div className="sidebar-category">
              <h4 className="category-title">การจัดการและติดต่อ</h4>
              <div className="category-underline"></div>
              <ul className="category-menu">
                <li className={isActive("/manage_account") ? "active" : ""}>
                  <Link to="/manage_account">
                    <i className="fas fa-cogs" title={sidebarMode === 'collapsed' ? "การจัดการผู้ใช้งาน" : ""}></i>
                    <span>การจัดการผู้ใช้งาน</span>
                  </Link>
                </li>
                <li className={isActive("/contact") ? "active" : ""}>
                  <Link to="/contact">
                    <i className="fas fa-envelope" title={sidebarMode === 'collapsed' ? "ติดต่อผู้พัฒนาโปรแกรม" : ""}></i>
                    <span>ติดต่อผู้พัฒนาโปรแกรม</span>
                  </Link>
                </li>
                <li className={isActive("/about_you") ? "active" : ""}>
                  <Link to="/about_you">
                    <HiOutlineOfficeBuilding className="menu-icon" style={{ marginRight:0  }} title={sidebarMode === 'collapsed' ? "เกี่ยวกับบริษัท" : ""} />
                    <span>เกี่ยวกับบริษัท</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <div className='logout-button'> 
          <button style={{ fontSize: 22 }} onClick={logout}>
              <i style={{marginRight:0}} className="fas fa-sign-out-alt"></i> {/* Sign Out Icon */}
              {/* {sidebarOpen && "ออกจากระบบ"} */}
              {sidebarMode === 'open' && "ออกจากระบบ"}
            </button>
          </div>
        </div>
      </div>
      
        
        <button className="toggle-button" onClick={toggleSidebar}>
          ☰
        </button>
          
       
        <div className="content">
          {children}
        </div>
      </div>
    
  );
};

export default Layout;
