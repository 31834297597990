import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import '../Home.css';
import Sidebar from '../sidebar';
import '../Profile.css';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { useNavigate,useLocation } from 'react-router-dom';
import firestore from '../Firebase/Firestore';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoSearchOutline, IoPencil, IoTrash, IoPerson, IoBarChart, IoNotifications } from "react-icons/io5";
import { IoFilterOutline,IoPersonAddSharp  } from "react-icons/io5";
import { BiSolidFileImport  } from "react-icons/bi";
import { MdGroupAdd } from "react-icons/md";
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Layout from '../Layout';
import { UserContext } from '../UserContext';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import 'dayjs/locale/th'
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from '../LoadingBackdrop';

function ProfileManage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [allUser,setAllUser] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const itemsPerPage = 10;
  const [showDel, setShowDel] = useState(false);
  const [selectID, setSelectID] = useState();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); //แปลง search toLowCase
  const [search, setSearch] = useState(''); //เก็บคำที่ search
  const [showFillter,setShowFillter] = useState(false);
  const [selectFillter,setSelectFillter] = useState('');
  const [position,setPosition] = useState('');
  const [name,setName] = useState('');
  const [sortOrderName, setSortOrderName] = useState('asc'); // Track sorting order for names
  const [showImportModal, setShowImportModal] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [positionOptions, setPositionOptions] = useState([]);
  const { account, companyId, userPlan } = useContext(UserContext);
  
  const getAllUsersSuccess=(doc)=>{
    let users = []
    if (allUser.length === 0) {
        
      doc.forEach((item) => {
        if(item.position != 'tester')
          users.push({id: item.id, name: item.name, position: item.position});
      });
      setAllUser(users);
      applyFilters(users);
    }
  }

  const getAllUsersUnsuccess=(error)=>{
    console.log("getAllUsers: "+error)
  }
  
  const handleFillterClose = () => setShowFillter(false);
  const handleFilterShow = () => { 
    setShowFillter(true);
  }

  const handleDelClose = () => setShowDel(false);
  const handleDelShow = (id,name) => {
    setSelectID(id)
    setName(name)
    setShowDel(true);
  }

  const Delete =()=>{
    setIsLoading(true)
    if(selectID != '8H1ETSH8pE0s7lvKeLzk'){
      firestore.deleteUser(companyId,selectID)
      firestore.deleteUsername(companyId,selectID)
      setIsLoading(false)
    }
    else{
      console.log('not Del!')
    }
    Swal.fire({
      // title: 'Success',
      // text: 'Cannot delete an Administrator account.',
      title: 'สำเร็จ',
      text: 'ลบข้อมูลพนักงานเรียบร้อย',
      icon: 'success',
      customClass: {
        popup: 'my-swal-size'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    });
    handleDelClose()
  }

  const maxEmployeeByPlan = (plan) => {
    switch (plan) {
      case 'demo':
        return 20;
      case '10':
        return 10;
      case '20':
        return 20;
      case '30':
        return 30;
      case '50':
        return 50;
      case '80':
        return 80;
      case '100':
        return 100;
      case '125':
        return 125;
      case '150':
        return 150;
      default:
        return 0; // กรณี plan อื่น ๆ
    }
  };

  const onAdd =()=>{
    console.log(account)
    const maxAllowed = maxEmployeeByPlan(userPlan);
    if (allUser.length >= maxAllowed) {
      //alert(`ไม่สามารถเพิ่มพนักงานได้เกิน ${maxAllowed} คน (plan: ${userPlan})`);
      Swal.fire({
        // title: 'Success',
        // text: 'Cannot delete an Administrator account.',
        title: 'แจ้งเตือน',
        text: `ไม่สามารถเพิ่มพนักงานได้เกิน ${maxAllowed} คน (plan: ${userPlan})`,
        icon: 'warning',
        customClass: {
          popup: 'my-swal-size'
        },
      });
      return;
    }
    navigate('/profile_add',{state:{startIndex, endIndex, search,
      searchQuery,
      position,}});
  }

  const onEdit =(id)=>{
    navigate('/profile_edit',{state:{uid:id,startIndex, endIndex,  search,
      searchQuery,
      position,}})
  }

  const onRole =(id)=>{
    navigate('/profile_role',{state:{uid:id,startIndex, endIndex,  search,
      searchQuery,
      position,}})
  }

  const onUpSkill =(id)=>{
    navigate('/profile_upskill',{state:{uid:id,startIndex, endIndex,  search,
      searchQuery,
      position,}})
  }

  const onNotice =(id)=>{
    navigate('/profile_notice',{state:{uid:id,startIndex, endIndex,  search,
      searchQuery,
      position,}})
  }

  const fetchDropdownOptions = async () => {
    try {
      
      const positionOptions = await firestore.getDropdownOptions(companyId,'position');
      setPositionOptions(positionOptions.map(option => option.name));
      //console.log(positionOptions)
      
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  const applyFilters = (users) => {
    let filtered = users || allUser;
    if (searchQuery) {
      filtered = filtered.filter(user => user.name.toLowerCase().includes(searchQuery) || user.position.toLowerCase().includes(searchQuery));
    }
    if (position) {
      filtered = filtered.filter(user => user.position.toLowerCase().includes(position.toLowerCase()));
    }
    setFilteredUsers(filtered);
    if(search){
      setStartIndex(0);
      setEndIndex(10);
    }
  };

  useEffect(() => {
    if (location.state && location.state.startIndex !== undefined) {
      setStartIndex(location.state.startIndex);
      setEndIndex(location.state.endIndex);
      setSearch(location.state.search || '');
      setSearchQuery(location.state.searchQuery || '');
      setPosition(location.state.position || '');
    } else {
      setStartIndex(0); // Default to first page if no state is provided
      setEndIndex(10);
    }
    firestore.getAllUser(companyId,getAllUsersSuccess,getAllUsersUnsuccess)
    fetchDropdownOptions();
  }, [location.state]);

  useEffect(() => {
    applyFilters(allUser);
  }, [searchQuery, allUser]);

  const onNext = () => {
    setStartIndex(startIndex + 10); // Increment the start index by 5
    setEndIndex(endIndex + 10); // Increment the end index by 5
  };

  const onPrevious = () => {
    setStartIndex(Math.max(startIndex - 10, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndIndex(Math.max(endIndex - 10, 10)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearch(event.target.value);
    setSearchQuery(query);
    // applyFilters(allUser); // Reapply filters whenever search changes
  };

  const handleFillter = () => {
    applyFilters(allUser); // Apply filters when filter button is clicked
    setShowFillter(false);
  };

  // Sorting function for names
  const sortByName = () => {
    const newOrder = sortOrderName === 'asc' ? 'desc' : 'asc';
    setSortOrderName(newOrder);
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return newOrder === 'asc' ? (nameA < nameB ? -1 : 1) : (nameA > nameB ? -1 : 1);
    });
    setFilteredUsers(sortedUsers);
  };

  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentPage = startIndex / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handlePageChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedPage - 1) * itemsPerPage;
    const newEndIndex = selectedPage * itemsPerPage;

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  };

  const handleImportExcel = () => {
    setShowImportModal(true);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setExcelFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    setIsLoading(true)
    setShowImportModal(false)
    if (!excelFile){
      setIsLoading(false)
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // สมมติอ่านเฉพาะ sheet แรก
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1,  blankrows: false,  });

      // jsonData จะเป็น Array ของแต่ละแถวในรูป [ [col1, col2, ...], [col1, col2, ...], ...]
      // หากไฟล์มี Header row เราสามารถแยก Column name แล้ว map ได้ตามต้องการ

      // สมมติ header row อยู่บรรทัดแรก
      // jsonData[0] => ['name', 'position', 'emID', 'address', ...]
      const headers = jsonData[0];
      // บรรทัดถัด ๆ ไปคือข้อมูลพนักงาน
      const rows = jsonData.slice(1);

      // map rows => objects
      const dataObjects = rows.map(row => {
        // สร้าง object โดย bind column เข้ากับ header
        let obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index] || ""; // ถ้าไม่มีค่าจะเป็น ''
        });
        return obj;
      });

      const maxAllowed = maxEmployeeByPlan(userPlan);
      const totalAfterImport = allUser.length + dataObjects.length; 
      if (totalAfterImport > maxAllowed) {
        // alert(
        //   `ไม่สามารถนำเข้าข้อมูลได้ เนื่องจากจำนวนพนักงานรวม (${totalAfterImport} คน) ` + 
        //   `จะเกินกว่า Plan ที่กำหนด (${maxAllowed} คน ใน plan: ${userPlan}).`
        // );
        setIsLoading(false)
        Swal.fire({
          // title: 'Success',
          // text: 'Cannot delete an Administrator account.',
          title: 'แจ้งเตือน',
          text: `ไม่สามารถนำเข้าข้อมูลได้ เนื่องจากจำนวนพนักงานรวม (${totalAfterImport} คน) ` + 
          `จะเกินกว่า Plan ที่กำหนด (${maxAllowed} คน ใน plan: ${userPlan}).`,
          icon: 'warning',
          customClass: {
            popup: 'my-swal-size'
          },
        });
        return; // ยกเลิกการ import
      }

      // จากนั้นอาจเรียกฟังก์ชันส่งไปบันทึกต่อ (เช่นส่ง dataObjects ให้บันทึกใน Firestore)
      handleSaveToFirestore(dataObjects);
    };
    reader.readAsArrayBuffer(excelFile);
  };

  const handleSaveToFirestore = async (dataArray) => {
    try {
      // duplicateRows จะเก็บชื่อ (fullName) ของ record ที่พบว่าซ้ำ
      let duplicateRows = [];
      
      // วนลูปผ่านข้อมูลที่ได้จาก Excel
      for (const row of dataArray) {
        // ดึงข้อมูลชื่อและนามสกุลจาก Excel
        let excelFirstName = row["ชื่อ"] || "";
        let excelLastName = row["นามสกุล"] || "";
        // สร้าง fullName แบบ trim และแปลงเป็น lowercase เพื่อให้ตรวจสอบแบบ case‑insensitive
        let excelFullName = `${excelFirstName.trim()} ${excelLastName.trim()}`.toLowerCase();
        
        // ตรวจสอบว่ามีผู้ใช้ใน allUser ที่มี fullName ตรงกันหรือไม่
        const duplicate = allUser.find(user => {
          // สมมติใน allUser มี field ชื่อ user.name และ user.lastname
          let userFullName = user.name.toLowerCase();
          return userFullName === excelFullName;
        });
        
        // ถ้ามี record ซ้ำ ให้บันทึกชื่อที่ซ้ำแล้ว skip record นี้
        if (duplicate) {
          duplicateRows.push(excelFullName);
          continue;
        }
        
        // ดำเนินการแปลงวันที่ (ในคอลัมน์ "วันที่เริ่มงาน")
        let excelValue = row["วันที่เริ่มงาน"];
        let workStartStr = "";
        if (typeof excelValue === "number") {
          const parsed = XLSX.SSF.parse_date_code(excelValue);
          const dateObj = new Date(parsed.y, parsed.m - 1, parsed.d);
          workStartStr = dayjs(dateObj).format("DD/MM/YYYY");
        } else if (excelValue instanceof Date) {
          workStartStr = dayjs(excelValue).format("DD/MM/YYYY");
        } else if (typeof excelValue === "string") {
          workStartStr = excelValue;
        } else {
          workStartStr = "";
        }
        
        // สร้าง object สำหรับบันทึกข้อมูลผู้ใช้งาน (profile) ลง Firestore
        let item = {
          name: excelFirstName,
          lastname: excelLastName,
          FName: "", // ถ้ามีข้อมูลภาษาอังกฤษ สามารถ split เพิ่มเติมได้
          LName: "",
          position: row["ตำแหน่ง"] || "",
          department: row["แผนก"] || "",
          workstart: workStartStr,
          phone: row["เบอร์โทร"] || "",
          address: row["ที่อยู่"] || "",
          image_off:
            "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/333.png?alt=media&token=f5b9e3a6-8644-417e-a366-c4cddac12007",
        };
        
        // บันทึกข้อมูลลง Firestore
        await firestore.addUser(companyId, item, (id) => console.log(`Imported user id: ${id}`), (error) => console.log(error));
      }
      
      // ถ้ามี duplicate ให้แจ้งเตือนผู้ใช้ด้วยว่ามี record ที่ถูกข้ามไป
      setIsLoading(false)
      if (duplicateRows.length > 0) {
        // ใช้ Set เพื่อเอาค่าที่ซ้ำกันออก (ไม่แจ้งซ้ำหลายครั้ง)
        const uniqueDuplicates = [...new Set(duplicateRows)];
        // alert(
        //   `มี ${duplicateRows.length} record ถูกข้ามไปเนื่องจากชื่อซ้ำ: ${uniqueDuplicates.join(", ")}`
        // );
        Swal.fire({
          // title: 'Success',
          // text: 'Cannot delete an Administrator account.',
          title: 'แจ้งเตือน',
          text: `มี ${duplicateRows.length} record ถูกข้ามไปเนื่องจากชื่อซ้ำ: ${uniqueDuplicates.join(", ")} \nข้อมูลอื่นๆ นำเข้าเรียบร้อย`,
          icon: 'success',
          customClass: {
            popup: 'my-swal-size'
          },
        });
      } else {
        //alert("Import & Save data success!");
        Swal.fire({
          // title: 'Success',
          // text: 'Cannot delete an Administrator account.',
          title: 'สำเร็จ',
          text: 'นำเข้าและบันทึกข้อมูลพนักงานเรียบร้อย',
          icon: 'success',
          customClass: {
            popup: 'my-swal-size'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });
      }
      setShowImportModal(false);
    } catch (error) {
      console.error(error);
      alert("Error importing data: " + error.message);
    }
  };

  return (
    
      <div className="dashboard">
        <Layout />
        
        <main className="main-content">
          
          <div className="main">
          <div className='header-page'>
          <header>
            <h1 >การจัดการข้อมูลพนักงาน</h1>
            {/* Add user profile and logout here */}
          </header>
          </div>
            <div className="main-contain">

              <div className="search-field">
                {/* <p style={{marginTop:17}}>ค้นหาพนักงาน</p> */}
                <input style={{width:'95%',margin:5,height:40,borderRadius:5,paddingInlineStart:10,fontSize:22,alignSelf:'center',justifyContent:'center'}}
                placeholder='search..' 
                value={search}
                onChange={handleSearch} />
                {/*<button className="search-button" ><IoSearchOutline size={24} /></button>*/}
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:10,width:'95%',alignSelf:'center'}}>
                <button className='fillter-button' onClick={()=>setShowFillter(true)}><IoFilterOutline size={20} /></button>
                <button className='Add-button' style={{marginRight:5}} title='เพิ่มพนักงาน' onClick={onAdd}><MdGroupAdd size={24}  /></button>
                <button className='Add-button' title='Import Excel' onClick={handleImportExcel}><BiSolidFileImport size={24} /></button>
              </div>
              
              <div style={{width:'95%',alignSelf:'center'}}>
              <div className="table-container">
              <TableBootstrap striped bordered hover className='table'>
                <thead>
                  <tr>
                    <th scope="col">ลำดับ</th>
                    <th scope="col" onClick={sortByName} style={{ cursor: 'pointer',width:'30%' }}>
                      ชื่อ-สกุล {sortOrderName === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </th>
                    <th scope="col" style={{width:'25%' }}>ตำแหน่ง</th>
                    <th scope="col" style={{ cursor: 'pointer',width:'30%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {/*{allUser.slice(startIndex, endIndex).map((item, index) => (*/}
                  {filteredUsers.slice(startIndex, endIndex).map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row">{startIndex + index + 1}</th>
                      {/* <th scope="row" style={{width:80}}>{index + 1}</th> */}
                      <td>
                        {item.name}
                      </td>
                      <td>{item.position}</td>
                      <td style={{width:'50%',textAlign:'center'}}>
                        <button className='Edit-button' style={{backgroundColor:'#292f56'}} onClick={()=>onEdit(item.id)} title="แก้ไข"><IoPencil size={20} /></button>
                        <button className='Delete-button' onClick={()=>handleDelShow(item.id,item.name)} title="ลบ"><IoTrash size={20} /></button>
                        <button className='Role-button' onClick={()=>onRole(item.id)} title="Role"><IoPerson size={20} /></button>
                        <button className='UpSkill-button' onClick={()=>onUpSkill(item.id)} title="Up Skill"><IoBarChart size={20} /></button>
                        <button className='Notice-button' onClick={()=>onNotice(item.id)} title="My Notice"><IoNotifications size={20} /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableBootstrap>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center'}}>
                <button className='Previous-button' onClick={onPrevious}>Previous</button>
                <div style={{ margin: '0 10px' }}>
                  <select
                    value={currentPage}
                    onChange={handlePageChange}
                    style={{ fontSize: '16px', padding: '2px 8px' }}
                  >
                    {Array.from({ length: totalPages }, (_, idx) => (
                      <option key={idx + 1} value={idx + 1}>
                        {idx + 1}
                      </option>
                    ))}
                  </select>
                  <span style={{ marginLeft: '5px' }}>จาก {totalPages}</span>
                </div>
                <button className='Next-button' onClick={onNext}>Next</button>
                </div>
              </div>

            </div>
          </div>
          
        </main>
        <Modal show={showDel} onHide={handleDelClose}>
        <Modal.Header closeButton>
          <Modal.Title>ลบข้อมูลพนักงาน</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>ยืนยันจะลบข้อมูลพนักงาน {name} หรือไม่</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" style={{backgroundColor:'#D3D3D3',color:'black'}} onClick={Delete}>
            OK
          </Button>
          <Button variant="secondary" style={{backgroundColor:'#343434'}} onClick={handleDelClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFillter} onHide={handleFillterClose}>
        <Modal.Header closeButton>
          <Modal.Title>เลือกข้อมูลที่ต้องการกรอง</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <TextField
                    id="filled-select"
                    select
                    label="ตำแหน่ง"
                    variant="filled"
                    style={{width:300,marginRight:10}}
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                    InputLabelProps={{
                      shrink: true, // Ensures the label is always shrunk
                    }}
                  >
                     <MenuItem value="">None / Clear Filter</MenuItem>
                    {positionOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  </TextField>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" style={{backgroundColor:'#D3D3D3',color:'black'}} onClick={handleFillter}>
            OK
          </Button>
          <Button variant="secondary" style={{backgroundColor:'#343434'}} onClick={handleFillterClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

       {/* Modal Import Excel (สวยงามด้วย Bootstrap) */}
       <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:28}}>Import จากไฟล์ Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" style={{fontSize:18}} accept=".xlsx,.xls,.xltx,.xlsm" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpload} style={{fontSize:18}}>
            Upload
          </Button>
          <Button variant="secondary" onClick={() => setShowImportModal(false)} style={{fontSize:18}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
      {isLoading && (
        <LoadingBackdrop />
      )}
      </div>
      
    
  );
}

export default ProfileManage;

  