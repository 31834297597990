import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import 'animate.css';
import './Loading.css'

const LoadingBackdrop =()=>{
    return(
        <div className="loader-backdrop">
          {/* ใส่ Animation หรือ Spinner */}
          {/* <div className="spinner" /> */}
          {/* หรือจะใส่ข้อความ Loading... */}
          <img 
            src={require('./icon/Dual Ring@1x-1.0s-200px-200px.gif')}
            alt="Loading..." 
            style={{ width: '150px', height: '150px' }} 
          />
        </div>
    )
}

export default LoadingBackdrop;