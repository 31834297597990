import React,{useContext} from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useNavigate } from 'react-router-dom';
import '../Home.css';
import Sidebar from '../sidebar';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { useEffect, useState } from 'react';
import firestore from '../Firebase/Firestore';
import storage from '../Firebase/Storage';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoSearchOutline, IoPencil, IoTrash, IoPerson, IoBarChart, IoNotifications } from "react-icons/io5";
import { RiStickyNoteAddFill } from "react-icons/ri";
import Layout from '../Layout';
import { UserContext } from '../UserContext';


function Annouce() {
  const navigate = useNavigate();
  const [title,setTitle] = useState('');
  const [date,setDate] = useState('');
  const [selectID,setSelectID] = useState('');
  const [allAnnouce,setAllAnnouce] = useState([]);
  const [show, setShow] = useState(false);
  const [filteredAnnouces, setFilteredAnnouces] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [startNews, setStartNews] = useState(0);
  const [endNews, setEndNews] = useState(5);
  const [startRule, setStartRule] = useState(0);
  const [endRule, setEndRule] = useState(5);
  const [startGeneral, setStartGeneral] = useState(0);
  const [endGeneral, setEndGeneral] = useState(5);
  const [startCampaign, setStartCampaign] = useState(0);
  const [endCampaign, setEndCampaign] = useState(5);
  const [startHoliday, setStartHoliday] = useState(0);
  const [endHoliday, setEndHoliday] = useState(5);
  const itemsPerPage = 5;
  const [newsAnnouce,setNewsAnnouce] = useState([]);
  const [filterNews,setFilterNews] = useState([]);
  const [ruleAnnouce,setRuleAnnouce] = useState([]);
  const [filterRule,setFilterRule] = useState([]);
  const [generalAnnouce,setGeneralAnnouce] = useState([]);
  const [filterGeneral,setFilterGeneral] = useState([]);
  const [campaignAnnouce,setCampaignAnnouce] = useState([]);
  const [filterCampaign,setFilterCampaign] = useState([]);
  const [holidayAnnouce,setHolidayAnnouce] = useState([]);
  const [filterHoliday,setFilterHoliday] = useState([]);
  const { setCurrentUser, companyId } = useContext(UserContext);
  const [delFile,setDelFile] = useState(null);
  const [delImageFile,setDelImageFile] = useState(null);

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const getAllAnnouceSuc=(doc)=>{
    
    let annouces = []
    let news = []
    let rules = []
    let generals = []
    let campaigns = []
    let holidays = []
    if (allAnnouce.length === 0) {
      
      doc.forEach((item) => {
        //console.log(item.title+":"+item.type)
        if(item.type == 1){
          annouces.push({id: item.id,title:item.title,date:item.date,type:item.type,file:item.file,imageFile:item.imageFile});
        }
        else if(item.type == 2){
          news.push({id: item.id,title:item.title,date:item.date,type:item.type,file:item.file,imageFile:item.imageFile});
        }
        else if(item.type == 3){
          rules.push({id: item.id,title:item.title,date:item.date,type:item.type,file:item.file,imageFile:item.imageFile});
        }
        else if(item.type==4){
          generals.push({id: item.id,title:item.title,date:item.date,type:item.type,file:item.file,imageFile:item.imageFile});
        }
        else if(item.type==5){
          campaigns.push({id: item.id,title:item.title,date:item.date,type:item.type,file:item.file,imageFile:item.imageFile});
        }
        else if(item.type==6){
          holidays.push({id: item.id,title:item.title,date:item.date,type:item.type,file:item.file,imageFile:item.imageFile});
        }
        
      });
      annouces.sort((a, b) => parseDate(b.date) - parseDate(a.date));
      news.sort((a, b) => parseDate(b.date) - parseDate(a.date));
      rules.sort((a, b) => parseDate(b.date) - parseDate(a.date));
      generals.sort((a, b) => parseDate(b.date) - parseDate(a.date));
      campaigns.sort((a, b) => parseDate(b.date) - parseDate(a.date));
      holidays.sort((a, b) => parseDate(b.date) - parseDate(a.date));
      setAllAnnouce(annouces);
      setNewsAnnouce(news)
      setRuleAnnouce(rules)
      setGeneralAnnouce(generals);
      setCampaignAnnouce(campaigns);
      setHolidayAnnouce(holidays);
      setFilteredAnnouces(annouces);
      setFilterNews(news)
      setFilterRule(rules)
      setFilterGeneral(generals)
      setFilterCampaign(campaigns)
      setFilterHoliday(holidays)
    }
  }

  const getAllAnnouceUnsuc=(error)=>{
    console.log("getAnnouce: "+error)
  }

  const handleClose = () => setShow(false);
  const handleShow = (id,item) => {
    setSelectID(id)
    if(item.file){
      setDelFile(item.file)
    }
    if(item.imageFile){
      setDelImageFile(item.imageFile)
    }
    setShow(true);
  }

  const deleteAnnouce =()=>{
    if(delFile){
      storage.deleteFile(delFile)
    }  
    if(delImageFile){
      storage.deleteFile(delImageFile)
    }
    firestore.deleteAnnouce(companyId,selectID)
    //console.log('Del'+selectID)
    handleClose()
  }

  const editAnnouce =(id)=>{
    navigate('/annouce_edit',{state:{id:id}})
  }


  useEffect(() => {
    firestore.getAllAnnouce(companyId,getAllAnnouceSuc,getAllAnnouceUnsuc)
  }, []);

  const onNext = () => {
    setStartIndex(startIndex + 5); // Increment the start index by 5
    setEndIndex(endIndex + 5); // Increment the end index by 5
  };

  const onPrevious = () => {
    setStartIndex(Math.max(startIndex - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndIndex(Math.max(endIndex - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const NextNews = () => {
    setStartNews(startNews + 5); // Increment the start index by 5
    setEndNews(endNews + 5); // Increment the end index by 5
  };

  const PreviousNews = () => {
    setStartNews(Math.max(startNews - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndNews(Math.max(endNews - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const NextRule = () => {
    setStartRule(startRule + 5); // Increment the start index by 5
    setEndRule(endRule + 5); // Increment the end index by 5
  };

  const PreviousRule = () => {
    setStartRule(Math.max(startRule - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndRule(Math.max(endRule - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const NextGeneral = () => {
    setStartGeneral(startGeneral + 5); // Increment the start index by 5
    setEndGeneral(endGeneral + 5); // Increment the end index by 5
    console.log(startGeneral)
  };

  const PreviousGeneral = () => {
    setStartGeneral(Math.max(startGeneral - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndGeneral(Math.max(endGeneral - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const NextCampaign = () => {
    setStartCampaign(startCampaign + 5); // Increment the start index by 5
    setEndCampaign(endCampaign + 5); // Increment the end index by 5
  };

  const PreviousCampaign = () => {
    setStartCampaign(Math.max(startCampaign - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndCampaign(Math.max(endCampaign - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const NextHoliday = () => {
    setStartHoliday(startHoliday + 5); // Increment the start index by 5
    setEndHoliday(endHoliday + 5); // Increment the end index by 5
  };

  const PreviousHoliday = () => {
    setStartHoliday(Math.max(startHoliday - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndHoliday(Math.max(endHoliday - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearch(event.target.value);
    setSearchQuery(query);
    const filtered = allAnnouce.filter(annouce => annouce.title.toLowerCase().includes(query));
    const filteredNews = newsAnnouce.filter(annouce => annouce.title.toLowerCase().includes(query));
    const filteredRule = ruleAnnouce.filter(annouce => annouce.title.toLowerCase().includes(query));
    const filteredGeneral = generalAnnouce.filter(annouce => annouce.title.toLowerCase().includes(query));
    const filteredCampaign = campaignAnnouce.filter(annouce => annouce.title.toLowerCase().includes(query));
    const filteredHoliday = holidayAnnouce.filter(annouce => annouce.title.toLowerCase().includes(query));
    setFilteredAnnouces(filtered);
    setFilterNews(filteredNews);
    setFilterRule(filteredRule);
    setFilterGeneral(filteredGeneral)
    setFilterCampaign(filteredCampaign)
    setFilterHoliday(filteredHoliday)
  };

  // const [startIndex, setStartIndex] = useState(0);
  // const [endIndex, setEndIndex] = useState(5);
  // const [startNews, setStartNews] = useState(0);
  // const [endNews, setEndNews] = useState(5);
  // const [startRule, setStartRule] = useState(0);
  // const [endRule, setEndRule] = useState(5);
  // const [startGeneral, setStartGeneral] = useState(0);
  // const [endGeneral, setEndGeneral] = useState(5);
  // const [startCampaign, setStartCampaign] = useState(0);
  // const [endCampaign, setEndCampaign] = useState(5);
  // const [startHoliday, setStartHoliday] = useState(0);
  // const [endHoliday, setEndHoliday] = useState(5);
  const totalEmerItems = filteredAnnouces.length;
  const totalEmerPages = Math.ceil(totalEmerItems / itemsPerPage);
  const currentEmerPage = startIndex / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handleEmerPageChange = (event) => {
    const selectedEmerPage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedEmerPage - 1) * itemsPerPage;
    const newEndIndex = selectedEmerPage * itemsPerPage;

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  };

  const totalNewsItems = filterNews.length;
  const totalNewsPages = Math.ceil(totalNewsItems / itemsPerPage);
  const currentNewsPage = startNews / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handleNewsPageChange = (event) => {
    const selectedNewsPage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedNewsPage - 1) * itemsPerPage;
    const newEndIndex = selectedNewsPage * itemsPerPage;

    setStartNews(newStartIndex);
    setEndNews(newEndIndex);
  };

  const totalRuleItems = filterRule.length;
  const totalRulePages = Math.ceil(totalRuleItems / itemsPerPage);
  const currentRulePage = startRule / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handleRulePageChange = (event) => {
    const selectedRulePage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedRulePage - 1) * itemsPerPage;
    const newEndIndex = selectedRulePage * itemsPerPage;

    setStartRule(newStartIndex);
    setEndRule(newEndIndex);
  };

  const totalGenItems = filterGeneral.length;
  const totalGenPages = Math.ceil(totalGenItems / itemsPerPage);
  const currentGenPage = startGeneral / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handleGenPageChange = (event) => {
    const selectedGenPage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedGenPage - 1) * itemsPerPage;
    const newEndIndex = selectedGenPage * itemsPerPage;

    setStartGeneral(newStartIndex);
    setEndGeneral(newEndIndex);
  };

  const totalHolidayItems = filterHoliday.length;
  const totalHolidayPages = Math.ceil(totalHolidayItems / itemsPerPage);
  const currentHolidayPage = startHoliday / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handleHolidayPageChange = (event) => {
    const selectedHolidayPage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedHolidayPage - 1) * itemsPerPage;
    const newEndIndex = selectedHolidayPage * itemsPerPage;

    setStartHoliday(newStartIndex);
    setEndHoliday(newEndIndex);
  };



  return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        <main className="main-content">
         
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>การประกาศข่าวสาร</h1>
                {/* Add user profile and logout here */}
              </header>
            </div>
            <div className="main-contain">
            <div className="search-field">
                {/* <p style={{marginTop:17}}>ค้นหาพนักงาน</p> */}
                <input style={{width:'95%',margin:5,height:40,borderRadius:5,paddingInlineStart:10,fontSize:22,alignSelf:'center',justifyContent:'center'}}
                placeholder='search..' 
                value={search}
                onChange={handleSearch} />
                {/*<button className="search-button" ><IoSearchOutline size={24} /></button>*/}
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:10,width:'95%',alignSelf:'center'}}>
                <button className='Add-button' onClick={()=> navigate('/annouce_add')} title='เพิ่มประกาศ'><RiStickyNoteAddFill size={24} /></button>
              </div>
              <div style={{width:'95%',alignSelf:'center',justifyContent:'center'}}>
                <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5}}>ประกาศฉุกเฉิน</p>
              <div className="table-container">
              <TableBootstrap striped bordered hover className='table'>
                <thead>
                  <tr>
                    <th scope="col" style={{width:'10%'}}>ลำดับ</th>
                    <th scope="col" style={{width:'40%'}}>หัวข้อ</th>
                    <th scope="col" style={{width:'15%'}}>วันที่</th>
                    <th scope="col" style={{width:'35%'}}></th>
                  </tr>
                </thead>
                <tbody>
                {filteredAnnouces.slice(startIndex, endIndex).map((item, index) => (
                    <tr key={item.id}> 
                      <th scope="row">{startIndex + index + 1}</th>
                      {/* <th scope="row">{index + 1}</th> */}
                      <td>
                        {item.title}
                      </td>
                      <td>{item.date}</td>
                      <td style={{width:'30%',textAlign:'center'}}>
                        <button className='Edit-button' onClick={()=>editAnnouce(item.id)} title="แก้ไข"><IoPencil size={20} /></button>
                        <button className='Delete-button' onClick={()=>handleShow(item.id,item)} title="ลบ"><IoTrash size={20} /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableBootstrap>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center'}}>
                  <button className='Previous-button' onClick={onPrevious}>Previous</button>
                  <div style={{ margin: '0 10px' }}>
                    <select
                      value={currentEmerPage}
                      onChange={handleEmerPageChange}
                      style={{ fontSize: '16px', padding: '2px 8px' }}
                    >
                      {Array.from({ length: totalEmerPages }, (_, idx) => (
                        <option key={idx + 1} value={idx + 1}>
                          {idx + 1}
                        </option>
                      ))}
                    </select>
                    <span style={{ marginLeft: '5px' }}>จาก {totalEmerPages}</span>
                  </div>
                  <button className='Next-button' onClick={onNext}>Next</button>
                </div>
              <div className="form-row" style={{ display: 'flex', marginBottom: 20,marginTop:20,alignItems:'center',justifyContent:'center'}}>
                <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5}}>ข่าวสารภายใน</p>
                <div className="table-container">
                <TableBootstrap striped bordered hover className='table'>
                  <thead>
                    <tr>
                      <th scope="col" style={{width:'10%'}}>ลำดับ</th>
                      <th scope="col" style={{width:'40%'}}>หัวข้อ</th>
                      <th scope="col" style={{width:'15%'}}>วันที่</th>
                      <th scope="col" style={{width:'35%'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {filterNews.slice(startNews, endNews).map((item, index) => (
                      <tr key={item.id}> 
                        <th scope="row">{startNews + index + 1}</th>
                        {/* <th scope="row">{index + 1}</th> */}
                        <td>
                          {item.title}
                        </td>
                        <td>{item.date}</td>
                        <td style={{width:'30%',textAlign:'center'}}>
                          <button className='Edit-button' onClick={()=>editAnnouce(item.id)} title="แก้ไข"><IoPencil size={20} /></button>
                          <button className='Delete-button' onClick={()=>handleShow(item.id,item)} title="ลบ"><IoTrash size={20} /></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableBootstrap>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center',width:'100%'}}>
                  <button className='Previous-button' onClick={PreviousNews}>Previous</button>
                  <div style={{ margin: '0 10px' }}>
                    <select
                      value={currentNewsPage}
                      onChange={handleNewsPageChange}
                      style={{ fontSize: '16px', padding: '2px 8px' }}
                    >
                      {Array.from({ length: totalNewsPages }, (_, idx) => (
                        <option key={idx + 1} value={idx + 1}>
                          {idx + 1}
                        </option>
                      ))}
                    </select>
                    <span style={{ marginLeft: '5px' }}>จาก {totalNewsPages}</span>
                  </div>
                  <button className='Next-button' onClick={NextNews}>Next</button>
                </div>
              </div>
              <div className="form-row" style={{ display: 'flex', marginBottom: 20,alignItems:'center',justifyContent:'center',}}>
              <div className="form-row" style={{ display: 'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
                <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5}}>กฎระเบียบ</p>
              </div>
              <div className="table-container">
                <TableBootstrap striped bordered hover className='table'>
                  <thead>
                    <tr>
                      <th scope="col" style={{width:'10%'}}>ลำดับ</th>
                      <th scope="col" style={{width:'40%'}}>หัวข้อ</th>
                      <th scope="col" style={{width:'15%'}}>วันที่</th>
                      <th scope="col" style={{width:'35%'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {filterRule.slice(startRule, endRule).map((item, index) => (
                      <tr key={item.id}> 
                        <th scope="row">{startRule + index + 1}</th>
                        {/* <th scope="row">{index + 1}</th> */}
                        <td>
                          {item.title}
                        </td>
                        <td>{item.date}</td>
                        <td style={{width:'30%',textAlign:'center'}}>
                          <button className='Edit-button' onClick={()=>editAnnouce(item.id)} title="แก้ไข"><IoPencil size={20} /></button>
                          <button className='Delete-button' onClick={()=>handleShow(item.id,item)} title="ลบ"><IoTrash size={20} /></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableBootstrap>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center',width:'100%'}}>
                  <button className='Previous-button' onClick={PreviousRule}>Previous</button>
                  <div style={{ margin: '0 10px' }}>
                    <select
                      value={currentRulePage}
                      onChange={handleRulePageChange}
                      style={{ fontSize: '16px', padding: '2px 8px' }}
                    >
                      {Array.from({ length: totalRulePages }, (_, idx) => (
                        <option key={idx + 1} value={idx + 1}>
                          {idx + 1}
                        </option>
                      ))}
                    </select>
                    <span style={{ marginLeft: '5px' }}>จาก {totalRulePages}</span>
                  </div>
                  <button className='Next-button' onClick={NextRule}>Next</button>
                </div>
              </div>

              <div className="form-row" style={{ display: 'flex', marginBottom: 20,alignItems:'center',justifyContent:'center',}}>
              <div className="form-row" style={{ display: 'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
                <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5}}>ข่าวสารทั่วไป</p>
              </div>
              <div className="table-container">
                <TableBootstrap striped bordered hover className='table'>
                  <thead>
                    <tr>
                      <th scope="col" style={{width:'10%'}}>ลำดับ</th>
                      <th scope="col" style={{width:'40%'}}>หัวข้อ</th>
                      <th scope="col" style={{width:'15%'}}>วันที่</th>
                      <th scope="col" style={{width:'35%'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {filterGeneral.slice(startGeneral, endGeneral).map((item, index) => (
                      <tr key={item.id}> 
                        <th scope="row">{startGeneral + index + 1}</th>
                        {/* <th scope="row">{index + 1}</th> */}
                        <td>
                          {item.title}
                        </td>
                        <td>{item.date}</td>
                        <td style={{width:'30%',textAlign:'center'}}>
                          <button className='Edit-button' onClick={()=>editAnnouce(item.id)} title="แก้ไข"><IoPencil size={20} /></button>
                          <button className='Delete-button' onClick={()=>handleShow(item.id,item)} title="ลบ"><IoTrash size={20} /></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableBootstrap>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center',width:'100%'}}>
                  <button className='Previous-button' onClick={PreviousGeneral}>Previous</button>
                  <div style={{ margin: '0 10px' }}>
                    <select
                      value={currentGenPage}
                      onChange={handleGenPageChange}
                      style={{ fontSize: '16px', padding: '2px 8px' }}
                    >
                      {Array.from({ length: totalGenPages }, (_, idx) => (
                        <option key={idx + 1} value={idx + 1}>
                          {idx + 1}
                        </option>
                      ))}
                    </select>
                    <span style={{ marginLeft: '5px' }}>จาก {totalGenPages}</span>
                  </div>
                  <button className='Next-button' onClick={NextGeneral}>Next</button>
                </div>
              </div>

              {/* <div className="form-row" style={{ display: 'flex', marginBottom: 20,alignItems:'center',justifyContent:'center',}}>
              <div className="form-row" style={{ display: 'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
                <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',marginLeft:5,borderLeft: '5px solid black',borderRadius:5}}>รณรงค์ลดโลกร้อน</p>
              </div>
                <TableBootstrap striped bordered hover className='table'>
                  <thead>
                    <tr>
                      <th scope="col" style={{width:'10%'}}>ลำดับ</th>
                      <th scope="col" style={{width:'40%'}}>หัวข้อ</th>
                      <th scope="col" style={{width:'15%'}}>วันที่</th>
                      <th scope="col" style={{width:'35%'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {filterCampaign.slice(startRule, endRule).map((item, index) => (
                      <tr key={item.id}> 
                        <th scope="row">{startIndex + index + 1}</th>
                        <td>
                          {item.title}
                        </td>
                        <td>{item.date}</td>
                        <td style={{width:'30%',textAlign:'center'}}>
                          <button className='Edit-button' onClick={()=>editAnnouce(item.id)}>แก้ไข</button>
                          <button className='Delete-button' onClick={()=>handleShow(item.id)}>ลบ</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableBootstrap>
                <div style={{width:'100%'}}>
                <button className='Previous-button' onClick={PreviousCampaign}>Previous</button>
                <button className='Next-button' onClick={NextCampaign}>Next</button>
                </div>
              </div> */}

              <div className="form-row" style={{ display: 'flex', marginBottom: 20,alignItems:'center',justifyContent:'center',}}>
              <div className="form-row" style={{ display: 'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
                <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5}}>ปฏิทินวันหยุด</p>
              </div>
              <div className="table-container">
                <TableBootstrap striped bordered hover className='table'>
                  <thead>
                    <tr>
                      <th scope="col" style={{width:'10%'}}>ลำดับ</th>
                      <th scope="col" style={{width:'40%'}}>หัวข้อ</th>
                      <th scope="col" style={{width:'15%'}}>วันที่</th>
                      <th scope="col" style={{width:'35%'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {filterHoliday.slice(startHoliday, endHoliday).map((item, index) => (
                      <tr key={item.id}> 
                        <th scope="row">{startHoliday + index + 1}</th>
                        {/* <th scope="row">{index + 1}</th> */}
                        <td>
                          {item.title}
                        </td>
                        <td>{item.date}</td>
                        <td style={{width:'30%',textAlign:'center'}}>
                          <button className='Edit-button' onClick={()=>editAnnouce(item.id)} title="แก้ไข"><IoPencil size={20} /></button>
                          <button className='Delete-button' onClick={()=>handleShow(item.id,item)} title="ลบ"><IoTrash size={20} /></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableBootstrap>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center',width:'100%'}}>
                  <button className='Previous-button' onClick={PreviousHoliday}>Previous</button>
                  <div style={{ margin: '0 10px' }}>
                    <select
                      value={currentHolidayPage}
                      onChange={handleHolidayPageChange}
                      style={{ fontSize: '16px', padding: '2px 8px' }}
                    >
                      {Array.from({ length: totalHolidayPages }, (_, idx) => (
                        <option key={idx + 1} value={idx + 1}>
                          {idx + 1}
                        </option>
                      ))}
                    </select>
                    <span style={{ marginLeft: '5px' }}>จาก {totalHolidayPages}</span>
                  </div>
                  <button className='Next-button' onClick={NextHoliday}>Next</button>
                </div>
              </div>
             
              </div>

            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ลบประกาศ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>ยืนยันจะลบประกาศหรือไม่</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" style={{backgroundColor:'#D3D3D3',color:'black'}} onClick={deleteAnnouce}>
            OK
          </Button>
          <Button variant="secondary" style={{backgroundColor:'#343434'}} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        </main>
      </div>
      
    
  );
}

export default Annouce;

  