//Login.js
import './App.css';
import React, { useState,useEffect, useContext  } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
//import { auth } from './Firebase/Config';
import { useNavigate } from 'react-router-dom';
import auth from './Firebase/Auth';
import firestore from './Firebase/Firestore';
import { UserContext } from './UserContext';
import { Tune } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Icons for visibility
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [currentUser,setCurrentUser] = useState(null)
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const [isLoading, setIsLoading] = useState(false);
  const { setCurrentUser, setCompanyId, companyId, setUserData,handleLogout } = useContext(UserContext);

  const allowedTestEmails = ['admin.miscible@pam.com','sisira.w@miscible.com','hr.miscible@pam.com','admin.demo@pam.com']

  const getAccountS = (data) => {
    if (data) {
      // Save companyId to local storage to persist across refreshes
      console.log(data);
      setIsLoading(false)
      setUserData(data);
      setCurrentUser(data);
      localStorage.setItem('userData', JSON.stringify(data));
      navigate('/home'); // Only navigate if account is found
    } else {
      getAccountUn();
    }
  };

  const getAccountUn = () => {
    setIsLoading(false)
    setCurrentUser(null);
    //alert("Account: Not Found user!!");
    Swal.fire({
      // title: 'Warning',
      // text: 'Cannot delete an Administrator account.',
      title: 'ผิดพลาด',
      text: `ไม่พบ user`,
      icon: 'error',
      customClass: {
        popup: 'my-swal-size'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    });
  }

  const loginSuc = (user) => {
    if (user) {
      // const userEmail = user.email.toLowerCase();
      // if (user.emailVerified || allowedTestEmails.includes(userEmail)) {
      //   //setCurrentUser(user);
      //   firestore.getAccount(companyId, user.uid, getAccountS, getAccountUn);
      // } else {
      //   // If the email isn't verified, and it's not in the allowed list, alert the user
      //   alert("Please verify your email address before logging in.");
      //   auth.signOut();  // Sign the user out if their email isn't verified
      // }
      if (companyId) {
        // ตรวจสอบสถานะของบริษัทก่อน
        firestore.checkPlan(
          companyId,
          (planData) => {
            if (planData.status === "inactive") {
              Swal.fire({
                title: 'Trial expired',
                text: 'Your demo trial has expired. Please contact admin for further details.',
                icon: 'warning',
                customClass: { popup: 'my-swal-size' },
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' }
              }).then(() => {
                setIsLoading(false)
                handleLogout();
              });
              return;
            }
            firestore.getAccount(companyId, user.uid, getAccountS, getAccountUn);
          },
          (error) => {
            setIsLoading(false)
            console.error("Error checking plan during login:", error);
            handleLogout();
          }
        );
      } else{
        setIsLoading(false)
        Swal.fire({
          // title: 'Warning',
          // text: 'Cannot delete an Administrator account.',
          title: 'ผิดพลาด',
          text: `ไม่พบ companyId`,
          icon: 'error',
          customClass: {
            popup: 'my-swal-size'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          } 
        });
      }
      
    } else {
      setIsLoading(false)
      //alert("Login: Not Found user!!");
      Swal.fire({
        // title: 'Warning',
        // text: 'Cannot delete an Administrator account.',
        title: 'ผิดพลาด',
        text: `ไม่พบ user`,
        icon: 'error',
        customClass: {
          popup: 'my-swal-size'
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        } 
      });
    }
  }

  const loginUnsuc = (err1, err2) => {
    console.log(err1 + ": " + err2);
    setIsLoading(false)
    //alert("email or password is wrong!!");
    Swal.fire({
      // title: 'Warning',
      // text: 'Cannot delete an Administrator account.',
      title: 'แจ้งเตือน',
      text: `อีเมลหรือรหัสผ่านผิด`,
      icon: 'warning',
      customClass: {
        popup: 'my-swal-size'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    });
  }

  const onLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true)
    auth.signin(email,password,loginSuc,loginUnsuc)
  };

  // Define the forgotPassword function
  const forgotPassword = () => {
    navigate('/forgot_password')
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className="App">
      <main className="App-header">
        <div className='Main'>
          <img src={require('./icon/PAM_HR_logo.png')} className='App-logo' width={200} height={200} alt="Logo" />
          <form onSubmit={onLogin}>
          <div style={{flex:1}}>
            <p style={{color:'black',fontSize:24,marginBottom:0,textAlign:'left',marginLeft:-5}}>กรุณาใส่ User ในรูปแบบของอีเมล</p>
            <input type="email" className="input-field" placeholder="Email" onChange={(e) => setEmail(e.target.value)} autoFocus={true} required />
            <p style={{color:'black',fontSize:24,marginBottom:0,textAlign:'left',marginLeft:-5}}>กรุณาใส่ Password</p>
            <div className="password-input-wrapper" style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                className="input-field"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div
                className="visibility-toggle"
                onClick={togglePasswordVisibility}
                style={{ color: 'black' }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </div>
            </div>
            <div className="forgotPass">
              <p 
                onClick={forgotPassword} 
                style={{ marginBottom: 0, display: 'inline-block', cursor: 'pointer' }}>
                forgot password?
              </p>
            </div>
            <button type="submit" className="login-button">LOGIN</button>
          </div>
          </form>
        </div>
        <div style={{fontSize:20,lineHeight: 1,marginTop:20}}>
            <p>© 2024 Miscible Technology Co.,Ltd. - all rights reserved</p>
        </div>
      </main>
      <footer className="footer-container" style={{marginTop:0}}>
        <div className="footer-left">
          {/* ปุ่มไปหน้า Administrator */}
          {/* <button
            className="footer-link"
            onClick={() => navigate('/login_admin')}
          >
            Administrator
          </button> */}
          <span style={{ marginLeft: '8px' }}>
            © 2024 Miscible Technology Co.,Ltd. - All rights reserved.
          </span>
          <button
            className="footer-link"
            onClick={() => navigate('/')}
          >
            Website
          </button>
        </div>
        <div className="footer-right">
          {/* ปุ่มไป Terms of Service */}
          <button
            className="footer-link"
            onClick={() => navigate('/terms-of-service')}
          >
            Terms of Service
          </button>
          {/* ปุ่มไป Privacy */}
          <button
            className="footer-link"
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy
          </button>
        </div>
      </footer>
      {isLoading && (
        <LoadingBackdrop />
      )}
    </div>
  );
};

export default Login;
