import React, { useState,useRef,useEffect,useContext } from 'react';
import Sidebar from '../sidebar';
import '../Home.css';
import { useNavigate,useLocation } from 'react-router-dom';
import '../addProfile.css'
import { Alert, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import firestore from '../Firebase/Firestore';
import storage from '../Firebase/Storage';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Layout from '../Layout';
import { UserContext } from '../UserContext';


function ProfileSalary() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const location = useLocation();

  const [action,setAction] = useState('');
  const [uid,setUid] = useState('');

  const [costL,setCostL] = useState(null); //ค่าครองชีพ
  const [allOT,setAllOT] = useState(null); //ค่าล่วงเวลา
  const [allowance,setAllowance] = useState(null); //เบี้ยเลี้ยง
  const [salary,setSalary] = useState(null); //ค่าเงินเดือน
  const [venhicle,setVenhicle] = useState(null); //ค่ายานพาหนะ
  const [sub,setSub] = useState(null); //ค่าจ้าง
  const [welth,setWelth] = useState(null); //ค่าสวัสดิการ
  const [bonus,setBonus] = useState(null); //เงินโบนัส
  const [tax,setTax] = useState(null); //หักภาษี
  const [insurance,setInsurance] = useState(null); //ประกันสังคม
  const [manualInsurance,setManualInsurance] = useState(false);
  const [late,setLate] = useState(null); //เข้างานสาย
  const [missing,setMissing] = useState(null); //ขาดงาน
  const [borrow,setBorrow] = useState(null); //เงินกู้ยืม
  const [withdraw,setWithdraw] = useState(null); //เงินเบิกล่วงหน้า
  const [allDeposit,setAllDeposit] = useState(null); //รายได้สะสม
  const [allInsurance,setAllInsurance] = useState(null); //ประกันสังคมสะสม

  
  const [costPerDay,setCostPerDay] = useState(null) //ค่าแรง/วัน
  const [costPerHr,setCostPerHr] = useState(null) //ค่าแรง/ชั่วโมง
  const [lateFee,setLateFee] = useState(null) //สาย
  const [attendance,setAttendance] = useState(null) //ขาดงาน
  const [sociteIns,setSociteIns] = useState(null) // %ประกันสังคม
  const [sociteInsMax,setSociteInsMax] = useState(null)
 
  const [disableAutoIns,setDisableAutoIns] = useState(false);
  const { setCurrentUser, companyId } = useContext(UserContext);

  const sanitizeInput = (input) => {
    // ตัดอักขระที่ไม่ใช่ตัวเลขหรือจุด
    let sanitized = input.replace(/[^0-9.]/g, '');
  
    // ตรวจสอบว่ามีกี่จุด (.)
    const parts = sanitized.split('.');
    if (parts.length > 2) {
      // กรณีมีจุดมากกว่า 1 จุด (เช่น "12.3.4"),
      // ให้เก็บเฉพาะส่วน before-dot + dot + after-dot ส่วนแรก
      // เช่น ["12", "3", "4"] -> "12.3"
      sanitized = parts[0] + '.' + parts[1];
    }
  
    return sanitized;
  };
  
  // 2) ฟังก์ชัน removeLeadingZeros
  const removeLeadingZeros = (input) => {
    if (input === '' || input === '.') {
      // กรณีพิมพ์แค่ '.' ก็ยังอนุญาตค้างไว้ได้
      return input;
    }
    // ถ้า input เป็น '0.???' ให้เก็บไว้เป็นรูปทศนิยม เช่น '0.12' => ไม่ต้องลบ
    if (input.startsWith('0.') ) {
      return input;
    }
    // กรณีเริ่มด้วย '0' แต่มีมากกว่า 1 หลัก เช่น '07', '0005'
    // parseFloat('0005') => 5 -> แปลงเป็น string '5'
    // parseFloat('000.12') => 0.12 -> '0.12'
    if (input.startsWith('0') && input.length > 1) {
      return parseFloat(input).toString();
    }
  
    return input;
  };
  
  const validateNumberInput = (input) => {
    // ขั้นตอนที่ 1 : remove non-numeric + จำกัดจุดได้แค่จุดเดียว
    let sanitized = sanitizeInput(input);
  
    // ขั้นตอนที่ 2 : remove leading zeros
    let noLeadingZeros = removeLeadingZeros(sanitized);
  
    return noLeadingZeros;
  };

  const updateSuccess = () => {
    alert('Save data success!!')
    navigate('/salary_list',{state:{uid:uid}})
  };

  const updateUnsuccess = (error) => {
    console.log(error);
  };

  const onSave= async()=>{

    let item ={
      costL: parseFloat((costL || "0").replace(/,/g, "")) || 0,
      allOT: parseFloat((allOT || "0").replace(/,/g, "")) || 0,
      allowance: parseFloat((allowance || "0").replace(/,/g, "")) || 0,
      salary: parseFloat((salary || "0").replace(/,/g, "")) || 0,
      venhicle: parseFloat((venhicle || "0").replace(/,/g, "")) || 0,
      sub: parseFloat((sub || "0").replace(/,/g, "")) || 0,
      welth: parseFloat((welth || "0").replace(/,/g, "")) || 0,
      bonus: parseFloat((bonus || "0").replace(/,/g, "")) || 0,
      allDeposit: parseFloat((allDeposit || "0").replace(/,/g, "")) || 0,
      tax: parseFloat((tax || "0").replace(/,/g, "")) || 0,
      insurance: parseFloat((insurance || "0").replace(/,/g, "")) || 0,
      late: parseFloat((late || "0").replace(/,/g, "")) || 0,
      missing: parseFloat((missing || "0").replace(/,/g, "")) || 0,
      borrow: parseFloat((borrow || "0").replace(/,/g, "")) || 0,
      withdraw: parseFloat((withdraw || "0").replace(/,/g, "")) || 0,
      allInsurance: parseFloat((allInsurance || "0").replace(/,/g, "")) || 0,
    }
    if(action=='edit'){
        firestore.updateUser(companyId, uid, item, updateSuccess, updateUnsuccess);
    }
    else{
      firestore.updateUser(companyId, uid, item, updateSuccess, updateUnsuccess);
    }
  }
    

  const getUserSuccess = (data) => {
    setSalary(data.salary.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setSub(data.sub.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setAllOT(data.allOT.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setAllowance(data.allowance.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setVenhicle(data.venhicle.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setWelth(data.welth.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setBonus(data.bonus.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setCostL(data.costL.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    if(data.insurance){
      setDisableAutoIns(true)
      setInsurance(data.insurance.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
    // setInsurance(data.insurance.toLocaleString('th-TH', {
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2
    // }));
    setLate(data.late.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setWithdraw(data.withdraw.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setBorrow(data.borrow.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setMissing(data.missing.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setTax(data.tax.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setAllDeposit(data.allDeposit.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setAllInsurance(data.allInsurance.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
  };

  const getUserUnsuccess = (e) => {
    console.log('f edit' + e);
  };

  useEffect(() => {
    if (location.state) {
        setAction(location.state.action)
        if(location.state.action=='edit'){
            setUid(location.state.uid)
            firestore.getUser(companyId,location.state.uid, getUserSuccess, getUserUnsuccess);
        }else{
          setUid(location.state.uid)
          firestore.getUser(companyId,location.state.uid, getUserSuccess, getUserUnsuccess)
          console.log(uid)
        }
      } else {
        console.warn('No ID found in location state');
      }
      const onSuccess = (data) => {
        if (data) {
          setSociteIns(data?.sociteIns || 0);
          setSociteInsMax(
            data.sociteInsMax
              ? parseFloat(data.sociteInsMax).toLocaleString('th-TH', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              : "0.00"
          );
        } else {
          setSociteIns(0);
        }
      };
      const onError = (error) => {
        console.error(error);
        alert('Error loading data: ' + error.message);
      };
      firestore.getSalarySetting(companyId, onSuccess, onError);
  }, [location.state]);

  useEffect(() => {
    if (!manualInsurance && !disableAutoIns) {
      const salaryNum = parseFloat((salary || "0").replace(/,/g, ''));
      const subNum = parseFloat((sub || "0").replace(/,/g, ''));
      const base = salaryNum + subNum;
  
      const rate = parseFloat(String(sociteIns || "0").replace(/,/g, ''));
      const max = parseFloat(String(sociteInsMax || "0").replace(/,/g, ''));
  
      if (base > 0 && rate > 0) {
        let ins = base * rate; // สูตรคูณ
        if (ins > max && max > 0) {
          ins = max; // ถ้าเกินเพดาน
        }
        setInsurance(ins.toLocaleString('th-TH', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      } else {
        // กรณี base <= 0 หรือ rate <= 0 ถือว่า = 0
        setInsurance('0.00');
      }
    }
  }, [insurance,salary,sociteIns,sociteInsMax,sub]);

  const handleIns = (e) => {
    const val = validateNumberInput(e.target.value);
    setInsurance(val !== '' ? val : '0');
    setManualInsurance(true);
  };

  const formatCurrency = (value) => {
    //return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(value || 0);
    return new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value || 0);
  };

  const handleBlur2Decimals=(value, setFn)=> {
    if (value === '' || value === '.') {
      setFn('0.00');
      return;
    }
    const numeric = parseFloat(value);
    if (!isNaN(numeric)) {
      // setFn(formatCurrency(numeric.toFixed(2)));
      setFn(numeric.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    } else {
      setFn('0.00');
    }
    //setManualWorkTimeCost(false);
  }

  return (
    
      <div className="dashboard">
        <Layout />
        
        <main className="main-content">
          
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>ข้อมูลเงินเดือนพนักงาน</h1>
                {/* Add user profile and logout here */}
              </header>
            </div>
            <div className="main-contain">
              
              <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%',marginTop:30}}>
              <div className="form-row" style={{ display: 'flex'}}>
                <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>เงินเดือน :</p>
              </div>
              <div className="form-row" style={{ display: 'flex',  marginBottom: '20px' }}>
              <TextField
                    label="เงินเดือน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={salary}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setSalary(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setSalary((salary || "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(salary, setSalary)}
                  />
                  <TextField
                    label="ค่าจ้าง"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={sub}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setSub(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setSub((sub|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(sub, setSub)}
                  />
                  <TextField
                    
                    label="ค่าล่วงเวลา"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={allOT}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setAllOT(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setAllOT((allOT|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(allOT, setAllOT)}
                  />
                  <TextField
                    label="ค่าเบี้ยเลี้ยง"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={allowance}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setAllowance(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setAllowance((allowance|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(allowance, setAllowance)}
                  >
                  </TextField>
                  <TextField
                    label="ค่าพาหนะ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={venhicle}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setVenhicle(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setVenhicle((venhicle|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(venhicle, setVenhicle)}
                  />
              </div>
              <div className="form-row" style={{ display: 'flex',  marginBottom: '20px' }}>
              <TextField
                    label="ค่าสวัสดิการ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={welth}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setWelth(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setWelth((welth|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(welth, setWelth)}
                  />
                   <TextField
                    label="เงินโบนัส"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={bonus}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setBonus(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setBonus((bonus|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(bonus, setBonus)}
                      />
                  <TextField
                    label="เงินพิเศษ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={costL}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setCostL(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setCostL((costL|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(costL, setCostL)}
                  />
                  <TextField
                    label="รายได้สะสม"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={allDeposit}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setAllDeposit(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setAllDeposit((allDeposit|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(allDeposit, setAllDeposit)}
                  />
              </div>
              <div className="form-row" style={{ display: 'flex' }}>
                <p style={{fontSize:28,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>รายการหัก :</p>
              </div>
              <div className="form-row" style={{ display: 'flex',  marginBottom: '20px' }}>
              <TextField
                    label="เงินประกันสังคม"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={insurance}
                    onChange={handleIns}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setInsurance((insurance|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(insurance, setInsurance)}
                  />
                  <TextField
                    label="เงินหัก ณ ที่จ่าย"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={late}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setLate(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setLate((late|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(late, setLate)}
                  />
                  <TextField
                    
                    label="เงินเบิกล่วงหน้า"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={withdraw}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setWithdraw(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setWithdraw((withdraw|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(withdraw, setWithdraw)}
                  />
                  <TextField
                    label="เงินกู้ยืมสวัสดิการ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={borrow}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setBorrow(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setBorrow((borrow|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(borrow, setBorrow)}
                  >
                  </TextField>
                  <TextField
                    label="ขาดงาน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={missing}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setMissing(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setMissing((missing|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(missing, setMissing)}
                  />
              </div>
              <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}} >
                  <TextField
                    label="หักภาษีเงินได้"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={tax}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setTax(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setTax((tax|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(tax, setTax)}
                  />
                  <TextField
                    label="เงินประกันสังคมสะสม"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    value={allInsurance}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setAllInsurance(val !== '' ? val : 0);
                    }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setAllInsurance((allInsurance|| "").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(allInsurance, setAllInsurance)}
                  />
                </div>
            
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#D3D3D3',marginRight:10}} onClick={onSave}>บันทึกข้อมูล</button>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF',marginRight:10}} onClick={()=>{navigate('/salary_list',{state:{uid:uid}})}}>ย้อนกลับ</button>
              </div>

            </div>
          </div>
        </main>
      </div>
      
    
  );
}

export default ProfileSalary;

  