//TermsOfService.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import logo from './icon/PAM_HR_logo.png';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';

const TermsOfService = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0); // State to track the current slide
  const [activeButton, setActiveButton] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const customScrollBarStyles = `
  .scroll-container {
    max-height: 70vh;     /* ปรับได้ตามต้องการ */
    overflow-y: auto;
    position: relative;

    /* Firefox จะใช้ scrollbar-width + scrollbar-color */
    scrollbar-width: none;     /* ตั้งค่าเริ่มต้นให้ “none” คือซ่อน */
    scrollbar-color: #888 #f1f1f1;
    -ms-overflow-style: none;  /* IE & old Edge ซ่อน scrollbar */
  }
  
  /* Default (ไม่มี show-scrollbar) => ซ่อน scrollbar (Chrome, Safari, Opera) */
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar {
    width: 0;
  }
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar-track {
    background: transparent;
  }
  .scroll-container:not(.show-scrollbar)::-webkit-scrollbar-thumb {
    background: transparent;
  }

  /* เมื่อมี .show-scrollbar => แสดง scrollbar */
  .scroll-container.show-scrollbar {
    scrollbar-width: thin;  /* Firefox: เปลี่ยนเป็นแบบบาง */
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar {
    width: 6px;             /* ความกว้าง scrollbar สำหรับ Chrome/Safari/Opera */
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  .scroll-container.show-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  `;

  useEffect(() => {
    let hideTimer;
    const scrollContainer = document.querySelector('.scroll-container');
    if (!scrollContainer) return;

    const handleScroll = () => {
      // เพิ่มคลาส show-scrollbar เมื่อเริ่ม scroll
      scrollContainer.classList.add('show-scrollbar');

      // ถ้ามี timer เดิมอยู่ ให้ยกเลิกก่อน
      if (hideTimer) clearTimeout(hideTimer);

      // ตั้งเวลาไว้ 2 วินาที (2000 ms) หากไม่เลื่อนต่อ => ซ่อน scrollbar
      hideTimer = setTimeout(() => {
        scrollContainer.classList.remove('show-scrollbar');
      }, 2000);
    };

    // ผูก event scroll
    scrollContainer.addEventListener('scroll', handleScroll);

    // ลบ event เมื่อ component ถูก unmount
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      if (hideTimer) clearTimeout(hideTimer);
    };
  }, []);

  return (
    <div className="service-container">
       <nav className="navbar">
        <div className="navbar-left">
          <div className="navbarlogo-container"  style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}>
            <img src={logo} alt="PAM Logo" className="navbarlogo" />
          </div>
          {/* <button
            className={`nav-button plan-button ${activeButton === 'plan' ? '' : ''}`}
            onClick={scrollToPlan}
          >
            Plans
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
            onClick={toGetApp}
          >
            Applications
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
            onClick={toAdmin}
          >
            For Admin
          </button>*/}
        </div>
        <div className="button-container">
          <button
            className="nav-button"
            style={{ backgroundColor: '#D9D9D9' }}
            onClick={() => navigate('/login_company')}
          >
            Login
          </button>
          <button
            className="nav-button"
            style={{ backgroundColor: '#343434', color: '#FFF' }}
            onClick={()=> navigate('/register')}
          >
            Register
          </button> 
        </div>
        <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className="navbarlogo-container" style={{width:'100%',justifyContent:'space-between'}} >
            <img src={logo} alt="PAM Logo" className="navbarlogo" onClick={() => navigate('/')} />
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
        {isMenuOpen && (
          <div className="mobile-menu">
            <button
              className="nav-button"
              style={{ backgroundColor: '#D9D9D9',marginBottom:5 }}
              onClick={() => navigate('/login_company')}
            >
              Login
            </button>
            <button
              className="nav-button"
              style={{ backgroundColor: '#343434', color: '#FFF' }}
              onClick={()=> navigate('/register')}
            >
              Register
            </button>
          </div>
        )}
      </nav>
      <style>{customScrollBarStyles}</style>
      <div className="scroll-container" style={{width:'95%',backgroundColor:'#FFFFFF',  maxHeight: '100vh',overflowY: 'auto',marginTop:40,alignSelf:'center',borderRadius:20}}>
        <div style={{margin:20}}>
            <h1 style={{textAlign:'center'}}>ข้อกำหนดและเงื่อนไข (Terms & Conditions)</h1>
            <ol style={{ fontSize: 20, lineHeight: 1.6 }}>
            <li>
              <h2>ข้อจำกัดในการใช้งาน (Restrictions)</h2>
              <p>
                ห้ามมิให้ผู้ซื้อทำการคัดลอก แก้ไข หรือแจกจ่ายซอฟต์แวร์นี้โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจากผู้ขาย
              </p>
              <p style={{ marginTop: 0 }}>
                The purchaser is prohibited from copying, modifying, or
                distributing the software without explicit written permission
                from the seller.
              </p>
            </li>
            <li>
              <h2>ทรัพย์สินทางปัญญา (Intellectual Property)</h2>
              <p>
                ซอฟต์แวร์นี้และทรัพย์สินทางปัญญาทั้งหมดที่เกี่ยวข้องเป็นของผู้ขายและได้รับการคุ้มครองตามกฎหมายทรัพย์สินทางปัญญา
              </p>
              <p style={{ marginTop: 0 }}>
                This software and all related intellectual property rights are
                owned by the seller and are protected under applicable
                intellectual property laws.
              </p>
            </li>
            <li>
              <h2>เงื่อนไขการชำระเงิน (Payment Terms)</h2>
              <p>
                ผู้ซื้อจะต้องชำระเงินค่าซอฟต์แวร์ทั้งหมดภายใน 30 วันนับจากวันที่ได้รับใบแจ้งหนี้
              </p>
              <p style={{ marginTop: 0 }}>
                The purchaser must pay the full software fee within 30 days from
                the date of the invoice receipt.
              </p>
            </li>
            <li>
              <h2>ระยะเวลาและการสิ้นสุด (Term and Termination)</h2>
              <p>
                สิทธิ์การใช้งานจะมีผลตั้งแต่วันที่ซื้อจนถึงวันที่สิ้นสุดการใช้งานตามข้อตกลง หากผู้ซื้อไม่ปฏิบัติตามเงื่อนไขใดๆ
                ผู้ขายมีสิทธิ์ยกเลิกสัญญาได้ทันที
              </p>
              <p style={{ marginTop: 0 }}>
                The license is effective from the purchase date until the
                termination of use as per the agreement. If the purchaser fails
                to comply with any terms, the seller reserves the right to
                terminate the contract immediately.
              </p>
            </li>
            <li>
              <h2>การสนับสนุนและการบำรุงรักษา (Support and Maintenance)</h2>
              <p>
                ผู้ขายจะให้การสนับสนุนทางเทคนิคผ่านทางอีเมลและโทรศัพท์ในช่วงเวลาทำการ
                และจะจัดให้มีการอัปเดตซอฟต์แวร์เป็นประจำเพื่อปรับปรุงประสิทธิภาพและแก้ไขข้อผิดพลาด
              </p>
              <p style={{ marginTop: 0 }}>
                The seller will provide technical support via email and phone
                during business hours and will regularly update the software to
                improve performance and fix bugs.
              </p>
            </li>
            <li>
              <h2>การรับประกันและข้อจำกัดความรับผิด (Warranty and Limitation of Liability)</h2>
              <p>
                ผู้ขายรับประกันว่าซอฟต์แวร์จะทำงานตามที่ระบุไว้ในเอกสารแนบ
                หากมีข้อบกพร่อง ผู้ขายจะทำการแก้ไขโดยไม่มีค่าใช้จ่ายเพิ่มเติม
                แต่ผู้ขายไม่รับผิดชอบต่อความเสียหายที่เกิดจากการใช้งานซอฟต์แวร์ในทางที่ไม่ถูกต้อง
              </p>
              <p style={{ marginTop: 0 }}>
                The seller warrants that the software will perform as specified
                in the accompanying documentation. In case of defects, the
                seller will rectify them at no additional cost. However, the
                seller is not liable for damages arising from improper use of the
                software.
              </p>
            </li>
            <li>
              <h2>การชดเชย (Indemnification)</h2>
              <p>
                ผู้ซื้อจะต้องชดเชยและคุ้มครองผู้ขายจากความเสียหายทุกประการที่เกิดขึ้นจากการใช้งานซอฟต์แวร์ที่ไม่ถูกต้องหรือไม่เป็นไปตามเงื่อนไขที่กำหนด
              </p>
              <p style={{ marginTop: 0 }}>
                The purchaser agrees to indemnify and hold the seller harmless
                from all damages arising from improper use or violation of the
                terms stipulated in this agreement.
              </p>
            </li>
            <li>
              <h2>กฎหมายที่ใช้บังคับและเขตอำนาจศาล (Governing Law and Jurisdiction)</h2>
              <p>
                สัญญานี้จะอยู่ภายใต้กฎหมายของราชอาณาจักรไทย
                และศาลที่มีเขตอำนาจในการพิจารณาคดีจะอยู่ที่กรุงเทพมหานคร
              </p>
              <p style={{ marginTop: 0 }}>
                This agreement shall be governed by the laws of the Kingdom of
                Thailand, and the courts located in Bangkok shall have exclusive
                jurisdiction to adjudicate any disputes arising from this
                agreement.
              </p>
            </li>
            <li>
              <h2>นโยบายความเป็นส่วนตัว (Privacy Policy)</h2>
              <p>
                ผู้ขายจะเก็บรวบรวมและใช้งานข้อมูลส่วนบุคคลของผู้ซื้อตามนโยบายความเป็นส่วนตัวที่แนบมาพร้อมกับเอกสารนี้
              </p>
              <p style={{ marginTop: 0 }}>
                The seller will collect and use the purchaser's personal data in
                accordance with the Privacy Policy attached to this document.
              </p>
            </li>
            <li>
              <h2>การแก้ไขและการเปลี่ยนแปลง (Amendments and Modifications)</h2>
              <p>
                การแก้ไขหรือเปลี่ยนแปลงข้อกำหนดและเงื่อนไขใดๆ
                จะต้องทำเป็นลายลักษณ์อักษรและได้รับการยินยอมจากทั้งผู้ขายและผู้ซื้อ
              </p>
              <p style={{ marginTop: 0 }}>
                Any amendments or modifications to these terms and conditions
                must be made in writing and agreed upon by both the seller and
                the purchaser.
              </p>
            </li>
          </ol>
        </div>

      </div>
      
      <footer className="footer-container">
        <div className="footer-left">
          {/* ปุ่มไปหน้า Administrator */}
          <button
            className="footer-link"
            //onClick={() => navigate('/login_admin')}
          >
            Administrator
          </button>
          <span style={{ marginLeft: '8px' }}>
            © 2024 Miscible Technology Co.,Ltd. - All rights reserved.
          </span>
        </div>
        <div className="footer-right">
          {/* ปุ่มไป Terms of Service */}
          <button
            className="footer-link"
            // onClick={() => navigate('/terms-of-service')}
          >
            Terms of Service
          </button>
          {/* ปุ่มไป Privacy */}
          <button
            className="footer-link"
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy
          </button>
        </div>
      </footer>
    </div>
  );
};

export default TermsOfService;
