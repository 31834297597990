//AnnouceExtend.js
import React,{useContext} from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useNavigate } from 'react-router-dom';
import '../Home.css';
import Sidebar from '../sidebar';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { useEffect, useState } from 'react';
import firestore from '../Firebase/Firestore';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoSearchOutline, IoPencil, IoTrash, IoPerson, IoBarChart, IoNotifications } from "react-icons/io5";
import { RiStickyNoteAddFill } from "react-icons/ri";
import Layout from '../Layout';
import { UserContext } from '../UserContext';

function AnnouceExtend() {
  const navigate = useNavigate();
  const [title,setTitle] = useState('');
  const [date,setDate] = useState('');
  const [selectID,setSelectID] = useState('');
  const [allHealth,setAllHealth] = useState([]);
  const [allClimate,setAllClimate] = useState([]);
  const [show, setShow] = useState(false);
  const [filteredHealth, setFilteredHealth] = useState([]);
  const [filteredClimate, setFilteredClimate] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [startClimate, setStartClimate] = useState(0);
  const [endClimate, setEndClimate] = useState(5);
  const itemsPerPage = 5;
  const [newsAnnouce,setNewsAnnouce] = useState([]);
  const [filterNews,setFilterNews] = useState([]);
  const { setCurrentUser, companyId } = useContext(UserContext);

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const getAllAnnouceSuc=(doc)=>{
    
    let annouces = []
    let relaxs = []
    let healths = []
    let climates = []
    
      
    doc.forEach((item) => {
      //console.log(item.title+":"+item.type)
      if(item.type == 1){
        relaxs.push({id: item.id,title:item.title,date:item.date,type:item.type});
      }
      else if(item.type == 2){
        healths.push({id: item.id,title:item.title,date:item.date,type:item.type});
      }
      else if(item.type == 3){
        climates.push({id: item.id,title:item.title,date:item.date,type:item.type});
      }
      
    });
    healths.sort((a, b) => parseDate(b.date) - parseDate(a.date));
    climates.sort((a, b) => parseDate(b.date) - parseDate(a.date));
    setAllHealth(healths);
    setAllClimate(climates)
    
    setFilteredHealth(healths);
    setFilteredClimate(climates)
      
    
  }

  const getAllAnnouceUnsuc=(error)=>{
    console.log("getAnnouce: "+error)
  }

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setSelectID(id)
    setShow(true);
  }

  const deleteAnnouce =()=>{
    firestore.deleteAnnouceHome2(companyId,selectID)
    //console.log('Del'+selectID)
    handleClose()
  }

  const editAnnouce =(id)=>{
    console.log(id)
    navigate('/2_annouce_extend/edit',{state:{id:id}})
  }

  const onRelax=()=>{
    navigate('/2_annouce_extend/add', { state: { sender: 'relax' } })
  }


  useEffect(() => {
    firestore.getAllAnnouceHome2(companyId,getAllAnnouceSuc,getAllAnnouceUnsuc)
  }, []);

  const onNext = () => {
    setStartIndex(startIndex + 5); // Increment the start index by 5
    setEndIndex(endIndex + 5); // Increment the end index by 5
  };

  const onPrevious = () => {
    setStartIndex(Math.max(startIndex - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndIndex(Math.max(endIndex - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const onNextClimate = () => {
    setStartClimate(startClimate + 5); // Increment the start index by 5
    setEndClimate(endClimate + 5); // Increment the end index by 5
  };

  const onPreviousClimate = () => {
    setStartClimate(Math.max(startClimate - 5, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndClimate(Math.max(endClimate - 5, 5)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };


  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearch(event.target.value);
    setSearchQuery(query);
    const filteredHealth = allHealth.filter(annouce => annouce.title.toLowerCase().includes(query));
    const filteredClimate = allClimate.filter(annouce => annouce.title.toLowerCase().includes(query));
    setFilteredHealth(filteredHealth);
    setFilteredClimate(filteredClimate);
  };

  const totalHealthItems = filteredHealth.length;
  const totalHealthPages = Math.ceil(totalHealthItems / itemsPerPage);
  const currentHealthPage = startIndex / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handleHealthPageChange = (event) => {
    const selectedHealthPage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedHealthPage - 1) * itemsPerPage;
    const newEndIndex = selectedHealthPage * itemsPerPage;

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  };

  const totalClimateItems = filteredClimate.length;
  const totalClimatePages = Math.ceil(totalClimateItems / itemsPerPage);
  const currentClimatePage = startClimate / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handleClimatePageChange = (event) => {
    const selectedClimatePage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedClimatePage - 1) * itemsPerPage;
    const newEndIndex = selectedClimatePage * itemsPerPage;

    setStartClimate(newStartIndex);
    setEndClimate(newEndIndex);
  };


  return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        <main className="main-content">
         
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>การประกาศ</h1>
                {/* Add user profile and logout here */}
              </header>
            </div>
            <div className="main-contain">
            <div className="search-field">
                {/* <p style={{marginTop:17}}>ค้นหาพนักงาน</p> */}
                <input style={{width:'95%',margin:5,height:40,borderRadius:5,paddingInlineStart:10,fontSize:22,alignSelf:'center',justifyContent:'center'}}
                placeholder='search..' 
                value={search}
                onChange={handleSearch} />
                {/*<button className="search-button" ><IoSearchOutline size={24} /></button>*/}
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginTop:10,width:'95%',alignSelf:'center'}}>
                <button className='Add-button' onClick={()=> navigate('/2_annouce_extend/add', { state: { sender: 'other' } })}title='เพิ่มประกาศ'><RiStickyNoteAddFill size={24} /></button>
              </div>
              <div style={{width:'95%',alignSelf:'center',justifyContent:'center',alignItems:'center'}}>
              <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                          alignSelf:'center',borderLeft: '5px solid black',borderRadius:5}}>Relaxation</p>
                          <button className='Previous-button' onClick={onRelax}>จัดการ</button>
              <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                          alignSelf:'center',borderLeft: '5px solid black',borderRadius:5}}>Health News</p>
              <div className="table-container">
              <TableBootstrap striped bordered hover className='table'>
                <thead>
                  <tr>
                    <th scope="col" style={{width:'10%'}}>ลำดับ</th>
                    <th scope="col" style={{width:'40%'}}>หัวข้อ</th>
                    <th scope="col" style={{width:'15%'}}>วันที่</th>
                    <th scope="col" style={{width:'35%'}}></th>
                  </tr>
                </thead>
                <tbody>
                {filteredHealth.slice(startIndex, endIndex).map((item, index) => (
                    <tr key={item.id}> 
                      <th scope="row">{startIndex + index + 1}</th>
                      {/* <th scope="row">{index + 1}</th> */}
                      <td>
                        {item.title}
                      </td>
                      <td>{item.date}</td>
                      <td style={{width:'30%',textAlign:'center'}}>
                        <button className='Edit-button' onClick={()=>editAnnouce(item.id)} title="แก้ไข"><IoPencil size={20} /></button>
                        <button className='Delete-button' onClick={()=>handleShow(item.id)} title="ลบ"><IoTrash size={20} /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableBootstrap>
              </div>
              <div style={{width:'100%',display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center'}}>
                  <button className='Previous-button' onClick={onPrevious}>Previous</button>
                  <div style={{ margin: '0 10px' }}>
                    <select
                      value={currentHealthPage}
                      onChange={handleHealthPageChange}
                      style={{ fontSize: '16px', padding: '2px 8px' }}
                    >
                      {Array.from({ length: totalHealthPages }, (_, idx) => (
                        <option key={idx + 1} value={idx + 1}>
                          {idx + 1}
                        </option>
                      ))}
                    </select>
                    <span style={{ marginLeft: '5px' }}>จาก {totalHealthPages}</span>
                  </div>
                  <button className='Next-button' onClick={onNext}>Next</button>
              </div>

              <div className="form-row" style={{ display: 'flex', marginBottom: 20,alignItems:'center',justifyContent:'center',marginTop:20}}>      
                <p style={{fontSize:28,textAlign:'center',backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5}}>Climate Content</p>
                <div className="table-container">
                <TableBootstrap striped bordered hover className='table'>
                  <thead>
                    <tr>
                      <th scope="col" style={{width:'10%'}}>ลำดับ</th>
                      <th scope="col" style={{width:'40%'}}>หัวข้อ</th>
                      <th scope="col" style={{width:'15%'}}>วันที่</th>
                      <th scope="col" style={{width:'35%'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                  {filteredClimate.slice(startClimate, endClimate).map((item, index) => (
                      <tr key={item.id}> 
                        <th scope="row">{startClimate + index + 1}</th>
                        <td>
                          {item.title}
                        </td>
                        <td>{item.date}</td>
                        <td style={{width:'30%',textAlign:'center'}}>
                          <button className='Edit-button' onClick={()=>editAnnouce(item.id)} title="แก้ไข"><IoPencil size={20} /></button>
                          <button className='Delete-button' onClick={()=>handleShow(item.id)} title="ลบ"><IoTrash size={20} /></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableBootstrap>
                </div>
                <div style={{width:'100%',display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center'}}>
                <button className='Previous-button' onClick={onPreviousClimate}>Previous</button>
                <div style={{ margin: '0 10px' }}>
                  <select
                    value={currentClimatePage}
                    onChange={handleClimatePageChange}
                    style={{ fontSize: '16px', padding: '2px 8px' }}
                  >
                    {Array.from({ length: totalClimatePages }, (_, idx) => (
                      <option key={idx + 1} value={idx + 1}>
                        {idx + 1}
                      </option>
                    ))}
                  </select>
                  <span style={{ marginLeft: '5px' }}>จาก {totalClimatePages}</span>
                </div>
                <button className='Next-button' onClick={onNextClimate}>Next</button>
                </div>
              </div>
             
              </div>

            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ลบประกาศ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>ยืนยันจะลบประกาศหรือไม่</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" style={{backgroundColor:'#D3D3D3',color:'black'}} onClick={deleteAnnouce}>
            OK
          </Button>
          <Button variant="secondary" style={{backgroundColor:'#343434'}} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        </main>
      </div>
      
    
  );
}

export default AnnouceExtend;

  