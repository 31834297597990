import React, { useState,useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Navigate } from 'react-router-dom';
import Sidebar from './sidebar';
import './Home.css';
import { useNavigate,useLocation } from 'react-router-dom';
import './addProfile.css'
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import firestore from './Firebase/Firestore';
import Layout from './Layout';
import { UserContext } from './UserContext';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { AiOutlineEdit } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';

function WelthfareManage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [uid,setUid] = useState('')
  const [name,setName] = useState('');
  const [absence, setAbsence] = useState(0);    // วันลากิจที่ "มีสิทธิ์" ทั้งหมด
  const [sick, setSick] = useState(0);          // วันลาป่วยทั้งหมด
  const [holiday, setHoliday] = useState(0);    // วันลาพักร้อนทั้งหมด
  const [maternity, setMaternity] = useState(0);// วันลาคลอดทั้งหมด
  const [kama, setKama] = useState(0);          // วันลาบวชทั้งหมด.
 // const [rip,setRIP] = useState(0);             // วันลางานศพ
  const [other, setOther] = useState(0);        // วันลาด้วยสิทธิ์อื่น ๆ

  const [absenceUsed, setAbsenceUsed] = useState(0);  // วันลากิจที่ใช้
  const [sickUsed, setSickUsed] = useState(0);        
  const [holidayUsed, setHolidayUsed] = useState(0);
  const [maternityUsed, setMaternityUsed] = useState(0);
  const [kamaUsed, setKamaUsed] = useState(0);
  const [otherUsed, setOtherUsed] = useState(0);

  const [absenceR, setAbsenceR] = useState(0);  // วันลากิจ "คงเหลือ"
  const [sickR, setSickR] = useState(0);        
  const [holidayR, setHolidayR] = useState(0);
  const [maternityR, setMaternityR] = useState(0);
  const [kamaR, setKamaR] = useState(0);
  const [ripR,setRIPR] = useState(0);
  const [otherR, setOtherR] = useState(0);
  
  const [attendence, setAttendence] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const { setCurrentUser, companyId } = useContext(UserContext);
  const [editingField, setEditingField] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    { type: 'ลากิจ', total: 0, used: 0, remaining: 0 },
    { type: 'ลาป่วย', total: 0, used: 0, remaining: 0 },
    { type: 'ลาพักร้อน', total: 0, used: 0, remaining: 0 },
    { type: 'ลาคลอด', total: 0, used: 0, remaining: 0 },
    { type: 'ลาบวช', total: 0, used: 0, remaining: 0 },
    { type: 'ลาด้วยสิทธิ์อื่นๆ', total: 0, used: 0, remaining: 0 },
    { type: 'ขาดงาน', total: 0, used: 0, remaining: 0 },
  ]);


  const handleNumberChange = (e, setter) => {
    const newValue = parseFloat(e.target.value);
    // ถ้า newValue เป็น NaN แสดงว่าผู้ใช้ลบจนเป็นค่าว่างหรือพิมพ์อักขระผิด
    setter(isNaN(newValue) ? 0 : newValue);
  };

  const getUserSuccess=(data)=>{
    setName(data.name+" "+data.lastname)
  }

  const getUserUnsuccess=(e)=>{
    console.log(e)
  }

  const addWelthSuc=()=>{
    setIsLoading(false)
    Swal.fire({
      title: 'เรียบร้อย',
      text: `บันทึกข้อมูลสำเร็จ`,
      icon: 'success',
      customClass: {
        popup: 'my-swal-size'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then(()=>{
      navigate('/welthfare', { state: { startIndex, endIndex } })
    })
  }

  const addWelthUnsuc=()=>{
    setIsLoading(false)
    Swal.fire({
      title: 'ผิดพลาด',
      text: `บันทึกข้อมูลไม่สำเร็จ`,
      icon: 'error',
      customClass: {
        popup: 'my-swal-size'
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }

  const getWelSuc=(data)=>{
    let item = data
    setAbsence(Number(item.absence) || 0)
    setAbsenceR(Number(item.absenceR) || 0)
    setAbsenceUsed(Number(item.absenceUsed) || 0)
    setSick(Number(item.sick) || 0)
    setSickR(Number(item.sickR) || 0)
    setSickUsed(Number(item.sickUsed) || 0)
    setHoliday(Number(item.holiday) || 0)
    setHolidayR(Number(item.holidayR) || 0)
    setHolidayUsed(Number(item.holidayUsed) || 0)
    setMaternity(Number(item.maternity) || 0)
    setMaternityR(Number(item.maternityR) || 0)
    setMaternityUsed(Number(item.maternityUsed) || 0)
    setKama(Number(item.kama) || 0)
    setKamaR(Number(item.kamaR) || 0)
    setKamaUsed(Number(item.kamaUsed) || 0)
    setOther(Number(item.other) || 0)
    setOtherR(Number(item.otherR) || 0)
    setOtherUsed(Number(item.otherUsed) || 0)
    setAttendence(Number(item.attendence) || 0)
  }
  
  const getWelunsuc=(err)=>{
    console.log(err)
  }


  const onSave=()=>{
    setIsLoading(true)
    let item = {
      name,
      absence: parseFloat((absence || 0).toFixed(2)),
      sick: parseFloat((sick || 0).toFixed(2)),
      holiday: parseFloat((holiday || 0).toFixed(2)),
      maternity: parseFloat((maternity || 0).toFixed(2)),
      kama: parseFloat((kama || 0).toFixed(2)),
      other: parseFloat((other || 0).toFixed(2)),

      absenceR: parseFloat((absenceR || 0).toFixed(2)),
      sickR: parseFloat((sickR || 0).toFixed(2)),
      holidayR: parseFloat((holidayR || 0).toFixed(2)),
      maternityR: parseFloat((maternityR || 0).toFixed(2)),
      kamaR: parseFloat((kamaR || 0).toFixed(2)),
      otherR: parseFloat((otherR || 0).toFixed(2)),

      absenceUsed: parseFloat((absenceUsed || 0).toFixed(2)),
      sickUsed: parseFloat((sickUsed || 0).toFixed(2)),
      holidayUsed: parseFloat((holidayUsed || 0).toFixed(2)),
      maternityUsed: parseFloat((maternityUsed || 0).toFixed(2)),
      kamaUsed: parseFloat((kamaUsed || 0).toFixed(2)),
      otherUsed: parseFloat((otherUsed || 0).toFixed(2)),

      attendence: parseFloat((attendence || 0).toFixed(2))
    };
    //console.log('save')
    firestore.addWelth(companyId,uid,item,addWelthSuc,addWelthUnsuc)
  }

  useEffect(() => {
    if (location.state && location.state.uid) {
      setUid(location.state.uid);
      setName(location.state.name)
      //console.log('from eff'+uid)
      //firestore.getUser(companyId,location.state.uid,getUserSuccess,getUserUnsuccess)
      firestore.getWelth(companyId,location.state.uid,getWelSuc,getWelunsuc)
    } else {
      console.warn('No ID found in location state');
    }
    if (location.state) {
      setStartIndex(location.state.startIndex || 0);
      setEndIndex(location.state.endIndex || 10);
    }
  }, [location.state]);

  const handleEdit = (index, field) => {
    setEditingField({ index, field });
  };

  const handleBlur = () => {
    setEditingField(null);
  };

  return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        <main className="main-content">
          
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>จัดการสิทธิ์และวันหยุด</h1>
                {/* Add user profile and logout here */}
              </header>
            </div>
            <div className="main-contain">
              <p style={{fontSize:28,marginLeft:'2.5%',marginTop:20}}>ชื่อ - นามสกุล: {name}</p>
              <TableBootstrap striped bordered hover className='table' style={{width:'95%',alignSelf:'center',tableLayout: 'fixed', }}>
                  <thead>
                    <tr>             
                      <th scope="col">สิทธิ์</th>
                      <th>
                        วันลาตามสิทธิ์ 
                      </th>
                      <th>
                        จำนวนวันที่ถูกใช้
                      </th>
                      <th>จำนวนวันคงเหลือ</th>
                    </tr>
                  </thead>
                  <tbody>
                  {/* --- ลากิจ --- */}
                <tr key={0}>
                  <td scope="row">ลากิจ</td>
                  <td
                    onClick={() => handleEdit(0, 'total')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 0 && editingField?.field === 'total' ? (
                      <input
                        type="number"
                        value={absence}
                        onChange={(e) => handleNumberChange(e, setAbsence)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((absence).toFixed(2))
                    )}
                  </td>
                  {/* <td>{parseFloat((absence - absenceR).toFixed(2))}</td> */}
                  <td 
                    onClick={() => handleEdit(0, 'used')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 0 && editingField?.field === 'used' ? (
                      <input
                        type="number"
                        value={absenceUsed}
                        onChange={(e) => handleNumberChange(e, setAbsenceUsed)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((absenceUsed?absenceUsed:(absence - absenceR)).toFixed(2))
                    )}
                  </td>
                  <td
                    onClick={() => handleEdit(0, 'remain')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 0 && editingField?.field === 'remain' ? (
                      <input
                        type="number"
                        value={parseFloat((absenceR).toFixed(2))}
                        onChange={(e) => handleNumberChange(e, setAbsenceR)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((absenceR).toFixed(2))
                    )}
                  </td>
                </tr>

                {/* --- ลาป่วย --- */}
                <tr key={1}>
                  <td scope="row">ลาป่วย</td>
                  <td
                    onClick={() => handleEdit(1, 'total')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 1 && editingField?.field === 'total' ? (
                      <input
                        type="number"
                        value={sick}
                        onChange={(e) => handleNumberChange(e, setSick)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((sick).toFixed(2))
                    )}
                  </td>
                  {/* <td>{parseFloat((sick - sickR).toFixed(2))}</td> */}
                  <td
                    onClick={() => handleEdit(1, 'used')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 1 && editingField?.field === 'used' ? (
                      <input
                        type="number"
                        value={sickUsed}
                        onChange={(e) => handleNumberChange(e, setSickUsed)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((sickUsed?sickUsed:(sick - sickR)).toFixed(2))
                    )}
                  </td>
                  <td
                    onClick={() => handleEdit(1, 'remain')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 1 && editingField?.field === 'remain' ? (
                      <input
                        type="number"
                        value={sickR}
                        onChange={(e) => handleNumberChange(e, setSickR)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((sickR).toFixed(2))
                    )}
                  </td>
                </tr>

                {/* --- ลาพักร้อน --- */}
                <tr key={2}>
                  <td scope="row">ลาพักร้อน</td>
                  <td
                    onClick={() => handleEdit(2, 'total')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 2 && editingField?.field === 'total' ? (
                      <input
                        type="number"
                        value={holiday}
                        onChange={(e) => handleNumberChange(e, setHoliday)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((holiday).toFixed(2))
                    )}
                  </td>
                  {/* <td>{parseFloat((holiday - holidayR).toFixed(2))}</td> */}
                  <td
                    onClick={() => handleEdit(2, 'used')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 2 && editingField?.field === 'used' ? (
                      <input
                        type="number"
                        value={holidayUsed}
                        onChange={(e) => handleNumberChange(e, setHolidayUsed)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((holidayUsed?holidayUsed:(holiday - holidayR)).toFixed(2))
                    )}
                  </td>
                  <td
                    onClick={() => handleEdit(2, 'remain')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 2 && editingField?.field === 'remain' ? (
                      <input
                        type="number"
                        value={holidayR}
                        onChange={(e) => handleNumberChange(e, setHolidayR)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((holidayR).toFixed(2))
                    )}
                  </td>
                </tr>

                {/* --- ลาคลอด --- */}
                <tr key={3}>
                  <td scope="row">ลาคลอด</td>
                  <td
                    onClick={() => handleEdit(3, 'total')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 3 && editingField?.field === 'total' ? (
                      <input
                        type="number"
                        value={maternity}
                        onChange={(e) => handleNumberChange(e, setMaternity)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                       parseFloat((maternity).toFixed(2))
                    )}
                  </td>
                  {/* <td>{parseFloat((maternity - maternityR).toFixed(2))}</td> */}
                  <td
                    onClick={() => handleEdit(3, 'used')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 3 && editingField?.field === 'used' ? (
                      <input
                        type="number"
                        value={maternityUsed}
                        onChange={(e) => handleNumberChange(e, setMaternityUsed)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((maternityUsed?maternityUsed:(maternity - maternityR)).toFixed(2))
                    )}
                  </td>
                  <td
                    onClick={() => handleEdit(3, 'remain')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 3 && editingField?.field === 'remain' ? (
                      <input
                        type="number"
                        value={maternityR}
                        onChange={(e) => handleNumberChange(e, setMaternityR)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left', width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                       parseFloat((maternityR).toFixed(2))
                    )}
                  </td>
                </tr>

                {/* --- ลาบวช --- */}
                <tr key={4}>
                  <td scope="row">ลาบวช</td>
                  <td
                    onClick={() => handleEdit(4, 'total')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 4 && editingField?.field === 'total' ? (
                      <input
                        type="number"
                        value={kama}
                        onChange={(e) => handleNumberChange(e, setKama)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((kama).toFixed(2))
                    )}
                  </td>
                  {/* <td>{parseFloat((kama - kamaR).toFixed(2))}</td> */}
                  <td
                    onClick={() => handleEdit(4, 'used')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 4 && editingField?.field === 'used' ? (
                      <input
                        type="number"
                        value={kamaUsed}
                        onChange={(e) => handleNumberChange(e, setKamaUsed)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((kamaUsed?kamaUsed:(kama - kamaR)).toFixed(2))
                    )}
                  </td>
                  <td
                    onClick={() => handleEdit(4, 'remain')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 4 && editingField?.field === 'remain' ? (
                      <input
                        type="number"
                        value={kamaR}
                        onChange={(e) => handleNumberChange(e, setKamaR)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((kamaR).toFixed(2))
                    )}
                  </td>
                </tr>

                {/* --- ลาด้วยสิทธิ์อื่นๆ --- */}
                <tr key={5}>
                  <td scope="row">ลาด้วยสิทธิ์อื่นๆ</td>
                  <td
                    onClick={() => handleEdit(5, 'total')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 5 && editingField?.field === 'total' ? (
                      <input
                        type="number"
                        value={other}
                        onChange={(e) => handleNumberChange(e, setOther)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      other
                    )}
                  </td>
                  {/* <td>{parseFloat((other - otherR).toFixed(2))}</td> */}
                  <td
                    onClick={() => handleEdit(5, 'used')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 5 && editingField?.field === 'used' ? (
                      <input
                        type="number"
                        value={otherUsed}
                        onChange={(e) => handleNumberChange(e, setOtherUsed)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((otherUsed?otherUsed:(other - otherR)).toFixed(2))
                    )}
                  </td>
                  <td
                    onClick={() => handleEdit(5, 'remain')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 5 && editingField?.field === 'remain' ? (
                      <input
                        type="number"
                        value={otherR}
                        onChange={(e) => handleNumberChange(e, setOtherR)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      parseFloat((otherR).toFixed(2))
                    )}
                  </td>
                </tr>

                {/* --- จำนวนวันขาดงาน --- */}
                <tr>
                  <th scope="col"></th>
                  <th>จำนวนวันขาดงาน</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr key={6}>
                  <td scope="row">ขาดงาน</td>
                  <td
                    onClick={() => handleEdit(6, 'used')}
                    style={{ cursor: 'pointer' }}
                  >
                    {editingField?.index === 6 && editingField?.field === 'used' ? (
                      <input
                        type="number"
                        value={attendence}
                        onChange={(e) => handleNumberChange(e, setAttendence)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{
                          border: 'none',
                          background: 'transparent',
                          textAlign: 'left',
                          width: '60px',       // กำหนดความกว้างตายตัว
                          minWidth: 0,  
                        }}
                      />
                    ) : (
                      attendence
                    )}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
                </TableBootstrap>
                <div style={{display:'flex', flexDirection:'row',justifyContent:'center',width:'100%'}}>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#D3D3D3',marginRight:10}} onClick={onSave}>บันทึกข้อมูล</button>
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF'}} onClick={()=>navigate('/welthfare', { state: { startIndex, endIndex } })}>ย้อนกลับ</button>
              </div>

            </div>
          </div>
        </main>
        {isLoading && (
          <LoadingBackdrop />
        )}
      </div>
      
    
  );
}

export default WelthfareManage;

  