//AssetSync.js
import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './Home.css';
import Sidebar from './sidebar';
import './Profile.css';
import "bootstrap/dist/css/bootstrap.min.css";
import TableBootstrap from "react-bootstrap/Table";
import { useNavigate,useLocation } from 'react-router-dom';
import firestore from './Firebase/Firestore';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoSearchOutline, IoPencil, IoTrash, IoPerson, IoBarChart, IoNotifications } from "react-icons/io5";
import { IoFilterOutline } from "react-icons/io5";
import { BiSolidFileImport  } from "react-icons/bi";
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Layout from './Layout';
import { UserContext } from './UserContext';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineFilter, AiOutlineExport } from "react-icons/ai";
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import 'dayjs/locale/th'
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';

function AssetSync() {
  const navigate = useNavigate();
  const location = useLocation();
  const [allAssets, setAllAssets] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);
  const itemsPerPage = 10;
  const [showDel, setShowDel] = useState(false);
  const [selectID, setSelectID] = useState();
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [search, setSearch] = useState('');
  const [showFillter,setShowFillter] = useState(false);
  const [selectFillter,setSelectFillter] = useState('');
  const [type,setType] = useState('');
  const [name,setName] = useState('');
  const [id,setID] = useState('');
  const [sortOrderName, setSortOrderName] = useState('asc'); // Track sorting order for names
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [users,setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [assetTypeOptions, setAssetTypeOptions] = useState([]);
  const { account, companyId } = useContext(UserContext);
  
  const handleFillterClose = () => setShowFillter(false);
  const handleFilterShow = () => { 
    setShowFillter(true);
  }

  const handleDelClose = () => setShowDel(false);
  const handleDelShow = (id,name) => {
    setSelectID(id)
    setName(name)
    setShowDel(true);
  }

  const onAdd =()=>{
    navigate('/asset_add',{state:{action:'add',uid:id||'',startIndex, endIndex,  search,
        searchQuery,
        }})
  }

  const onEdit =(id)=>{
    console.log(id)
    navigate(`/asset_edit/${id}`, {state: {action: 'edit',uid:id||'',startIndex,endIndex,search,searchQuery,type}});
  }

  const fetchDropdownOptions = async () => {
    try {
      
      const assetTypeOptions = await firestore.getDropdownOptions(companyId,'asset_type')
      setAssetTypeOptions(assetTypeOptions.map(option => option.name));
      //console.log(positionOptions)
      
    } catch (error) {
      console.error('Error fetching dropdown options:', error);
    }
  };

  const applyFilters = () => {
    let filtered = allAssets;
    
    if (searchQuery) {
      filtered = filtered.filter(asset =>
        asset.name.toLowerCase().includes(searchQuery) ||
        asset.type.toLowerCase().includes(searchQuery) ||
        asset.date.toLowerCase().includes(searchQuery)
      );
    }
    
    if (type) {
      filtered = filtered.filter(asset => asset.type.toLowerCase().includes(type.toLowerCase()));
    }
    
    setFilteredAssets(filtered);
    if(search){
      setStartIndex(0);
      setEndIndex(10);
    }
    
  };

  useEffect(() => {
    if (location.state) {
      setStartIndex(location.state.startIndex !== undefined ? location.state.startIndex : 0);
      setEndIndex(location.state.endIndex !== undefined ? location.state.endIndex : 10);
      setSearch(location.state.search || '');
      setSearchQuery(location.state.searchQuery || '');
      setType(location.state.type || '');
    } else {
      setStartIndex(0);
      setEndIndex(10);
    }
    //firestore.getAllUser(companyId,getAllUsersSuccess,getAllUsersUnsuccess)
    fetchDropdownOptions();
  }, [location.state]);

  useEffect(() => {
    const unsubscribe = firestore.getAllAsset(companyId, (assets) => {
      // แปลงวันที่จาก string เป็น Date object เพื่อให้สามารถเรียงได้
      const parsedAssets = assets.map(asset => {
        const [day, month, year] = asset.date.split('/');
        // สร้าง Date object (เดือนเริ่มต้นที่ 0)
        return {
          ...asset,
          parsedDate: new Date(`${year}-${month}-${day}`)
        };
      });
  
      // เรียงลำดับ descending โดยใช้วันที่ (ล่าสุดมาก่อน)
      parsedAssets.sort((a, b) => b.parsedDate - a.parsedDate);
  
      setAllAssets(parsedAssets);
      setFilteredAssets(parsedAssets);
    }, (error) => {
      console.error("Error fetching assets:", error);
    });
    firestore.getAllUser(companyId,(data)=>setUsers(data),(e)=>console.log(e))
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [companyId]);

  useEffect(() => {
    applyFilters();
  }, [searchQuery, allAssets]);

  const onNext = () => {
    setStartIndex(startIndex + 10); // Increment the start index by 5
    setEndIndex(endIndex + 10); // Increment the end index by 5
  };

  const onPrevious = () => {
    setStartIndex(Math.max(startIndex - 10, 0)); // Decrement the start index by 5, ensuring it doesn't go below 0
    setEndIndex(Math.max(endIndex - 10, 10)); // Decrement the end index by 5, ensuring it doesn't go below 5
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setSearch(event.target.value);
  };

  const handleFillter = () => {
    applyFilters();
    setShowFillter(false);
  };

  // Sorting function for names
  const sortByName = () => {
    const newOrder = sortOrderName === 'asc' ? 'desc' : 'asc';
    setSortOrderName(newOrder);
    const sortedAssets = [...filteredAssets].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return newOrder === 'asc' ? -1 : 1;
      if (nameA > nameB) return newOrder === 'asc' ? 1 : -1;
      return 0;
    });
    
    setFilteredAssets(sortedAssets);
  };

  const handleSelectAsset = (assetId) => {
    if (selectedAssets.includes(assetId)) {
      setSelectedAssets(selectedAssets.filter(id => id !== assetId));
    } else {
      setSelectedAssets([...selectedAssets, assetId]);
    }
  };

  const handleSelectAll = () => {
    const currentPageAssetIds = filteredAssets.slice(startIndex, endIndex).map(asset => asset.id);
    const allSelected = currentPageAssetIds.every(id => selectedAssets.includes(id));
    
    if (allSelected) {
      // Deselect all
      setSelectedAssets(selectedAssets.filter(id => !currentPageAssetIds.includes(id)));
    } else {
      // Select all
      const newSelected = [...new Set([...selectedAssets, ...currentPageAssetIds])];
      setSelectedAssets(newSelected);
    }
  };

  const handleDeleteShow = (asset) => {
    setSelectedAsset(asset);
    setShowDel(true);
  };

  const handleDeleteClose = () => {
    setSelectedAsset(null);
    setShowDel(false);
  };

  const deleteAsset = () => {
    setIsLoading(true)
    if (selectedAsset) {
     
      firestore.deleteAsset(companyId, selectedAsset.id)
        .then(() => {
          console.log('Asset deleted successfully');
          handleDeleteClose();
          setIsLoading(false)
            Swal.fire({
              title: 'สำเร็จ',
              text: 'ลบข้อมูลสำเร็จ',
              // text: `ไม่สามารถเพิ่มพนักงานได้เกิน ${maxAllowed} คน (plan: ${userPlan})`,
              icon: 'success',
              customClass: {
                popup: 'my-swal-size'
              },
            });
        })
        .catch(error => {
          console.error('Error deleting asset:', error);
          setIsLoading(false)
            Swal.fire({
              title: 'พบข้อผิดพลาด',
              // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
              text: `เกิดปัญหาขณะลบข้อมูล error: ${error}`,
              icon: 'error',
              customClass: {
                popup: 'my-swal-size'
              },
            });
        });
    }
  };

  const bulkDeleteAssets = () => {
    setIsLoading(true)
    if (selectedAssets.length === 0) {
      //alert('Please select at least one asset to delete.');
      setIsLoading(false)
      Swal.fire({
        title: 'แจ้งเตือน',
        text: 'โปรดเลือกอย่างน้อย 1 รายการ',
        // text: `ไม่สามารถเพิ่มพนักงานได้เกิน ${maxAllowed} คน (plan: ${userPlan})`,
        icon: 'warning',
        customClass: {
          popup: 'my-swal-size'
        },
      });
      return;
    }
    
    // ยืนยันก่อนลบ
    const confirmed = window.confirm('Are you sure you want to delete the selected assets?');
    if (!confirmed){ 
      setIsLoading(false)
      return;
    }
  
    selectedAssets.forEach(assetId => {
      firestore.deleteAsset(companyId, assetId)
        .then(() => {
          console.log(`Asset ${assetId} deleted successfully`);
        })
        .catch(error => {
          console.error(`Error deleting asset ${assetId}:`, error);
          setIsLoading(false)
            Swal.fire({
              title: 'พบข้อผิดพลาด',
              // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
              text: `เกิดปัญหา ${assetId}: ${error}`,
              icon: 'error',
              customClass: {
                popup: 'my-swal-size'
              },
            });
        });
    });
    setIsLoading(false)
    Swal.fire({
      title: 'สำเร็จ',
      // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
      text: `ลบรายการที่เลือกสำเร็จ`,
      icon: 'success',
      customClass: {
        popup: 'my-swal-size'
      },
    });
    // Clear selected assets after deletion
    setSelectedAssets([]);
  };

  const totalItems = filteredAssets.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentPage = startIndex / itemsPerPage + 1;

  // ฟังก์ชันเปลี่ยนหน้าจาก dropdown
  const handlePageChange = (event) => {
    const selectedPage = parseInt(event.target.value, 10);
    const newStartIndex = (selectedPage - 1) * itemsPerPage;
    const newEndIndex = selectedPage * itemsPerPage;

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  };

  const handleImportExcel = () => {
    setShowImportModal(true);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setExcelFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (!excelFile) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // สมมติอ่านเฉพาะ sheet แรก
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1,  blankrows: false,  });

      // jsonData จะเป็น Array ของแต่ละแถวในรูป [ [col1, col2, ...], [col1, col2, ...], ...]
      // หากไฟล์มี Header row เราสามารถแยก Column name แล้ว map ได้ตามต้องการ

      // สมมติ header row อยู่บรรทัดแรก
      // jsonData[0] => ['name', 'position', 'emID', 'address', ...]
      const headers = jsonData[0];
      // บรรทัดถัด ๆ ไปคือข้อมูลพนักงาน
      const rows = jsonData.slice(1);

      // map rows => objects
      const dataObjects = rows.map(row => {
        // สร้าง object โดย bind column เข้ากับ header
        let obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index] || ""; // ถ้าไม่มีค่าจะเป็น ''
        });
        return obj;
      });

      // จากนั้นอาจเรียกฟังก์ชันส่งไปบันทึกต่อ (เช่นส่ง dataObjects ให้บันทึกใน Firestore)
      handleSaveToFirestore(dataObjects);
    };
    reader.readAsArrayBuffer(excelFile);
  };

  const handleSaveToFirestore = async (dataArray) => {
    try {
      // dataArray คือตัวอย่างข้อมูลแต่ละ row = { name: 'นาย A บี', position: 'Developer', emID: 'EMP001', address: '...', ...}
      // Array สำหรับเก็บ record ที่ซ้ำกัน
      let duplicateRecords = [];
      for (const row of dataArray) {
        
        
        // วนลูปแต่ละ row จาก Excel
        // ดึงข้อมูลชื่อสินทรัพย์และเลขทะเบียนทรัพย์สินจาก Excel
        const assetNameFromExcel = (row["ชื่อสินทรัพย์"] || "").trim().toLowerCase();
        const assetSerialFromExcel = (row["เลขทะเบียนทรัพย์สิน"] || "").trim().toLowerCase();
        
        // ตรวจสอบข้อมูลที่มีอยู่ใน allAssets ว่ามี record ซ้ำหรือไม่
        const duplicate = allAssets.find(asset => {
          const existingName = (asset.name || "").trim().toLowerCase();
          const existingSerial = (asset.serial || "").trim().toLowerCase();
          return existingName === assetNameFromExcel || existingSerial === assetSerialFromExcel;
        });
        
        if (duplicate) {
          // ถ้าพบข้อมูลซ้ำ ให้เก็บลง duplicateRecords แล้วข้าม record นี้
          duplicateRecords.push(`${row["ชื่อสินทรัพย์"]} (${row["เลขทะเบียนทรัพย์สิน"]})`);
          continue;
        }

        // ดึงค่าวันที่จาก row
        let excelValue = row['วันที่'];

        // สร้างตัวแปร dateStr เอาไว้เก็บค่าเป็น "dd/mm/yyyy"
        let dateStr = '';

        // เช็คว่าเป็น Number (Serial Date ของ Excel) หรือไม่
        if (typeof excelValue === 'number') {
          // ใช้ XLSX.SSF.parse_date_code() เพื่อแปลงเป็น {y,m,d,...}
          const parsed = XLSX.SSF.parse_date_code(excelValue);
          // สร้าง Date object
          const dateObj = new Date(parsed.y, parsed.m - 1, parsed.d);
          // Format ให้อยู่ในรูป dd/mm/yyyy (เช่น 07/06/2023)
          dateStr = dayjs(dateObj).format('DD/MM/YYYY'); 
        }
        // ถ้า sheet_to_json แปลงให้เป็น Date object อยู่แล้ว
        else if (excelValue instanceof Date) {
          dateStr = dayjs(excelValue).format('DD/MM/YYYY');
        }
        // ถ้าเป็น String อยู่แล้ว (บางกรณี)
        else if (typeof excelValue === 'string') {
          // สมมติผู้ใช้กรอก "7/6/2006" อยู่แล้ว อาจจะใช้ต่อได้เลย
          // หรือจะ try-catch parse ด้วย dayjs อีกทีก็ได้
          dateStr = excelValue; 
        }
        // ถ้าไม่มีหรือไม่ตรงรูปแบบ
        else {
          dateStr = ''; // เก็บค่าว่างไป
        }

              // อ่านค่าสถานะจาก Excel
        let rawStatus = row["สถานะ"] || "";
        
        // สร้างตัวแปรไว้เก็บใน Firestore
        let statusObj = {};
        
        if (rawStatus === "ใช้งาน") {
          statusObj = { label: "ใช้งาน", value: 0 };
        } else if (rawStatus === "รอซ่อม") {
          statusObj = { label: "รอซ่อม", value: 1 };
        } else if (rawStatus === "เสื่อมสภาพ") {
          statusObj = { label: "เสื่อมสภาพ", value: 2 };
        } else {
          // กรณี input ไม่ตรงกับที่เราเคยกำหนด
          // เก็บไว้เป็น label = ค่าที่ผู้ใช้พิมพ์ใน Excel, value = ''
          statusObj = { label: rawStatus, value: "" };
        }

         // จัดการ allower / undertaker
        let excelAllowerName = row["ชื่อผู้อนุมัติ"] || "";
        let excelUndertakerName = row["ผู้ที่รับผิดชอบ/ดูแล"] || "";

        // หา id จาก users
        let foundAllower = users.find(u => u.name === excelAllowerName.trim());
        let foundUndertaker = users.find(u => u.name === excelUndertakerName.trim());

        let item = {
          name: row.ชื่อสินทรัพย์ || '',
          type: row.ประเภทสินทรัพย์ || '', // ถ้าต้องแยกชื่อ-นามสกุล ควร split อีกที
          asset_Status: statusObj,
          date: dateStr || '',
          serial: row.เลขทะเบียนทรัพย์สิน || '',
          allower: excelAllowerName,             // เก็บชื่อเต็มๆ ที่มาจาก Excel
          allowerId: foundAllower ? foundAllower.id : '',  // ถ้าไม่เจอเก็บเป็น ''
          undertaker: excelUndertakerName,
          undertakerId: foundUndertaker ? foundUndertaker.id : '',
          // หรือจะนำค่า column อื่นมาเก็บก็ทำได้
        };

        // บันทึกใน Firestore
        firestore.addAndUpdateAsset(companyId,'add',id,item, ()=>alert('Add asset success.'), (e)=>console.log(e));
      }

      // แจ้งเตือนผู้ใช้ถ้ามี record ที่ถูกข้ามไป
      if (duplicateRecords.length > 0) {
        const uniqueDuplicates = [...new Set(duplicateRecords)];
        alert(
          `มี ${duplicateRecords.length} record ถูกข้ามไปเนื่องจากข้อมูลซ้ำ: ${uniqueDuplicates.join(", ")}`
        );
      } else {
        alert("Import & Save data success!");
      }
      
      setShowImportModal(false);
    } catch (error) {
      console.error(error);
      alert('Error importing data: ' + error.message);
    }
  };

  return (
    
      <div className="dashboard">
        <Layout />
        
        <main className="main-content">
          
          <div className="main">
          <div className='header-page'>
          <header>
            <h1 >Asset Sync</h1>
            {/* Add user profile and logout here */}
          </header>
          </div>
            <div className="main-contain">

              <div className="search-field">
                {/* <p style={{marginTop:17}}>ค้นหาพนักงาน</p> */}
                <input style={{width:'95%',margin:5,height:40,borderRadius:5,paddingInlineStart:10,fontSize:22,alignSelf:'center',justifyContent:'center'}}
                placeholder='search..' 
                value={search}
                onChange={handleSearch} />
                {/*<button className="search-button" ><IoSearchOutline size={24} /></button>*/}
              </div>
              <div style={{display:'flex',flexDirection:'row', justifyContent: 'space-between',alignItems: 'center',marginTop:-5,marginBottom:10,width:'95%',alignSelf:'center',overflowX:'auto'}}>
                <div>
                  <IoTrash
                    size={50}
                    className="trash-icon"
                    onClick={bulkDeleteAssets}
                    style={{     
                      // marginBottom: '10px',    
                      cursor: 'pointer',       
                      color: 'red',            
                      border: '2px solid red', 
                      padding: '5px',          
                      borderRadius: '5px',  
                      marginRight:10
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <button 
                    style={{ borderRadius: 10, marginRight: 10,width:50,height:50 }} 
                    onClick={() => setShowFillter(true)}
                  >
                    <IoFilterOutline size={20} />
                  </button>
                  <button 
                    className='Add-button' 
                    style={{marginBottom:5,marginRight:5}}
                    onClick={onAdd}
                  >
                    เพิ่มรายการ
                  </button>
                  <button className='Add-button' title='Import Excel'  style={{marginBottom:5}} onClick={handleImportExcel}><BiSolidFileImport size={20} /></button>
                </div>
              </div>
              
              <div style={{width:'95%',alignSelf:'center'}}>
                <div className="table-container">
                <TableBootstrap striped bordered hover className='table'>
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={
                            filteredAssets.slice(startIndex, endIndex).length > 0 &&
                            filteredAssets.slice(startIndex, endIndex).every(asset => selectedAssets.includes(asset.id))
                          }
                        />
                      </th>
                      <th scope="col">ลำดับ</th>
                      <th scope="col">วันที่</th>
                      <th scope="col" onClick={sortByName} style={{ cursor: 'pointer' }}>
                        ชื่อทรัพย์สิน{sortOrderName === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </th>
                      <th scope="col">ประเภท</th>
                      <th scope="col">ผู้รับผิดชอบ/ดูแล</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/*{allUser.slice(startIndex, endIndex).map((item, index) => (*/}
                    {filteredAssets.slice(startIndex, endIndex).map((asset, index) => (
                      <tr key={asset.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedAssets.includes(asset.id)}
                            onChange={() => handleSelectAsset(asset.id)}
                          />
                        </td>
                        <td>{startIndex + index + 1}</td>
                        {/* <th scope="row" style={{width:80}}>{index + 1}</th> */}
                        <td>{asset.date}</td>
                        <td>{asset.name}</td>
                        <td>{asset.type}</td>
                        <td>{asset.undertaker}</td>
                        <td style={{width:'30%',textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                          <button
                            className='Edit-button'
                            onClick={() => onEdit(asset.id)}
                            style={{ marginRight: 10, }}
                            title="แก้ไข"
                          >
                            <IoPencil size={20} />
                          </button>
                          <button
                            className='Delete-button'
                            onClick={() => handleDeleteShow(asset)}
                            title="ลบ"
                          >
                            <IoTrash size={20} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableBootstrap>
                </div>  
              <div style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center'}}>
                <button className='Previous-button' onClick={onPrevious} disabled={startIndex === 0}>Previous</button>
                <div style={{ margin: '0 10px' }}>
                  <select
                    value={currentPage}
                    onChange={handlePageChange}
                    style={{ fontSize: '16px', padding: '2px 8px' }}
                  >
                    {Array.from({ length: totalPages }, (_, idx) => (
                      <option key={idx + 1} value={idx + 1}>
                        {idx + 1}
                      </option>
                    ))}
                  </select>
                  <span style={{ marginLeft: '5px' }}>จาก {totalPages}</span>
                </div>
                <button className='Next-button' onClick={onNext} disabled={endIndex >= filteredAssets.length}>Next</button>
                </div>
              </div>

            </div>
          </div>
        </main>

        <Modal show={showFillter} onHide={() => setShowFillter(false)}>
        <Modal.Header closeButton>
          <Modal.Title>เลือกข้อมูลที่ต้องการกรอง</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            id="filled-select"
            select
            label="ประเภท"
            variant="filled"
            style={{ width: 300, marginRight: 10 }}
            value={type}
            onChange={(e) => setType(e.target.value)}
            SelectProps={{
              displayEmpty: true,
            }}
            InputLabelProps={{
              shrink: true, // Ensures the label is always shrunk
            }}
          >
            <MenuItem value="">None / Clear Filter</MenuItem>
            {assetTypeOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{ backgroundColor: '#D3D3D3', color: 'black' }}
            onClick={handleFillter}
          >
            OK
          </Button>
          <Button
            variant="secondary"
            style={{ backgroundColor: '#343434' }}
            onClick={() => setShowFillter(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDel} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAsset && (
            <p>Are you sure you want to delete the asset "{selectedAsset.name}"?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={deleteAsset}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Import Excel (สวยงามด้วย Bootstrap) */}
      <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:28}}>Import จากไฟล์ Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" style={{fontSize:18}} accept=".xlsx,.xls,.xltx,.xlsm" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpload} style={{fontSize:18}}>
            Upload
          </Button>
          <Button variant="secondary" onClick={() => setShowImportModal(false)} style={{fontSize:18}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading && (
        <LoadingBackdrop />
      )}
      </div>
      
    
  );
}

export default AssetSync;

  