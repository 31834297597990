//Register.js
import './App.css';
import React, { useState,useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
//import { auth } from './Firebase/Config';
import { useNavigate } from 'react-router-dom';
import { IoEye, IoEyeOff } from 'react-icons/io5'; 
import auth from './Firebase/Auth';
import firestore from './Firebase/Firestore';
import { hashPassword } from './hashPassword';
import logo from './icon/PAM_HR_logo.png';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [level, setLevel] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const [currentUser,setCurrentUser] = useState(null)

  const [activeButton, setActiveButton] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const EMAILJS_SERVICE_ID = 'service_5egw6b8';
  const EMAILJS_TEMPLATE_ID = 'template_wrkesla';
  const EMAILJS_USER_ID = 'sT8ZIWDhLD1_zjzvb';

  const [verificationCode, setVerificationCode] = useState(''); // โค้ดที่ระบบสุ่ม
  const [userInputCode, setUserInputCode] = useState('');        // โค้ดที่ผู้ใช้กรอก
  const [isEmailVerified, setIsEmailVerified] = useState(false); // ผลลัพธ์การ verify?
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);   // ระหว่างส่งโค้ด Verify
  const [verifyCooldown, setVerifyCooldown] = useState(0);         // เคานต์ดาวน์กี่วิ
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const levels = [
      // { label: 'Administrator', value: 0 },
      { label: 'Super Admin', value: 1 },
      { label: 'Admin', value: 2 },
      { label: 'User', value: 3 }
  ];

  const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
  };

  // ตรวจสอบความสมบูรณ์ของฟอร์ม
  const isFormFilled = () => {
    // เช็คว่า field สำคัญๆ ไม่ว่าง
    return (
      companyName.trim() !== '' &&
      companyId.trim() !== '' &&
      email.trim() !== '' &&
      name.trim() !== '' &&
      position.trim() !== ''
    );
  };

     // 1) ฟังก์ชันส่งเมลแจ้ง Admin
  const sendEmailToAdmin = async (adminEmail, accountData) => {
    // ตัวอย่างการส่งเมลด้วย emailjs
    try {
      const templateParams = {
        to_email: adminEmail,
        subject: 'New Demo Registration Request',
        message: `There is a new Demo Request:\n\n${JSON.stringify(accountData, null, 2)}  \nApprove: https://www.pam-hrmsoftware.com/login_admin`
        // คุณสามารถส่งพารามิเตอร์อื่น ๆ ไปได้ เช่น accountData.companyName
      };

      const result = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams,
        EMAILJS_USER_ID
      );

      console.log('Email sent to Admin:', result.text);
    } catch (error) {
      console.error('Error sending email to Admin:', error);
      throw error;
    }
  };

  // 2) ฟังก์ชันส่งเมลแจ้งผู้ใช้
  const sendEmailToUser = async (userEmail) => {
    try {
      const templateParams = {
        to_email: userEmail,
        subject: 'Your Demo Registration is Received',
        message: 'We have received your demo request. Please wait for admin approval...'
      };

      const result = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams,
        EMAILJS_USER_ID
      );

      console.log('Email sent to User:', result.text);
    } catch (error) {
      console.error('Error sending email to User:', error);
      throw error;
    }
  };

  const handleSendVerificationCode = async () => {
    setIsLoading(true)
    // ถ้ากำลังรอ cooldown หรือกำลังโหลด ไม่ให้กดซ้ำ
    if (isVerifyLoading || verifyCooldown > 0) {
      setIsLoading(false)
      return;
    }

    setErrorMessage('');
    if (!email) {
      setIsLoading(false)
      setErrorMessage('Please enter your email first.');
      return;
    }

    setIsVerifyLoading(true);
    try {
      const code = Math.floor(100000 + Math.random() * 900000).toString();
      setVerificationCode(code);
      setIsEmailVerified(false);

      const templateParams = {
        to_email: email,
        subject: 'PAM - Email Verification',
        message: `Your verification code is: ${code}`
      };
      const result = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams,
        EMAILJS_USER_ID
      );
      console.log('Verification code sent to user:', result.text);
      setIsLoading(false)
      //alert(`We sent a verification code to: ${email}\nPlease check your inbox/spam.`);
      Swal.fire({
        // title: 'Warning',
        // text: 'Cannot delete an Administrator account.',
        title: 'ส่งสำเร็จ',
        text: `ส่งรหัสยืนยันไปที่ ${email} \nโปรดตรวจสอบกล่องจดหมายหรือสแปมของคุณ `,
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      });
      // ตั้ง cooldown 30 วินาที (ตัวอย่าง)
      setVerifyCooldown(30);
    } catch (error) {
      console.error('Error sending verification code:', error);
      setErrorMessage('Failed to send verification code. Please try again.');
    } finally {
      setIsVerifyLoading(false);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    let timer;
    if (verifyCooldown > 0) {
      timer = setInterval(() => {
        setVerifyCooldown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [verifyCooldown]);

  // ========= ฟังก์ชันตรวจสอบโค้ดจากผู้ใช้ =========
  const handleVerifyCode = () => {
    setErrorMessage('');
    // ถ้าตรงแสดงว่ายืนยันอีเมลแล้ว
    if (userInputCode === verificationCode) {
      setIsEmailVerified(true);
      //alert('Email verified successfully!');
      Swal.fire({
        // title: 'Warning',
        // text: 'Cannot delete an Administrator account.',
        title: 'สำเร็จ',
        text: `ยืนยันอีเมล ${email} สำเร็จ`,
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      });
    } else {
      setErrorMessage('Verification code is incorrect.');
    }
  };

  const verifyCompanyId = async () => {
     // 1) ตรวจซ้ำใน account_cms
    const isInCms = await new Promise((resolve) => {
      firestore.checkCompany(companyId, (found) => {
        resolve(found); // found = true => ซ้ำ
      }, () => {
        resolve(false);
      });
    });

    if (isInCms) {
      setErrorMessage("Company ID is already in use (in CMS).");
      return false;
    }

    // 2) ตรวจซ้ำใน requestDemo
    const isInRequestDemo = await new Promise((resolve) => {
      firestore.checkRequestDemoCompanyId(companyId, (found) => {
        resolve(found);
      }, () => {
        resolve(false);
      });
    });

    if (isInRequestDemo) {
      setErrorMessage("Company ID is already in use (in requestDemo).");
      return false;
    }

    // ถ้าไม่ซ้ำทั้ง 2 ที่
    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    if (isRegisterLoading){
      setIsLoading(false)
      return;
    }

      // 1) เช็คกรอกข้อมูลครบหรือยัง
    if (!isFormFilled()) {
      setIsLoading(false)
      setErrorMessage('Please fill all required fields.');
      return;
    }

    // 2) เช็คว่าอีเมลผ่านการ Verify แล้วหรือยัง
    if (!isEmailVerified) {
      setIsLoading(false)
      setErrorMessage('Please verify your email before registering.');
      return;
    }

    let isCompanyIdValid = await verifyCompanyId();
    console.log(isCompanyIdValid)
    if (!isCompanyIdValid) {
      setIsLoading(false)
      return
    };

    // Validate password and confirm password
    // if (password !== confirmPassword) {
    //     setErrorMessage('Passwords do not match.');
    //     return;
    // }

    try {
        
        // Step 1: Account creation was successful, now add account data to Firestore
        //const hashedPassword = await hashPassword(password); // Hash the password

        const accountData = {
            email,
            name,
            position,
            level: 1,
            //password: hashedPassword,
            companyId:companyId,
            companyName:companyName,
            plan:'demo',
            status:false,
            createdAt: new Date()
        };

        // Step 2: Add user to Firestore
        await firestore.sendRequestOrg(companyId, accountData);
        
        // ตั้ง adminEmail หรือจะให้ adminEmail มาจาก config ก็ได้
        const adminEmail = 'miscible.dev@gmail.com';

        // ส่งเมลแจ้ง Admin
        await sendEmailToAdmin(adminEmail, accountData);

        // ส่งเมลแจ้งลูกค้า
        await sendEmailToUser(email);
        // Step 4: Alert the user that the account was created
        setIsLoading(false)
        //alert('Your demo request has been submitted successfully! Please wait for admin approval.');
        Swal.fire({
          // title: 'Warning',
          // text: 'Cannot delete an Administrator account.',
          title: 'สำเร็จ',
          text: `คำขอ demo คุณถูกส่งสำเร็จ โปรดรอการอนุมัติจาก Admin`,
          icon: 'success',
          customClass: {
            popup: 'my-swal-size'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        }).then(() => {
          navigate('/'); 
        });

        // Step 5: Redirect to login page after successful sign out
       // Ensure we navigate to the login page
    } catch (error) {
        console.error('Error creating account or adding to Firestore:', error);
        setErrorMessage('Could not submit request. Please try again later.');
    } finally {
      setIsLoading(false)
      setIsRegisterLoading(false);
    }
  };

  return (
  <div className="App">
      <nav className="navbar">
      <div className="navbar-left">
        <div className="navbarlogo-container"  style={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}>
          <img src={logo} alt="PAM Logo" className="navbarlogo" />
        </div>
      </div>
      <div className="button-container">
        <button
          className="nav-button"
          style={{ backgroundColor: '#D9D9D9' }}
          onClick={() => navigate('/login_company')}
        >
          Login
        </button>
      </div>
      <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div className="navbarlogo-container" style={{width:'100%',justifyContent:'space-between'}}>
          <img src={logo} alt="PAM Logo" className="navbarlogo" />
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
      {isMenuOpen && (
        <div className="mobile-menu">
          <button
            className="nav-button"
            style={{ backgroundColor: '#D9D9D9',marginBottom:5 }}
            onClick={() => navigate('/login_company')}
          >
            Login
          </button>
        </div>
      )}
    </nav>
      <header className="App-header">
          <div className='Main'>
          <h2 style={{color:'black',fontWeight:'bold'}}>Request Demo</h2>

          <input
              type="text"
              className="input-field"
              placeholder="Organization or Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
              style={{ marginBottom: '10px' }} // Add space between inputs
          />

          {/* Company ID */}
          <input
              type="text"
              className="input-field"
              placeholder="Company ID"
              value={companyId}
              onChange={(e) =>{ 
                const newValue = e.target.value;
                if (/\s/.test(newValue)) {
                  setErrorMessage("Company ID cannot contain spaces.");
                } else {
                  setErrorMessage("");
                  setCompanyId(newValue);
                }
              }}
              required
              style={{ marginBottom: '10px' }} // Add space between inputs
          />

          {/* Email */}
          <div style={{ position: 'relative', marginBottom: '10px' }}>
            <input
              type="email"
              className="input-field"
              placeholder="Email"
              style={{
                width: '100%',
                paddingRight: '60px', // เผื่อพื้นที่ทางขวาสำหรับ "Verify"
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage('');
                setIsEmailVerified(false);
              }}
              required
            />
            {email && <span
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: (isVerifyLoading || verifyCooldown > 0) ? 'not-allowed' : 'pointer',
                color: '#343434',           // หรือสีที่ต้องการ
                fontSize:20,
                textDecoration:'underline' ,
                display: 'inline-block',
                textAlign: 'center'
              }}
              onClick={handleSendVerificationCode} // เรียกฟังก์ชันเมื่อคลิก
            >
              {isVerifyLoading
                  ? 'Sending...'
                  : verifyCooldown > 0
                    ? `Wait ${verifyCooldown}s`
                    : 'Verify'
                }
            </span>}
          </div>

          {/* หากมีการส่ง code แล้ว จะแสดงช่องให้กรอก code และปุ่ม Verify */}
          {verificationCode && !isEmailVerified && (
            <div style={{ marginBottom: '10px' }}>
              <input
                type="text"
                className="input-field"
                placeholder="Enter verification code"
                value={userInputCode}
                onChange={(e) => setUserInputCode(e.target.value)}
                required
                style={{marginBottom: 5 }}
              />
              <button type="submit" className="login-button" onClick={handleVerifyCode} style={{ width:'25%' }}>
                Confirm
              </button>
            </div>
          )}

          {/* แสดงข้อความว่าผ่านการ verify แล้ว */}
          {isEmailVerified && (
            <p style={{ color: 'green', margin: 0 }}>
              Email Verified!
            </p>
          )}

          {/* Password */}
          {/* <div style={{ position: 'relative', marginBottom: '10px' }}> 
              <input
              type={showPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ paddingRight: '2.5rem' }}
              />
              <button
              type="button"
              onClick={togglePasswordVisibility}
              style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer'
              }}
              >
              {showPassword ? <IoEyeOff size={24} /> : <IoEye size={24} />}
              </button>
          </div> */}

          {/* Confirm Password */}
          {/* <div style={{ position: 'relative', marginBottom: '10px' }}> 
              <input
              type={showConfirmPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={{ paddingRight: '2.5rem' }}
              />
              <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer'
              }}
              >
              {showConfirmPassword ? <IoEyeOff size={24} /> : <IoEye size={24} />}
              </button>
          </div> */}

          {/* Name */}
          <input
              type="text"
              className="input-field"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{ marginBottom: '10px' }} // Add space between inputs
          />

          {/* Position */}
          <input
              type="text"
              className="input-field"
              placeholder="Position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              required
              style={{ marginBottom: '10px' }} // Add space between inputs
          />

          {/* Level */}
          {/* <select
              className="input-field"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              required
              style={{ marginBottom: '10px' }} // Add space between inputs
          >
              <option value="" disabled>Select Level</option>
              {levels.map((levelOption) => (
              <option key={levelOption.value} value={levelOption.value}>
                  {levelOption.label}
              </option>
              ))}
          </select> */}

          {/* Error Message */}
          {errorMessage && <p style={{ color: 'red',fontSize:20,marginBottom:0,marginTop:-10 }}>{errorMessage}</p>}
          
          <button
            type="submit"
            className="login-button"
            onClick={handleRegister}
            style={{ marginTop: 0 }}
            disabled={!isFormFilled() || isRegisterLoading}
          >
            {isRegisterLoading ? 'Please wait...' : 'Register'}
          </button>
          </div>
          <div style={{fontSize:20,lineHeight: 1,marginTop:20}}>
              <p>By giving information, you agree to our Terms &amp; Conditions</p>
              <p>© 2024 Miscible Technology Co.,Ltd. - all rights reserved</p>
          </div>
      </header>

      {isLoading && (
        <LoadingBackdrop />
      )}
  </div>
  );
};

export default Register;
