import React, { useState,useEffect,useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Navigate } from 'react-router-dom';
import Sidebar from './sidebar';
import './Home.css';
import { useNavigate,useLocation } from 'react-router-dom';
import './addProfile.css'
import { TextField,InputAdornment, IconButton,Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import firestore from './Firebase/Firestore';
import storage from './Firebase/Storage';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import './fonts/THSarabunNew-normal'
import THSarabunNew from './fonts/THSarabunNew-normal';
import 'jspdf-autotable';
import logo from './fonts/logo';
import Layout from './Layout';
import { UserContext } from './UserContext';
import InfoIcon from '@mui/icons-material/Info';
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';

function SalaryCal() {
  const navigate = useNavigate();
  const location = useLocation();
  const [status,setStatus] = useState(''); //new or edit
  const [id,setId] = useState(''); // user id
  const [uid,setUid] = useState(''); //id doc
  const [name,setName] = useState('');
  const [position,setPosition] = useState('');
  const [editable,setEditable] = useState(true)
  const [date,setDate] = useState(dayjs(new Date()));
  //รายได้
  const [costL,setCostL] = useState(""); //ค่าครองชีพ
  const [food,setFood] = useState(""); //ค่าอาหาร
  const [allowance,setAllowance] = useState(""); //เบี้ยเลี้ยง
  const [salary,setSalary] = useState(""); //ค่าเงินเดือน
  const [venhicle,setVenhicle] = useState(""); //ค่ายานพาหนะ
  const [sub,setSub] = useState(""); //ค่าจ้าง
  const [manualSub,setManualSub] = useState(false);
  const [welth,setWelth] = useState(""); //ค่าสวัสดิการ
  const [bonus,setBonus] = useState(""); //เงินโบนัส
  //รายหัก
  const [tax,setTax] = useState(""); //หักภาษี
  const [insurance,setInsurance] = useState(""); //ประกันสังคม
  const [manualInsurance,setManualInsurance] = useState(false);
  const [late,setLate] = useState(""); //หักค่าเข้างานสาย
  const [missing,setMissing] = useState(""); //ขาดงาน
  const [borrow,setBorrow] = useState(""); //กองทุนกู้ยืม กยศ.
  const [withdraw,setWithdraw] = useState(""); //เงินเบิกล่วงหน้า
  const [other,setOther] = useState("") //เงินหักอื่นๆ
  const [lend,setLend] = useState("") // เงินยืมชำระเป็นงวด
  const [liveFund,setLiveFund] = useState("") // กองทุนสำรองเลี้ยงชีพ
  const [withholding,setWithholding] = useState("") // หัก ณ ที่จ่าย 

  const [amount,setAmount] = useState(""); //รายได้สุทธิ
  const [manualAmount,setManualAmount] = useState(false);
  const [deposit,setDeposit] = useState("");// รวมรายได้
  const [manualDeposit, setManualDeposit] = useState(false);
  const [allDeposit,setAllDeposit] = useState(""); // รายได้สะสม
  const [manualallDeposit,setManualallDeposit] = useState(false);
  const [allWithdraw,setAllWithdraw] = useState(""); //รวมรายหัก
  const [manualallWithdraw, setManualallWithdraw] = useState(false);
  const [allInsurance,setAllInsurance] = useState(""); //รวมประกันสังคม
  const [manualAllIns,setManualAllIns] = useState(false);
  const [oldDeposit,setOldDeposit] = useState("");
  const [oldWithdraw,setOldWithdraw] = useState("");
  const [oldInsurance,setOldInsurance] = useState("");
  const [confirm,setConfirm] = useState(false);
  const [urlPDF,setURLpdf] = useState('');
  // เวลาทำงาน
  const [amountDay,setAmountDay] = useState(null);
  const [earnPerDay,setEarnPerDay] = useState("");
  const [costPerDay,setCostPerDay] = useState("") //ค่าแรง/วัน
  const [manualPerDay, setManualPerDay] = useState(false);
  const [amountHr,setAmountHr] = useState(null);
  const [earnPerHr,setEarnPerHr] = useState("");
  const [costPerHr,setCostPerHr] = useState("") //ค่าแรง/ชั่วโมง
  const [manualPerHr, setManualPerHr] = useState(false);
  const [lateFee,setLateFee] = useState("") //ค่าปรับสาย
  const [lateDay,setLateDay] = useState(null) //จำนวนวันมาสาย
  const [manualLate, setManualLate] = useState(false);
  const [attendanceFee,setAttendanceFee] = useState("") //ขาดงาน
  const [attendenceDay,setAttendenceDay] = useState(null) //จำนวนวันขาดงาน
  const [manualMissing, setManualMissing] = useState(false)
  const [workTimeCost,setWorkTimeCost] = useState("") // รวมค่าเวลาทำงาน
  const [manualWorkTimeCost, setManualWorkTimeCost] = useState(false);

  //คิด OT
  const [ot,setOT] = useState(0); //ค่าล่วงเวลา
  const [otHr1_5,setOtHr1_5] = useState(null);
  const [manualOtHr1_5, setManualOtHr1_5] = useState(false)
  const [otHr2,setOtHr2] = useState(null);
  const [manualOtHr2, setManualOtHr2] = useState(false)
  const [otResult1_5,setOtResult1_5] = useState("");
  const [otResult2,setOtResult2] = useState("");
  const [allOT,setAllOT] = useState("");
  const [manualAllOT, setManualAllOT] = useState(false)

  const [sociteIns,setSociteIns] = useState("") // %ประกันสังคม
  const [sociteInsMax,setSociteInsMax] = useState("")

  const [depositOnBill,setDepositOnBill] = useState(null)
  const [deductionOnBill,setDeductionOnBill] = useState(null)
  
  const [disableAutoIns,setDisableAutoIns] = useState(false);
  const [disableAutoSub,setDisableAutoSub] = useState(false);
  const [disableAutoOT1_5,setDisableAutoOT1_5] = useState(false);
  const [disableAutoOT2,setDisableAutoOT2] = useState(false);
  const [disableEarnDay,setDisableEarnDay] = useState(false);
  const { setCurrentUser, companyId, company_name } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);


  const getUserSuccess=(data)=>{
    setName(data.name+" "+data.lastname)
    setPosition(data.position)
    setSalary(data.salary.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    
    if(data.sub){
      setDisableAutoSub(true)
      setSub(data.sub.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
    setOT(data.allOT.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setAllowance(data.allowance.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setVenhicle(data.venhicle.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    console.log(data.venhicle)
    setWelth(data.welth.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setBonus(data.bonus.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setCostL(data.costL.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    if(data.insurance){
      setDisableAutoIns(true)
      setInsurance(data.insurance.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
    setLate(data.late.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setWithdraw(data.withdraw.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setBorrow(data.borrow.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setMissing(data.missing.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setTax(data.tax.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setAllDeposit(data.allDeposit.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setAllInsurance(data.allInsurance.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setAllWithdraw(data.allWithdraw?.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) || "0");
    setOldDeposit(data.allDeposit.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setOldInsurance(data.allInsurance.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }))
    setOldWithdraw(data.allWithdraw?.toLocaleString('th-TH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) || "0")
    setConfirm(false)
  }

  const getUserUnsuccess=(e)=>{
    console.log(e)
  }

  const getBillSuc = (billData) => {
    // ใบเดียว
    setDate(dayjs(billData.date, "DD-MM-YYYY"));
    setName(billData.name);
    setPosition(billData.position || "");

    setSalary(formatCurrency(billData.salary));
    
    if(billData.sub){
      setDisableAutoSub(true)
      setSub(formatCurrency(billData.sub));
    }
    if(billData.earnPerDay){
      setDisableEarnDay(true)
      setEarnPerDay(formatCurrency(billData.earnPerDay));
    }
    setOT(formatCurrency(billData.ot));
    setAllOT(formatCurrency(billData.allOT));
    setAllowance(formatCurrency(billData.allowance));
    setVenhicle(formatCurrency(billData.venhicle));
    setWelth(formatCurrency(billData.welth));
    setBonus(formatCurrency(billData.bonus));
    setCostL(formatCurrency(billData.costL));

    setLate(formatCurrency(billData.late));
    setWithdraw(formatCurrency(billData.withdraw));
    setBorrow(formatCurrency(billData.borrow));
    setMissing(formatCurrency(billData.missing));
    setTax(formatCurrency(billData.tax));

    setAllWithdraw(formatCurrency(billData.allWithdraw || 0));
    setAllInsurance(formatCurrency(billData.allInsurance));
    setOldDeposit(formatCurrency(billData.oldDeposit));
    setOldInsurance(formatCurrency(billData.oldInsurance));
    setOldWithdraw(formatCurrency(billData.oldWithdraw || 0));

    
    setAmountDay(formatCurrency(billData.amountDay));
    setEarnPerHr(formatCurrency(billData.earnPerHr));
    setAmountHr(formatCurrency(billData.amountHr));
    setLateDay(formatCurrency(billData.lateDay));
    setAttendenceDay(formatCurrency(billData.attendenceDay));
    setCostPerDay(formatCurrency(billData.costPerDay));
    setCostPerHr(formatCurrency(billData.costPerHr));
    setLateFee(formatCurrency(billData.lateFee));
    setAttendanceFee(formatCurrency(billData.attendanceFee));
    setWorkTimeCost(formatCurrency(billData.workTimeCost));
    setOtResult1_5(formatCurrency(billData.otResult1_5));
    setOtHr1_5(formatCurrency(billData.otHr1_5));
    setOtResult2(formatCurrency(billData.otResult2));
    setOtHr2(formatCurrency(billData.otHr2));

    setSociteIns(formatCurrency(billData.sociteIns));
    setSociteInsMax(formatCurrency(billData.sociteInsMax));
    setWithholding(formatCurrency(billData.withholding));
    setLiveFund(formatCurrency(billData.liveFund));
    setOther(formatCurrency(billData.other));
    setAmount(formatCurrency(billData.amount));

    setConfirm(billData.confirm);
    setURLpdf(billData.urlPDF);

    if (billData.confirm) {
      setEditable(false);
    }
  };

  const getBillUnsuc=()=>{

  }

  const saveSuc=()=>{
    setIsLoading(false)
    Swal.fire({
      title: 'สำเร็จ',
      text: 'บันทึกข้อมูลสำเร็จ',
      // text: `ไม่สามารถเพิ่มพนักงานได้เกิน ${maxAllowed} คน (plan: ${userPlan})`,
      icon: 'success',
      customClass: {
        popup: 'my-swal-size'
      },
    });
    navigate('/salary_list',{state:{uid:id}})
  }

  const saveUnsuc=(e)=>{
    console.log(e)
    setIsLoading(false)
    Swal.fire({
      title: 'พบข้อผิดพลาด',
      // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
      text: `เกิดปัญหา error`,
      icon: 'error',
      customClass: {
        popup: 'my-swal-size'
      },
    });
  }

  const onSave=()=>{
    setIsLoading(true)
    let date_str = date.format('DD/MM/YYYY');
    let item ={
      id:id,
      name:name,
      position:position,
      date:date_str,
      costL: parseNumber(costL),
      allOT: parseNumber(allOT),
      allowance: parseNumber(allowance),
      salary: parseNumber(salary),
      venhicle: parseNumber(venhicle),
      sub: parseNumber(sub),
      welth: parseNumber(welth),
      bonus: parseNumber(bonus),
      tax: parseNumber(tax),
      insurance: parseNumber(insurance),
      late: parseNumber(late),
      missing: parseNumber(missing),
      borrow: parseNumber(borrow),
      withdraw: parseNumber(withdraw),
      deposit: parseNumber(deposit),
      allDeposit: parseNumber(allDeposit),
      allWithdraw: parseNumber(allWithdraw),
      allInsurance: parseNumber(allInsurance),
      amount: parseNumber(amount),

      earnPerDay: parseNumber(earnPerDay),
      amountDay: parseNumber(amountDay),
      earnPerHr: parseNumber(earnPerHr),
      amountHr: parseNumber(amountHr),
      lateDay: parseNumber(lateDay),
      attendenceDay: parseNumber(attendenceDay),
      costPerDay: parseNumber(costPerDay),
      costPerHr: parseNumber(costPerHr),
      lateFee: parseNumber(lateFee),
      attendanceFee: parseNumber(attendanceFee),
      workTimeCost: parseNumber(workTimeCost),

      otResult1_5: parseNumber(otResult1_5),
      otHr1_5: parseNumber(otHr1_5),
      otResult2: parseNumber(otResult2),
      otHr2: parseNumber(otHr2),

      sociteIns: parseNumber(sociteIns),
      sociteInsMax: parseNumber(sociteInsMax),
      withholding: parseNumber(withholding),
      liveFund: parseNumber(liveFund),
      other: parseNumber(other),

      oldDeposit: parseNumber(oldDeposit),
      oldWithdraw: parseNumber(oldWithdraw),
      oldInsurance: parseNumber(oldInsurance),
      confirm:false,
      urlPDF:urlPDF,
    }
    console.log('save')
    if(status == "edit"){
      firestore.updateBill(companyId, uid, item, saveSuc, saveUnsuc);
    }else{
      firestore.addBill(companyId,item,saveSuc,saveUnsuc)
    }
  }

  useEffect(() => {
    const onSuccess = (data) => {
      if (data) {
        setCostPerDay(
          parseFloat(data.costPerDay || 0).toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
        setCostPerHr(
          parseFloat(data.costPerHr || 0).toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
        setLateFee(
          parseFloat(data.late || 0).toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
        setAttendanceFee(
          parseFloat(data.attendance || 0).toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
        // สมมุติว่า Firestore เก็บ sociteIns เป็นเลขทศนิยม (เช่น 0.05) แต่เราต้องการแสดงเป็น 5.00%
        setSociteIns(
          data?.sociteIns
            ? (parseFloat(data.sociteIns)).toLocaleString('th-TH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            : "0.00"
        );
        setSociteInsMax(
          data.sociteInsMax
            ? parseFloat(data.sociteInsMax).toLocaleString('th-TH', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            : "0.00"
        );
      } else {
        setCostPerDay(0);
        setCostPerHr(0);
        setLateFee(0);
        setAttendanceFee(0);
        setSociteIns(0);
        setSociteInsMax(0);
      }
    };
    const onError = (error) => {
      console.error(error);
      alert('Error loading data: ' + error.message);
    };

    if (location.state && location.state.uid) {
      setId(location.state.uid);
      //console.log('from eff'+uid)
      if(location.state.act == "cal"){
        firestore.getUser(companyId,location.state.uid,getUserSuccess,getUserUnsuccess)
        //firestore.getSalarySetting(companyId, onSuccess, onError);
        firestore.getWelth(companyId,location.state.uid,(data)=>{
        // data.attendence เป็น number (เช่น 2)
        // เก็บเป็น string + comma
        setAttendenceDay(
          (data.attendence || 0).toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        );
      },
          (e)=>console.log(e));
      }
      if(location.state.act=="edit"){
        console.log("edit "+ location.state.id)
        setStatus(location.state.act)
        setUid(location.state.id)
        firestore.getBill(companyId,location.state.id,location.state.date,getBillSuc,getBillUnsuc)
      }
      firestore.getSalarySetting(companyId, onSuccess, onError);
    } else {
      console.warn('No ID found in location state');
    }
   
  }, [location.state]);

  const parseNumber = (value) => parseFloat((value || '0').replace(/,/g, '')) || 0;


  useEffect(() => {
    if(location.state.act=='cal'){
      if(!amountDay){
        let suds= (sub.replace(/,/g, ""))
        console.log(sub)
        setEarnPerDay(sub)
        // if(costPerDay){
        //   setAmountDay((sub/costPerDay).toFixed(2))
        // }
      }else{
        if (!manualPerDay) {
          const dayCount = parseNumber(amountDay);
          const dailyRate = parseNumber(costPerDay);
          const autoPerDay = dayCount * dailyRate;
          setEarnPerDay(autoPerDay.toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
          setSub(autoPerDay.toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
      }
    }
  }, [amountDay, manualPerDay, costPerDay,sub]);

  useEffect(() => {
    if(location.state=='edit'){
      if(disableEarnDay){
        setEarnPerDay(earnPerDay)
        // if(costPerDay){
        //   setAmountDay((sub/costPerDay).toFixed(2))
        // }
      }else{
        if (!manualPerDay) {
          const dayCount = parseNumber(amountDay);
          const dailyRate = parseNumber(costPerDay);
          const autoPerDay = dayCount * dailyRate;
          setEarnPerDay(autoPerDay.toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
          setSub(autoPerDay.toLocaleString('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
      }
    }
  }, [amountDay, manualPerDay, costPerDay,sub]);

  useEffect(() => {
    if (!manualPerHr) {
      const hourCount = parseNumber(amountHr);
      const hourlyRate = parseNumber(costPerHr);
      const autoPerHr = hourCount * hourlyRate;
      setEarnPerHr(autoPerHr.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [amountHr, costPerHr, manualPerHr]);

  useEffect(() => {
    if (!manualLate) {
      const lateMinutes = parseNumber(lateDay);
      const latePenalty = parseNumber(lateFee);
      const autoLate = lateMinutes * latePenalty;
      setLate(autoLate.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [lateDay, lateFee, manualLate]);

  useEffect(() => {
    if (!manualMissing) {
      const absentDays = parseNumber(attendenceDay);
      const absencePenalty = parseNumber(attendanceFee);
      const autoMissing = absentDays * absencePenalty;
      setMissing(autoMissing.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [attendenceDay, attendanceFee, manualMissing]);

  useEffect(() => {
    if (!manualWorkTimeCost) {
      const s = parseFloat((salary || "0").replace(/,/g, ""));
      const eDay = parseFloat((earnPerDay || "0").replace(/,/g, ""));
      const eHr = parseFloat((earnPerHr || "0").replace(/,/g, ""));
      const l = parseFloat((late || "0").replace(/,/g, ""));
      const m = parseFloat((missing || "0").replace(/,/g, ""));
  
      const cal = s + eDay + eHr - l - m;
      setWorkTimeCost(
        cal.toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
  }, [salary, late, missing, manualWorkTimeCost,earnPerDay,earnPerHr]);

  useEffect(() => {
    if (!manualOtHr1_5) {
      const hours = parseNumber(otHr1_5);
      const hourlyRate = parseNumber(costPerHr);
      const autoOtHr1_5 = hours * hourlyRate * 1.5;
      setOtResult1_5(autoOtHr1_5.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [otHr1_5, costPerHr, manualOtHr1_5]);

  useEffect(() => {
    if (!manualOtHr2) {
      const hours = parseNumber(otHr2);
      const hourlyRate = parseNumber(costPerHr);
      const autoOtHr2 = hours * hourlyRate * 2;
      setOtResult2(autoOtHr2.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [otHr2, costPerHr, manualOtHr2]);

  useEffect(() => {
    if (!manualAllOT) {
      // สมมุติสูตร = salary - late - missing
      const ot1 = parseNumber(otResult1_5);
      const ot2 = parseNumber(otResult2);
      const cal = ot1 + ot2;
      setAllOT(cal.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [otResult1_5, otResult2, manualAllOT]);

  useEffect(() => {
    if (!manualSub&&!disableAutoSub) {
      const cal = parseFloat(earnPerDay || 0) //+ parseFloat(earnPerHr || 0)
      setSub(cal.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
    // setSub(0);
  }, [earnPerDay, earnPerHr,manualSub]);

  useEffect(() => {
    if (!manualDeposit) {
      const calculated = [
        parseNumber(workTimeCost),
        parseNumber(allOT),
        parseNumber(allowance),
        parseNumber(venhicle),
        parseNumber(welth),
        parseNumber(bonus),
        parseNumber(costL),
      ].reduce((acc, val) => acc + val, 0);
      const cal = parseFloat(workTimeCost || 0) + parseFloat(allOT || 0) + parseFloat(allowance || 0)+ parseFloat(venhicle || 0) 
                  + parseFloat(welth || 0) + parseFloat(bonus || 0) + parseFloat(costL || 0);
      setDeposit(calculated.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [workTimeCost, sub, allOT, allowance,venhicle,welth,bonus,costL,manualDeposit]);

  useEffect(() => {
    if (!manualInsurance && !disableAutoIns) {
      const salaryNum = parseFloat(String(salary || "0").replace(/,/g, ''));
      const subNum = parseFloat(String(sub || "0").replace(/,/g, ''));
      const base = salaryNum + subNum;
  
      const rate = parseFloat(String(sociteIns || "0").replace(/,/g, ''));
      const max = parseFloat(String(sociteInsMax || "0").replace(/,/g, ''));
  
      if (base > 0 && rate > 0) {
        let ins = base * rate; // สูตรคูณ
        if (ins > max && max > 0) {
          ins = max; // ถ้าเกินเพดาน
        }
        setInsurance(ins.toLocaleString('th-TH', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      } else {
        // กรณี base <= 0 หรือ rate <= 0 ถือว่า = 0
        setInsurance('0.00');
      }
    }
  }, [insurance, withholding, withdraw,borrow,liveFund,tax,other,manualallWithdraw,salary,sociteIns,sociteInsMax,sub]);

  useEffect(() => {
    if (!manualallWithdraw) {
      //let costIns = insurance || (((salary&&sub&&sociteIns)||sociteInsMax)?((parseFloat(salary)+parseFloat(sub))*parseFloat(sociteIns))>sociteInsMax?setInsurance(sociteInsMax):setInsurance((parseFloat(salary)+parseFloat(sub))*parseFloat(sociteIns)):0)
      const calculated = [
        parseNumber(insurance),
        parseNumber(withholding),
        parseNumber(withdraw),
        parseNumber(borrow),
        parseNumber(liveFund),
        parseNumber(tax),
        parseNumber(other),
      ].reduce((acc, val) => acc + val, 0);
      const cal = parseFloat(insurance || 0) + parseFloat(withholding || 0) + parseFloat(withdraw || 0)+ parseFloat(borrow || 0) 
                  + parseFloat(liveFund || 0) + parseFloat(tax || 0) + parseFloat(other || 0);
      setAllWithdraw(calculated.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [insurance, withholding, withdraw,borrow,liveFund,tax,other,manualallWithdraw,salary,sociteIns,sociteInsMax,sub]);

  useEffect(() => {
    if (!manualallDeposit) {
      const cal = parseNumber(oldDeposit) + parseNumber(deposit);
      setAllDeposit(cal.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [oldDeposit, amount,manualallDeposit,deposit]);

  useEffect(() => {
    if (!manualAmount) {
      const cal = parseNumber(deposit) - parseNumber(allWithdraw);
      setAmount(cal.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [allDeposit, allWithdraw,manualAmount,deposit]);

  useEffect(() => {
    if (!manualAllIns) {
      //let costIns = insurance || ((salary&&sociteIns&&sociteInsMax)?((parseFloat(salary)+parseFloat(sub))*parseFloat(sociteIns))>sociteInsMax?setInsurance(sociteInsMax):setInsurance((parseFloat(salary)+parseFloat(sub))*parseFloat(sociteIns)):0)
      const cal = parseNumber(oldInsurance) + parseNumber(insurance);
      setAllInsurance(cal.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    }
  }, [oldInsurance, salary,sociteIns,sociteInsMax,manualAllIns,insurance]);

  const handleDayChange = (e) => {
    const val = validateNumberInput(e.target.value);
    setEarnPerDay(val !== '' ? val : 0);
    setManualPerDay(true);  // บอกว่า user override แล้ว
  };

  const handleHrChange = (e) => {
    const val = validateNumberInput(e.target.value);
    setEarnPerHr(val !== '' ?val: 0);
    setManualPerHr(true);  // บอกว่า user override แล้ว
  };

  const handleLateChange = (e) => {
    const val = validateNumberInput(e.target.value);
    setLate(val !== '' ? val : 0);
    setManualLate(true);  // บอกว่า user override แล้ว
  };

  const handleMissingChange = (e) => {
    const val = validateNumberInput(e.target.value);
    setMissing(val !== '' ? val : 0);
    setManualMissing(true);
  };

  const handleWorkTimeCostChange = (e) => {
    const val = validateNumberInput(e.target.value);
    setWorkTimeCost(val !== '' ? val : 0);
    setManualWorkTimeCost(true);
  };

  const handleAllOT = (e) => {
    const val = validateNumberInput(e.target.value);
    setAllOT(val !== '' ? val : 0);
    setManualAllOT(true);
  };

  const handleSub = (e) => {
    const val = validateNumberInput(e.target.value);
    setSub(val !== '' ? val : 0);
    setManualSub(true);
  };

  const handleIns = (e) => {
    const val = validateNumberInput(e.target.value);
    setInsurance(val !== '' ? val : 0);
    setManualInsurance(true);
  };

  const formatCurrency = (value) => {
    //return new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(value || 0);
    return new Intl.NumberFormat('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value || 0);
  };

 const sanitizeInput = (input) => {
    // ตัดอักขระที่ไม่ใช่ตัวเลขหรือจุด
    let sanitized = input.replace(/[^0-9.]/g, '');
  
    // ตรวจสอบว่ามีกี่จุด (.)
    const parts = sanitized.split('.');
    if (parts.length > 2) {
      // กรณีมีจุดมากกว่า 1 จุด (เช่น "12.3.4"),
      // ให้เก็บเฉพาะส่วน before-dot + dot + after-dot ส่วนแรก
      // เช่น ["12", "3", "4"] -> "12.3"
      sanitized = parts[0] + '.' + parts[1];
    }
  
    return sanitized;
  };
  
  // 2) ฟังก์ชัน removeLeadingZeros
  const removeLeadingZeros = (input) => {
    if (input === '' || input === '.') {
      // กรณีพิมพ์แค่ '.' ก็ยังอนุญาตค้างไว้ได้
      return input;
    }
    // ถ้า input เป็น '0.???' ให้เก็บไว้เป็นรูปทศนิยม เช่น '0.12' => ไม่ต้องลบ
    if (input.startsWith('0.') ) {
      return input;
    }
    // กรณีเริ่มด้วย '0' แต่มีมากกว่า 1 หลัก เช่น '07', '0005'
    // parseFloat('0005') => 5 -> แปลงเป็น string '5'
    // parseFloat('000.12') => 0.12 -> '0.12'
    if (input.startsWith('0') && input.length > 1) {
      return parseFloat(input).toString();
    }
  
    return input;
  };
  
  const validateNumberInput = (input) => {
    // ขั้นตอนที่ 1 : remove non-numeric + จำกัดจุดได้แค่จุดเดียว
    let sanitized = sanitizeInput(input);
  
    // ขั้นตอนที่ 2 : remove leading zeros
    let noLeadingZeros = removeLeadingZeros(sanitized);
  
    return noLeadingZeros;
  };

  const exportToPDF = async () => {
    setIsLoading(true)
  const doc = new jsPDF();
  
  doc.addFileToVFS("THSarabunNew.ttf", THSarabunNew.base64);
  doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
  doc.setFont("THSarabunNew");
  
  // Add title and company information
  doc.setFontSize(24);
  doc.text(company_name, 14, 22);
  doc.setFontSize(20);
  doc.text("ใบแจ้งเงินเดือน (PAY SLIP)", 14, 30);
  doc.text(`ชื่อ: ${name}`, 14, 38);
  doc.text(`ตำแหน่ง: ${position}`, 14, 46);  // You can add the department dynamically if available
  doc.text(`วันที่จ่าย (DATE): ${date.format('DD/MM/YYYY')}`, 14, 54);
  
  // Define columns and rows for the table
  const tableColumn = [
    [{ content: "รวมรายได้", colSpan: 2, styles: { halign: 'center' } }, { content: "รวมเงินหัก", colSpan: 2, styles: { halign: 'center' } }]
  ];
  let subs = earnPerDay > 0 ?earnPerDay: earnPerHr
  // const tableRows = [
  //   ["เงินเดือน", salary.toLocaleString('th-TH', { minimumFractionDigits: 2 }), "ประกันสังคม", insurance.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   ["ค่าจ้าง", subs.toLocaleString('th-TH', { minimumFractionDigits: 2 }), "ภาษีหัก ณ ที่จ่าย", late.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   ["ค่าพาหนะ", venhicle.toLocaleString('th-TH', { minimumFractionDigits: 2 }), "เงินเบิกล่วงหน้า", withdraw.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   ["ค่าเบี้ยเลี้ยง", allowance.toLocaleString('th-TH', { minimumFractionDigits: 2 }), "เงินกู้ยืมสวัสดิการ", borrow.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   ["ค่าล่วงเวลา", ot.toLocaleString('th-TH', { minimumFractionDigits: 2 }),  "กองทุนสำรองเลี้ยงชีพ", liveFund.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   ["ค่าสวัสดิการ", welth.toLocaleString('th-TH', { minimumFractionDigits: 2 }),  "ภาษีเงินได้", tax.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   ["เงินโบนัส", bonus.toLocaleString('th-TH', { minimumFractionDigits: 2 }), "เงินหักอื่นๆ", other.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   ["เงินโบนัส/พิเศษ", costL.toLocaleString('th-TH', { minimumFractionDigits: 2 }), "-", "-"],
  //   ["สาย", `- ${late.toLocaleString('th-TH', { minimumFractionDigits: 2 })}`, "-", "-"],
  //   ["ขาดงาน", `- ${missing.toLocaleString('th-TH', { minimumFractionDigits: 2 })}`, "-", "-"],
  //   ["เงินได้สะสม", allDeposit.toLocaleString('th-TH', { minimumFractionDigits: 2 }), "ประกันสังคมสะสม", allInsurance.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   ["รวมเงินได้", deposit.toLocaleString('th-TH', { minimumFractionDigits: 2 }), "รวมเงินหัก", allWithdraw.toLocaleString('th-TH', { minimumFractionDigits: 2 })],
  //   // [{ content: "เงินได้สุทธิ", colSpan: 4, styles: { halign: 'center' } }]
  // ];

  const tableRows = [
    ["เงินเดือน", salary, "ประกันสังคม", insurance],
    ["ค่าจ้าง", sub, "ภาษีหัก ณ ที่จ่าย", late],
    ["ค่าพาหนะ", venhicle, "เงินเบิกล่วงหน้า", withdraw],
    ["ค่าเบี้ยเลี้ยง", allowance, "เงินกู้ยืมสวัสดิการ", borrow],
    ["ค่าล่วงเวลา", ot,  "กองทุนสำรองเลี้ยงชีพ", liveFund],
    ["ค่าสวัสดิการ", welth,  "ภาษีเงินได้", tax],
    ["เงินโบนัส", bonus, "เงินหักอื่นๆ", other],
    ["เงินโบนัส/พิเศษ", costL, "-", "-"],
    ["สาย", `- ${late}`, "-", "-"],
    ["ขาดงาน", `- ${missing}`, "-", "-"],
    ["เงินได้สะสม", allDeposit, "ประกันสังคมสะสม",allInsurance],
    ["รวมเงินได้",deposit, "รวมเงินหัก", allWithdraw],
    // [{ content: "เงินได้สุทธิ", colSpan: 4, styles: { halign: 'center' } }]
  ];
  
  // Add the table with custom rendering for description and value
  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: 60,
    styles: {
      font: 'THSarabunNew',
      fontSize: 18,
      cellPadding: 3,
      overflow: 'linebreak',
      halign: 'right', // Align numbers to the right
      valign: 'middle',
      lineWidth: 0.5,
      lineColor: [0, 0, 0],
    },
    headStyles: {
      fillColor: [191, 228, 255], // Light blue background for header
      textColor: [0, 0, 0], // Black text color for header
      halign: 'center', // Center-align header
    },
    columnStyles: {
      0: { halign: 'left' }, // Left align for the first column (descriptions)
      1: { halign: 'right',cellWidth: 30 ,}, // Right align for the second column (values)
      2: { halign: 'left' }, // Left align for the third column (descriptions)
      3: { halign: 'right',cellWidth: 30 }, // Right align for the fourth column (values)
    },
    tableLineColor: [0, 0, 0], // Color of table borders
    tableLineWidth: 0.75,
    didDrawCell: (data) => {
      // Remove border between column 1 and 2
      if (data.column.index === 1) {
        doc.setDrawColor(255, 255, 255); // Set the draw color to white to "erase" the border
        doc.line(data.cell.x - 0.5, data.cell.y, data.cell.x - 0.5, data.cell.y + data.cell.height);
      }
      // Remove border between column 3 and 4
      if (data.column.index === 3) {
        doc.setDrawColor(255, 255, 255); // Set the draw color to white to "erase" the border
        doc.line(data.cell.x - 0.5, data.cell.y, data.cell.x - 0.5, data.cell.y + data.cell.height);
      }
    },
  });
  
  // Calculate the final line (Net Salary)
  const netSalary = amount;
  doc.setFontSize(20);
  doc.setTextColor(0, 0, 0);
  doc.setFillColor(191, 228, 255); // Light blue background color
  doc.rect(14, doc.lastAutoTable.finalY + 10, 182, 10, 'F'); // Draw a filled rectangle
  //doc.text(`เงินได้สุทธิ: ${netSalary.toLocaleString('th-TH', { minimumFractionDigits: 2 })}`, 18, doc.lastAutoTable.finalY + 17);
  doc.text(`เงินได้สุทธิ: ${netSalary}`, 18, doc.lastAutoTable.finalY + 17);
  
  // Save or open the PDF
  const pdfDataUri = doc.output('dataurlstring');
  const newWindow = window.open();
  newWindow.document.write(`<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`);

   // Save the PDF to the user's device
   const pdfBlob = doc.output('blob');
   const pdfFileName = `${name}_salary_${date.format('DD_MM_YYYY')}.pdf`;
   saveAs(pdfBlob, pdfFileName);
 
   //Upload the PDF to Firebase Storage
   try {
    const downloadURL = await storage.uploadBill(companyId, pdfFileName, pdfBlob);
 
     // Store the download URL in Firestore
     setURLpdf(downloadURL)
     //alert('PDF uploaded and link saved successfully!');
     if(!urlPDF){
      firestore.updateBill(companyId, uid, {urlPDF:downloadURL}, saveSuc, saveUnsuc);
    }
   } catch (error) {
     console.error('Error uploading PDF: ', error);
     //alert('Error uploading PDF.');
     setIsLoading(false)
      Swal.fire({
        title: 'พบข้อผิดพลาด',
        // text: 'บันทึกข้อมูลพื้นที่ปฏิบัติการสำเร็จ',
        text: `เกิดปัญหา error: ${error}`,
        icon: 'error',
        customClass: {
          popup: 'my-swal-size'
        },
      });
   }
};
  
  const handleBlur2Decimals=(value, setFn)=> {
    if (value === '' || value === '.') {
      setFn('0.00');
      return;
    }
    const numeric = parseFloat(value);
    if (!isNaN(numeric)) {
      // setFn(formatCurrency(numeric.toFixed(2)));
      setFn(numeric.toLocaleString('th-TH', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));
    } else {
      setFn('0.00');
    }
    //setManualWorkTimeCost(false);
  }

  return (
    
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Layout />
        <main className="main-content">
          
          <div className="main">
            <div className='header-page'>
              <header>
                <h1>คำนวณเงินเดือน</h1>
                {/* Add user profile and logout here */}
              </header>
              </div>
            <div className="main-contain">
              
              <div style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%'}}>
                <p style={{fontSize:28,marginTop:20}}>เงินเดือน: {name}</p>
                <div style={{display:'flex', gap: '10px', marginBottom: '10px',flexDirection:'row',alignItems:'center'}}>
                  <p style={{fontSize:24}}>วันออกบิลเงินเดือน</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                        <DatePicker
                        label="วันที่"
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        disabled={!editable}
                        />
                  </LocalizationProvider>
                </div>
                {/* คำนวณเวลาทำงาน */}
                <p style={{fontSize:24,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>คำนวณเวลาทำงาน</p>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px',justifyItems:'center',alignItems:'center' }}>
                  <div style={{width:'10%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end',
                        paddingInlineStart:5
                    }}>
                    เงินเดือน :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                  </p></div>
                  <TextField
                    //label="เงินเดือน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                    value={salary}
                    onChange={(e) => {
                        const val = validateNumberInput(e.target.value);
                        setSalary(val !== '' ? val : 0);
                      }}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setSalary(salary.replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(salary, setSalary)}
                  />
                  
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                <div style={{width:'10%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end',
                        paddingInlineStart:5
                    }}>
                    ค่าแรงรายวัน :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                      </p></div>
                      <TextField
                        //label="เงินเดือน"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        disabled
                        value={earnPerDay}
                        onChange={handleDayChange}
                      />
                      <TextField
                        label="จำนวนวัน"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                        value={amountDay}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setAmountDay(val !== '' ? val : 0);
                          setDisableEarnDay(false)
                          }}
                      />
                      <p style={{ 
                        marginRight:'1.25%', 
                        // display: 'inline-flex', 
                        alignItems: 'center',
                        // paddingInlineStart:5
                    }}>X</p>
                      <TextField
                        label="ค่าแรง/วัน"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                        value={costPerDay}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setCostPerDay(val !== '' ? val : 0);
                          setDisableEarnDay(false)
                          }}
                        onFocus={() => {
                          setCostPerDay(String(costPerDay).replace(/,/g, ''));
                        }}
                        onBlur={() => handleBlur2Decimals(costPerDay, setCostPerDay)}
                      />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                <div style={{width:'10%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end',
                        paddingInlineStart:5
                    }}>
                    ค่าแรง/ชั่วโมง :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                      </p></div>
                      <TextField
                        //label="เงินเดือน"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        disabled
                        value={earnPerHr}
                        onChange={ handleHrChange}
                      />
                      <TextField
                        label="ชั่วโมง"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                        value={amountHr}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setAmountHr(val !== '' ? val : 0);
                          }}
                        onBlur={() => handleBlur2Decimals(amountHr, setAmountHr)}
                      />
                      <p style={{ 
                        marginRight:'1.25%', 
                        // display: 'inline-flex', 
                        alignItems: 'center',
                        // paddingInlineStart:5
                    }}>X</p>
                      <TextField
                        label="ค่าแรง/ชั่วโมง"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                        value={costPerHr}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setCostPerHr(val !== '' ? val : 0);
                          }}
                          onFocus={() => {
                            setCostPerHr(String(costPerHr).replace(/,/g, ''));
                          }}
                          onBlur={() => handleBlur2Decimals(costPerHr, setCostPerHr)}
                      />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                <div style={{width:'10%',marginRight:'1.25%',}}>
                    <p style={{ 
                        margin: 0, 
                        display: 'inline-flex', 
                        alignItems: 'flex-end',
                        paddingInlineStart:5
                    }}>
                    สาย :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                      </p></div>
                      <TextField
                        //label="เงินเดือน"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        disabled
                        value={late}
                        onChange={handleLateChange}
                      />
                      <TextField
                        label="นาที"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                        value={lateDay}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setLateDay(val !== '' ? val : 0);
                          }}
                        onBlur={() => handleBlur2Decimals(lateDay, setLateDay)}
                      />
                      <p style={{ 
                        marginRight:'1.25%', 
                        // display: 'inline-flex', 
                        alignItems: 'center',
                        // paddingInlineStart:5
                    }}>X</p>
                      <TextField
                        label="ค่าปรับ/นาที"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                        value={lateFee}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setLateFee(val !== '' ? val : 0);
                          }}
                          onFocus={() => {
                            setLateFee(String(lateFee).replace(/,/g, ''));
                          }}
                        onBlur={() => handleBlur2Decimals(lateFee, setLateFee)}
                      />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <div style={{width:'10%',marginRight:'1.25%',}}>
                  <p style={{ 
                      margin: 0, 
                      display: 'inline-flex', 
                      alignItems: 'flex-end',
                      paddingInlineStart:5
                  }}>
                    ขาดงาน :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                  </p></div>
                  <TextField
                    //label="เงินเดือน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    disabled
                    value={missing}
                    onChange={
                      handleMissingChange
                      }
                  />
                  <TextField
                        label="จำนวนวัน"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                        value={attendenceDay}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setAttendenceDay(val !== '' ? val : 0);
                          }}
                      />
                      <p style={{ 
                        marginRight:'1.25%', 
                        // display: 'inline-flex', 
                        alignItems: 'center',
                        // paddingInlineStart:5
                    }}>X</p>
                      <TextField
                        label="ค่าปรับ/วัน"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                        value={attendanceFee}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setAttendanceFee(val !== '' ? val : 0);
                          }}
                          onFocus={() => {
                            setAttendanceFee(String(attendanceFee).replace(/,/g, ''));
                          }}
                        onBlur={() => handleBlur2Decimals(attendanceFee, setAttendanceFee)}
                      />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                    <TextField
                        label="รวมเวลาทำงาน"
                        className="form-field"
                        variant="filled"
                        style={{width:'100%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                        value={workTimeCost}
                        onChange={
                          handleWorkTimeCostChange
                          }
                        onFocus={() => {
                          // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                          setWorkTimeCost(workTimeCost.replace(/,/g, ''));
                        }}
                        onBlur={() => handleBlur2Decimals(workTimeCost, setWorkTimeCost)}
                      />
                </div>
                {/* คำนวณโอที */}
                <p style={{fontSize:24,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>คำนวณโอที</p>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <div style={{width:'10%',marginRight:'1.25%',}}>
                  <p style={{ 
                      margin: 0, 
                      display: 'inline-flex', 
                      alignItems: 'flex-end',
                      paddingInlineStart:5
                  }}>
                    1.5 เท่า :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                  </p></div>
                  <TextField
                    //label="เงินเดือน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    disabled
                    value={otResult1_5}
                    // onChange={handleMissingChange}
                  />
                  <TextField
                        label="จำนวนชั่วโมง"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                        value={otHr1_5}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setOtHr1_5(val !== '' ? val : 0);
                          }}
                      />
                      <p style={{ 
                        marginRight:'1.25%', 
                        // display: 'inline-flex', 
                        alignItems: 'center',
                        // paddingInlineStart:5
                    }}>X</p>
                      <TextField
                        label="ค่าแรง x 1.5 เท่า"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        value={(costPerHr*1.5).toFixed(2)}
                        // onChange={(e) => {
                        //   const val = validateNumberInput(e.target.value);
                        //   setAttendanceFee(val !== '' ? parseFloat(val) : 0);
                        //   }}
                      />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <div style={{width:'10%',marginRight:'1.25%',}}>
                  <p style={{ 
                      margin: 0, 
                      display: 'inline-flex', 
                      alignItems: 'flex-end',
                      paddingInlineStart:5
                  }}>
                    2 เท่า :
                    {/* <Tooltip title="เงินเดือนของพนักงาน">
                        <InfoIcon style={{ cursor: 'pointer', marginLeft: 5 }} />
                    </Tooltip> */}
                  </p></div>
                  <TextField
                    //label="เงินเดือน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                    disabled
                    value={otResult2}
                    // onChange={handleMissingChange}
                  />
                  <TextField
                        label="จำนวนชั่วโมง"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } ,readOnly: !editable}}
                        value={otHr2}
                        onChange={(e) => {
                          const val = validateNumberInput(e.target.value);
                          setOtHr2(val !== '' ? val : 0);
                          }}
                      />
                      <p style={{ 
                        marginRight:'1.25%', 
                        // display: 'inline-flex', 
                        alignItems: 'center',
                        // paddingInlineStart:5
                    }}>X</p>
                      <TextField
                        label="ค่าแรง x 2 เท่า"
                        className="form-field"
                        variant="filled"
                        style={{width:'19%',marginRight:'1.25%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        value={(costPerHr*2).toFixed(2)}
                        // onChange={(e) => {
                        //   const val = validateNumberInput(e.target.value);
                        //   setAttendanceFee(val !== '' ? parseFloat(val) : 0);
                        //   }}
                      />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                <TextField
                        label="รวมโอที"
                        className="form-field"
                        variant="filled"
                        style={{width:'100%'}}
                        InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000', backgroundColor: '#fff' } }}
                        value={allOT}
                        onChange={handleAllOT}
                        onFocus={() => {
                          setAllOT(String(allOT).replace(/,/g, ''));
                        }}
                        onBlur={() => handleBlur2Decimals(allOT, setAllOT)}
                      />
                </div>

                {/* สรุปรายการเงินเดือน */}
                <p style={{fontSize:24,backgroundColor:'#D3D3D3',width:'100%',
                            alignSelf:'center',borderLeft: '5px solid black',borderRadius:5,paddingLeft:5}}>สรุปรายการ</p>
                <p style={{fontSize:24}}>รายได้</p>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <TextField
                    label="เงินเดือน"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={salary}
                    // onChange={(e) => {
                    //   const val = validateNumberInput(e.target.value);
                    //   setSalary(val !== '' ? val : 0);
                    //   }}
                    // onBlur={() => handleBlur2Decimals(salary, setSalary)}
                  />
                  <TextField
                    label="ค่าจ้าง"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={sub} //sub
                    //onChange={handleSub}
                    onFocus={() => {
                      setSub(String(sub || "0").replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(sub, setSub)}
                  />
                  <TextField
                    label="ค่าล่วงเวลา"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={allOT}
                    onChange={handleAllOT}
                    onFocus={() => {
                      setAllOT(String(allOT).replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(allOT, setAllOT)}
                  />
                  <TextField
                    label="ค่าเบี้ยเลี้ยง"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={allowance}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setAllowance(val !== '' ? val : 0);
                      }}
                      onFocus={() => {
                        setAllowance(String(allowance).replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(allowance, setAllowance)}
                  >
                  </TextField>
                  <TextField
                    label="ค่าพาหนะ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={venhicle}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setVenhicle(val !== '' ? val : 0);
                      }}
                      onFocus={() => {
                        setVenhicle(String(venhicle).replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(venhicle, setVenhicle)}
                  />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px', }}>
                  <TextField
                    label="ค่าสวัสดิการ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={welth}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setWelth(val !== '' ? val : 0);
                      }}
                    onFocus={() => {
                      setWelth(String(welth).replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(welth, setWelth)}
                  />
                   <TextField
                    label="เงินโบนัส"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={bonus}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setBonus(val !== '' ? val : 0);
                      }}
                      onFocus={() => {
                        setBonus(String(bonus).replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(bonus, setBonus)}
                      />
                  <TextField
                    label="เงินโบนัส/พิเศษ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={costL}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setCostL(val !== '' ? val : 0);
                      }}
                      onFocus={() => {
                        setCostL(String(costL).replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(costL, setCostL)}
                  />
                </div>
                <div className="form-row" style={{ display: 'flex'}}>
                  <p style={{fontSize:22}}>รวมรายได้
                  <Tooltip title={<p style={{fontSize:14 }}>รวมเวลาทำงาน + ค่าล่วงเวลา + ค่าเบี้ยเลี้ยง + ค่าพาหนะ + ค่าสวัสดิการ + เงินโบนัส + เงินโบนัส/พิเศษ</p>}>
                    <IconButton size="small">  
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  </p>
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}} >
                <TextField
                    variant="filled"
                    className="form-field"
                    style={{width:'100%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ readOnly: true,style: { color: '#000', backgroundColor: '#fff' } }}
                    value={deposit}
                    // onChange={(e) => setDeposit(e.target.value)}
                  />
                </div>
                
                <div className="form-row" style={{ display: 'flex',}} >
                  <p style={{fontSize:22}}>รายการหัก</p>
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}} >
                <TextField
                    label="เงินประกันสังคม"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{ 
                      style: { color: '#000', backgroundColor: '#fff' },
                      readOnly: !editable,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={<p style={{fontSize:14 }}>{`(เงินเดือน + ค่าจ้าง) x ประกันสังคม(%) = (${formatCurrency(parseNumber(salary))} + ${formatCurrency(parseNumber(sub))}) x ${parseNumber(sociteIns)*100}% = ${formatCurrency((parseNumber(salary)+parseNumber(sub))*parseNumber(sociteIns))}`}</p>}>
                            <IconButton size="small">
                              <InfoIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    value={insurance}
                    onChange={handleIns}
                    onFocus={() => {
                      // ลบ comma เมื่อ field ได้รับ focus เพื่อให้ผู้ใช้แก้ไขได้สะดวก
                      setInsurance(insurance.replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(insurance, setInsurance)}
                  />
                  <TextField
                    label="เงินหัก ณ ที่จ่าย"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={withholding}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setWithholding(val !== '' ? val : 0);
                      }}
                    onFocus={() => {
                      setWithholding(String(withholding).replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(withholding, setWithholding)}
                  />
                  <TextField
                    
                    label="เงินเบิกล่วงหน้า"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={withdraw}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setWithdraw(val !== '' ? val : 0);
                      }}
                      onFocus={() => {
                        setWithdraw(String(withdraw).replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(withdraw, setWithdraw)}
                  />
                  <TextField
                    label="เงินกู้ยืมสวัสดิการ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={borrow}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setBorrow(val !== '' ? val : 0);
                      }}
                    onFocus={() => {
                      setBorrow(String(borrow).replace(/,/g, ''));
                    }}
                    onBlur={() => handleBlur2Decimals(borrow, setBorrow)}
                  >
                  </TextField>
                  <TextField
                    label="กองทุนสำรองเลี้ยงชีพ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={liveFund}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setLiveFund(val !== '' ? val : 0);
                      }}
                      onFocus={() => {
                        setLiveFund(String(liveFund).replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(liveFund, setLiveFund)}
                  />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}} >
                  <TextField
                    label="หักภาษีเงินได้"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%',marginRight:'1.25%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={tax}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setTax(val !== '' ? val : 0);
                      }}
                      onFocus={() => {
                        setTax(String(tax).replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(tax, setTax)}
                  />
                  <TextField
                    label="เงินหักอื่นๆ"
                    className="form-field"
                    variant="filled"
                    style={{width:'19%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={other}
                    onChange={(e) => {
                      const val = validateNumberInput(e.target.value);
                      setOther(val !== '' ? val : 0);
                      }}
                      onFocus={() => {
                        setOther(String(other).replace(/,/g, ''));
                      }}
                    onBlur={() => handleBlur2Decimals(other, setOther)}
                  />
                </div>
                <div className="form-row" style={{ display: 'flex'}}>
                  <p style={{fontSize:22}}>รวมรายการหัก</p>
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}} >
                  <TextField
                    variant="filled"
                    className="form-field"
                    style={{width:'100%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ readOnly: true,style: { color: '#000', backgroundColor: '#fff' } }}
                    value={allWithdraw}
                    //onChange={(e) => setAllWithdraw(e.target.value)}
                  />
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}} >
                  <p style={{fontSize:22}}>รวมรายได้สะสม
                  <Tooltip title={<p style={{fontSize:14 }}>{oldDeposit} + {deposit} = {allDeposit}</p>}>
                    <IconButton size="small">  
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  </p>
                  <TextField
                    variant="filled"
                    className="form-field"
                    style={{width:'100%'}}
                    InputLabelProps={{ shrink: true,style: { color: '#000' } }}
                    InputProps={{ style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={allDeposit}
                    onChange={(e) => setAllDeposit(validateNumberInput(e.target.value))}
                  />
                </div>
                <div className="form-row" style={{ display: 'flex'}}>
                  <p style={{fontSize:22}}>รวมเงินประกันสังคมสะสม
                  <Tooltip title={<p style={{fontSize:14 }}>{formatCurrency(oldInsurance || 0)} + {formatCurrency(insurance || 0)} = {formatCurrency(allInsurance || 0)}</p>}>
                    <IconButton size="small">  
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  </p>
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}} >
                  <TextField
                    variant="filled"
                    className="form-field"
                    style={{width:'100%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{style: { color: '#000', backgroundColor: '#fff' },readOnly: !editable }}
                    value={allInsurance}
                    onChange={(e) => setAllInsurance(Number(e.target.value))}
                  />
                </div>
                <div className="form-row" style={{ display: 'flex'}}>
                  <p style={{fontSize:22}}>สรุปรวมทั้งหมด</p>
                </div>
                <div className="form-row" style={{ display: 'flex', marginBottom: '20px'}} >
                <TextField
                    variant="filled"
                    className="form-field"
                    style={{width:'100%'}}
                    InputLabelProps={{shrink: true, style: { color: '#000' } }}
                    InputProps={{style: { color: '#000', backgroundColor: '#fff' },readOnly: true }}
                    value={amount}
                    //onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                {confirm == false && <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#D3D3D3',marginRight:10}} onClick={onSave}>บันทึกข้อมูล</button>}
                <button style={{width:100,height:50,borderRadius:5,backgroundColor:'#343434',color:'#FFFFFF'}} onClick={()=>navigate('/salary_list',{state:{uid:id}})}>ย้อนกลับ</button>
                {status == 'edit' && <button style={{ width: 100, height: 50, borderRadius: 5, backgroundColor: '#026440', color: '#FFFFFF', marginLeft: 10 }} onClick={exportToPDF}>Export PDF</button>}
              </div>

            </div>
          </div>
        </main>
        {isLoading && (
          <LoadingBackdrop />
        )}
      </div>
      
    
  );
}

export default SalaryCal;

  