import './App.css';
import React, { useState, useContext,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import firestore from './Firebase/Firestore';
import auth from './Firebase/Auth';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from './icon/PAM_HR_logo.png';


const GetApk = () => {
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState('');
  const [linkApp,setLinkApp] = useState('');
  const [versionName,setVersionName] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //const { setCompanyId: setContextCompanyId } = useContext(UserContext);

  const onVerify = async (e) => {
    e.preventDefault();
    const trimmedCompanyId = companyId.replace(/\s+/g, '');
    const success = (found) => {
      if (found) {
        // setContextCompanyId(companyId);
        // localStorage.setItem('companyId', companyId); 

        // Direct download link
        const downloadUrl = linkApp;

        // Create a temporary anchor element and trigger the download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `PAM_HR_v${versionName}.apk`; // Optional: specify the file name
        console.log(`PAM_HR_v${versionName}.apk`)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // navigate('/login');
      } else {
        alert('Company not found!');
      }
    };

    const unsuccess = () => {
      alert('Company not found!');
    };

    firestore.checkCompany(trimmedCompanyId, success, unsuccess);
  };

  const register = () => {
    navigate('/register')
  };

//   useEffect(() => {
//     auth.checksignin((user) => {
//       if (user) {
//         firestore.getAccount(companyId,user.id,(data)=>console.log(data),(e)=>console.log(e))
//         navigate('/home');
//       }
//     });
//   }, [navigate]);

useEffect(() => {
  const fetchData = async () => {
    const appConfig = await firestore.getLatestVersionOnce(); // รอตัว Promise ให้เสร็จ
    if (appConfig) {
      //console.log(appConfig.versionName);
      setLinkApp(appConfig.downloadUrl);
      setVersionName(appConfig.versionName);
    } else {
      console.log('ไม่พบข้อมูล version ใน Firestore');
    }
  };

  fetchData();
}, []);

  return (
    <div className="App">
      <nav className="navbar">
        <div className="navbar-left">
          <div className="navbarlogo-container"  style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}>
            <img src={logo} alt="PAM Logo" className="navbarlogo" />
          </div>
          {/* <button
            className={`nav-button plan-button ${activeButton === 'plan' ? '' : ''}`}
            onClick={scrollToPlan}
          >
            Plans
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
            onClick={toGetApp}
          >
            Applications
          </button>
          <button
            className={`nav-button plan-button ${activeButton === 'app' ? '' : ''}`}
            onClick={toAdmin}
          >
            For Admin
          </button>*/}
        </div>
        <div className="button-container">
          <button
            className="nav-button"
            style={{ backgroundColor: '#D9D9D9' }}
            onClick={() => navigate('/login_company')}
          >
            Login
          </button>
          <button
            className="nav-button"
            style={{ backgroundColor: '#343434', color: '#FFF' }}
            onClick={()=> navigate('/register')}
          >
            Register
          </button> 
        </div>
        <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className="navbarlogo-container" style={{width:'100%',justifyContent:'space-between'}} >
            <img src={logo} alt="PAM Logo" className="navbarlogo" onClick={() => navigate('/')} />
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
        {isMenuOpen && (
          <div className="mobile-menu">
            <button
              className="nav-button"
              style={{ backgroundColor: '#D9D9D9',marginBottom:5 }}
              onClick={() => navigate('/login_company')}
            >
              Login
            </button>
            <button
              className="nav-button"
              style={{ backgroundColor: '#343434', color: '#FFF' }}
              onClick={()=> navigate('/register')}
            >
              Register
            </button>
          </div>
        )}
      </nav>
      <header className="App-header">
        <div className='Main'>
          <img src={require('./icon/PAM_HR_logo.png')} width={200} height={200} alt="Logo" />
          <form onSubmit={onVerify}>
            <input
              style={{ marginTop: 50, textAlign: companyId ? 'left' : 'center'}}
              className="input-field"
              placeholder="Company ID"
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
              required
              autoComplete="off"
            />
            {/* <div style={{ textAlign: 'right',marginRight:8}}>
            <p className="register">
              ถ้าคุณยังไม่มีบัญชี{' '}
              <span className="register-link" onClick={register}>ลงทะเบียน</span>
            </p>
            </div> */}
            <button type="submit" className="login-button" style={{marginTop:30}}>Download</button>
          </form>
        </div>
        <div style={{fontSize:20,lineHeight: 1,marginTop:20}}>
            <p>© 2024 Miscible Technology Co.,Ltd. - all rights reserved</p>
        </div>
      </header>
    </div>
  );
};

export default GetApk;
