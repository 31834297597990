//RegistDemo.js
import './App.css';
import React, { useState,useEffect,useContext } from 'react';
import { UserContext } from './UserContext';
//import { auth } from './Firebase/Config';
import { useNavigate,useParams  } from 'react-router-dom';
import { IoEye, IoEyeOff } from 'react-icons/io5'; 
import auth from './Firebase/Auth';
// import firestore from './Firebase/Firestore';
import { hashPassword } from './hashPassword';
import logo from './icon/PAM_HR_logo.png';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import adminFirestore from './Firebase/AdminFirestore';
import adminAuth from './Firebase/AdminAuth';
import Swal from 'sweetalert2';
import 'animate.css';
import LoadingBackdrop from './LoadingBackdrop';

const RegistDemo = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [level, setLevel] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 

  const [activeButton, setActiveButton] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const EMAILJS_SERVICE_ID = 'service_5egw6b8';
  const EMAILJS_TEMPLATE_ID = 'template_wrkesla';
  const EMAILJS_USER_ID = 'sT8ZIWDhLD1_zjzvb';

  const [verificationCode, setVerificationCode] = useState(''); // โค้ดที่ระบบสุ่ม
  const [userInputCode, setUserInputCode] = useState('');        // โค้ดที่ผู้ใช้กรอก
  const [isEmailVerified, setIsEmailVerified] = useState(false); // ผลลัพธ์การ verify?
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);   // ระหว่างส่งโค้ด Verify
  const [verifyCooldown, setVerifyCooldown] = useState(0);         // เคานต์ดาวน์กี่วิ
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id: docId } = useParams();

   // ----- State -----
   const [requestDemoItem, setRequestDemoItem] = useState(null); // เก็บข้อมูล requestDemo ที่ load มา
   const [loadingData, setLoadingData] = useState(true);         // โหลดข้อมูลอยู่?
 
   // PIN Verification
   const [userPin, setUserPin] = useState('');
   const [pinVerified, setPinVerified] = useState(false);
   const [verifyError, setVerifyError] = useState('');

   const {setCurrentUser,setUserData,userData,setLoading } = useContext(UserContext);
 

  const levels = [
      // { label: 'Administrator', value: 0 },
      { label: 'Super Admin', value: 1 },
      { label: 'Admin', value: 2 },
      { label: 'User', value: 3 }
  ];

  const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
  };

  // ตรวจสอบความสมบูรณ์ของฟอร์ม
  const isFormFilled = () => {
    return (
      companyId.trim() !== '' &&
      companyName.trim() !== '' &&
      email.trim() !== '' &&
      name.trim() !== '' &&
      position.trim() !== '' &&
      password.trim() !== '' &&
      confirmPassword.trim() !== ''
    );
  };

  useEffect(() => {
    let timer;
    if (verifyCooldown > 0) {
      timer = setInterval(() => {
        setVerifyCooldown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [verifyCooldown]);

  useEffect(() => {
    const loadData = async () => {
      try {
        // สมมติมีฟังก์ชัน adminFirestore.getRequestDemoById
        const docData = await adminFirestore.getRequestDemoById(docId);
        if (!docData) {
          setErrorMessage("Invalid request ID");
          setLoadingData(false);
          return;
        }
        if (docData.used) {
          setErrorMessage("This demo request is already used. You cannot register again.");
          setLoadingData(false);
          return;
        }
        // ถ้าเจอ doc => setState
        setRequestDemoItem(docData);

        // เตรียม default ให้ form
        setCompanyId(docData.companyId || '');
        setCompanyName(docData.companyName || '');
        setEmail(docData.email || '');
        setName(docData.name || '');
        setPosition(docData.position || '');
      } catch (error) {
        console.error("Error loading requestDemo =>", error);
        setErrorMessage("Cannot load request data");
      } finally {
        setLoadingData(false);
      }
    };
    loadData();
  }, [docId]);

  const handleVerifyPin = async () => {
    setVerifyError('');
    setIsLoading(true)
    if (!userPin) {
      setIsLoading(false)
      setVerifyError("Please enter PIN code.");
      return;
    }
    if (!requestDemoItem) {
      setIsLoading(false)
      setVerifyError("No request data loaded.");
      return;
    }

    try {
      // สมมติ Firestore เก็บ pin ไว้เป็น pin_hash (ถ้าต้อง hash)
      // ถ้า Firestore เก็บ pin plain => เปลี่ยน logic เทียบ string ได้เลย
      // ตัวอย่าง: สมมติ docData.pin_hash = "xxx"
      const hashed = await hashPassword(userPin);
      if (hashed !== requestDemoItem.pin) {
        setIsLoading(false)
        setVerifyError("Invalid PIN code.");
        return;
      }

      // ถ้าเก็บ pin เป็น plain => 
      // if (userPin !== requestDemoItem.pin) {
      //   setVerifyError("Invalid PIN code.");
      //   return;
      // }

      // ถ้าเทียบผ่าน => Verified
      setPinVerified(true);
      setIsLoading(false)
      //alert("PIN verified. Please fill the form below.");
      Swal.fire({
        // title: 'Warning',
        // text: 'Cannot delete an Administrator account.',
        title: 'เรียบร้อย',
        text: `รหัสยืนยันแล้ว กรอกข้อมูลให้ครบ`,
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false)
      setVerifyError("Error verifying PIN");
    }
  };

  const logOutSuccess = () => {
    setCurrentUser(null);  // Clear user from context
    setCompanyId(null);    // Clear companyId from context
    setUserData(null);     // Clear userData from context
    localStorage.removeItem('companyId');  // Clear companyId from localStorage
    localStorage.removeItem('userData'); // Clear companyId from localStorage
    localStorage.removeItem('sidebarOpen');
    setLoading(false)
  };

  const logout = () => {
    //e.preventDefault();
    auth.signOut(logOutSuccess);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    if (!pinVerified) {
      setIsLoading(false)
      setErrorMessage("Please verify PIN first.");
      return;
    }
    if (!isFormFilled()) {
      setIsLoading(false)
      setErrorMessage("Please fill all required fields.");
      return;
    }
    if (password !== confirmPassword) {
      setIsLoading(false)
      setErrorMessage("Passwords do not match.");
      return;
    }
    // ตรวจสอบว่า companyId ตรงกับในเอกสารหรือไม่
    if (requestDemoItem && companyId !== requestDemoItem.companyId) {
      setIsLoading(false)
      setErrorMessage("Company ID does not match with approved data.");
      return;
    }
    // สมมติ status===true ต้องเช็คว่าจริงไหม
    if (!requestDemoItem.status) {
      setIsLoading(false)
      setErrorMessage("This request is not approved yet.");
      return;
    }

    try {
      setIsRegisterLoading(true);
      setErrorMessage('');

      // 1) สร้างเอกสารใน companies/{companyId}
      const companyObj = {
        companyName:companyName,
        companyId:companyId,
        aboutUs: " ",
        help_1: " ",
        help_2: " ",
        help_3: " ",
        logo: "https://firebasestorage.googleapis.com/v0/b/pamproject-a57c5.appspot.com/o/PAM_HR_logo.png?alt=media&token=00d6bf28-0af6-49f9-bdd4-2a999fd19382",
        plan: "demo"  ,
        trialStart: new Date(),  // วันที่เริ่มต้นทดลองใช้งาน
        status: "active"       
      };
      await adminFirestore.createCompanyDoc(companyId, companyObj);

      // 2) สร้าง Account ใน Firebase Auth
      const user = await adminAuth.createAccount(
        email,
        password,
        (u) => console.log("Create Account Success =>", u.uid),
        (e) => console.error("Create Account Error =>", e)
      );
      const userId = user.uid;

      // 3) สร้างเอกสารใน companies/{companyId}/account_cms/{userId}
      const hashedPassword = await hashPassword(password);
      const accountObj = {
        email: email,
        level: 1,        // สมมติเป็น level=1 
        name: name,
        password: hashedPassword,
        position: position
      };
      await adminFirestore.addAccount(companyId, userId, accountObj);

      // (ถ้าต้องการ updateRequestDemo ว่า "used" หรือ "registered" ก็ทำ)
      // await adminFirestore.updateRequestDemo(docId, { ... });

      await adminFirestore.updateRequestDemo(docId, {
        used: true, // หรือ registered: true
        usedAt: new Date(), // ถ้าต้องการเก็บวันที่ที่ลงทะเบียน
        trialStart: new Date(),  // วันที่เริ่มต้นทดลองใช้งาน
        status: true       
      });
      setIsLoading(false)
      //alert("Register Success!");
      Swal.fire({
        // title: 'Warning',
        // text: 'Cannot delete an Administrator account.',
        title: 'สำเร็จ',
        text: `ลงทะเบียนสำเร็จ`,
        icon: 'success',
        customClass: {
          popup: 'my-swal-size'
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(() => {
        logout()
        navigate('/login_company');
      });
      // ไปหน้า login หรืออะไรก็ว่าไป
    } catch (error) {
      console.error(error);
      setIsLoading(false)
      setErrorMessage("Register failed: " + error.message);
    } finally {
      setIsLoading(false)
      setIsRegisterLoading(false);
    }
  };

  if (loadingData) {
    return <div style={{ textAlign:'center', marginTop:50 }}>Loading data...</div>;
  }

  return (
  <div className="App">
      <nav className="navbar">
      <div className="navbar-left">
        <div className="navbarlogo-container"  style={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}>
          <img src={logo} alt="PAM Logo" className="navbarlogo" />
        </div>
      </div>
      <div className="button-container">
        {/* <button
          className="nav-button"
          style={{ backgroundColor: '#D9D9D9' }}
          onClick={() => navigate('/login_company')}
        >
          Login
        </button> */}
      </div>
      <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div className="navbarlogo-container" style={{width:'100%',justifyContent:'space-between'}}>
          <img src={logo} alt="PAM Logo" className="navbarlogo" />
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
      {isMenuOpen && (
        <div className="mobile-menu">
          {/* <button
            className="nav-button"
            style={{ backgroundColor: '#D9D9D9',marginBottom:5 }}
            onClick={() => navigate('/login_company')}
          >
            Login
          </button> */}
        </div>
      )}
    </nav>
      <header className="App-header">
          <div className='Main'>
          <h2 style={{color:'black',marginTop:-30,fontWeight:'bold'}}>Sign Up (Demo)</h2>

          {errorMessage && (
            <p style={{ color: 'red', fontSize:20, marginBottom:10 }}>{errorMessage}</p>
          )}

          {/* Verify Code */}
          {!pinVerified && (
            <>
              <div style={{ position:'relative', marginBottom:'10px' }}>
                <input
                  type="text"
                  className="input-field"
                  placeholder="PIN Code"
                  value={userPin}
                  onChange={(e) => setUserPin(e.target.value)}
                  required
                  style={{ paddingRight:'5rem' }}
                />
                <button
                  type="button"
                  onClick={handleVerifyPin}
                  style={{
                    position:'absolute', top:'50%', right:'10px',
                    transform:'translateY(-50%)',
                    background:'#666', color:'#FFF',
                    border:'none', borderRadius:'4px',
                    cursor:'pointer',height:30,justifyContent:'center',alignItems:'center'
                  }}
                >
                  Verify
                </button>
              </div>
              {verifyError && <p style={{ color:'red', fontSize:16 }}>{verifyError}</p>}
            </>
          )}

          {/* Company ID */}
          {pinVerified && (
            <>
              {/* Company ID */}
              <input
                type="text"
                className="input-field"
                placeholder="Company ID"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
                required
                style={{ marginBottom: '10px' }}
              />

              {/* Email */}
              <input
                type="email"
                className="input-field"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ marginBottom: '10px' }}
              />

              {/* Password */}
              <div style={{ position: 'relative', marginBottom:'10px' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="input-field"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ paddingRight: '2.5rem' }}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  {showPassword ? <IoEyeOff size={24} /> : <IoEye size={24} />}
                </button>
              </div>

              {/* Confirm Password */}
              <div style={{ position: 'relative', marginBottom:'10px' }}>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="input-field"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  style={{ paddingRight: '2.5rem' }}
                />
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  {showConfirmPassword ? <IoEyeOff size={24} /> : <IoEye size={24} />}
                </button>
              </div>

              <button
                className="login-button"
                onClick={handleRegister}
                disabled={isRegisterLoading}
              >
                {isRegisterLoading ? 'Please wait...' : 'Register'}
              </button>
            </>
          )}
          </div>
          <div style={{fontSize:20,lineHeight: 1,marginTop:20}}>
              {/* <p>By giving information, you agree to our Terms &amp; Conditions</p> */}
              <p>© 2024 Miscible Technology Co.,Ltd. - all rights reserved</p>
          </div>
      </header>

      {isLoading && (
        <LoadingBackdrop />
      )}
  </div>
  );
};

export default RegistDemo;
